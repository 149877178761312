<style lang="scss">
    .CreditRules {

    }
</style>
<template>
    <div class="CreditRules">
        <div class="block o-p o-plr-s c-line-10">
            <div v-if="main._item" class="o-plr" v-html="main._item.content"></div>
        </div>
    </div>
</template>

<script>
import StoreMix from '@lottery.2.0/mixin/store.js'
export default {
    name: 'CreditRules',
    mixins : [StoreMix],
    data() {
        return {
            store : 'game/rule',
            Filter :{
                type : 2,
            },
        }
    },
    methods: {
        init(){
            this.Item(31).then(res=>{
                // console.log(res)
            })
        },
    },
    activated(){
        this.init()
    },
}
</script>
