<style lang="scss">
    .CreditSubReportDetails {
        .table {
            td,th {
                &:first-child {padding-left:8px;}
                &:last-child {padding-right:8px;}
            }
            td {
                border:.03rem solid #999;
            }
            tbody {
                td,th {
                    padding-top:8px; padding-bottom:8px;
                }  
            }
        }
    }
</style>
<template>
    <div class="CreditSubReportDetails">
        <div class="block o-pb-s" style="min-width:950px;">
            <table class="table" border="0" cellspacing="1" cellpadding="0">
                <thead>
                    <tr style="text-align:left;" class="hbg-2">
                        <td style="text-align:left;">注单号</td>
                        <td style="text-align:left;">日期</td>                        
                        <td style="text-align:left;">期数</td>
                        <td style="text-align:left;" v-if="Opt.isTeam">会员</td>
                        <td style="text-align:left;">盘类 / 退水%</td>
                        <td style="text-align:left;">游戏</td>
                        <td style="text-align:left;">玩法</td>
                        <td style="text-align:left;">投注内容</td>
                        <td style="text-align:left;">下注时间</td>
                        <td style="text-align:left;">赔率</td>
                        <td v-if="Filter.transfer >= 1">走飞类型</td>
                        <td style="text-align:left;">
                            <span v-if="Filter.transfer == 0">下注金额</span>
                            <span v-if="Filter.transfer == 1">走飞金额</span>
                            <span v-if="Filter.transfer == 2">收飞金额</span>
                        </td>
                        <td style="text-align:left;">派彩</td>
                        <td style="text-align:left;">退水金额</td>
                        <!-- <td style="text-align:left;">结果</td> -->
                    </tr>
                </thead>
                <tbody style="text-align:left;" class="zebra">
                    <tr v-for="(item,index) in main._list">
                        <td style="text-align:left;">{{ item.sequence }}</td>
                        <td style="text-align:left;">{{ item.lottery_date }}</td>                        
                        <td style="text-align:left;">{{ item.lottery_number }}</td>
                        <td style="text-align:left;" v-if="Opt.isTeam">{{ item.username }}</td>
                        <td style="text-align:left;">{{ item.side }} / {{ item.backwater_percent }}</td>
                        <td style="text-align:left;">{{ item.games }}</td>
                        <td style="text-align:left;">{{ item.plays }}</td>
                        <td style="text-align:left;">{{ CheckContextLength(item.code,48) }}</td>
                        <td style="text-align:left;">{{ item.create_at }}</td>
                        <td style="text-align:left;">{{ item.odds }}</td>

                        <td v-if="Filter.transfer >= 1">
                            <span v-if="item.transfer_t == 1">自动</span>
                            <span v-if="item.transfer_t == 2">手动</span>
                        </td>
                        <td style="text-align:left;">{{ item.amount }}</td>
                        <td style="text-align:left;">{{ item.bonus }}</td>                        
                        <td style="text-align:left;">{{ item.backwater }}</td>
                        <!-- <td style="text-align:left;" :class="{'mark':item.result<0}">{{ item.result }}</td>                         -->
                    </tr>
                </tbody>
                <tfoot>
                    <tr v-if="main._empty">
                        <td class="empty" colspan="100">暂无数据</td>
                    </tr>
                    <tr class="subtotal fbg-1" v-if="!main._empty">
                        <td style="text-align:left;" :colspan="Opt.isTeam ? 9 : 8">
                            <p class="c-text-r o-pr">页面小计 (共 {{ main._list.length }} 笔)</p>
                        </td>
                        <td v-if="Filter.transfer >= 1">-</td>
                        <td style="text-align:left;">{{ CalcuRow('amount') }}</td>
                        <td style="text-align:left;">{{ CalcuRow('bonus') }}</td>
                        <td style="text-align:left;">{{ CalcuRow('backwater') }}</td>                        
                        <td style="text-align:left;" :class="{'mark':CalcuRow('result')<0}">{{ CalcuRow('result') }}</td>
                    </tr>
                    <tr class="total fbg-4" v-if="!main._empty">
                        <td style="text-align:left;" :colspan="Opt.isTeam ? 9 : 8">
                            <p class="c-text-r o-pr">合计 (共 {{ summary.bets_num }} 笔)</p>
                        </td>
                        <td v-if="Filter.transfer >= 1">-</td>
                        <td style="text-align:left;">{{ Price(summary.amount,{separate:false}) }}</td>
                        <td style="text-align:left;"></td>
                        <td style="text-align:left;">{{ Price(summary.backwater,{separate:false}) }}</td>
                        <td style="text-align:left;" :class="{'mark':summary.result<0}">{{ Price(summary.result,{separate:false}) }}</td>                        
                    </tr>
                </tfoot>
            </table>
            <Pagination v-if="main._total" class="o-mtb" v-model="Page" @turning="_Get" :total="main._total" :count="main._count"></Pagination>
        </div>
    </div>
</template>

<script>
import { Indicator } from 'mint-ui'
import StoreMix from '@lottery.mobile/mixin/store.js'
export default {
    name: 'CreditSubReportDetails',
    mixins : [StoreMix],
    data() {
        return {
            store : 'game/order',
            Filter : {
                per_page : 20,
            },
            summary : {},
        }
    },
    watch:{
        'main._loading'(val){
            if(val){
                Indicator.open()
            }else{
                Indicator.close()
            }
        },
    },
    methods: {
        init() {
            this.DataReset('summary')
            this.DataReset('Filter')
            for(let key in this.$route.params){
                this.$set(this.Filter,key,this.$route.params[key])
            }
            this.Cm('myReport/_MYREPORT_CLEAN',{base:'order',key:'_list'})
            this.Get(1)
            this.GetSum()
        },
        _Get(page){
            this.Get(page)
            this.GetSum()
        },
        GetSum(){
            this.Get('ordersum').then(res=>{
                this.$set(this,'summary',res.data)
            })
        },
    },
    beforeRouteEnter(to,from,next){
        next(vm=>{
            vm.init()
        })
    },
}
</script>