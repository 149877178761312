<style lang="scss">
.limit-login {
    position:absolute; top:0; left:0; right:0; bottom:0; position:fixed; background-color:#fff;
}
</style>
<template>
    <div id="app" :class="Config.environment">
        <template v-if="!loading">
            <template v-if="init || $route.meta.public">
                <Password v-if="User && User.security_level == 0"></Password>
                <template v-else>
                    <keep-alive>
                        <router-view></router-view>
                    </keep-alive>
                </template>
            </template>
        </template>
<!--         <div class="limit-login" style="z-index:99999;" v-if="Opt.isTeam && !Opt.isDev">
            <div class="c-text-c o-ptb-h">
                <p class="o-p-b">代理请使用电脑版登录</p>
                <div>
                    <Button @click="Logout()">切换账号</Button>
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>

export default {
    data() {
        return {
            init: false,
            loading: true,
        }
    },
    watch: {
        '$route.name'(name) {
            if(!this.$route.meta.public) {
                this.LoginCheck()
            }
        }
    },
    methods:{
        Logout(){
            let token = this.Cache('token')
            return this.$store.dispatch('base/BASE_SIGNOUT',{token}).then(res=>{
                this.Cache('token',null)
                this.Go('login')
                window.location.reload()
                return res
            })
        },
        LoadIcon(){
            if(this.Config.icon){
                var s = document.createElement('script')
                s.type = 'text/javascript'
                s.src = this.Config.icon
                document.body.appendChild(s)
            }
        },
    },
    mounted(){
        this.LoadIcon()
        this.$store.state.base.Event.removeAllListeners()
        this.$store.state.base.Event.on('login', () => {
            Promise.all([
                this.Dp('game/INIT_GAMES'), // 初始化游戏
                this.Dp('base/BASE_OPTIONS'), // 初始化配置
            ]).then(refs => {
                this.init = true
                this.interval = setInterval(()=>{
                    if (this.Token()) {
                        try{
                            this.Dp('base/BASE_USER')
                        }catch(err){}
                    }
                }, 30000)
            })
        })
        this.Login().then(res=>{
            this.loading = false
        })
    }
}
</script>