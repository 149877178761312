
// 样式
import './library/components.js'
import './style/project.scss'
import 'mint-ui/lib/style.css'
import './style/mint.scss'

// 主程序
import Vue from 'vue'
import methods from './library/methods.js'
import './library/mixin.js'
import './library/mixin_2.js'

import config from './library/config.js'
Vue.mixin({
    data(){
        return {
            Config : config,
        }
    },
})

// import './style/element-variables.scss'
// import ElementUI from 'element-ui'
// Vue.use(ElementUI)

//手机版本
import router from './router/index'
import scroll from 'vue-seamless-scroll'
Vue.use(scroll)

import { MessageBox, Toast } from 'mint-ui';
Vue.prototype.MessageBox = MessageBox
Vue.prototype.MToast = Toast
import MintUI from 'mint-ui'
Vue.use(MintUI)


// import NutUI from '@nutui/nutui';
// import '@nutui/nutui/dist/nutui.css';

router.beforeEach((to, from, next) => {
	if(window.AppOptions){
		document.title = `${to.meta.name ? window.AppOptions.title : '404'}`
	}else{
		document.title = `${to.meta.name ? to.meta.name : '404'}`
	}    
    next()
})

// 数据管理
import store from '@lottery.2.0/store/index.js'

// 启动应用
import index from './page/app.vue'
window.Vue = Vue
window.App = new Vue({
    el: '#app',
    store,
    router,
    methods,
    render: h => h(index)
})