<style lang="scss">
    @import "../../../style/config.scss";
    .IndexMy {
        background-repeat:no-repeat; background-size:100%; 
        .board {
                background-color: #fff; //background: linear-gradient(180deg,#ddfcfe,#54b9cf)
        }
        .info {
            background-color:#fff;
            font-size:11px;
            .total {
                height:1rem; line-height:1rem; font-size:.8rem;
            }
        }
        .menu {
            img {width:38px; height:38px;}
        }
        .card {
            border-radius:5px; overflow:hidden; box-shadow:0px 4px 10px rgba(0,0,0,.07); background-color:#fff;
        }
    }
</style>
<template>
    <div class="IndexMy">
        <div class="board o-mb" style="padding:.5rem .6rem;">                
            <div class="l-flex-c">
                <div class="l-flex-1 o-pl-s">
                    <p class="c-text-75">
                        <span>欢迎，<b class="c-text-10">{{ User.username }}</b></span>
<!--                         <span v-if="Opt.isAdmin">管理员</span>
                        <span v-else-if="Opt.isTeam">代理</span>
                        <span v-else>会员</span>
                    </p> -->
                    <div class="l-flex" style="padding-top:.4rem;" v-if="CreditInfo.balance">
                        <span>登陆时间: {{ User.login_time }}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="info o-mb" v-if="!Opt.isTeam">
            <div class="l-flex-c o-ptb c-text-c">
                <div class="l-flex-1">
                    <p class="c-color-g c-text-55 o-pb-s">今天投注</p>
                    <p class="total">{{ main._list.amount }}</p>
                </div>
                <div class="l-flex-1">
                    <p class="c-color-g c-text-55 o-pb-s">今天盈亏</p>
                    <p class="total">{{ main._list.profit_loss }}</p>
                </div>
                <div class="l-flex-1">
                    <p class="c-color-g c-text-55 o-pb-s">今天中奖</p>
                    <p class="total">{{ main._list.bonus }}</p>
                </div>
            </div>
        </div>

        {{/* 记录 */}}
        <div class="o-plr o-mtb" v-if="Opt.isAdmin && !Opt.isSub">
            <div class="card">
                <div class="block menu o-ptb o-plr-s c-text-c">
                    <div class="l-flex">
                        <li v-for="(item,index) in adminMenu" :key="item.name" @click="Go(item.name)" class="l-flex-1">
                            <Icon :name="item.i" size="1.6" :style="'color:'+item.color"></Icon>
                            <p class="c-text-6 o-mt-s">{{ item.title }}</p>
                        </li>
                    </div>
                </div>
            </div>
        </div>
        <div class="o-mb" v-if="Opt.isTeam">
            <mt-cell v-for="(item,index) in teamMenu" :key="index" :class="{'u-bt':index}" :title="item.title" @click.native="Go(item.name)" v-if="!item.disabled" is-link>
                <Icon class="o-mr-s" :name="item.i" size="1" :style="'color:'+item.color" slot="icon"></Icon>
            </mt-cell>
        </div>
        <div class="o-mb">
            <mt-cell v-for="(item,index) in menu" :key="index" :class="{'u-bt':index}" :title="item.title" @click.native="Go(item.name)" v-if="!item.disabled" is-link>
                <Icon class="o-mr-s" :name="item.i" size="1" :style="'color:'+item.color" slot="icon"></Icon>
            </mt-cell>
        </div>
        <div class="o-mt">
            <mt-cell title="退出登录" @click.native="Logout()" is-link>
                <Icon class="o-mr-s" name="qiehuanzuhu" size="1" :style="'color:#3785ea;'" slot="icon"></Icon>
            </mt-cell>
        </div>
        <p class="o-pt-l c-text-c c-text-6" style="color:#bbb;">版本号：{{ Config.edition }}</p>
    </div>
</template>

<script>
import StoreMix from '@lottery.mobile/mixin/store.js'
export default {
    mixins : [StoreMix],
    data() {
        return {
            store : 'credit/today',
            test : 1,
        }
    },
    computed:{
        adminMenu(){
            return [
                // {
                //     i : 'round_crown_fill',
                //     color : '#3aadfa',
                //     title : "开奖",
                //     name : "index/pages/system/open",
                // },
                {
                    i : 'round_menu_fill',
                    color : '#3aadfa',
                    icon : "tdzl",
                    title : "系统设置",
                    name : "index/pages/system/base",
                },
                {
                    i : 'round_pay_fill',
                    color : '#3aadfa',
                    icon : "tdzl",
                    title : "基本退水",
                    name : "index/pages/system/backwater",
                },
                // {
                //     i : 'round_transfer_fill',
                //     color : '#3aadfa',
                //     icon : "tdzl",
                //     title : "基本赔率",
                //     name : "index/pages/system/oddsbase",
                // },
                {
                    i : 'round_light_fill',
                    color : '#3aadfa',
                    icon : "tdzl",
                    title : "自动赔率",
                    name : "index/pages/system/oddsauto",
                },
                // {
                //     i : 'round_text_fill',
                //     color : '#3aadfa',
                //     icon : "tdzl",
                //     title : "注单取消",
                //     name : "index/pages/system/betcancel",
                // },
            ]
        },
        teamMenu(){
            return [
                // {
                //     i : 'copy',
                //     color : '#3785ea',
                //     title : "开奖",
                //     name : "index/pages/system/open",
                //     disabled :!this.Opt.isAdmin,
                // },
                {
                    i : 'friend',
                    color : '#3785ea',
                    title : "帐号管理",
                    name : "index/pages/user",
                    disabled : !this.CheckAuth(true,3),
                },
                {
                    i : 'friendadd',
                    color : '#3785ea',
                    title : "子帐号",
                    name : "index/pages/account",
                    disabled : this.Opt.isSub,
                },
                {
                    i : 'edit',
                    color : '#3785ea',
                    title : "操作日志",
                    name : "index/pages/actlog",
                    disabled : false,
                },
            ]
        },
        menu(){
            return [
                {
                    i : 'form',
                    color : '#ee463f',
                    title : "投注记录",
                    name : "index/pages/query",
                    disabled : this.Opt.isTeam,
                },                
                {
                    i : 'creative',
                    color : '#3aadfa',
                    title : "个人资讯",
                    name : "index/pages/limit",
                },
                {
                    i : 'rank',
                    color : '#f37638',
                    title : "统计报表",
                    name : "index/pages/report",
                    disabled : !this.CheckAuth(this.Opt.isTeam,6),
                },
                {
                    i : 'rank',
                    color : '#3785ea',
                    title : "个人报表",
                    name : "index/pages/reportself",
                    disabled : this.Opt.isTeam,
                },
                {
                    i : 'lock',
                    color : '#ff6600',
                    title : "修改密码",
                    name : "index/pages/password",
                },
                {
                    i : 'community',
                    color : '#34c748',
                    icon : "yhk",
                    title : "系统公告",
                    name : "index/pages/announ",
                },
                {
                    i : 'newshot',
                    color : '#3785ea',
                    title : "游戏规则",
                    name : "index/pages/rules",
                },
            ]
        },
    },
    methods: {
        init(){
            this.Get()
        },
        link(link) {
            this.tabshow = false;
            this.$router.push({ name: 'my' + link });
        },
        CheckAuth(origin,key){
            if(!origin){
                return false
            }
            if(this.Opt.isSub && key){
                if(!this.Opt.isSub[key]){
                     return false
                }
            }
            return true
        },
    },
    activated(){
        this.init()
    }
}
</script>
