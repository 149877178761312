
export default {

    name: '首页',
    list: [
        {
            path: '/',
            name: 'index',
            meta: {
                name: '首页',
                public: true,
            },
            redirect: {
                name: 'index/home'
            },
            component: require('@lottery.mobile/page/index/index.vue').default,
            children: [
                {
                    path: '/home',
                    name: 'index/home',
                    meta: {
                        name: '首页',
                    },
                    component: require('@lottery.mobile/page/index/tabs/home.vue').default,
                },
                {
                    path: '/game',
                    name: 'index/game',
                    meta: {
                        name: '彩票大厅',
                    },
                    component: require('@lottery.mobile/page/index/tabs/game.vue').default,
                },
                {
                    path: '/output',
                    name: 'index/output',
                    meta: {
                        name: '最新开奖',
                    },
                    component: require('@lottery.mobile/page/index/tabs/output.vue').default,
                },
                {
                    path: '/my',
                    name: 'index/my',
                    meta: {
                        name: '个人中心',
                    },
                    component: require('@lottery.mobile/page/index/tabs/my.vue').default,
                },
                //
                {
                    path: '/page/',
                    name: 'index/page',
                    meta: {
                        name: '子页面',
                    },
                    component: require('@lottery.mobile/page/index/pages/index.vue').default,
                    children: [
                        {
                            path: '/rules',
                            name: 'index/pages/rules',
                            meta: {
                                name: '游戏规则',
                            },
                            component: require('@lottery.mobile/page/index/pages/rules.vue').default,
                        },
                        {
                            path: '/announ',
                            name: 'index/pages/announ',
                            meta: {
                                name: '系统公告',
                            },
                            component: require('@lottery.mobile/page/index/pages/announ.vue').default,
                        },
                        {
                            path: '/limit',
                            name: 'index/pages/limit',
                            meta: {
                                name: '彩种限额',
                            },
                            component: require('@lottery.mobile/page/index/pages/limit.vue').default,
                        },
                        {
                            path: '/user',
                            name: 'index/pages/user',
                            meta: {
                                name: '账户管理',
                            },
                            component: require('@lottery.mobile/page/index/pages/user.vue').default,
                        },
                            {
                                path: '/user/edit',
                                name: 'index/pages/sub/user',
                                meta: {
                                    name: '账户管理',
                                },
                                component: require('@lottery.mobile/page/index/pages/sub/user.vue').default,
                            },
                            {
                                path: '/user/level/:id',
                                name: 'index/pages/sub/user/details',
                                meta: {
                                    name: '会员详情',
                                },
                                component: require('@lottery.mobile/page/index/pages/sub/user_details.vue').default,
                            },
                            {
                                path: '/user/level/:id',
                                name: 'index/pages/sub/user/level',
                                meta: {
                                    name: '会员占成',
                                },
                                component: require('@lottery.mobile/page/index/pages/sub/user_level.vue').default,
                            },
                            {
                                path: '/user/limit/:id',
                                name: 'index/pages/sub/user/limit',
                                meta: {
                                    name: '会员占成',
                                },
                                component: require('@lottery.mobile/page/index/pages/sub/user_limit.vue').default,
                            },
                        {
                            path: '/account',
                            name: 'index/pages/account',
                            meta: {
                                name: '子帐号',
                            },
                            component: require('@lottery.mobile/page/index/pages/account.vue').default,
                        },
                            {
                                path: '/account/edit',
                                name: 'index/pages/sub/account',
                                meta: {
                                    name: '子帐号',
                                },
                                component: require('@lottery.mobile/page/index/pages/sub/account.vue').default,
                            },
                        {
                            path: '/actlog',
                            name: 'index/pages/actlog',
                            meta: {
                                name: '操作日志',
                            },
                            component: require('@lottery.mobile/page/index/pages/actlog.vue').default,
                        },
                        {
                            path: '/query',
                            name: 'index/pages/query',
                            meta: {
                                name: '投注记录',
                            },
                            component: require('@lottery.mobile/page/index/pages/query.vue').default,
                        },
                        {
                            path: '/bets/:id',
                            name: 'index/pages/bets',
                            meta: {
                                name: '本期注单',
                            },
                            component: require('@lottery.mobile/page/index/pages/bets.vue').default,
                        },
                        
                            {
                                path: '/query/:id',
                                name: 'index/pages/sub/query',
                                meta: {
                                    name: '注单详情',
                                },
                                component: require('@lottery.mobile/page/index/pages/sub/query.vue').default,
                            },
                        {
                            path: '/report',
                            name: 'index/pages/report',
                            meta: {
                                name: '统计报表',
                            },
                            component: require('@lottery.mobile/page/index/pages/report.vue').default,
                        },
                            {
                                path: '/report/amount',
                                name: 'index/pages/report/amount',
                                meta: {
                                    name: '总账报表',
                                },
                                component: require('@lottery.mobile/page/index/pages/sub/report_amount.vue').default,
                            },
                            {
                                path: '/report/subsidiary',
                                name: 'index/pages/report/subsidiary',
                                meta: {
                                    name: '分类账报表',
                                },
                                component: require('@lottery.mobile/page/index/pages/sub/report_subsidiary.vue').default,
                            },
                            {
                                path: '/report/details',
                                name: 'index/pages/report/details',
                                meta: {
                                    name: '注单详情',
                                },
                                component: require('@lottery.mobile/page/index/pages/sub/report_details.vue').default,
                            },
                        
                        {
                            path: '/reportself',
                            name: 'index/pages/reportself',
                            meta: {
                                name: '个人报表',
                            },
                            component: require('@lottery.mobile/page/index/pages/reportself.vue').default,
                        },
                            {
                                path: '/reportself/bets',
                                name: 'index/pages/sub/reportself',
                                meta: {
                                    name: '投注记录',
                                },
                                component: require('@lottery.mobile/page/index/pages/sub/reportself.vue').default,
                            }, 
                        {
                            path: '/password',
                            name: 'index/pages/password',
                            meta: {
                                name: '修改密码',
                            },
                            component: require('@lottery.mobile/page/index/pages/password.vue').default,
                        },
                        {
                            path: '/output/:model/:id',
                            name: 'index/pages/output',
                            meta: {
                                name: '开奖历史',
                            },
                            component: require('@lottery.mobile/page/index/pages/output.vue').default,
                        },                       
                        {
                            path: '/table/:id',
                            name: 'index/pages/table',
                            meta: {
                                name: '游戏',
                            },
                            component: require('@lottery.mobile/page/index/pages/table.vue').default,
                        },
                        // 管理员
                        {
                            path: '/system/open',
                            name: 'index/pages/system/open',
                            meta: {
                                name: '开奖',
                            },
                            component: require('@lottery.mobile/page/index/pages/system/open.vue').default,
                        },
                            {
                                path: '/system/open/edit',
                                name: 'index/pages/system/sub/open',
                                meta: {
                                    name: '开奖',
                                },
                                component: require('@lottery.mobile/page/index/pages/system/sub/open.vue').default,
                            }, 
                        
                        {
                            path: '/system/base',
                            name: 'index/pages/system/base',
                            meta: {
                                name: '系统设置',
                            },
                            component: require('@lottery.mobile/page/index/pages/system/base.vue').default,
                        },
                        {
                            path: '/system/backwater',
                            name: 'index/pages/system/backwater',
                            meta: {
                                name: '基本退水',
                            },
                            component: require('@lottery.mobile/page/index/pages/system/backwater.vue').default,
                        },
                        {
                            path: '/system/oddsbase',
                            name: 'index/pages/system/oddsbase',
                            meta: {
                                name: '基本赔率',
                            },
                            component: require('@lottery.mobile/page/index/pages/system/oddsbase.vue').default,
                        },
                        {
                            path: '/system/betcancel',
                            name: 'index/pages/system/betcancel',
                            meta: {
                                name: '注单取消',
                            },
                            component: require('@lottery.mobile/page/index/pages/system/betcancel.vue').default,
                        },
                        {
                            path: '/system/oddsauto',
                            name: 'index/pages/system/oddsauto',
                            meta: {
                                name: '自动赔率',
                            },
                            component: require('@lottery.mobile/page/index/pages/system/oddsauto.vue').default,
                        }, 
                        // 操盘走飞
                        {
                            path: '/operate/automatic',
                            name: 'index/pages/operate/automatic',
                            meta: {
                                name: '自动走飞',
                            },
                            component: require('@lottery.mobile/page/index/pages/operate/automatic.vue').default,
                        },
                        {
                            path: '/operate/transfer',
                            name: 'index/pages/operate/transfer',
                            meta: {
                                name: '注单列表',
                            },
                            component: require('@lottery.mobile/page/index/pages/operate/transfer.vue').default,
                        },

                                              
                    ],
                },
            ],
        },
    ]
}