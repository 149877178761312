<style lang="scss">
    .OperateLhc-6 {
        h6.title {
            padding:3px 5px; text-align:center; background-color:#f3655e; color:#fff;
        }
    }
</style>
<template>
    <div class="OperateLhc-6">
        <div class="mobile-tabs u-bb">
            <div v-for="item in tabs.list" class="mobile-tab" :class="{'active': tabs.active === item}" @click="tabs.active = item">{{ item }}</div>
        </div>
        <template v-if="tabs.active === '生肖'">
            <board-fast :client="client" :group="[0]" :step="true" :default-step="0.01" />
            <group :client="client" :list="Table[0]" :length="1" show-total="end" />
        </template>
        <template v-if="tabs.active === '尾数'">
            <board-fast :client="client" :group="[1]" :step="true" :default-step="0.01" />
            <group :client="client" :list="Table[1]" :length="1" show-total="end" />
        </template>
    </div>
</template>

<script>
import CommonMix from '../common.js'
import Group from '../group_mobile.vue'
import BoardFast from '../fast_mobile.vue'
export default {
    name: 'OperateLhc6',
    mixins: [CommonMix],
    data() {
        return {
            tabs: {
                active: '生肖',
                list: ['生肖', '尾数']
            }
        }
    },
    props: {
        client: {}
    },
    computed: {

    },
    methods: {

    },
    components: {
        Group,
        BoardFast
    }
}
</script>
