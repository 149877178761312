<style lang="scss">
    @import "../../../style/config.scss";
    .OperateBoardFast {
        .six-balls {
            text-align:center;
            .six-nav {
                background-color:#ccc; padding:4px 0;
            }
            .balls{
                height:30px; line-height:30px;
                .el-col {
                    height:34px;
                }
                .ball {
                    width:24px; height:24px; margin-top:3px; margin-left:1px; margin-right:1px; line-height:24px; background-color:$color-t; color:#fff; display:inline-block; border-radius:99px; text-align:center;
                    &.red {background-color:#ec4d4d!important;}
                    &.blue {background-color:#5f79ef!important;}
                    &.green {background-color:#4a9a34!important;}
                }
                .icon {
                    color:#eee;
                    &.active {
                        color:#666;
                    }
                    &.disabled {
                        color:#ffe3ce;
                    }
                }
            }
        }
        .el-input-group__append, .el-input-group__prepend {
            padding:0 10px;
        }
        .el-input__inner {
            padding:0 10px;
        }
        .fast-tabs {
            .fast-tab {
                padding:4px 0; background-color:#f5f5f5;
                &.active {
                    background-color:#5b9be0; color:#fff;
                }
            }
        }
        .mobile-fast {
            overflow:hidden;
        }
        .mobile-fast-item {
            width:16.66%; margin:0 0 5px 0; text-align:center; float:left; font-size:.6rem;
            box-sizing:border-box;
            a {
                display:block; border-radius:3px; padding:2px 0; border:.03rem solid #ddd;
            }
        }
    }
</style>
<template>
    <div class="OperateBoardFast o-pt-s">
        <div v-if="client && client.mode" class="board block">
            <div class="l-flex-1">
                <div v-if="OperateAuth.bind == 2 && OperateAuth.manipulation" class="step">
                    <li class="o-plr o-ptb-s c-text-c">
                        <span>赔率变动值：</span>
                        <input v-model.number="FastBoardStep.active" class="o-mr-s mini">
                        <span v-if="step"><button @click="RebateSet(null,'+')">增加</button></span>
                        <span v-if="step"><button @click="RebateSet(null,'-')">减少</button></span>
                    </li>
                </div>
            </div>
            <div v-if="OperateAuth.bind == 3 && OperateAuth.transmit">
                <!-- <div v-if="OperateAuth.admin" class="o-pt-l o-ptb-s c-text-c">
                    注意：顶级代理请使用电脑版走飞！
                </div> -->
                <template>
                    <div class="o-ptb-s o-plr">
                        <span v-if="transmit === 'normal' || transmit === 'integration'" class="o-pr-s">
                            <span>风险值:</span>
                            <input v-model.number="capacity" class="o-mr-s mini" style="width:70px;">
                        </span>
                        <span>
                            <span>走飞金额:</span>
                            <input v-model.number="transmit_amount" class="o-mr-s mini" style="width:60px;">
                        </span>
                        <template v-if="transmit !== 'normal' && transmit !== 'integration'" class="o-ptb-s o-plr">
                            <button size="samll" @click="TransmitSubmit()">走飞</button>
                            <button size="samll" @click="BoardFast(null)">取消</button>
                        </template>
                    </div>
                    <span v-if="!OperateAuth.transfer_status" class="o-pl-s c-color-e c-text-6">(走飞功能已被上级关闭)</span>
                    <div v-else-if="transmit === 'normal' || transmit === 'integration'" class="o-ptb-s o-plr">
                        <button size="samll" @click="TransmitSubmit()">走飞</button>
                        <button size="samll" @click="BoardFast(null)">取消</button>
                    </div>
                </template>
            </div>
            <div v-if="client.mode && !radio && Table && fast" class="o-plr o-pt-s">
                <div class="l-flex-c fast-tabs">
                    <!-- <li class="l-flex-1">快捷选择：</li> -->
                    <li v-for="(row,i) in Table" class="l-flex-1 c-text-c fast-tab u-bb u-bt" :class="{'active': FastBoardTable.active == i}" @click="$set(FastBoardTable,'active',i)">{{ row.title }}</li>
                </div>
                <div v-for="(row,i) in Table" v-if="FastBoardTable.active == i" class="mobile-fast o-mt-s">
                    <li v-for="(item,index) in row.child" class="mobile-fast-item">
                        <a :class="{'active': active[item]}" @click="BoardFast(item)">{{ item }}</a>
                    </li>
                </div>
            </div>
            <template v-if="OperateAuth.bind == 3 && OperateAuth.transmit">
                <div class="step o-mt-s o-pt-s">
                    <div v-if="transmit !== 'normal'" class="o-pb">
                        <div v-if="transmit === 'six-balls'" class="six-balls">
                            <div class="l-flex">
                                <div v-for="(pack,unit) in 5" class="l-flex-1">
                                    <div v-for="(item,index) in 10" v-if="(pack - 1) * 10 + item <= 49" class="balls" @click="HandleBall((pack - 1) * 10 + item)">
                                        <span class="ball c-text-7" :class="CodeColor((pack - 1) * 10 + item)">{{ FillNumber((pack - 1) * 10 + item) }}</span>
                                        <Icon class="icon" :class="{'active': FastBoxDir[(pack - 1) * 10 + item],'disabled': !FastBoxDir[(pack - 1) * 10 + item] && limit && FastBox['sixballs'].length === limit}" name="squarecheckfill" size="1.1" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="transmit === 'six-zodiac'" class="six-balls">
                            <div class="l-flex">
                                <div v-for="(pack,unit) in [['鼠','牛','虎'],['兔','龙','蛇'],['马','羊','猴'],['鸡','狗','猪']]" class="l-flex-1">
                                    <div v-for="(item,index) in pack" class="balls" @click="HandleBall(item)">
                                        <span class="c-text-7" style="padding-right:3px;">{{ item }}</span>
                                        <Icon class="icon" :class="{'active': FastBoxDir[item],'disabled': !FastBoxDir[item] && limit && FastBox['sixballs'].length === limit}" name="squarecheckfill" size="1.1" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import SixMix from '@lottery.admin.2.0/mixin/six.js'
export default {
    name: 'OperateBoardFast',
    mixins: [SixMix],
    data() {
        return {
            odds: null,
            max_odds: undefined,
            backwater_percent: null,
            web_name: undefined,
            transmit_amount: null,
            capacity: -10000,
            active: {
                '全部玩法': false
            },
            FastBox: {
                'sixballs': []
            },
            FastBoardTable: {
                view: false,
                active: 0,
                lhc: {
                    0: {
                        title: '两面',
                        child: ['单', '双', '大', '小', '合单', '合双', '家禽', '野兽', '尾大', '尾小', '尾单', '尾双']
                    },
                    1: {
                        title: '波色',
                        child: ['红波', '绿波', '蓝波', '红单', '红双', '红大', '红小', '绿单', '绿双', '绿大', '绿小', '蓝单', '蓝双', '蓝大', '蓝小']
                    },
                    2: {
                        title: '头尾',
                        child: ['0头', '1头', '2头', '3头', '4头', '0尾', '1尾', '2尾', '3尾', '4尾', '5尾', '6尾', '7尾', '8尾', '9尾']
                    },
                    3: {
                        title: '生肖',
                        child: ['鼠', '牛', '虎', '兔', '龙', '蛇', '马', '羊', '猴', '鸡', '狗', '猪']
                    }
                }
            },
            FastBoardStep: {
                active: 0.5,
                list: [10, 5, 4, 3, 2, 1, 0.5, 0.4, 0.3, 0.2, 0.1, 0.05, 0.03, 0.01, 0.005, 0.001]
            }
        }
    },
    props: {
        client: {},
        list: {
            default: null
        },
        type: {
            default: 'default'
        },
        group: {
            default: null
        },
        transmit: {
            default: 'normal' // none:禁止走飞  handle:手动走飞  normal:风险值走飞  six-balls:六合彩球走飞  six-zodiac:六合彩生肖走飞  six-tail:六合彩尾数走飞  six-level 过关走飞  integration 整合走飞
        },
        step: {
            default: false
        },
        radio: {
            default: false
        },
        limit: {
            default: undefined
        },
        repair: {
            default: 0 // 赔率修正
        },
        double: {
            default: false
        },
        fast: {
            default: false
        },
        defaultStep: {
            default: 0.5
        }
    },
    computed: {
        Table() {
            let type = this.type
            if (this.FastBoardTable[type]) {
                return this.FastBoardTable[type]
            }
            return null
        },
        OperateAuth() {
            if (this.$parent.OperateAuth) {
                return this.$parent.OperateAuth
            }
            return {}
        },
        FastBoxDir() {
            let dir = {}
            let list = this.FastBox['sixballs']
            for (let item of list) {
                if (item) {
                    dir[item] = true
                }
            }
            return dir
        }
    },
    watch: {
        capacity(val) {
            if (typeof val === 'number') {
                this.SetCapacity(val)
            }
        },
        'FastBoardStep.active'(val) {
            this.SaveStep(val)
        },
        'group'(val) {
            this.SaveStep()
        },
        'limit'(val) {
            this.FastBoxChange()
        },
        'FastBox.sixballs'(val) {
            this.FastBoxChange()
            this.$emit('change', val)
        },
        'double'(val) {
            if (!val) {
                this.max_odds = undefined
            }
        }
    },
    methods: {
        BoardFast(item) {
            let input = undefined
            if (!this.list) {
                input = !this.active[item]
                this.$set(this.active, item, input)
            }
            if (this.$parent.BoardFast) {
                this.$parent.BoardFast(item, this.group, input)
            }
        },
        RebateSet(item, type) {
            if (this.$parent.RebateSet) {
                this.$parent.RebateSet(null, type, this.group, this.FastBoardStep.active)
            }
        },
        TransmitSubmit() {
            let mode = 'sixballs'
            if (this.limit) {
                let list = this.FastBox[mode]
                if (!list || !list.length) {
                    return this.Err('请选择对应的生肖或号码')
                }
                if (list.length != this.limit) {
                    return this.Err(`本项目走飞需要勾选 ${this.limit} 种号码，当前只勾选了 ${list.length} 种`)
                }
            }
            if (this.$parent.TransmitSubmit) {
                if (this.transmit==='normal' || this.transmit==='integration') {
                    if (!this.transmit_amount) {
                        return this.$parent.TransmitSubmit(this.capacity, this.group)
                    } else {
                        return this.$parent.TransmitSubmit(this.capacity, this.group, this.FastBox[mode].join(','), this.transmit_amount)
                    }
                } else {
                    if (mode && (!this.FastBox[mode] || !this.FastBox[mode].length)) {
                        return this.Err('请选择彩球号码')
                    }
                    if (!this.transmit_amount) {
                        return this.Err('请选输入需要走飞的金额')
                    }
                    return this.$parent.TransmitSubmit(undefined, this.group, this.FastBox[mode].join(','), this.transmit_amount)
                }
            }
        },
        TransmitListSubmit(mode) {
            if (mode && (!this.FastBox[mode] || !this.FastBox[mode].length)) {
                return this.Err('请选择彩球号码')
            }
            if (!this.transmit_amount) {
                return this.Err('请选输入需要走飞的金额')
            }
            this.$parent.TransmitSubmit(undefined, this.group, this.FastBox[mode].join(','), this.transmit_amount)
        },
        SetCapacity(val) {
            if (typeof val === 'number') {
                let group = this.group
                if (!group) {
                    group = []
                }
                if (typeof group === 'number') {
                    group = [group]
                }
                if (typeof group === 'string') {
                    group = group.split(',')
                }
                for (let id of group) {
                    this.$set(this.client.capacitys, id, val)
                }
            }
        },
        FillNumber(num) {
            return num < 10 ? '0'+num : ''+num
        },
        SaveStep(val=this.FastBoardStep.active) {
            if (!val || typeof val !=='number') {
                val = '0'
            }
            let group = this.Origin(this.group)
            if (group) {
                if (typeof group === 'number') {
                    group = [group]
                }
                for (let g of group) {
                    this.$set(this.client.board.stepdir, g, `${val}`)
                }
            }
        },
        HandleBall(code) {
            for (let i=0;i<this.FastBox['sixballs'].length;i++) {
                if (this.FastBox['sixballs'][i] == code) {
                    this.FastBox['sixballs'].splice(i, 1)
                    //this.$emit('change',this.FastBox['sixballs'])
                    return
                }
            }
            if (this.limit === undefined || this.FastBox['sixballs'].length < this.limit) {
                this.FastBox['sixballs'].push(code)
            }
            //this.$emit('change',this.FastBox['sixballs'])
        },
        FastBoxChange() {
            if (this.limit && this.limit > 0) {
                let list = this.FastBox['sixballs']
                while (list.length > this.limit) {
                    list.pop()
                }
            }
            //this.$emit('change',this.FastBox['sixballs'])
        }
    },
    mounted() {
        this.SetCapacity(this.capacity)
        this.DataReset('FastBox')
        this.SaveStep()
        //this.FastBoardStep.active = this.defaultStep
        this.$set(this.FastBoardStep, 'active', this.defaultStep)
        //console.log(123123)
    }
}
</script>
