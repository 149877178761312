<style lang="scss">
    .OperateLhc-8 {

    }
</style>
<template>
    <div class="OperateLhc-8">
        <board-fast :client="client" :group="[0]" :step="true" transmit="six-tail" :radio="true" />
        <group :client="client" :list="Table[0]" :length="1" :radio="true" :transmit="false" show-total="end" />
    </div>
</template>

<script>
import CommonMix from '../common.js'
import Group from '../group_mobile.vue'
import BoardFast from '../fast_mobile.vue'
export default {
    name: 'OperateLhc8',
    mixins: [CommonMix],
    data() {
        return {

        }
    },
    props: {
        client: {}
    },
    computed: {

    },
    methods: {

    },
    components: {
        Group,
        BoardFast
    }
}
</script>
