const packages = {
    lhc: {
        lhc_5abdf8b8520b71f3: require('./components/lhc_mobile/1.vue').default, // 特码
        lhc_38a77aa456fc813a: require('./components/lhc_mobile/2.vue').default, // 正码1-6
        lhc_0987b8b338d6c90b: require('./components/lhc_mobile/3.vue').default, // 连码
        lhc_31ca0ca71184bbdb: require('./components/lhc_mobile/4.vue').default, // 自选不中
        lhc_91d0dbfd38d950cb: require('./components/lhc_mobile/6.vue').default, // 一肖尾数
        lhc_be1bc7997695495f: require('./components/lhc_mobile/5.vue').default, // 连肖
        lhc_4fa91c19016cb1f8: require('./components/lhc_mobile/7.vue').default, // 正码特
        lhc_4a533591763dfa74: require('./components/lhc_mobile/8.vue').default, // 连尾
        lhc_e1fc9c082df6cfff: require('./components/lhc_mobile/9.vue').default, // 合肖
        lhc_e3ea33961a7c5b1e: require('./components/lhc_mobile/10.vue').default, // 正码
        lhc_integration: require('./components/lhc_mobile/integration.vue').default
    },
    default: require('./components/default_mobile.vue').default
}
export default {
    components: {
	    ...packages.lhc, // 六合彩
	    default: packages.default
    },
    methods: {
    	UsePackage(name='') {
    		let type = name.split('_')[0]
    		if (packages[type] && packages[type][name]) {
    			return name
    		}
    		return 'default'
    	}
    }
}