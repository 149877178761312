<style lang="scss">
@import "../../../../style/config.scss";
.CreditSubUser {
    $fontSize: 13.5px;
    padding-bottom: 60px; font-size: $fontSize;
    .mint-field {
        font-size: $fontSize!important;        
        &.small .mint-cell-title {
            width: 80px;
        }
        &.normal .mint-cell-title {
            width: 100px;
        }
        &.long .mint-cell-title {
            width: 150px;
        }
        &.long-2 .mint-cell-title {
            width: 175px;
        }
    }
    .box {
        input.mint-field-core {
            padding: 0 5px; line-height: 34px; border-radius: 6px; box-sizing: border-box; margin-right: 3px;
            background-color: #F0F0F0; border: 1px solid #DDD;
        }
    }
    .mint-cell-text, .mint-field-other {
        font-size: $fontSize!important;
    }
    .Actionsheet {
        .mint-cell-wrapper {
            .mint-cell-value.is-link {
                margin-right: 18px;
            }
            .mint-cell-allow-right::after {
                right: 14px!important;
            }
        }
        // font-size: $fontSize;
        // .mint-field, .mint-cell-text {
        //     font-size: $fontSize;
        // }
    }
    .checkbox {
        .checkbox-item {
            color:#aaa;
            .icon {
                color:#ddd;
            }
            &.active {
                color:#333;
                .icon {
                    color:$color-t;
                } 
            }
        }
    }
}
</style>

<template>
    <div class="CreditSubUser">
        <template v-if="!Params.id && Params.type === null">
            <div class="block c-text-c o-ptb-h">                
                <Button @click="PickType(0)">新增会员</Button>
                <Button @click="PickType(1)">新增代理</Button>
            </div>
        </template>
        <div v-else>
            <p class="o-plr o-ptb-s c-text-65 c-line-10">基本属性</p>
            <div class="block">
                <mt-field class="u-bb small box" label="登录帐号" placeholder="请输入登录帐号" v-model="Params.username" :readonly="!!Params.id">
                    <span v-if="userCheck.pass === true"><i class="el-icon-success c-color-s"></i><span class="o-pl-s">可使用</span></span>
                    <span v-if="userCheck.pass === false"><i class="el-icon-error c-color-e"></i><span class="o-pl-s c-color-e">被占用!</span></span>
                </mt-field>
                <mt-field class="l-flex-1 u-bb small box" label="真实姓名" placeholder="请输入真实姓名" v-model="Params.real_name" />
                <mt-field class="l-flex-1 u-bb small box" label="登录密码" placeholder="请输入登录密码" v-model="Params.pass" />

                <div class="l-flex">
                    <mt-field class="l-flex-1 u-bb u-br small" label="所属上级" :readonly="true">{{ agent.info.username }}</mt-field>
                    <Actionsheet class="l-flex-1 u-bb" v-model="Params.type" :actions="[{name: '会员', value: 0}, {name: '代理', value: 1}]" title="账户类型" :disabled="true" />
                </div>
                <div class="l-flex">
                    <Actionsheet class="l-flex-1 u-bb u-br" v-model="Params.amount_type" :actions="[{name:'手动',value:0},{name:'自动',value:1}]" title="额度恢复类型" v-if="Params.type == 0" />
                    <Actionsheet class="l-flex-1 u-bb" v-model="Params.status" :actions="[{name:'启用',value:1},{name:'暂停',value:2},{name:'停用',value:3}]" title="账户状态" />
                </div>
            </div>
            <p class="o-plr o-ptb-s c-text-6 c-line-10">游戏设置</p>
            <template v-for="(item,index) in hasDir">
                <div class="block o-mb">
                    <mt-field class="u-bb" :readonly="true" :label="item.title">
                        <div class="l-flex-c">
                            <div v-if="item.name == 'lhc' && Params[item.name+'_enable']" class=" c-text-r checkbox">
                                <span class="checkbox-item" style="padding-left: 12px;" v-for="side in ['A','B','C','D','E']" @click="PickSide(item.name,side)" :class="{'active':SideDir[item.name][side]}">
                                    <Icon class="icon" size="1" name="roundcheckfill"></Icon><span style="padding-left:1px; vertical-align:middle;">{{ side }}</span>                                    
                                </span>
                            </div>
                            <div style="width: 1px; height: 15px; margin: 0 12px; background-color: #CCC;" />
                            <HmSwitch v-model="Params[item.name+'_enable']" />
                        </div>
                    </mt-field>


                    <div v-if="Params[item.name+'_enable']" style="border-left:5px solid #ccc">
                        <!-- <mt-field class="u-bb" :readonly="true" label="可用盘类">
                            <div v-if="item.name=='lhc'" class=" c-text-r checkbox">
                                <span class="checkbox-item" style="padding-left:12px;" v-for="side in ['A','B','C','D']" @click="PickSide(item.name,side)" :class="{'active':SideDir[item.name][side]}">
                                    <Icon class="icon" size="1.1" name="roundcheckfill"></Icon><span style="padding-left:1px; vertical-align:middle;">{{ side }}</span>                                    
                                </span>
                            </div>
                        </mt-field> -->
                        <div class="l-flex-c">
                            <mt-field class="l-flex-1 u-bb normal u-br" label="额度设置上限" :readonly="true">{{ CheckAmount(item.name) }}</mt-field>
                            <mt-field class="l-flex-1 u-bb small box" v-model.number="Params[item.name+'_amount']" placeholder="请输入额度" label="信用额度" disableClear></mt-field>
                        </div>



<mt-field class="u-bb long-2" :readonly="true" label="新澳门占成设置上限">
                            <span class="o-pl-l c-color-e">
                                <b v-if="agent.info.is_admin">不限</b>
                                <b v-else>{{ Price(agent.info[item.name+'_proportion_xac'],{separate:false}) }}</b>
                            </span>
                        </mt-field>
                        <mt-field class="u-bb long-2" :readonly="true" label="新澳门最大占成必须>=">
                            <span class="o-pl-l c-color-e">
                                <b v-if="agent.info.is_admin">不限</b>
                                <b v-else>{{ Price(agent.info[item.name+'_lock_proportion_xac'],{separate:false}) }}</b>
                            </span>
                        </mt-field>

                        {{/* 会员 */}}
                        <template v-if="Params.type == 0">
                            <mt-field class="u-bb long-2 box" v-model.number="Params[item.name+'_proportion_max_xac']" placeholder="请输入占成百分比" label="新澳门代理占成(%)"></mt-field>
                        </template>
                        {{/* 代理 */}}
                        <template v-if="Params.type == 1">
                            <template v-if="agent.info.is_admin">
                                <mt-field class="u-bb long-2 box" v-model="Params[item.name+'_proportion_xac']" placeholder="请输入占成百分比" label="新澳门下级代理可占成(%)"></mt-field>
                                <mt-field class="u-bb long-2 box" v-model="Params[item.name+'_lock_proportion_xac']" placeholder="请输入占成百分比" label="新澳门锁定下级代理占成(%)"></mt-field>
                            </template>
                            <template v-else>
                                <!-- <mt-field class="u-bb long-2" v-model="Params[item.name+'_proportion_min_ac']" placeholder="请输入占成百分比" label="澳门上级代理最小占成(%)"></mt-field> -->
                                <mt-field class="u-bb long-2 box" v-model="Params[item.name+'_proportion_max_xac']" placeholder="请输入占成百分比" label="新澳门上级代理最大占成(%)"></mt-field>
                                <mt-field class="u-bb long-2 box" v-model="Params[item.name+'_transfer_rebate_xac']" placeholder="请输入占成百分比" label="新澳门上级收飞最大占成(%)"></mt-field>
                                <mt-field class="u-bb long-2 box" v-model="Params[item.name+'_proportion_xac']" placeholder="请输入占成百分比" label="新澳门下级代理可占成(%)"></mt-field>
                                <mt-field class="u-bb long-2 box" v-model="Params[item.name+'_lock_proportion_xac']" placeholder="请输入占成百分比" label="新澳门锁定下级代理占成(%)"></mt-field>
                            </template>
                        </template>

                        <mt-field class="u-bb long-2" :readonly="true" label="香港占成设置上限">
                            <span class="o-pl-l c-color-e">
                                <b v-if="agent.info.is_admin">不限</b>
                                <b v-else>{{ Price(agent.info[item.name+'_proportion'],{separate:false}) }}</b>
                            </span>
                        </mt-field>
                        <mt-field class="u-bb long-2" :readonly="true" label="香港最大占成必须>=">
                            <span class="o-pl-l c-color-e">
                                <b v-if="agent.info.is_admin">不限</b>
                                <b v-else>{{ Price(agent.info[item.name+'_lock_proportion'],{separate:false}) }}</b>
                            </span>
                        </mt-field>
                        
                        {{/* 会员 */}}
                        <template v-if="Params.type == 0">
                            <mt-field class="u-bb long-2 box" v-model.number="Params[item.name+'_proportion_max']" placeholder="请输入占成百分比" label="香港代理占成(%)"></mt-field>
                        </template>
                        {{/* 代理 */}}
                        <template v-if="Params.type == 1">
                            <template v-if="agent.info.is_admin">
                                <mt-field class="u-bb long-2 box" v-model="Params[item.name+'_proportion']" placeholder="请输入占成百分比" label="香港下级代理可占成(%)"></mt-field>
                                <mt-field class="u-bb long-2 box" v-model="Params[item.name+'_lock_proportion']" placeholder="请输入占成百分比" label="香港锁定下级代理占成(%)"></mt-field>
                                <!-- <mt-cell class="u-bb long-2" title="开启操盘">
                                    <HmSwitch v-model="Params[item.name+'_trader_status']" :active-value="'1'" :inactive-value="'0'"></HmSwitch>
                                </mt-cell> -->
                            </template>
                            <template v-else>
                                <!-- <mt-field class="u-bb long-2 box" v-model="Params[item.name+'_proportion_min']" placeholder="请输入占成百分比" label="香港上级代理最小占成(%)"></mt-field> -->
                                <mt-field class="u-bb long-2 box" v-model="Params[item.name+'_proportion_max']" placeholder="请输入占成百分比" label="香港上级代理最大占成(%)"></mt-field>
                                <mt-field class="u-bb long-2 box" v-model="Params[item.name+'_transfer_rebate']" placeholder="请输入占成百分比" label="香港上级收飞最大占成(%)"></mt-field>
                                <mt-field class="u-bb long-2 box" v-model="Params[item.name+'_proportion']" placeholder="请输入占成百分比" label="香港下级代理可占成(%)"></mt-field>
                                <mt-field class="u-bb long-2 box" v-model="Params[item.name+'_lock_proportion']" placeholder="请输入占成百分比" label="香港锁定下级代理占成(%)"></mt-field>
                            </template>
                            <!-- <Datetime v-if="item.name=='lhc'" type="time" title="六合彩封盘时间" v-model="Params.lhc_end_bets_date"></Datetime> -->
                        </template>


                        <mt-field class="u-bb long-2" :readonly="true" label="旧澳门占成设置上限">
                            <span class="o-pl-l c-color-e">
                                <b v-if="agent.info.is_admin">不限</b>
                                <b v-else>{{ Price(agent.info[item.name+'_proportion_ac'],{separate:false}) }}</b>
                            </span>
                        </mt-field>
                        <mt-field class="u-bb long-2" :readonly="true" label="旧澳门最大占成必须>=">
                            <span class="o-pl-l c-color-e">
                                <b v-if="agent.info.is_admin">不限</b>
                                <b v-else>{{ Price(agent.info[item.name+'_lock_proportion_ac'],{separate:false}) }}</b>
                            </span>
                        </mt-field>

                        {{/* 会员 */}}
                        <template v-if="Params.type == 0">
                            <mt-field class="u-bb long-2 box" v-model.number="Params[item.name+'_proportion_max_ac']" placeholder="请输入占成百分比" label="旧澳门代理占成(%)"></mt-field>
                        </template>
                        {{/* 代理 */}}
                        <template v-if="Params.type == 1">
                            <template v-if="agent.info.is_admin">
                                <mt-field class="u-bb long-2 box" v-model="Params[item.name+'_proportion_ac']" placeholder="请输入占成百分比" label="旧澳门下级代理可占成(%)"></mt-field>
                                <mt-field class="u-bb long-2 box" v-model="Params[item.name+'_lock_proportion_ac']" placeholder="请输入占成百分比" label="旧澳门锁定下级代理占成(%)"></mt-field>
                            </template>
                            <template v-else>
                                <!-- <mt-field class="u-bb long-2" v-model="Params[item.name+'_proportion_min_ac']" placeholder="请输入占成百分比" label="澳门上级代理最小占成(%)"></mt-field> -->
                                <mt-field class="u-bb long-2 box" v-model="Params[item.name+'_proportion_max_ac']" placeholder="请输入占成百分比" label="旧澳门上级代理最大占成(%)"></mt-field>
                                <mt-field class="u-bb long-2 box" v-model="Params[item.name+'_transfer_rebate_ac']" placeholder="请输入占成百分比" label="旧澳门上级收飞最大占成(%)"></mt-field>
                                <mt-field class="u-bb long-2 box" v-model="Params[item.name+'_proportion_ac']" placeholder="请输入占成百分比" label="旧澳门下级代理可占成(%)"></mt-field>
                                <mt-field class="u-bb long-2 box" v-model="Params[item.name+'_lock_proportion_ac']" placeholder="请输入占成百分比" label="旧澳门锁定下级代理占成(%)"></mt-field>
                            </template>
                        </template>

                        

                        <mt-field class="u-bb long-2" :readonly="true" label="台湾占成设置上限">
                            <span class="o-pl-l c-color-e">
                                <b v-if="agent.info.is_admin">不限</b>
                                <b v-else>{{ Price(agent.info[item.name+'_proportion_tw'],{separate:false}) }}</b>
                            </span>
                        </mt-field>
                        <mt-field class="u-bb long-2" :readonly="true" label=台湾最大占成必须>
                            <span class="o-pl-l c-color-e">
                                <b v-if="agent.info.is_admin">不限</b>
                                <b v-else>{{ Price(agent.info[item.name+'_lock_proportion_tw'],{separate:false}) }}</b>
                            </span>
                        </mt-field>

                        {{/* 会员 */}}
                        <template v-if="Params.type == 0">
                            <mt-field class="u-bb long-2 box" v-model.number="Params[item.name+'_proportion_max_tw']" placeholder="请输入占成百分比" label="台湾代理占成(%)"></mt-field>
                        </template>
                        {{/* 代理 */}}
                        <template v-if="Params.type == 1">
                            <template v-if="agent.info.is_admin">
                                <mt-field class="u-bb long-2 box" v-model="Params[item.name+'_proportion_tw']" placeholder="请输入占成百分比" label="台湾下级代理可占成(%)"></mt-field>
                                <mt-field class="u-bb long-2 box" v-model="Params[item.name+'_lock_proportion_ac']" placeholder="请输入占成百分比" label="台湾锁定下级代理占成(%)"></mt-field>
                            </template>
                            <template v-else>
                                <mt-field class="u-bb long-2 box" v-model="Params[item.name+'_proportion_min_tw']" placeholder="请输入占成百分比" label="台湾上级代理最小占成(%)"></mt-field>
                                <mt-field class="u-bb long-2 box" v-model="Params[item.name+'_proportion_max_tw']" placeholder="请输入占成百分比" label="台湾上级代理最大占成(%)"></mt-field>
                                <mt-field class="u-bb long-2 box" v-model="Params[item.name+'_transfer_rebate_tw']" placeholder="请输入占成百分比" label="台湾上级收飞最大占成(%)"></mt-field>
                                <mt-field class="u-bb long-2 box" v-model="Params[item.name+'_proportion_tw']" placeholder="请输入占成百分比" label="台湾下级代理可占成(%)"></mt-field>
                                <mt-field class="u-bb long-2 box" v-model="Params[item.name+'_lock_proportion_tw']" placeholder="请输入占成百分比" label="台湾锁定下级代理占成(%)"></mt-field>
                            </template>
                        </template>

                         <mt-field class="u-bb long-2" :readonly="true" label="新加坡占成设置上限">
                            <span class="o-pl-l c-color-e">
                                <b v-if="agent.info.is_admin">不限</b>
                                <b v-else>{{ Price(agent.info[item.name+'_proportion_xjp'],{separate:false}) }}</b>
                            </span>
                        </mt-field>
                        <mt-field class="u-bb long-2" :readonly="true" label="新加坡最大占成必须>=">
                            <span class="o-pl-l c-color-e">
                                <b v-if="agent.info.is_admin">不限</b>
                                <b v-else>{{ Price(agent.info[item.name+'_lock_proportion_xjp'],{separate:false}) }}</b>
                            </span>
                        </mt-field>

                        {{/* 会员 */}}
                        <template v-if="Params.type == 0">
                            <mt-field class="u-bb long-2 box" v-model.number="Params[item.name+'_proportion_max_xjp']" placeholder="请输入占成百分比" label="新加坡代理占成(%)"></mt-field>
                        </template>
                        {{/* 代理 */}}
                        <template v-if="Params.type == 1">
                            <template v-if="agent.info.is_admin">
                                <mt-field class="u-bb long-2 box" v-model="Params[item.name+'_proportion_xjp']" placeholder="请输入占成百分比" label="新加坡下级代理可占成(%)"></mt-field>
                                <mt-field class="u-bb long-2 box" v-model="Params[item.name+'_lock_proportion_xjp']" placeholder="请输入占成百分比" label="新加坡锁定下级代理占成(%)"></mt-field>
                            </template>
                            <template v-else>
                                <mt-field class="u-bb long-2 box" v-model="Params[item.name+'_proportion_min_xjp']" placeholder="请输入占成百分比" label="新加坡上级代理最小占成(%)"></mt-field>
                                <mt-field class="u-bb long-2 box" v-model="Params[item.name+'_proportion_max_xjp']" placeholder="请输入占成百分比" label="新加坡上级代理最大占成(%)"></mt-field>
                                <mt-field class="u-bb long-2 box" v-model="Params[item.name+'_transfer_rebate_xjp']" placeholder="请输入占成百分比" label="新加坡上级代理收飞最大占成(%)"></mt-field>
                                <mt-field class="u-bb long-2 box" v-model="Params[item.name+'_proportion_xjp']" placeholder="请输入占成百分比" label="新加坡下级代理可占成(%)"></mt-field>
                                <mt-field class="u-bb long-2 box" v-model="Params[item.name+'_lock_proportion_xjp']" placeholder="请输入占成百分比" label="新加坡锁定下级代理占成(%)"></mt-field>
                            </template>
                        </template>

                    </div>
                </div>
            </template>
            <div v-if="Params.type == 1" class="o-plr c-text-6 c-line-10">
                <p>● 上级最少占成 ＋ 代理可占成數不可比  <span class="c-color-e">上限</span> 多。</p>
                <p>● 上级收飞最大占成不可比 <span class="c-color-e">上限</span> 多。</p>
                <p>● <span class="c-color-e">上级最大占成不可比最少占成小。</span></p>
                <p>● 管理层走飞一律<span class="c-color-e">以 A 盘走飞。</span></p>
                <p>● <span class="c-color-e">上级代理如设置了锁定占成，则你设置的上级代理最大占成与上级代理收飞最大占成需 >= 上级代理设置的比例。</span></p>
            </div>
            <div class="o-p">
                <Button @click="Submit()" full>提交数据</Button>
            </div>
        </div>
    </div>
</template>
<script>
import StoreMix from '@lottery.admin.2.0/mixin/store.sub.js'
export default {
    name: 'CreditSubUser',
    mixins : [StoreMix],
    data() {
        return {
            store : 'myTeam/user',
            Params : {
                type : null,
                lhc_end_bets_date : '21:30:00',
                has_games_type : '',
                amount_type : 0,
                source : '代理添加',
                status : 1,
                ssc_enable : false,
                ffc_enable : false,
                klc_enable : false,
                pks_enable : false,
                dpc_enable : false,
                lhc_enable : true,

                ssc_decrease_rebate : 0,
                ffc_decrease_rebate : 0,
                klc_decrease_rebate : 0,
                pks_decrease_rebate : 0,
                dpc_decrease_rebate : 0,
                lhc_decrease_rebate : 0,

                ssc_transfer_status : true,
                ffc_transfer_status : true,
                klc_transfer_status : true,
                pks_transfer_status : true,
                dpc_transfer_status : true,
                lhc_transfer_status : true,

                lhc_has_bet : 'a,b,c,d,e',
            },
            agent : {
                loading : true,
                info : {},
            },
            userCheck : {
                pass : null,
            },
        }
    },
    watch:{
        'Params.username'(val){
            if(val && val.length >=3 && !this.Params.id){
                this.CheckName(val)
            }
        },
    },
    computed: {
        type(){
            return this.$store.state.game.group
        },
        percentage(){
            return this.$store.state.myTeam.percentage
        },
        hasDir(){
            let dir = this.agent.info.has_games_type
            let arr = []
            if(dir){
                for(let id of dir.split(',')){
                    for(let item of this.type._list){
                        if(item.id == id){
                            arr.push({
                                ...item,
                                name : item.code,
                            })
                        }
                    }
                }
            }
            return arr
        },
        SideDir(){
            let dir = {}
            for(let name of ['ssc','ffc','klc','pks','dpc','lhc']){
                let key = `${name}_has_bet`
                dir[name] = {}
                if(this.Params[key]){
                    for(let side of this.Params[key].split(',')){
                        dir[name][side.toUpperCase ()] = true
                    }
                }
            }
            return dir
        },
        'ssc_has_bet':{get:function(){return this.Params.ssc_has_bet ? this.Params.ssc_has_bet.split(',') : []},set:function(val){this.$set(this.Params,'ssc_has_bet',val.join(','))}},
        'ffc_has_bet':{get:function(){return this.Params.ffc_has_bet ? this.Params.ffc_has_bet.split(',') : []},set:function(val){this.$set(this.Params,'ffc_has_bet',val.join(','))}},
        'klc_has_bet':{get:function(){return this.Params.klc_has_bet ? this.Params.klc_has_bet.split(',') : []},set:function(val){this.$set(this.Params,'klc_has_bet',val.join(','))}},
        'pks_has_bet':{get:function(){return this.Params.pks_has_bet ? this.Params.pks_has_bet.split(',') : []},set:function(val){this.$set(this.Params,'pks_has_bet',val.join(','))}},
        'dpc_has_bet':{get:function(){return this.Params.dpc_has_bet ? this.Params.dpc_has_bet.split(',') : []},set:function(val){this.$set(this.Params,'dpc_has_bet',val.join(','))}},
        'lhc_has_bet':{get:function(){return this.Params.lhc_has_bet ? this.Params.lhc_has_bet.split(',') : []},set:function(val){this.$set(this.Params,'lhc_has_bet',val.join(','))}},
    },
    methods: {
        init(){
            this.DataReset()
            for(let key in this.$route.params){
                this.$set(this.Params,key,this.$route.params[key])
            }
            try{
                this.Params.pid = this.Params.parent.id
            }catch(e){
                this.Params.pid = this.User.id
            }
            this.$delete(this.Params,'parent')

            this.DataReset('agent')
            this.Dp('myTeam/_GET_USER',{id:this.Params.pid}).then(res=>{
                this.agent.loading = false
                if(this.form && this.form.has_games_type){
                    this.$nextTick(()=>{
                        for(let id of this.form.has_games_type.split(',')){
                            for(let item of this.hasDir){
                                if(item.id == id){
                                    this.$set(this.Params,`${item.name}_enable`,true)
                                }
                            }                            
                        }
                    })
                }
                this.$set(this.agent,'info',res.data)
            })
        },
        PickType(type){
            this.Params.type = type
        },
        Submit(model=this.storePath[1]){
            let params = this.Origin(this.Params)
            let list = []
            for(let item of this.hasDir){
                if(this.Params[`${item.name}_enable`]){
                    list.push(item.id)
                }
            }
            if(params.id){
                params.action = 'info'
            }
            if(this.agent.info.is_admin){
                params._proportion = params._proportion_max
            }
            params.has_games_type = list.join(',')
            params.id ?
                this.Put(params,model) :
                this.Post(params,model)
        },
        CheckName(username){
            this.Dp('base/CHECK_USER',username).then(res=>{
                if(this.Params.username == username){
                    this.$set(this.userCheck,'pass',res.data ? false : true)
                }
            })
        },
        CheckAmount(name,amount){
            try{
                if(this.agent.info && this.agent.info.is_admin){
                    return '无限制'
                }
                let origin = this.form && this.form[`${name}_amount`] ? this.form[`${name}_amount`] : 0
                return this.Big(this.agent.info[`${name}_balance`]).sub(this.agent.info[`${name}_use_balance`]).toNumber()
            }catch(err){
                return 'NaN'
            }
        },
        Proportion(e,name){
            if(this.agent.info.is_admin){
                for(let key of ['_proportion_max','_transfer_rebate','_proportion']){
                    this.$set(this.Params,`${name}${key}`,e)
                }
            }
        },
        PickSide(name,side){
            let key = `${name}_has_bet`
            let dir = {}
            side = side.toLowerCase()
            for(let name of this.Params[key].split(',')){
                dir[name] = true
            }
            dir[side] = !dir[side]
            let arr = []
            for(let _side of ['a','b','c','d']){
                if(dir[_side]){
                    arr.push(_side)
                }
            }
            this.Params[key] = arr.join(',')
        },
    },
    activated(){
        this.init()
    },
}
</script>
