<style lang="scss">
    .CreditSubUserLimit {
        width: 100%; height: 100%;
        position: fixed; top: 0; left: 0; right: 0; bottom: 0; z-index: 21;
        overflow-x: scroll; overflow-y: auto;
        display: flex; flex-direction: column; align-items: center;
        .btn-back {
            width: 32px; height: 32px; border-radius: 99px; z-index: 12;
            position: fixed; top: 12px; left: 16px;
            display: flex; justify-content: center; align-items: center;
            background-color: rgba(0, 0, 0, .5);
        }
        .Actionsheet {
            .mint-cell {
                background-color: #F0F0F0; border-radius: 6px; min-height: 36px;
                height: 38px!important; min-height: 38px!important;
            }
            .mint-cell-wrapper {
                .mint-cell-value.is-link {
                    margin-right: 18px;
                }
                .mint-cell-allow-right::after {
                    right: 14px!important;
                }
            }
            .mint-cell-text, .mint-cell-wrapper {
                font-size: 13.5px!important;
            }
        }
        input, .top-input {
            width: 60px; height: 24px; line-height: 24px; padding: 0 3px; border-radius: 4px; font-size: 13px;
            box-sizing: border-box;  overflow: hidden;
            background-color: #FFF; border: 1px solid #CCC; outline: none;
            &[type=checkbox] {
                width: 16px; height: 16px;
            }   
        }
        .table {
            $mark-danger:#ff8f76;
            $mark-warning:yellow;
            input {
                &[state=danger] {
                    background-color: $mark-danger; border-color: $mark-danger; 
                }
                &[state=warning] {
                    background-color: $mark-warning; border-color: $mark-warning;
                }
            }
            thead {
                tr td {
                    background-color: #DDD;
                }
            }
            tbody {
                tr td {
                    border-top: 1px solid #DDD;
                }
                tr:nth-child(odd) {
                    background-color: #edf1fb;
                }
                tr.pick {
                    td {
                        background-color: #ffecd5;
                    }
                }
            }
        }
        .row-bock {
            width: 100%; box-sizing: border-box;
        }
    }
</style>
<template>
    <div class="CreditSubUserLimit">

        <div class="row-bock c-bg-f u-bb" style="padding: 8px 9px; overflow: auto; box-shadow: 0 0 12px rgba(0, 0, 0, .12);">
            <div class="l-flex-v" style="width: 100%; min-width: 360px;">
                <div class="l-flex-c">
                    <div class="o-pl" @click="Back()">
                        <icon name="right" size="1" style="transform: rotate(180deg); color: #333; position: relative; top: -2px;" />
                        <span class="o-ml-s">返回</span>
                    </div>
                    <!-- <Button size="small" @click="rotateScreen()">rotateScreen</Button> -->
                    <div style="width: 1px; height: 15px; margin: 0 12px; background-color: #CCC;" />
                    <div style="flex: 0 0 180px;">
                        <Actionsheet v-model="Filter.games_id" :actions="games._list.map(row => ({name: row.title, value: row.id}))" title="游戏:" />
                    </div>
                    <div style="width: 1px; height: 15px; margin: 0 12px; background-color: #CCC;" />
                    <Button size="small" @click="tpls.status = 1">模版</Button>
                </div>
                <div class="l-flex-c o-pt">
                    <Button size="small" @click="load()">重读数据</Button>
                    <Button size="small" @click="Max()">与我同步</Button>
                    <div style="width: 1px; height: 15px; margin: 0 12px; background-color: #CCC;" />
                    <Button size="small" @click="Submit()">提交修改</Button>
                </div>
            </div>
        </div>
        <div class="l-flex-1" style="width: 100%; overflow: auto;">
            <div style="width: 100%; min-width: 880px; padding-bottom: 16px;">
                <div class="l-flex-c o-p u-bb">
                    <input v-model="table.pickall" @change="PickAll($event)" type="checkbox" />
                    <span class="o-plr">全选</span>
                    <!-- <div style="width: 1px; height: 15px; margin: 0 6px; background-color: #CCC;" /> -->
                    <span class="o-pr-s o-pl">A盘</span>
                    <input v-model="table.A" size="mini" class="top-input" style="width: 42px;" />
                    <span class="o-pr-s o-pl">B盘</span>
                    <input v-model="table.B" size="mini" class="top-input" style="width: 42px;" />
                    <span class="o-pr-s o-pl">C盘</span>
                    <input v-model="table.C" size="mini" class="top-input" style="width: 42px;" />
                    <span class="o-pr-s o-pl">D盘</span>
                    <input v-model="table.D" size="mini" class="top-input" style="width: 42px;" />
                    <span class="o-pr-s o-pl">E盘</span>
                    <!-- <div style="width: 1px; height: 15px; margin: 0 6px; background-color: #CCC;" /> -->
                    <input v-model="table.E" size="mini" class="top-input" style="width: 42px;" />
                    <span style="padding-left: 16px; padding-right: 6px;">单注限额</span>
                    <input v-model="table.order_max_amount" size="mini" style="width: 56px;" />
                    <span style="padding-left: 12px; padding-right: 6px;">下注限额</span>
                    <input v-model="table.bets_limit_amount" size="mini" style="width: 56px;" />
                    <!-- <div style="width: 1px; height: 15px; margin: 0 6px; background-color: #CCC;" /> -->
                    <Button class="o-ml" size="small" @click="FastLoad()" plain>清除</Button>
                    <Button size="small" @click="FastSubmit()">传输</Button>
                </div>
                <table class="table" border="0" cellspacing="1" cellpadding="0" style="width: 100%; border-collapse: collapse;">
                    <thead>
                        <tr style="text-align:left;">
                            <td style="text-align:center;">选择</td>
                            <td style="text-align:left;">玩法</td>
                            <td>A盘</td>
                            <td>B盘</td>
                            <td>C盘</td>
                            <td>D盘</td>
                            <td>E盘</td>
                            <td>单注限额</td>
                            <td>下注限额</td>
                        </tr>
                    </thead>
                    <tbody style="text-align:left;">
                        <tr v-for="(item, index) in table.list" :class="{'pick': item.pick}">
                            <td style="text-align:center;">
                                <input v-model="item.pick" type="checkbox" />
                            </td>
                            <td>{{ item.name }}</td>
                            <td><input v-model="item.backwater_a" v-maxlimit="item" name="backwater_a"><span class="c-color-e" style="padding-left: 4px;">({{ item.limit.backwater_a == 0 ? '-' : item.limit.backwater_a }})</span></td>
                            <td><input v-model="item.backwater_b" v-maxlimit="item" name="backwater_b"><span class="c-color-e" style="padding-left: 4px;">({{ item.limit.backwater_b == 0 ? '-' : Price(item.limit.backwater_b,{separate:false}) }})</span></td>
                            <td><input v-model="item.backwater_c" v-maxlimit="item" name="backwater_c"><span class="c-color-e" style="padding-left: 4px;">({{ item.limit.backwater_c == 0 ? '-' : Price(item.limit.backwater_c,{separate:false}) }})</span></td>
                            <td><input v-model="item.backwater_d" v-maxlimit="item" name="backwater_d"><span class="c-color-e" style="padding-left: 4px;">({{ item.limit.backwater_d == 0 ? '-' : Price(item.limit.backwater_d,{separate:false}) }})</span></td>
                            <td><input v-model="item.backwater_e" v-maxlimit="item" name="backwater_e"><span class="c-color-e" style="padding-left: 4px;">({{ item.limit.backwater_e == 0 ? '-' : Price(item.limit.backwater_e,{separate:false}) }})</span></td>
                            <td><input v-model="item.order_max_amount" v-maxlimit="item" name="order_max_amount" style="width: 64px;"><span class="c-color-e" style="padding-left: 4px;">({{ Price(item.limit.order_max_amount,{separate:false}) }})</span></td>
                            <td><input v-model="item.bets_limit_amount" v-maxlimit="item" name="bets_limit_amount" style="width: 64px;"><span class="c-color-e" style="padding-left: 4px;">({{ Price(item.limit.bets_limit_amount,{separate:false}) }})</span></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        
        <div v-show="tpls.status" style="position: fixed; top: 0; left: 0; right: 0; bottom: 0; z-index: 21; background-color: rgba(0, 0, 0, .65);" @click.self="tpls.status = 0">
            <div class="l-flex-v" style="width: 100%; height: 450px; position: absolute; left: 0; right: 0; bottom: 0; padding: 18px 15px 15px 15px;  background-color: #FFF; border-radius: 12px 12px 0 0; box-sizing: border-box;">
                <div v-show="tpls.status === 1" class="l-flex-1" style="opacity: auto;">
                    <li v-for="item in tpls.list" :key="item.id" class="l-flex-c o-mtb-s">
                        <div class="l-flex-1 o-pr c-line-1">{{ item.data.title }}</div>
                        <Button type="danger" size="small" @click="removeTPL(item)">删除</Button>
                        <Button type="warning" size="small" @click="useTPL(item)">使用</Button>
                    </li>
                    <li v-if="tpls.list.length === 0" class="o-p-l c-color-g c-text-c">当前游戏下暂无模板</li>
                </div>
                <div v-show="tpls.status === 2" class="l-flex-1 l-flex-v">
                    <p class="o-ptb c-text-c">建议先提交当前限额配置后再保存为模板</p>
                    <input v-model="tpls.title" placeholder="请输入模板名称" maxlength="20" style="width: 200px; font-size: 14px; height: 40px; line-height: 40px; display: block; margin: 0 auto;" />
                </div>
                <div class="o-p c-text-c">
                    <Button v-show="tpls.status === 1" type="info" @click="tpls.status = 0">取消</Button>
                    <Button v-show="tpls.status === 1" @click="tpls.status = 2; tpls.title = '';">保存当前配置为模板</Button>
                    <Button type="info" v-show="tpls.status === 2" @click="tpls.status = 1">取消</Button>
                    <Button v-show="tpls.status === 2" @click="saveTPL">保存</Button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import StoreMix from '@lottery.2.0/mixin/store.modul.js'
import parentComponent from '@lottery.2.0/page/game/sub/model/limit'
import { MessageBox } from 'mint-ui';
export default {
    name: 'CreditSubUserLimit',
    extends: parentComponent,
    mixins : [StoreMix],
    data() {
        return {
            tpls: {
                list: [],
                keys: ['backwater_a', 'backwater_b', 'backwater_c', 'backwater_d', 'backwater_e', 'order_max_amount', 'bets_limit_amount'],
                title: '',
                status: 0
            }
        }
    },
    watch: {
        'Filter.games_id'(id) {
            if (id) {
                this.$set(this.tpls, 'list', [])
                this.loadTPL()
            }
        }
    },
    methods: {
        formatData() {
            const data = []
            for (let item of this.table.list) {
                const keys = this.tpls.keys
                for (let key of keys) {
                    if (item[key] != item.origin[key]) {
                        let obj = {
                            id: item.id,
                            mark_id: item.mark_id
                        }
                        for (let k of keys) {
                            obj[k] = item[k]
                        }
                        data.push(obj)
                        break
                    }

                }
            }
            return data
        },
        loadTPL() {
            this.Dp('myTeam/_GET_USER_LIMIT_TPL', {params: {games_id: this.Filter.games_id}}).then(res => {
                if (res) {
                    this.$set(this.tpls, 'list', res.data.map(item => {
                        try {
                            item.data = JSON.parse(item.str_data)
                        } catch (e) {
                            item.data = {title: '', list: []}
                        }
                        return item
                    }))
                }
            })
        },
        saveTPL() {
            const {title} = this.tpls
            if (!title) return this.Msg('请输入模板名称')
            const data = {title, list: this.table.list.map(item => {
                const obj = {}
                for (const key of this.tpls.keys) {
                    obj[key] = item[key]
                }
                return obj
            })}
            this.Dp('myTeam/_POST_USER_LIMIT_TPL', {data: {games_id: this.Filter.games_id, str_data: JSON.stringify(data)}}).then(res => {
                if (res) {
                    this.tpls.status = 1
                    this.Suc('保存成功')
                    this.loadTPL()
                }
            })
        },
        removeTPL(item) {
            MessageBox.confirm('是否确认移除选定数据?').then(action => {
                if (action === 'confirm') {
                    this.Dp('myTeam/_DELETE_USER_LIMIT_TPL', {id: item.id}).then(res => {
                        if (res) {
                            this.tpls.status = 1
                            this.Suc('提交成功')
                            this.loadTPL()
                        }
                    })
                }
            })
        },
        useTPL(item) {
            try {
                const list = JSON.parse(item.str_data).list
                list.forEach((item, index) => {
                    if (this.table.list[index]) {
                        for (const key of this.tpls.keys) {
                            this.table.list[index][key] = item[key]
                        }
                    }
                })
                this.Suc('数据已应用')
                this.tpls.status = 0
            } catch (err) {
                this.Msg('数据损坏，无法套用数据')
                console.log(err)
            }
        },
        rotateScreen(direction) {
            const body = document.getElementsByTagName('body')[0]
            const el = document.getElementsByClassName('CreditSubUserLimit')[0]
            const {clientWidth: screenWidth, clientHeight: screenHeight} = body
            const {clientWidth: width, clientHeight: height} = el
            const currentDirection = width > height ? 'horizontal' : 'vertical'
            if (!direction) direction = currentDirection === 'horizontal' ? 'vertical' : 'horizontal'
            if (currentDirection !== 'direction') {
                if (width > height) {
                    el.style.width = screenWidth + 'px'
                    el.style.height = screenHeight + 'px'
                    el.style.top = '0px'
                    el.style.left = '0px'
                    el.style.transform = 'rotate(0deg)'
                } else {
                    el.style.width = screenHeight + 'px'
                    el.style.height = screenWidth + 'px'
                    el.style.top = (screenHeight - screenWidth) / 2 + 'px'
                    el.style.left = 0 - (screenHeight - screenWidth) / 2 + 'px'
                    el.style.transform = 'rotate(0deg)'
                    el.style.transform = 'rotate(90deg)'
                }
            }
            this.Cache('MOBILE_USER_LIMIT_SCREEN_DIRECTION', direction)
        },
        Submit() {
            const data = this.formatData()
            if (data.length) {
                this.Dp('myAccount/_POST_GAMESGROUP', {
                    data: {
                        uid: this.Params.uid,
                        games_id: this.Filter.games_id,
                        data: JSON.stringify(data)
                    }
                }).then(res => {
                    this.Suc('限额已修改成功')
                    this.load()
                })
            } else {
                this.Suc('当前页面无可提交的数据', '提交成功', 'warning')
            }
        }
    },
    directives: {
        maxlimit: {
            update(el, binding, vnode, oldvnode) {
                let {value, oldValue} = binding
                let name = el.getAttribute('name')
                let val = Number(value[name])
                let limit = value.limit ? Number(value.limit[name]) : null
                let origin = Number(value.origin[name])
                if (val !== '-') {

                } else if (val == origin) {
                    el.setAttribute('state', 'origin')
                } else if (limit && limit < val) {
                    el.setAttribute('state', 'danger')
                } else {
                    el.setAttribute('state', 'warning')
                }
            }
        }
    },
    mounted() {
        
        // const direction = this.Cache('MOBILE_USER_LIMIT_SCREEN_DIRECTION') || 'vertical'
        // this.rotateScreen(direction)
    },
    activated() {
        document.getElementsByClassName('header-1')[0].style.display = 'none'
        this.Params.id = this.$route.params.id
        this.Params.uid = this.$route.params.id
        this.loadTPL()
        this.init()
    },
    deactivated() {
        document.getElementsByClassName('header-1')[0].style.display = 'flex'
    }
}
</script>