<style lang="scss">
    .OperateLhc-1 {
        .o-pr-s {padding-left:.1rem;}
        .o-plr-s {padding-left:.1rem; padding-right:.1rem;}
        .o-pr-s {padding-right:.1rem;}
    }
</style>
<template>
    <div class="OperateLhc-1">
        <div class="mobile-tabs u-bb">
            <div v-for="item in tabs.list" class="mobile-tab" :class="{'active': tabs.active === item}" @click="tabs.active = item">{{ item }}</div>
        </div>
        <template v-if="tabs.active === '号码'">
            <board-fast v-show="OperateAuth.bind != 1" key="1" :client="client" :group="[1]" type="lhc" :step="true" :outbtn="true" :fast="true" />
            <group :client="client" :list="Table[1]" :length="1" show-total="end" />
        </template>
        <template v-if="tabs.active === '两面'">
            <board-fast key="2" :client="client" :group="[4,2,5,51,8,81,6,7,10]" type="lhc" :step="true" :outbtn="true" :default-step="0.01" />
            <group :client="client" :list="Table[4]" :length="1" show-total="end" @RebateSet="RebateSetEmit" />
            <group :client="client" :list="Table[2]" :length="1" show-total="end" @RebateSet="RebateSetEmit" />
            <group :client="client" :list="Table[5]" :length="1" show-total="end" @RebateSet="RebateSetEmit" />
            <group :client="client" :list="Table[51]" :length="1" show-total="end" @RebateSet="RebateSetEmit" />
            <group :client="client" :list="Table[8]" :length="1" show-total="end" @RebateSet="RebateSetEmit" />
            <group :client="client" :list="Table[81]" :length="1" show-total="end" @RebateSet="RebateSetEmit" />
            <group :client="client" :list="Table[6]" :length="1" show-total="end" @RebateSet="RebateSetEmit" />
            <group :client="client" :list="Table[7]" :length="1" show-total="end" @RebateSet="RebateSetEmit" />
            <group :client="client" :list="Table[10]" :length="1" show-total="end" @RebateSet="RebateSetEmit" />
        </template>
        <template v-if="tabs.active === '生肖'">
            <board-fast key="3" :client="client" :group="[3]" type="lhc" :step="true" :outbtn="true" :default-step="0.01" />
            <group :client="client" :list="Table[3]" :length="1" show-total="end" @RebateSet="RebateSetEmit" />
        </template>
        <template v-if="tabs.active === '色波'">
            <board-fast key="4" :client="client" :group="[9,19]" type="lhc" :step="true" :outbtn="true" :default-step="0.01" />
            <group :client="client" :list="Table[9]" :length="1" show-total="end" @RebateSet="RebateSetEmit" />
            <group :client="client" :list="Table[19]" :length="1" show-total="end" @RebateSet="RebateSetEmit" />
        </template>
    </div>
</template>

<script>
// 特码
import CommonMix from '../common.js'
import Group from '../group_mobile.vue'
import BoardFast from '../fast_mobile.vue'
export default {
    name: 'OperateLhc1',
    mixins: [CommonMix],
    data() {
        return {
            tabs: {
                active: '号码',
                list: ['号码', '两面', '生肖', '色波']
            }
        }
    },
    props: {
        client: {}
    },
    components: {
        Group,
        BoardFast
    }
}
</script>
