<style lang="scss">
    .HmSwitch {
        .mint-switch-core {
            width:42px; height:22px;
            &:before {
                width:41px; height:20px;
            }
            &:after {
                width:20px; height:21px;
            }
        }
    }
</style>
<template>
    <mt-switch class="HmSwitch" v-model="avalue"></mt-switch>
</template>

<script>
export default {
    name : 'HmSwitch',
    data(){
        return {
            avalue : false,
        }
    },
    computed:{

    },
    props : {
        value : {},
        activeValue: {
            default : true,
        },
        inactiveValue : {
            default : false,
        },
    },
    watch:{
        value(val){
            this.Save()
        },
        avalue(val){
            this.$emit('input',val ? this.activeValue : this.inactiveValue)
        },
    },
    methods: {
        Save(){
            let val = this.value
            this.$set(this,'avalue',val === this.activeValue ? true : false)
        },
    },
    mounted(){
        this.Save()
    }
}
</script>