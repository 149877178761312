<style lang="scss">
    @import "../../style/config.scss";
    .Maintain {
        width:100%; height:100%;
        position:fixed; top:0; left:0; right:0; bottom:0;
        .core {
            padding:1rem; box-sizing:border-box;
            .context {
                max-width:640px; margin:0 auto; padding:1rem; position:relative; top:80px; top:10vh; box-sizing: border-box;
                border-radius:6px; box-shadow:0 4px 12px rgba(0,0,0,0.3);
                background-color:#fff;
                img {
                    width:86%; margin:1em auto;
                }
            }
        }
    }
</style>

<template>
    <div class="Maintain">
        <div class="core">
            <div class="context c-text-c">
                <img :src="Img('maintain.png')">
                <h4 class="c-color-p">抱歉，平台正在进行升级维护</h4>
                <h6 class="o-mt" v-if="$route.params.msg">{{ $route.params.msg }}</h6>
                <div class="o-pt-l o-pb-l">
                    <!-- <Button class="o-pr-s" v-if="token" @click="Logout()">清除缓存</Button> -->
                    <a :href="rdlink">
                        <Button>重试</Button>
                    </a>
                </div>                
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name : 'Error',
    data (){
        return {
            token : null,
        }
    },
    computed:{
        rdlink(){
            return `//${window.location.host}`
        }
    },
    methods: {
        init(){
            this.$set(this,'token',this.Token())
        },
        Logout(){
            let token = this.token
            return this.$store.dispatch('base/BASE_SIGNOUT',{token}).then(res=>{
                this.Cache('token',null)
                this.Go('login')
                window.location.reload()
                return res
            })
        },
    },
    mounted(){
       this.init()
    },
}
</script>