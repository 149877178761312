<style lang="scss">
@import "../../../../style/config.scss";
.CreditSubUser {
    padding-bottom:60px;
    .mint-field {
        font-size:14px;
        .mint-cell-text {
            font-size:14px;
        }
        &.long .mint-cell-title {
            width:150px;
        }
        &.long-2 .mint-cell-title {
            width:175px;
        }
    }
    .checkbox {
        .checkbox-item {
            color:#aaa;
            .icon {
                color:#ddd;
            }
            &.active {
                color:#333;
                .icon {
                    color:$color-t;
                } 
            }
        }
    }
}
</style>

<template>
    <div class="CreditSubUser">
        <p class="o-plr o-ptb-s c-text-6">基本属性</p>
        <div class="block">
            <mt-field class="u-bb" label="登录帐号" :readonly="true">{{ Params.username }}</mt-field>
            <mt-field class="u-bb" label="真实姓名" :readonly="true">{{ Params.real_name }}</mt-field>
            <mt-field class="u-bb" label="所属上级" :readonly="true">{{ agent.info.username }}</mt-field>
            <mt-field class="u-bb" label="账户类型" :readonly="true">
                <span v-if="Params.type == 0">会员</span>
                <span v-if="Params.type == 1">代理</span>
            </mt-field>
            <mt-field class="u-bb" label="额度恢复类型" :readonly="true" v-if="Params.type==0">
                <span v-if="Params.amount_type == 0">手动</span>
                <span v-if="Params.amount_type == 1">自动</span>
            </mt-field>
            <mt-field class="u-bb" label="账户状态" :readonly="true">
                <span v-if="Params.status == 1">启用</span>
                <span v-if="Params.status == 2">暂停</span>
                <span v-if="Params.status == 3">停用</span>
            </mt-field>
        </div>
        <p class="o-plr o-ptb-s c-text-6">游戏设置</p>
        <template v-for="(item,index) in hasDir" v-if="Params[item.name+'_enable']">
            <div class="block o-mb">
                <mt-field class="u-bb" :readonly="true" :label="item.title">启用</mt-field>
                <div v-if="Params[item.name+'_enable']" style="border-left:5px solid #ccc">
                    <mt-field class="u-bb" :readonly="true" label="可用盘类">
                        <div v-if="item.name=='lhc'" class=" c-text-r checkbox">
                            <span class="checkbox-item" style="padding-left:12px;" v-for="side in ['A','B','C','D','E']" :class="{'active':SideDir[item.name][side]}">
                                <Icon class="icon" size="1.1" name="roundcheckfill"></Icon><span style="padding-left:1px; vertical-align:middle;">{{ side }}</span>                                    
                            </span>
                        </div>
                    </mt-field>
                    <mt-field class="u-bb long-2" placeholder="" label="信用额度" :readonly="true">{{ Params[item.name+'_amount'] }}</mt-field>
                    {{/* 会员 */}}
                    <template v-if="Params.type == 0">
                        <mt-field class="u-bb long" placeholder="" label="代理占成" :readonly="true">{{ Params[item.name+'_proportion_max'] }}%</mt-field>
                    </template>
                    {{/* 代理 */}}
                    <template v-if="Params.type == 1">
                        <template v-if="agent.info.is_admin">
                            <mt-field class="u-bb long-2" label="下级代理可占成" :readonly="true">{{ Params[item.name+'_proportion'] }}%</mt-field>
                            <mt-cell class="u-bb long-2" title="开启操盘">
                                <span v-if="Params[item.name+'_trader_status'] == 1">是</span>
                                <span v-if="Params[item.name+'_trader_status'] == 0">否</span>
                            </mt-cell>
                        </template>
                        <template v-else>
                            <mt-field class="u-bb long-2" label="上级代理最小占成(%)" :readonly="true">{{ Params[item.name+'_proportion_min'] }}</mt-field>
                            <mt-field class="u-bb long-2" label="上级代理最大占成(%)" :readonly="true">{{ Params[item.name+'_proportion_max'] }}</mt-field>
                            <mt-field class="u-bb long-2" label="上级代理收飞最大占成(%)" :readonly="true">{{ Params[item.name+'_transfer_rebate'] }}</mt-field>
                            <mt-field class="u-bb long-2" label="下级代理可占成(%)" :readonly="true">{{ Params[item.name+'_proportion'] }}</mt-field>
                        </template>
                        <mt-field v-if="item.name=='lhc'" class="u-bb long-2" label="六合彩封盘时间" :readonly="true">{{ Params.lhc_end_bets_date }}</mt-field>
                    </template>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
import StoreMix from '@lottery.admin.2.0/mixin/store.sub.js'
export default {
    name: 'CreditSubUserDetails',
    mixins : [StoreMix],
    data() {
        return {
            store : 'myTeam/user',
            Params : {
                type : null,
                lhc_end_bets_date : '21:30:00',
                has_games_type : '',
                amount_type : 0,
                source : '代理添加',
                status : 1,
                ssc_enable : false,
                ffc_enable : false,
                klc_enable : false,
                pks_enable : false,
                dpc_enable : false,
                lhc_enable : true,

                ssc_decrease_rebate : 0,
                ffc_decrease_rebate : 0,
                klc_decrease_rebate : 0,
                pks_decrease_rebate : 0,
                dpc_decrease_rebate : 0,
                lhc_decrease_rebate : 0,

                ssc_transfer_status : true,
                ffc_transfer_status : true,
                klc_transfer_status : true,
                pks_transfer_status : true,
                dpc_transfer_status : true,
                lhc_transfer_status : true,

                lhc_has_bet : 'a,b,c,d,e',
            },
            agent : {
                loading : true,
                info : {},
            },
            userCheck : {
                pass : null,
            },
        }
    },
    watch:{
        'Params.username'(val){
            if(val && val.length >=3 && !this.Params.id){
                this.CheckName(val)
            }
        },
    },
    computed: {
        type(){
            return this.$store.state.game.group
        },
        percentage(){
            return this.$store.state.myTeam.percentage
        },
        hasDir(){
            let dir = this.agent.info.has_games_type
            let arr = []
            if(dir){
                for(let id of dir.split(',')){
                    for(let item of this.type._list){
                        if(item.id == id){
                            arr.push({
                                ...item,
                                name : item.code,
                            })
                        }
                    }
                }
            }
            return arr
        },
        SideDir(){
            let dir = {}
            for(let name of ['ssc','ffc','klc','pks','dpc','lhc']){
                let key = `${name}_has_bet`
                dir[name] = {}
                if(this.Params[key]){
                    for(let side of this.Params[key].split(',')){
                        dir[name][side.toUpperCase ()] = true
                    }
                }
            }
            return dir
        },
        'ssc_has_bet':{get:function(){return this.Params.ssc_has_bet ? this.Params.ssc_has_bet.split(',') : []},set:function(val){this.$set(this.Params,'ssc_has_bet',val.join(','))}},
        'ffc_has_bet':{get:function(){return this.Params.ffc_has_bet ? this.Params.ffc_has_bet.split(',') : []},set:function(val){this.$set(this.Params,'ffc_has_bet',val.join(','))}},
        'klc_has_bet':{get:function(){return this.Params.klc_has_bet ? this.Params.klc_has_bet.split(',') : []},set:function(val){this.$set(this.Params,'klc_has_bet',val.join(','))}},
        'pks_has_bet':{get:function(){return this.Params.pks_has_bet ? this.Params.pks_has_bet.split(',') : []},set:function(val){this.$set(this.Params,'pks_has_bet',val.join(','))}},
        'dpc_has_bet':{get:function(){return this.Params.dpc_has_bet ? this.Params.dpc_has_bet.split(',') : []},set:function(val){this.$set(this.Params,'dpc_has_bet',val.join(','))}},
        'lhc_has_bet':{get:function(){return this.Params.lhc_has_bet ? this.Params.lhc_has_bet.split(',') : []},set:function(val){this.$set(this.Params,'lhc_has_bet',val.join(','))}},
    },
    methods: {
        init(){
            this.DataReset()
            for(let key in this.$route.params){
                this.$set(this.Params,key,this.$route.params[key])
            }
            try{
                this.Params.pid = this.Params.parent.id
            }catch(e){
                this.Params.pid = this.User.id
            }
            this.$delete(this.Params,'parent')

            this.DataReset('agent')
            this.Dp('myTeam/_GET_USER',{id:this.Params.pid}).then(res=>{
                this.agent.loading = false
                if(this.form && this.form.has_games_type){
                    this.$nextTick(()=>{
                        for(let id of this.form.has_games_type.split(',')){
                            for(let item of this.hasDir){
                                if(item.id == id){
                                    this.$set(this.Params,`${item.name}_enable`,true)
                                }
                            }                            
                        }
                    })
                }
                this.$set(this.agent,'info',res.data)
            })
        },
        PickType(type){
            this.Params.type = type
        },
        Submit(model=this.storePath[1]){
            let params = this.Origin(this.Params)
            let list = []
            for(let item of this.hasDir){
                if(this.Params[`${item.name}_enable`]){
                    list.push(item.id)
                }
            }
            if(params.id){
                params.action = 'info'
            }
            if(this.agent.info.is_admin){
                params._proportion = params._proportion_max
            }
            params.has_games_type = list.join(',')
            params.id ?
                this.Put(params,model) :
                this.Post(params,model)
        },
        CheckName(username){
            this.Dp('base/CHECK_USER',username).then(res=>{
                if(this.Params.username == username){
                    this.$set(this.userCheck,'pass',res.data ? false : true)
                }
            })
        },
        CheckAmount(name,amount){
            try{
                if(this.agent.info && this.agent.info.is_admin){
                    return '无限制'
                }
                let origin = this.form && this.form[`${name}_amount`] ? this.form[`${name}_amount`] : 0
                return this.Big(this.agent.info[`${name}_balance`]).sub(this.agent.info[`${name}_use_balance`]).toNumber()
            }catch(err){
                return 'NaN'
            }
        },
        Proportion(e,name){
            if(this.agent.info.is_admin){
                for(let key of ['_proportion_max','_transfer_rebate','_proportion']){
                    this.$set(this.Params,`${name}${key}`,e)
                }
            }
        },
        PickSide(name,side){
            let key = `${name}_has_bet`
            let dir = {}
            side = side.toLowerCase()
            for(let name of this.Params[key].split(',')){
                dir[name] = true
            }
            dir[side] = !dir[side]
            let arr = []
            for(let _side of ['a','b','c','d']){
                if(dir[_side]){
                    arr.push(_side)
                }
            }
            this.Params[key] = arr.join(',')
        },
    },
    activated(){
        this.init()
    },
}
</script>
