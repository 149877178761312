<style lang="scss">
    .OperateLhc-integration {

    }
</style>
<template>
    <div class="OperateLhc-integration">
        <!-- <board-fast :client="client" :group="[1]" type="lhc" :step="true" transmit="integration" :fast="true" /> -->
        <group :client="client" :list="Table[1]" :length="1" show-total="end" :integration="1" disabled />
        <div class="block c-text-6 o-mt-s">
            <p class="c-text-c o-p">注意：特整合包含，特单、特双、特大、特小、合单、合双、合大、合小、尾大、尾小、合尾大、合尾小、红波、蓝波、绿波、大单、大双、小单、小双、家禽、野兽、特半波、特肖、合肖</p>
        </div>
    </div>
</template>

<script>
// 特整合
import CommonMix from '../common.js'
import Group from '../group_mobile.vue'
import BoardFast from '../fast_mobile.vue'
export default {
    name: 'OperateLhcIntegration',
    mixins: [CommonMix],
    props: {
        client: {}
    },
    components: {
        Group,
        BoardFast
    }
}
</script>
