<style lang="scss">
    @import "../../../style/config.scss";
    .IndexReportSelf {
        padding-top:44px;
        .nav-board {
            position:absolute; top:44px; left:0; right:0; bottom:0; background-color:#fff; border-bottom:.03rem solid #ccc; z-index:100;
            font-size:12px;
            .filter {

            }
        }
        a.btn {
            padding:5px 9px; margin:0 3px; background-color:#f0f0f0; color:#999; border-radius:4px;
            &.active {
                background-color:$color-t; color:#fff;
            }
        }
    }
</style>
<template>
    <div class="IndexReportSelf">
        <HeadLimit v-model="Filter.interval" @change="MakeFilter('empty')">
            <Actionsheet v-model="Filter.games_id" title="游戏" :actions="[{id: 0, title: '全部'}, ...Opt.hasDir.game]" size="small" name="title" val="id" emptytext="全部" @change="MakeFilter('empty')"></Actionsheet>
        </HeadLimit>
        <div class="c-bg-f o-ptb u-bb c-text-c">
            <a class="btn" v-for="(item,index) in datatime" :class="{'active':Filter.interval && Filter.interval[0] == item.start && Filter.interval[1] == item.end}" @click="PickDate(item.name)">{{ item.title }}</a>
        </div>
        <Loading v-if="main._loading" :main="main"></Loading>
        <div v-else-if="main._empty" class="o-mt-s block">
            <div class="o-ptb-h o-plr c-text-c">暂无数据</div>
        </div>        
        <div v-else class="o-mt-s">
            <template v-for="(pack,unit) in main._list">
                <ul class="block">
                    <li class="c-line-20" :class="{'u-bt':index}" v-for="(row,index) in rows" >
                        <span class="c-text-r o-pr-l" style="width:90px; display:inline-block;">{{ row.title }}：</span>

                        <span v-if="row.val == 1">
                          
                         <b v-if="pack[row.name] > 0">
                                     {{ pack[row.name] }}
                                </b>

                                <b v-if="pack[row.name] < 1">
                                    <b style="color:red">{{ pack[row.name] }}</b>
                                </b>

                        </span>

                        <span v-if="row.val == 0">
                                {{ pack[row.name] }}
                        </span>

                    </li>
                </ul>
                <mt-cell class="o-mt" title="查看注单列表" @click.native="Bets()" is-link></mt-cell>
            </template>
        </div>
    </div>
</template>

<script>
import StoreMix from '../../../mixin/store.js'
export default {
    name: 'IndexReportSelf',
    mixins : [StoreMix],
    data() {
        return {
            store : 'credit/report',
            type : 'amount',
            use : null,
            Filter : {
                games_id : undefined,
                lottery_id : undefined,
                status : 1,
            },
            rows : [
                {title:'会员帐号',name:'username',val:0},
                {title:'会员名称',name:'real_name',val:0},
                {title:'下单数',name:'bets_num',val:0},
                {title:'下注金额',name:'bets_amount',val:0},
                // {title:'有效金额',name:'valid_amount',val:0},
                {title:'奖金',name:'bets_bonus',val:0},
                {title:'退水',name:'percent_backwater_amount',val:0},
                {title:'会员结果',name:'members_result',val:1},
            ],
            datatime : [
                {name:'day',title:'今日',},
                {name:'yesterday',title:'昨天',},
                {name:'lweek',title:'上周',},
                {name:'week',title:'本周',},
                {name:'lmonth',title:'上月',},
                {name:'month',title:'本月',},
            ]
        }
    },
    methods: {
        init() {
            this.use = null
            this.Filter.pid = this.User.id
            this.Filter.games_id = undefined
            for (let item of this.datatime) {
                this.$set(item,'start',this.FilterInterval(item.name)[0])
                this.$set(item,'end',this.FilterInterval(item.name)[1])
            }
            this.FilterIntervalSet('day')
            this.Get(1)
        },
        Check() {
            this.use = null
            this.headview = false
            this.$nextTick(()=>{
                this.use = this.type

            })
        },
        Bets() {
            this.Go('index/pages/sub/reportself', this.Filter)
        },
        PickDate(type) {
            this.FilterIntervalSet(type)
            this.Get(1)
        }
    },
    components : {

    },
    activated(){
        this.init()
    },
}
</script>
