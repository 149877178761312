<style lang="scss">
    @import "./group.scss";
    .OperateGroup {

    }
</style>
<template>
    <table class="OperateGroup" :class="size + ' ' + layout">
        <tr class="layout-tr">
            <td v-for="(pack,unit) in MakeQueue(list,length,'vertical',fill)" class="layout-td">
                <table class="table">
                    <thead>
                        <tr>
                            <td>号码</td>
                            <td>赔率</td>
                            <td>金额</td>
                            <td v-if="win">输赢</td>
                            <td v-if="OperateAuth.bind == 3 && OperateAuth.transmit && transmit && win">补货</td>
                            <td v-if="OperateAuth.bind == 2 && OperateAuth.manipulation && manipulation">操盘</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in pack" class="row" :class="{'pick': item.pick,'double': doubleLine}" @click="Pick(item)">
                            <template v-if="item.name">
                                <td>
                                    <a :class="BallType(item.name)">{{ item.name }}</a>
                                </td>
                                <td>
                                    <p>
                                        <b class="c-color-e">{{ Big(item.odds).sub(repair) }}</b>
                                    </p>
                                    <p v-if="item.has_double_odds" style="padding-top:2px;">
                                        <b class="c-color-e">{{ parseFloat(item.max_odds) }}</b>
                                    </p>
                                </td>
                                <td>
                                    <span class="o-plr-s">
                                        <a @click.stop="ShowDetails(item,integration)"><b style="text-decoration:underline;">{{ AmountTotal(item) }}</b></a>
                                    </span>
                                </td>
                                <td v-if="win">
                                    <span class="o-plr-s" :style="Interval[3]" :class="{'c-color-s': WinTotal(item) > 0,'win-color': WinTotal(item) < 0}"><b>{{ WinTotal(item) }}</b></span>
                                </td>
                                <td v-if="OperateAuth.bind == 3 && OperateAuth.transmit && transmit && win">
                                    <span class="o-plr-s" :style="Interval[4]">{{ TransmitCalcu(item) }}</span>
                                </td>
                                <td v-if="OperateAuth.bind == 2 && OperateAuth.manipulation && manipulation">
                                    <span class="c-text-c" style="width:2.6rem;">
                                        <p>
                                            <Icon class="remove" name="wuuiconsuoxiao" @click.native.stop="RebateSetGroup(item,'-')" />
                                            <Icon class="plus" name="wuuiconxiangjifangda" @click.native.stop="RebateSetGroup(item,'+')" />
                                        </p>
                                        <p v-if="item.has_double_odds" style="padding-top:10px;">
                                            <Icon class="remove" name="wuuiconsuoxiao" @click.native.stop="RebateSetGroup(item,'-','max_odds')" />
                                            <Icon class="plus" name="wuuiconxiangjifangda" @click.native.stop="RebateSetGroup(item,'+','max_odds')" />
                                        </p>
                                    </span>
                                </td>
                            </template>
                            <td v-else colspan="10" />
                        </tr>
                        <tr v-if="showTotal === 'end' && unit === length - 1" class="c-text-r">
                            <td class="c-text-r o-pr" colspan="99"><b>笔数：{{ TotalAmount.count }}，合计: {{ TotalAmount.amount }}</b></td>
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
        <tr class="layout-tr">
            <td v-if="showTotal === true" class="layout-td total" colspan="9">
                <p class="c-text-r"><b class="o-pr">笔数：{{ TotalAmount.count }}，合计: {{ TotalAmount.amount }}</b></p>
            </td>
        </tr>
    </table>
</template>

<script>
import Common from './group.js'
export default {
    name: 'OperateGroup',
    mixins: [Common],
    methods: {
        // ShowDetails(item,integration){
        //     @click="Go('index/pages/operate/transfer',{games_id:TabItem.id,lottery_id:TabItem.lot.data.lottery_id,mode:'transfer'})"
        // },
    }

}
</script>