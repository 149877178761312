<style lang="scss">
    .CreditOperateAutomatic {
        table.table {
            tr td {
                text-align:center; padding-top:5px; padding-bottom:5px;
            }
            input {
                padding:0 4px; height:26px; line-height:26px; width:60px; border-radius:3px; outline:none; border:1px solid #ccc;
            }
        }
    }
</style>
<template>
    <div class="CreditOperateAutomatic o-pb-h">
        <div class="block o-pb">
            <table class="table">
                <thead>
                    <tr class="hbg-2">                        
                        <td>玩法名称</td>
                        <td>启用开关</td>
                        <td>自动走飞金额</td>
                    </tr>
                </thead>
                <tbody class="zebra">
                    <tr v-for="(item,index) in Table">
                        <td>{{ item.name }}</td>
                        <template v-if="item.id === 408">
                            <td colspan="3">(特码 B 合并入特码 A 中，以特码 A 中金额为准)</td>
                        </template>
                        <template v-else>
                            <template v-if="item.support">
                                <td>
                                    <Icon name="squarecheckfill" v-if="!item.auto_transfer" style="color:#d5d5d5;" size="1.1" @click.native="$set(item,'auto_transfer',1)"></Icon>
                                    <Icon name="squarecheckfill" v-if="item.auto_transfer" style="color:#5b9be0;" size="1.1" @click.native="$set(item,'auto_transfer',0)"></Icon>
                                </td>
                                <td><input v-model="item.auto_transfer_amount" style="width:120px;"></input></td>
                            </template>
                            <td v-else colspan="2" style="text-align:left;">
                                <span style="padding-left:20px;">暂不支持</span>
                            </td>
                        </template>                        
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="layout-bottom" style="z-index:1;">
            <div class="o-ptb-s o-plr">
                <Button @click="Submit()" full>提交</Button>
            </div>
        </div>
    </div>
</template>

<script>
import StoreMix from '@lottery.2.0/mixin/store.js'
import SixMix from '@lottery.admin.2.0/mixin/six.js'
export default {
    name: 'CreditOperateAutomatic',
    mixins : [StoreMix,SixMix],
    data() {
        return {
            store : 'game/matic',
            Params : {},
            Table : [],
        }
    },
    methods: {
        init() {
            this.Get()
        },
    },
    components : {
        //editer,
    },
    watch:{
        active(val){
            this.all = false
        }
    },
    methods: {
        init(){
            this.$set(this,'Table', [])
            this.Filter.games_id = this.$route.params.id
            this.Reload()
        },
        Reload(){
            this.Get().then(res=>{
                for(let item of res.data){
                    this.$set(item,'_auto_transfer',item.auto_transfer)
                    this.$set(item,'_auto_transfer_amount',item.auto_transfer_amount)
                }
                this.$set(this,'Table',res.data)
            })
        },
        Submit(){
            let data = []
            for(let item of this.Table){
                if(item.auto_transfer != item._auto_transfer || item.auto_transfer_amount != item._auto_transfer_amount){
                    data.push({
                        id : item.id,
                        name : item.name,
                        support : item.support,
                        auto_transfer : item.auto_transfer,
                        auto_transfer_amount : item.auto_transfer_amount,
                    })
                }
            }
            if(data.length){
                this.Dp(`game/_POST_MATIC`,{data:{data:JSON.stringify(data)}}).then(res=>{
                    this.Suc('自动走飞配置已保存')
                    this.$emit('finish',res ? res : 1)
                    this.Reload()
                })
            }else{
                this.Err('没有需要更改的选项')
            }
        },
    },
    activated(){
        this.init()
    },
}
</script>
