<style lang="scss">
    .CreditSubUserLevel {
        .table tr td {
            text-align:center;
        }
    }
</style>
<template>
    <div class="CreditSubUserLevel">
        <div class="block">
            <div class="o-plr o-pt">
                <span>游戏：</span>
                <select v-model="Filter.games_id" style="margin-top: 6px;" @change="reload">
                    <option v-for="item in games._list" :key="item.id" :value="item.id">{{ item.title }}</option>
                </select>
            </div>
            <div class="o-pt-l o-pb-h o-plr-s">
                <p v-if="Filter.username" class="o-pb o-plr-s">会员 <b>{{ Filter.username }}</b> 上级占成列表</p>
                <table class="table u-bl u-bb u-br">
                    <thead>
                        <tr class="hbg-1">
                            <td>帐号</td>
                            <td>级别</td>
                            <td>占成</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="c-text-c" v-for="(item,index) in list">
                            <td>{{ item.name }}</td>
                            <td>
                                <span v-if="item.type==1">代理</span>
                                <span v-if="item.type==0">会员</span>
                            </td>
                            <td>{{ item.point }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import StoreMix from '@lottery.2.0/mixin/store.modul.js'
export default {
    name: 'CreditSubUserLevel',
    mixins : [StoreMix],
    data() {
        return {
            store : 'myTeam/superior',
            Filter : {
                id : null,
                games_id: 31
            },
            list : [],
        }
    },
    computed: {
        games: vm => vm.$store.state.game.games
    },
    methods: {
        init(){
            this.DataReset('list')
            this.reload()
        },
        reload() {
            this.Filter.id = this.$route.params.id
            this.Filter.username = this.$route.params.username
            this.Get().then(res => {
                this.$set(this, 'list', res.data.reverse())
            })
        }
    },
    activated() {
        this.init()
    },
}
</script>
