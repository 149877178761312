<style lang="scss">
    .OperateDefault {

    }
</style>
<template>
    <div class="OperateDefault">
        <group :client="client" :list="Table[0]" :length="1" />
        <BoardFast :client="client" :group="[0]" :step="true" />
    </div>
</template>

<script>
import CommonMix from './common.js'
import Group from './group.vue'
export default {
    name: 'OperateDefault',
    mixins: [CommonMix],
    data() {
        return {

        }
    },
    props: ['client'],
    computed: {

    },
    methods: {

    },
    components: {
        Group
    }
}
</script>
