<style lang="scss">

</style>
<template>
    <div class="CreditAccount">
        <div class="block">
            <table class="table normal" border="0" cellspacing="1" cellpadding="0">
                <thead>
                    <tr class="hbg-2">
                        <td>帐号</td>
                        <td>名称</td>
                        <td style="text-align:center;">最后登录时间</td>
                        <td style="text-align:right;">操作</td>
                    </tr>
                </thead>
                <tbody class="zebra">
                    <tr class="c-text-c" v-for="(item,index) in main._list">
                        <td>{{ item.account }}</td>
                        <td>{{ item.name }}</td>
                        <td style="text-align:center;">{{ item.log_time }}</td>
                        <td style="text-align:right;">
                            <Button type="work" size="mini" @click="Go('index/pages/sub/account',item)">修改</Button>
                            <Button type="danger" size="mini" @click="Del(item)">删除</Button>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr v-if="main._empty">
                        <td class="empty c-text-c" colspan="100" style="padding-top:24px; padding-bottom:24px; color:#aaa;">暂无数据</td>
                    </tr>
                </tfoot>
            </table>
            <Load v-if="!main._init && main.loading"></Load>
        </div>
    </div>
</template>

<script>
import StoreMix from '@lottery.2.0/mixin/store.js'
export default {
    name: 'CreditAccount',
    mixins : [StoreMix],
    data() {
        return {
            store : 'admin/account',
            Filter : {},
            HeadNav : {
                text : '新增',
                icon : 'friendadd',
                event : ()=>{
                    this.Go('index/pages/sub/account')
                }
            },
        }
    },
    methods: {
        init(){        
            this.Get(1)
        },
        Del(item){
            this.DelConfirm(()=>{
                this.Dp('admin/_PUT_ACCOUNT',{id:item.id,data:{status:9}}).then(res=>{
                    this.Get(1)
                })
            })
        },
    },
    activated(){
        this.init()
    },
    beforeRouteEnter(to, from, next){
        next(vm=>{
            if(from && from.name === 'index/pages/sub/account'){
                vm.init()
            }
        })
    },
}
</script>
