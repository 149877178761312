<style lang="scss">
    @import "../../../style/config.scss";
    .Home {
        .banner {
            min-height:100px;
            .banner-item {
                display:block; width:100%; height:100%;
                img {
                    display:block; width:100%; height:100%;
                }
            }
        }
        .marquee-content {
            background-color:#d1eaf9; padding:3px 0; font-size:13px; display:block;
        }
        .rows{
            .title {
                padding:5px 8px 5px 12px;
                i.icon {
                    font-size:10px; color:#ccc; vertical-align:middle;
                }
            }
            .games {
                height: 250px;
                .games-item {
                    width:33%; padding:8px 0; text-align:center;float: left;
                    img {
                        width:80%; max-width:72px; display:block; margin:0 auto;
                    }
                }
            }
            .order {
                .order-item {

                }
            }
        }
    }
</style>
<template>
    <div class="Home">
        <div class="banner" :style="bannerHeight">
            <mt-swipe :auto="6000" v-if="main._init">
                <mt-swipe-item v-for="(item,index) in main._list" :key="item.id">
                    <a class="banner-item" @click="Go('index/pages/table',{id:31})">
                        <img :src="Img(item.cover_path)">
                    </a>
                </mt-swipe-item>
            </mt-swipe>
        </div>
        <marquee v-if="Opt.isTeam" class="marquee-content l-flex-1 o-plr" behavior="scroll" direction="left" loop="2">{{ Opt.platform_lantern_agent }}</marquee>
        <marquee v-else class="marquee-content l-flex-1 o-plr" behavior="scroll" direction="left" loop="2">{{ Opt.platform_lantern_members }}</marquee>
        <div class="rows block o-mb" v-if="Opt.isTeam">
            <div class="title u-bb l-flex-c">
                <p class="l-flex-1"><b>在线人数</b></p>
            </div>
            <p class="o-p">
                <span>代理：{{ online.online_agent }}，会员：{{ online.online_member }}</span>
            </p>
        </div>
        <div class="rows block">
            <div class="title u-bb l-flex-c">
                <p class="l-flex-1"><b>热门彩种</b></p>
            </div>
            <ul class="games">
                <li class="games-item" v-for="(item,index) in Opt.hasDir.game" @click="Go('index/pages/table', {id: item.id})">
                    <img :src="Img(item.info.logo_img)">
                    <p>{{ item.title }}</p>
                </li>
                <a :href="user_link">
                <li class="games-item">
                    <img src="https://api.ac6677.com/static/images/logo/quanming.jpg" />
                    <p>番摊</p>
                </li>
                </a>
            </ul>
        </div>
        <div class="rows block o-mt-s" v-if="!Opt.isTeam">
            <div class="title u-bb l-flex-c">
                <p class="l-flex-1"><b>近期投注</b></p>
                <p class="c-color-g c-text-6" @click="Go('index/pages/query')">
                    <span>更多</span>
                    <Icon name="right" size=".6" style="color:#ccc"></Icon>
                </p>
            </div>
            <ul class="order">
                <Loading v-if="order._loading" :loading="true"></Loading>
                <template v-else>
                    <li class="order-item o-ptb-s u-bb l-flex-c" v-for="(item,index) in order._list" @click="Go('index/pages/sub/query', item)">
                        <div class="l-flex-1">
                            <div class="l-flex-c o-plr c-text-65 c-line-10">
                                <p class="l-flex-1">
                                    <b class="o-pr">{{ item.games }}</b>
                                    <span class="c-color-g c-text-6">期号：{{ item.lottery_number }}</span>
                                </p>
                                <p class="c-text-6">{{ item.status }}</p>
                            </div>
                            <div class="l-flex-c o-plr c-text-6 c-line-10">
                                <p class="l-flex-1 o-pr c-line-1" style="-webkit-box-orient:vertical;">
                                    <span class="o-pr">
                                        <span class="c-color-t">{{ item.plays }}</span>
                                        <span class="o-pl-s">{{ item.code }}</span>
                                    </span>
                                </p>
                                <p class="c-color-e"><b>{{ item.amount }}</b>元</p>
                            </div>
                        </div>
                        <Icon class="o-pr-s" name="right" size=".6" style="color:#ccc"></Icon>
                    </li>
                </template>
            </ul>
        </div>
    </div>
</template>

<script>
import StoreMix from '@lottery.mobile/mixin/store.js'
export default {
    name: 'Home',
    mixins : [StoreMix],
    data() {
        return {
            store : 'base/activity',
            screenWidth : 320,
            onlineTimer : null,
            online : {},
            user_link : 'https://api.ac6677.com/v3/ft?id='+localStorage.getItem('fantantoken'),
        }
    },
    computed: {
        order(){
            return this.$store.state.base.order
        },
        bannerHeight(){
            let height
            return `height:${this.screenWidth*0.46}px;`
        },
    },
    methods: {
        init(){
            this.Get()
            this.$set(this,'screenWidth', window.screen.width)
            this.OnlineTimerRun()      
        },
        GetOrder(){
            if(!this.Opt.isTeam){
                this.Dp('base/_GET_ORDER',{params: {}})
            }
        },
        OnlineTimerRun(){
            clearInterval(this.onlineTimer)
            let LoadOnline = ()=>{
                if(this.Opt.isTeam){
                    this.Dp('base/RUNING_ONLINE').then(res=>{
                        this.$set(this,'online',res.data)
                    })
                }
            }
            LoadOnline()
            this.oddsTimer = setInterval(()=>{
                LoadOnline()
            }, 120000)
        }
    },
    activated(){
        this.GetOrder()
    },
    mounted() {
        this.init()
    }
}
</script>
