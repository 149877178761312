<style lang="scss">
    @import "./group_mobile.scss";
    .GameCreditGroup_M {
        $border:.04rem solid #ddd;
        .title {
            background-color:#ccc; color:#333; font-size:12px; padding:2px 0; text-align:center;
        }
        .row {
            text-align: center; background-color: #f5f5f5; min-height: 52px;
            border-bottom: $border; border-left: $border;
            padding-top: 6px; padding-bottom: 6px; box-sizing:border-box; display:block; position:relative;
            &:first-child{
                border-top:$border;
            }
            .goods {
                font-weight: normal; height: 20px; line-height: 20px; margin-top: 2px;
                &.ball {
                    font-size:16px; font-weight:normal;
                }
                &.six_color {
                    height: 29px; line-height: normal;
                }
                .red { color: $color-six-red;}
                .blue { color: $color-six-blue;}
                .green { color: $color-six-green;}
            }
            &.pick {
                background-color: #ffe9c3;
            }
            &.dcheckbox {
                &::after {
                    content: ""; background-color:rgba(200,200,200,.3);
                    position:absolute; top:0; left:0; right:0; bottom:0; z-index: 1;
                    width: 100%; height: 100%; display:block; outline:2px dashed #ccc;
                }
            }
            .odds {
                color:#9a1d0f; height:14px; font-size:9px; line-height:14px; position:absolute; top:1.5px; right:2px;
            }
            .amount {
                height:14px; line-height:14px; font-size:11px;
            }
        }
        .column {
            box-sizing:border-box;
            &:first-child{
                .row {
                    border-left:none;
                }
            }
        }
    }
</style>
<template>
    <ul v-if="list && (list.child || list.length)" class="GameCreditGroup_M" :class="['type-' + COptions.type,type]">

        <template v-if="type == 'normal'">
            {{/*普通单列*/}}
            <p v-if="Title" class="title">{{ Title }}</p>
            <label v-for="(item,index) in list.child" class="row" :class="{'pick': item.pick || item.amount,'dcheckbox': mode === 'box' && (disabled || item.disabled)}" @click="Pick(item)">
                <li v-if="dice" class="goods" :class="IsInt(item.name) ? 'dice' : 'text'">
                    <template v-if="IsInt(item.name)">
                        <a v-for="num in item.name.split('')" class="dice">
                            <img v-if="num == 1" src="../../../../../image/game/dice/1.png">
                            <img v-else-if="num == 2" src="../../../../../image/game/dice/2.png">
                            <img v-else-if="num == 3" src="../../../../../image/game/dice/3.png">
                            <img v-else-if="num == 4" src="../../../../../image/game/dice/4.png">
                            <img v-else-if="num == 5" src="../../../../../image/game/dice/5.png">
                            <img v-else-if="num == 6" src="../../../../../image/game/dice/6.png">
                        </a>
                    </template>
                    <span v-else>{{ item.name }}</span>
                </li>
                <li v-else class="goods" :class="[IsInt(item.name) ? 'ball' : 'text', six_color ? 'six_color' : '']">
                    <lhc-ball v-if="six_color && IsInt(item.name)" :number="item.name" />
                    <span v-else>{{ item.name }}</span>
                    <!-- <span :class="six_color ? CodeColor(item.name) : ''">{{ item.name }}</span> -->
                </li>
                <!-- <div :key="item.id" v-odds="item.odds" class="odds">
                    <template v-if="CalcuOdds(item)">
                        <span>x{{ CalcuOdds(item).odds }}</span><span v-if="CalcuOdds(item).has_double_odds"><span style="padding:0 2px;">/</span>{{ CalcuOdds(item).max_odds }}</span>
                    </template>
                </div>
                <div v-if="!COptions.opt.onlyQuick" class="amount">
                    <span v-show="!Quick && item.amount">{{ item.amount }}元</span>
                </div> -->
                <p style="color: #ff4949;">{{ CalcuOdds(item) ? CalcuOdds(item).odds : '-' }}</p>
            </label>
            <label v-for="(item,index) in Replenish(list.child)" :key="'replenish' + item.i" class="row">
                <div class="replenish">
                    <div v-if="six_color" style="width: 100%; height: 47px;" />
                </div>
            </label>
        </template>

        <template v-else-if="type == 'row'">
            {{/*普通单行*/}}
            <p v-if="Title" class="title">{{ Title }}</p>
            <el-row tag="ul">
                <el-col v-for="(item,index) in list.child" :key="index" tag="li" :span="Span(list.child.length)" class="c-text-c u-bb" :class="{'pick': item.pick}" @click.native="Pick(item)">
                    <template v-if="!Quick">
                        <div class="row">
                            <b class="o-pr-s" :style="color && color[index] ? 'color:' + color[index] : ''">{{ item.name }}</b>
                            <span class="c-text-6 odds">{{ CalcuOdds(item.odds) }}</span>
                        </div>
                        <el-popover popper-class="quick-modul" placement="bottom" width="85" trigger="click" :disabled="!COptions.quick.value || !COptions.quick.value.length > 0 || !COptions.quick.enable" :value="item.quickView">
                            <div v-for="(val,i) in COptions.quick.value" class="quick-item" @click="Input(item,val)">{{ val }}</div>
                            <input slot="reference" v-model.number="item.amount" style="width:90%; margin-bottom:.6rem;" :disabled="disabled">
                        </el-popover>
                    </template>
                    <div v-else class="o-ptb-s">
                        <div class="row" :style="color && color[index] ? 'color:' + color[index] : ''">{{ item.name }}</div>
                        <div class="row odds">{{ CalcuOdds(item.odds) }}</div>
                    </div>
                </el-col>
            </el-row>
        </template>

        <template v-else-if="type == 'queue'">
            {{/*混组单列*/}}
            <p v-if="Title" class="title">{{ Title }}</p>
            <div class="l-flex">
                <div v-for="(pack,unit) in MakeQueue(list,length)" :key="unit" class="column l-flex-1">
                    <GameCreditGroup :list="pack" :title="false" :dice="dice" :min="minQueue" :six_color="six_color" :mode="mode" :pick-mark="pickMark" :disabled="disabled" @box="$emit('box',$event)" />
                </div>
            </div>
        </template>

        <!--         <template v-else-if="type=='queuetab'">
            {{/*选项混组单列 */}}
            <el-row tag="div">
                <el-col :span="Span(list.length)" v-for="(pack,unit) in list" :key="unit">
                    <p class="title" v-if="pack && pack.title" :class="{'active':active==unit}" @click="active=unit">{{ pack.title }}</p>
                </el-col>
            </el-row>
            <el-row tag="div">
                <el-col :span="5" v-for="(pack,unit) in MakeQueue(TabsActive,length)" :key="unit">
                    <GameCreditGroup :list="pack" :title="false" :dice="dice" :pickMark="pickMark"></GameCreditGroup>
                </el-col>
            </el-row>
        </template> -->
        <NumberInput ref="custom" type="model" @change="QuickAmountCustom($event)" />
    </ul>
</template>

<script>
import Credit from '../../../../../mixin/credit.js'
import SixMix from '@lottery.admin.2.0/mixin/six.js'
import LhcBall from './lhc_ball'
export default {
    name: 'GameCreditGroup',
    mixins: [Credit, SixMix],
    data() {
        return {
            //amount : '',
            custom_active: {},
            active: 0
        }
    },
    props: {
        list: {},
        title: {
            default: true
        },
        prompt: {
            type: Array,
            default() {
                return ['号码', '赔率', '金额']
            }
        },
        quick: {
            default: true
        },
        type: {
            default: 'normal' // row
        },
        color: {
            default: null
        },
        length: {
            default: 5
        },
        dice: {
            default: false //  是否是骰子
        },
        min: {

        },
        mode: {
            default: 'normal'
        },
        disabled: {
            default: false
        },
        six_color: {
            default: false
        },
        pickMark: {
            default: false
        },
        oddsanimation: {
            default: true
        }
    },
    computed: {
        Title() {
            if (this.title && this.list && this.list.title) {
                if (typeof this.title === 'string') {
                    return this.title
                }
                return this.list.title
            }
            return ''
        },
        Quick() {
            if (this.COptions.opt && this.COptions.opt.onlyQuick) {
                return false
            }
            return this.COptions.type == 2
        },
        TabsActive() {
            let i = this.active
            return this.list[i]
        },
        minQueue(len) {
            if (this.min && this.length) {
                return this.min / this.length
            }
            return false
        }
    },
    directives: {
        odds: {
            inserted(el, e, vnode, oldvnode) {
                el.setAttribute('oddsanimation', 0)
            },
            update(el, binding, vnode, oldvnode) {
                let {value, oldValue} = binding
                if (oldValue && value != oldValue) {
                    let count = Number(el.getAttribute('oddsanimation'))
                    el.setAttribute('oddsanimation', count + 1)
                    if (el.className.split(' ').indexOf('change') < 0) {
                        el.className = el.className + ' change'
                    }
                    setTimeout(() => {
                        if (el) {
                            let count = Number(el.getAttribute('oddsanimation'))
                            el.setAttribute('oddsanimation', count - 1)
                            if (count <= 1) {
                                let classNames = el.className.split(' ')
                                let arr = []
                                for (let item of classNames) {
                                    if (item != 'change') {
                                        arr.push(item)
                                    }
                                }
                                el.className = arr.join(' ')
                            }
                        }
                    }, 30000)
                }
            }
        }
    },
    methods: {
        Pick(item) {
            if (item.id) {
                console.log('Pick 1', item, this.mode === 'box', this.COptions.type == 3, this.Quick, this.COptions)
                if (this.mode === 'box') {
                    if (!this.disabled && !item.disabled) {
                        this.$set(item, 'pick', !item.pick)
                        this.$emit('box', item)
                    }
                } else if (this.COptions.type == 3) {
                    this.custom_active = item
                    this.$refs['custom'].Open(item.amount ? item.amount : null)
                } else if (this.Quick) {
                    this.$set(item, 'pick', !item.pick)
                } else {
                    let amount = item.amount ? item.amount : 0
                    this.$set(item, 'amount', this.COptions.quick.value[0] + amount)
                }
            }
        },
        QuickAmountCustom(e, item) {
            this.$set(this.custom_active, 'amount', e ? e : null)
        },
        IsInt(str) {
            if (!str) {
                return false
            }
            return /^[0-9]*$/.test(str)
        },
        Input(item, val) {
            this.$set(item, 'amount', val)
            this.$set(item, 'quickView', false)
        },
        Span(len) {
            return this.Big(24).div(len).round(0, 1).toNumber()
        },
        Replenish(list, type, len=this.min) {
            let arr = []
            if (list && len && list.length < len) {
                for (let i=0;i<len-list.length;i++) {
                    arr.push({i})
                }
            }
            return arr
        },
        MakeQueue(item, length=1) {
            let queue = []
            if (item) {
                let {title='', belong, child} = item
                let balls = []
                let other = []
                for (let i=0;i<child.length;i++) {
                    let item = child[i]
                    if (parseInt(item.name) == item.name) {
                        balls[parseInt(item.name-1)] = i
                    } else {
                        other.push(i)
                    }
                }
                for (let i=0;i<length;i++) {
                    queue.push({
                        title, belong, child: []
                    })
                }
                let unit = 0
                for (let index of balls.concat(other)) {
                    let item = child[index]
                    if (unit == length) {
                        unit = 0
                    }
                    queue[unit].child.push(item)
                    unit++
                }
            }
            return queue
        }
    },
    components: {
        LhcBall
    }
}
</script>
