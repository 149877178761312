<style lang="scss">
    @import "../../style/config.scss";
    %appfixed {
        width:100%; height:100%;
        position:fixed; z-index:0; top:0; left:0; right:0; bottom:0;
    }
    .Index {
        @extend %appfixed;
        .app-view {
            @extend %appfixed;
            max-width:768px; max-height:100%; margin:0 auto; background-color:#f0f0f0;
            .block {
                background-color:#fff;
            }
            .tabs {
                background-color:#fff; border-top:1px solid #eee;
                .Icon, .icon, .title {
                    color:#bbb;
                }
                a.active {
                    color:$color-t;
                    .Icon, .icon, .title {
                        color:$color-t;
                    }
                }
                .title {
                    font-size:9px;
                }
            }
        }
        .table {
            width:100%;
            td,th {
                vertical-align:middle; padding:3px; font-size:.6rem;
                &:first-child {padding-left:5px;}
                &:last-child {padding-right:5px;}
            }
            .hbg-1 {background-color:#7d8de0; color:#fff;}
            .hbg-2 {background-color:#b3dfef; color:#333;}
            .hbg-3 {background-color:#fbd7f4; color:#333;}
            .hbg-4 {background-color:#e3fff1; color:#333;}            
            .fbg-1 {background-color:#bbbbbb; color:#fff;}
            .fbg-2 {background-color:#cdcfff; color:#333;}
            .fbg-3 {background-color:#537aa9; color:#fff;}
            .fbg-4 {background-color:#8a8a8a; color:#fff;}
            .zebra {
                tr {
                    td,th {
                        background-color:#fff;
                    }
                }
                tr:nth-child(even) td,th {
                    background-color:#f2f2f2;
                }
            }
            &.table.normal {
                td,th {
                    padding:5px;
                    &:first-child {padding-left:8px;}
                    &:last-child {padding-right:8px;}
                }
            }
        }
        .layout-fixed {
            position:fixed; max-width:$max-width; margin:0 auto;
        }
        .layout-bottom {
            @extend .layout-fixed; border-top:.03rem solid #e5e5e5;
            left:0; right:0; bottom:0; background-color:#fff;
        }
        .announ {
            width:100%; height:100%;
            position:fixed; top:0; left:0; right:0; bottom:0; background-color:rgba(0,0,0,0.5);
            .core{
                width:88%; max-width:360px; height:300px; height:74vh; margin:0 auto;
                background:#fff; border-radius:5px; box-shadow:0 5px 12px rgba(0,0,0,.2); overflow:hidden;
                position:relative; top:24px; top:10vh;
                .header {
                    background-color:#6583da;
                }
                .context {
                    position:absolute; top:8px; left:0; right:0; bottom:8px; overflow-y:auto;
                }
            }
        }
    }
</style>
<template>
    <div class="Index Mobile">
        <div class="app-view l-flex-v">
            <section class="l-flex-1 o-pb-h" style="overflow:auto;" ref="index">
                <keep-alive>
                    <router-view></router-view>
                </keep-alive>
            </section>
            <nav class="tabs l-flex c-text-c" v-if="hasTab">
                <template v-for="(item,index) in tabs">
                    <a class="l-flex-1" v-if="item.name == 'index/service'" :class="{'active':$route.name==item.name}" style="padding:5px 0;" :href="Opt.zxkf_url">
                        <Icon size="1.15" :name="item.icon"></Icon>
                        <p class="title">{{ item.title }}</p>
                    </a>
                    <a class="l-flex-1" v-else :class="{'active':$route.name==item.name}" style="padding:5px 0;" @click="Rd(item.name)">
                        <Icon size="1.15" :name="item.icon"></Icon>
                        <p class="title">{{ item.title }}</p>
                    </a>
                </template>
            </nav>
        </div>
        <div class="announ" v-if="announ.view" style="z-index:3000;" @click.self="announ.view=false">
            <div class="core l-flex-v">
                <div class="header l-flex-c o-p c-color-f">
                    <h6 class="l-flex-1"><b>系统公告</b></h6>
                    <!-- <i class="icon icon-roundclose" @click="announ.view=false"></i> -->
                    <Icon name="roundclose" @click="announ.view=false"></Icon>
                </div>
                <div class="l-flex-1" style="position:relative;">
                    <ul class="context o-plr">
                        <li v-for="(item,index) in announ.list" class="o-pb-s" :class="{'u-bt':index}">
                            <h6 class="c-text-8 o-pt-s">{{ item.title }}</h6>
                            <p class="c-color-g c-text-6 c-line-10">{{ item.create_at }}</p>
                            <div class="c-text-65 o-mt-s c-line-9" v-html="item.content"></div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Index_M',
    data() {
        return {
            parentData: false,
            activeNames: [],
            TabName: 'first',
            bonuslist: [],
            list: [],
            activity : null,
            subDialog : {
                view : false,
                title : null,
                html : null,
            },
            tabActive : 0,
            announ : {
                view : false,
                list : [],
            }
        }
    },
    computed:{
        hasTab(){
            let name = this.$route.name
            for(let item of this.tabs){
                if(item.name === name){
                    return true
                }
            }
            return false
        },
        tabs(){
            return [
                {
                    icon : 'home',
                    title : '首页',
                    name : 'index/home',
                },
                {
                    icon : 'upstage',
                    title : '游戏大厅',
                    name : 'index/game',
                },
                {
                    icon : 'calendar',
                    title : '开奖历史',
                    name : 'index/output',
                },
                {
                    icon : 'peoplelist',
                    title : '我的',
                    name : 'index/my',
                },
            ] 
        },
    },
    watch:{
        '$route.name'(val){
            if(val){
                this.$refs['index'].scrollTo(0,0);
            }
        }
    },
    methods: {
        init(){
            this.Dp('base/_GET_ANNOUN').then(res=>{
                if(res.data && res.data.length){
                    this.$set(this.announ,'list',res.data)
                    if(res.msg == 'not'){
                        this.$set(this.announ,'view',false)
                    }else{
                        this.$set(this.announ,'view',true)
                    }
                    
                }
            })
        },
        tabChange(index){

        },
    },
    components: {

    },
    created(){
        if (!this.$store.state.game.init._init) {
            this.$store.dispatch('game/INIT_GAMES')
        }
    },
    mounted() {
        this.init()
    }
}
</script>
