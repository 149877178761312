<style lang="scss">
    .CreditSystemSubOpten {

    }
</style>
<template>
    <div class="CreditSystemSubOpten o-pb-h">
        <p class="o-ptb-s o-plr c-text-6 c-color-g">基本参数</p>
        <div class="block">
            <Datetime class="u-bb" type="date" title="开奖日期" v-model="Params.number_date" ></Datetime>
            <mt-field class="u-bb" label="期号" placeholder="请输入期号" v-model="Params.number">
                <Button :loading="main._editing" @click="submit(1)" size="small" type="warning" v-if="Params.status==2">颁奖</Button>
                <Button :loading="main._editing" @click="submit(1)" size="small" type="warning" v-if="Params.status==1">重新颁奖</Button>
            </mt-field>
<!--             <div class="o-ptb-s c-text-c" v-if="!Params.id || Params.status < 1">
                <p class="o-ptb-s c-color-g">填写开奖号码并保存即可颁奖</p>
            </div> -->
        </div>
        <p class="o-ptb-s o-plr c-text-6 c-color-g">开奖结果</p>
        <div class="block">
            <mt-field class="u-bb" label="正码一" placeholder="请输入开奖号码" v-model.number="Params.code_one"></mt-field>
            <mt-field class="u-bb" label="正码二" placeholder="请输入开奖号码" v-model.number="Params.code_two"></mt-field>
            <mt-field class="u-bb" label="正码三" placeholder="请输入开奖号码" v-model.number="Params.code_three"></mt-field>
            <mt-field class="u-bb" label="正码四" placeholder="请输入开奖号码" v-model.number="Params.code_four"></mt-field>
            <mt-field class="u-bb" label="正码五" placeholder="请输入开奖号码" v-model.number="Params.code_five"></mt-field>
            <mt-field class="u-bb" label="正码六" placeholder="请输入开奖号码" v-model.number="Params.code_six"></mt-field>
            <mt-field class="u-bb" label="特别码" placeholder="请输入开奖号码" v-model.number="Params.special"></mt-field>            
        </div>
        <div class="layout-bottom">
            <Button :loading="main._editing" size="big" v-if="Params.id" @click="submit(0)" block>提交</Button>
            <Button :loading="main._editing" size="big" v-if="!Params.id" @click="submit()" block>提交</Button>
        </div>
    </div>
</template>

<script>
import StoreMix from '@lottery.admin.2.0/mixin/store.sub.js'
export default {
    name: 'CreditSystemSubOpten',
    mixins : [StoreMix],
    data() {
        return {
            store : 'admin/output_lhc',
            Filter : {
                //games_id : 31,
            },
            Params : {
                number_date : '',
            }
        }
    },
    methods: {
        init(){
            this.DataReset()
            let data = this.$route.params
            for(let key in data){
                this.$set(this.Params,key,this.$route.params[key])
            }
            if(!this.Params.number_date){
                 this.$set(this.Params,'number_date',this.Untimestamp(new Date(new Date().toLocaleDateString()),'yyyy-MM-dd'))
            }
            if(data.date){
                this.$set(this.Params,'number_date',data.date)
            }
            if(data.data){
                let str = data.data
                let normal = str.split('+')[0]
                if(normal){
                    normal = normal.split(',')
                }
                let special = str.split('+')[1]
                if(special){
                    special = special.split(',')
                    normal = normal.concat(special)
                }
                this.$set(this.Params,'code_one',normal[0])
                this.$set(this.Params,'code_two',normal[1])
                this.$set(this.Params,'code_three',normal[2])
                this.$set(this.Params,'code_four',normal[3])
                this.$set(this.Params,'code_five',normal[4])
                this.$set(this.Params,'code_six',normal[5])
                this.$set(this.Params,'special',special[0])
            }
        },
        submit(num){
            this.Params.open_status = num
            this.Submit()
        },
        Submit(model=this.storePath[1]){
            let params = this.Origin(this.Params)
            return params.id ?
                this.Put(params,model) :
                this.Post(params,model)
        },
        Put(params=this.Origin(this.Params),model=this.storePath[1],callback){
            let path = `${this.storePath[0]}/_PUT_${model.toUpperCase()}`
            if(!callback){
                callback = (res) =>{
                    if(res){
                        this.Suc('已成功提交至服务器')
                        this.Item(params.id).then(res=>{
                            if(res.status == 1){
                                this.view = false
                            }
                            if(res.status == 2){
                                this.$set(this,'Params',{
                                    ...this.Params,
                                    ...res,
                                })
                            }
                        })
                        this.Back()
                    }
                    return res
                }
            }
            return this.$store.dispatch(path,{data:params,id:params.id||params.sequence}).then(callback)
        },
    },
    computed : {

    },
    activated(){
        this.init()
    },
}
</script>
