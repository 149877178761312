
<style lang="scss">
    .LoginRegisteredM {
            
    }
</style>

<template>
    <div class="LoginRegisteredM">
        <div class="login-box">
            <div class="login-logo">
                <img :src="Opt.logo_url" alt="无限彩票Logo">
            </div>
<!--             <div class="login-text">
                <p>如进入网站有收到关于本站关闭或者转新网</p>
                <p>请立即联系本站24小时在线客服</p>
            </div> -->
            <div class="login-account">
                <el-input id="username" v-model="params.username" placeholder="请输入帐号">
                    <template slot="prepend"><img :src="this.Config.base_img_m + '/login/account.png'" class="icon"></template>
                </el-input>
            </div>
            <div class="login-password">
                <el-input id="password" v-model="params.password" type="password" placeholder="请输入密码">
                    <template slot="prepend"><img :src="this.Config.base_img_m + '/login/password.png'" class="icon"></template>
                </el-input>
            </div>
            <div class="login-btn">
                <el-button id="login" style="width:100%" @click="submit" :loading="isDisable" type="primary">登录</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import signMixin from '../../mixin/sign'
export default {
    name: 'LoginRegisteredM',
    mixins: [signMixin],
    data() {
        return {
            key: null,
            poshide: false,
            state: false,
            txt: '点击按钮进行验证',
        }
    },
    computed: {

    },
    methods: {
        init() {
            this.DataReset('params'); // 重置表单
        },
        jump() {
            this.state = true
            this.txt = '验证成功'
        },
    },
    activated() {
        this.init()
    },
    mounted() {

    }
}
</script>
