import Vue from 'vue'

import VueRouter from 'vue-router'
Vue.use(VueRouter);


let work = [
    require('./routers/index.js').default,
    // require('./routers/game.js').default,
   // require('./routers/sub.js').default,
    require('./routers/other.js').default,
]
let routes = []
for (let i = 0; i < work.length; i++) {
    let name = work[i].name;
    let icon = work[i].icon;
    let list = work[i].list;
    for (let x = 0; x < list.length; x++) {
        list[x].meta.index = i;
        list[x].meta.category = name;
        list[x].meta.category_icon = icon;
        routes.push(list[x])
    }
}

export default new VueRouter({
    routes
})
