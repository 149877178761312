<style lang="scss">
    .CreditSubQuery {
        label {
            display:inline-block; width:90px; vertical-align:middle;
        }
    }
</style>
<template>
    <div class="CreditSubQuery">
        <div class="block o-p u-bb l-flex-c">
            <div class="l-flex-1">
                <h6 class="c-line-12"><b>{{ item.games }}</b></h6>
                <p>期号：{{ item.lottery_number }}</p>
            </div>
            <span>{{ item.status }}</span>
        </div>
        <div class="block o-mt u-bt u-bb">
            <li class="u-bb o-plr o-ptb-s l-flex-c">
                <label>注单编号：</label>
                <span>{{ item.sequence }}</span>
            </li>
            <li class="u-bb o-plr o-ptb-s l-flex-c">
                <label>下注时间：</label>
                <span>{{ item.create_at }}</span>
            </li>
            <li class="u-bb o-plr o-ptb-s l-flex-c">
                <label>游戏玩法：</label>
                <span>{{ item.plays }}</span>
            </li>
            <li class="u-bb o-plr o-ptb-s l-flex-c">
                <label>盘口赔率：</label>
                <span>{{ item.side }} / {{ item.odds }}</span>
            </li>
            <li class="u-bb o-plr o-ptb-s l-flex-c">
                <label>下注内容：</label>
                <span>{{ item.code }}</span>
            </li>
            <li class="u-bb o-plr o-ptb-s l-flex-c">
                <label>开奖号码：</label>
                <span>{{ item.code_number ? item.code_number : '-' }}</span>
            </li>
            <li class="u-bb o-plr o-ptb-s l-flex-c">
                <label>投注金额：</label>
                <b class="c-color-e">{{ item.amount }}</b>
            </li>
            <li class="u-bb o-plr o-ptb-s l-flex-c">
                <label>平台派奖：</label>
                <span>{{ item.bonus }}</span>
            </li>
            <li class="u-bb o-plr o-ptb-s l-flex-c">
                <label>退水：</label>
                <span>{{ item.backwater }}</span>
            </li>
            <li class="u-bb o-plr o-ptb-s l-flex-c">
                <label>结果：</label>
                <span>{{ item.result ? item.result : '-' }}</span>
            </li>
        </div>
    </div>
</template>

<script>
import StoreMix from '@lottery.admin.2.0/mixin/store.sub.js'
export default {
    name: 'CreditSubQuery',
    mixins : [StoreMix],
    data() {
        return {
            store : 'myRecord/order',
            Filter : {

            },
        }
    },
    methods: {
        init(){
          
        },
    },
    computed : {
        item(){
            return this.$route.params ? this.$route.params : {}
        }
    },
    activated(){
        this.init()
    },
}
</script>
