<style lang="scss">
    @import "../../../style/config.scss";
    .CreditQuery {
        padding-top:40px;
        a.btn {
            padding:5px 9px; margin:0 3px; background-color:#f0f0f0; color:#999; border-radius:4px;
            &.active {
                background-color:$color-t; color:#fff;
            }
        }
    }
</style>
<template>
    <div class="CreditQuery">
        <HeadLimit v-model="Filter.interval" @change="MakeFilter('empty')">
            <Actionsheet v-model="Filter.games_id" title="游戏" :actions="[{id: 0, title: '全部'}, ...Opt.hasDir.game]" size="small" name="title" val="id" emptytext="所有游戏" @change="MakeFilter('empty')"></Actionsheet>
        </HeadLimit>
        <PullTo :is-top-bounce="false" :top-load-method="LoadTop" v-infinite-scroll="LoadMore" :is-bottom-bounce="false" :infinite-scroll-disabled="!main._init || main._loading || !main._more" infinite-scroll-distance="10">
            <div class="c-bg-f o-ptb u-bb c-text-c">
                <a class="btn" v-for="(item,index) in datatime" :class="{'active':Filter.interval && Filter.interval[0] == item.start && Filter.interval[1] == item.end}" @click="PickDate(item.name)">{{ item.title }}</a>
            </div>
            <li v-for="(item,index) in main._list" @click="Go('index/pages/sub/query',item)" class="block o-mt-s"> 
                <table class="table u-b" border="0" cellspacing="1" cellpadding="0">
                    <tr class="hbg-1">
                        <td colspan="4">注单号：{{ item.sequence }}</td>
                        <td style="text-align:right;">{{ item.status }}</td>
                    </tr>
                    <tr class="hbg-2">
                        <td style="text-align:center;">游戏</td>
                        <td style="text-align:center;">期号</td>
                        <td style="text-align:center;">盘类</td>
                        <td style="text-align:center;">玩法</td>
                        <td style="text-align:center;">赔率</td>
                    </tr>
                    <tr>
                        <td style="text-align:center;">{{ item.games }}</td>
                        <td style="text-align:center;">{{ item.lottery_number }}</td>
                        <td style="text-align:center;">{{ item.side }}</td>
                        <td style="text-align:center;">{{ item.plays }}</td>
                        <td style="text-align:center;">{{ item.odds }}</td>
                    </tr>
                    <tr class="hbg-2">
                        <td style="text-align:center;">投注时间</td>
                        <td style="text-align:center;">金额</td>
                        <td style="text-align:center;">派奖</td>
                        <td style="text-align:center;">退水</td>
                        <td style="text-align:center;">结果</td>
                    </tr>
                    <tr>
                        <td style="text-align:center;">{{ Time(item.create_at,'yy-MM-dd hh:mm') }}</td>
                        <td style="text-align:center;"><b class="c-color-e">{{ item.amount }}</b></td>
                        <td style="text-align:center;">{{ item.bonus }}</td>
                        <td style="text-align:center;">{{ item.backwater }}</td>
                        <td style="text-align:center;">
                            
                            <div v-if="item.result > 0">
                                    {{ item.result }}
                                </div>
                                <div v-if="item.result < 1">
                                    <b class="c-color-e">{{ item.result }}</b>
                                </div>
                            
                            </td>
                    </tr>
                    <tr>
                        <td class="u-bt" colspan="5" style="text-align:center;">投注内容：{{ CheckContextLength(item.code,100) }}</td>
                    </tr>
                </table>
            </li>
            <Loading :main="main"></Loading>
        </PullTo>
    </div>
</template>

<script>
import StoreMix from '@lottery.mobile/mixin/store.js'
import Rolling from '@lottery.mobile/mixin/rolling.js'
export default {
    name: 'CreditQuery',
    mixins : [StoreMix,Rolling],
    data() {
        return {
            store : 'myRecord/order',
            Filter : {
                games_id : undefined,
                lottery_id : null,
                per_page : 24,
            },
            expect : {
                list : [],
            },
            summary : null,
            ready : false,
            datatime : [
                {name:'day',title:'今日',},
                {name:'yesterday',title:'昨天',},
                {name:'lweek',title:'上周',},
                {name:'week',title:'本周',},
                {name:'lmonth',title:'上月',},
                {name:'month',title:'本月',},
            ]
        }
    },
    methods: {
        init(){
            this.DataReset('Filter')
            this.FilterIntervalSet('day')
            for (let item of this.datatime) {
                this.$set(item, 'start', this.FilterInterval(item.name)[0])
                this.$set(item, 'end', this.FilterInterval(item.name)[1])
            }
            this.Get()
        },
        PickDate(type) {
            this.FilterIntervalSet(type)
            this.Get(1)
        }
    },
    mounted(){
        this.$set(this.Roll,'target',document.getElementsByClassName('page-view')[0])
    },
    beforeRouteEnter(to, from, next) {
        next(vm=>{
            if(from && from.name === 'index/pages/sub/query'){
                vm.Roll.enable = true
            }else{
                vm.Roll.enable = false
                vm.init()
            }
        })
    },
}
</script>