<style lang="scss">
    @import "../../../../style/config.scss";
    .CreditSubAccount{
        .box {
            overflow:hidden;
        }
        .row {
            width:33.33%; float:left; padding:7px 0;
            span {
                padding-left:2px;
            }
            .icon {
                color:#ccc;
            }
            &.active {
                .icon {
                    color:$color-t;
                }
            }
        }
    }
</style>
<template>
    <div class="CreditSubAccount">
        <div class="block o-mt">
            <mt-field class="u-bb" label="帐号" placeholder="请输入帐号" v-model="Params.account" :readonly="!!Params.id"></mt-field>
            <mt-field class="u-bb" label="名称" placeholder="请输入名称" v-model="Params.name"></mt-field>
            <mt-field class="u-bb" label="密码" placeholder="请输入密码" v-model="Params.pass"></mt-field>
        </div>

        <div class="block o-mt">
            <div class="o-p u-bb">帐号权限</div>
            <ul class="box o-p">
                <li class="row" v-for="(item,index) in authDir" v-if="item.view && item.enable" :class="{'active':menu[index]}" @click="Pick(index)">
                    <Icon class="icon" name="roundcheckfill"></Icon>
                    <span>{{ item.title }}</span>
                </li>
            </ul>
        </div>
        <div class="layout-bottom">
            <Button size="big" @click="Submit()" block>提交</Button>
        </div>
    </div>
</template>

<script>
import StoreMix from '@lottery.admin.2.0/mixin/store.sub.js'
export default {
    name: 'CreditSubAccount',
    mixins : [StoreMix],
    data() {
        return {
            store : 'admin/account',
            Filter : {
                //games_id : 31,
            },
            Params : {
                menu : '1',
            },
        }
    },
    methods: {
        init(){
            this.DataReset()
            for(let key in this.$route.params){
                this.$set(this.Params,key,this.$route.params[key])
            }
        },
        Pick(index){
            let menu = this.Origin(this.menu)
            menu[index] = !menu[index]
            let arr = []
            for(let i in menu){
                if(menu[i]){
                    arr.push(i)
                }
            }
            this.$set(this.Params,'menu',arr.join(','))
        },
    },
    computed : {
        authDir(){
            let dir = this.Origin(this.Opt.routerAuthDir)
            if(this.Opt.isSub){
                for(let key in dir){
                    if(!this.Opt.isSub[key]){
                        dir[key].enable = false
                    }
                }
            }
            return dir
        },
        menu:{
            get:function(){
                try{
                    let obj = {}
                    for(let key of this.Params.menu.split(',')){
                        obj[key] = true
                    }
                    return obj
                }catch(err){
                    return {}
                }
                return {}
            },
            // set:function(val){
            //     this.$set( this.Params,'menu',val ? val.join(',') : '')
            // }
        }
    },
    activated(){
        this.init()
    },
}
</script>
