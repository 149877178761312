<style lang="scss">
.OpeningTransmitMudul {
    position:fixed; top:0; left:0; right:0; bottom:0; background-color:rgba(0,0,0,.5);
    .core {
        width:100%; height:300px; height:72vh;
        position:absolute; left:0; right:0; bottom:0; background-color:#fff; box-shadow:2px 0 8px rgba(0,0,0,.3);
        .list {
            position:relative;
        }
    }
    .el-input__inner {
        padding:0 7px;
    }
    .el-dialog {
        width: 680px;
        min-width: 720px;
        max-width: 720px;
    }
    .el-dialog__body {
        padding-top:15px;
    }
    table.table {

        tr td {
            padding-top:5px!important; padding-bottom:5px!important; font-size:10.5px;
            input {
                outline:none; border:1px solid #ccc; height:22px; line-height:22px; padding:0 3px; border-radius:4px;
            }
        }
    }
}
</style>
<template>
    <div v-if="view" class="OpeningTransmitMudul" style="z-index:999;">
        <div class="core l-flex-v">
            <!-- <h6 class="o-ptb-s o-plr">走飞确认列表</h6> -->
            <div class="list l-flex-1">
                <div style="position:absolute; width:100%; height:100%; top:0; left:0; right:0; bottom:0; overflow-y:auto;">
                    <table class="table u-bb">
                        <thead>
                            <tr>
                                <td>项目</td>
                                <td>赔率</td>
                                <td v-if="type === 'admin'">退水</td>
                                <td>有效金额</td>
                                <td>输赢</td>
                                <!-- <td>号码</td> -->
                                <td>走飞金额</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item,index) in Params.list" v-if="Params && Params.list">
                                <td>{{ item.title }}</td>
                                <td>
                                    <template v-if="item.hand">
                                        <input v-model.number="item.hand.odds" size="mini" style="width:30px;">
                                    </template>
                                    <span v-else>
                                        <span v-if="item.old_odds" class="c-color-g c-text-6">{{ item.old_odds }} ></span>
                                        <b class="c-color-e">{{ item.odds }}</b>
                                    </span>
                                </td>
                                <td v-if="type === 'admin'">
                                    <template v-if="item.hand">
                                        <input v-model.number="item.hand.backwater" size="mini" style="width:30px;">
                                    </template>
                                    <span v-else>
                                        {{ item.backwater }}
                                    </span>
                                </td>
                                <td>{{ item.amount }}</td>
                                <td>{{ item.win }}</td>
                                <!-- <td>{{ item.codes ? item.codes : '-' }}</td> -->
                                <td>
                                    <input v-if="!item.suc" v-model.number="item.val" style="width:55px;">
                                    <span v-else>已成功走飞</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="o-ptb-s o-plr">
                <div class="l-flex-c">
                    <div class="l-flex-1">
                        <Button type="warning" full @click="Cancel()">取消</Button>
                    </div>
                    <div class="l-flex-2 o-pl-s">
                        <Button full @click="Submit()">确认走飞</Button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import StoreMix from '@lottery.admin.2.0/mixin/store.modul.js'
export default {
    name: 'OpeningTransmitMudul',
    mixins: [StoreMix],
    data() {
        return {
            double: false
            //store : 'game/trader',
        }
    },
    props: {
        model: {},
        type: {
            default: 'normal' // admin
        },
        OperateAuth: {}
    },
    methods: {
        init() {
            this.CheckDouble()
        },
        Submit() {
            this.$emit('confirm', this.Params)
        },
        Cancel() {
            this.view = false
            this.$emit('cancel')
        },
        CheckDouble() {
            this.double = false
            if (this.OperateAuth.bind === 3 && this.Params && this.Params.list) {
                for (let item of this.Params.list) {
                    if (item.title == '三中二' || item.title == '二中特') {
                        return this.double = true
                    }
                }
            }
        }
    }
}
</script>
