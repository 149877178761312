import Vue from 'vue'

// 分页控件
import Pagination from '@lottery.2.0/components/pagination.vue'
Vue.component('Pagination',Pagination)

import Loading from '@lottery.2.0/components/loading.vue'
Vue.component('Loading',Loading)

import Load from '@lottery.2.0/components/load.vue'
Vue.component('Load',Load)

import Avatar from '@lottery.2.0/components/avatar.vue'
Vue.component('Avatar',Avatar)

import ImageUnload from '@lottery.admin.2.0/components/image-unload.vue'
Vue.component('ImageUnload',ImageUnload)

import Sound from '@lottery.2.0/components/sound.vue'
Vue.component('Sound',Sound)

import Button from '../components/button.vue'
Vue.component('Button',Button)

import Actionsheet from '../components/actionsheet.vue'
Vue.component('Actionsheet',Actionsheet)

import Datetime from '../components/datetime.vue'
Vue.component('Datetime',Datetime)

import HeadNav from '../components/headnav.vue'
Vue.component('HeadNav',HeadNav)

import HeadLimit from '../components/headlimit.vue'
Vue.component('HeadLimit',HeadLimit)

import Icon from '@lottery.2.0/components/icon.vue'
Vue.component('Icon',Icon)

import NumberInput from '../components/numinput.vue'
Vue.component('NumberInput',NumberInput)

import Password from '@lottery.2.0/components/password.vue'
Vue.component('Password',Password)

import HmSwitch from '../components/switch.vue'
Vue.component('HmSwitch',HmSwitch)

import PullTo from 'vue-pull-to'
Vue.component('PullTo',PullTo)
