
<style lang="scss">
    .LoginM {
        position:absolute; top:0; left:0; bottom:0; right:0;
        .login-box {
            max-width:310px; margin:0 auto;
            form {
                padding:32px 20px; background:#fff; margin-top:10%; margin-top:15vh; border-radius:6px; box-shadow:0 3px 12px rgba(0,0,0,.1); background-color:rgba(255,255,255,.9); border:3px solid #c0c0c0;
            }
            input {
                width:100%; box-sizing:border-box; border:.05rem solid #ddd; outline:none;
                height:2.2rem; line-height:2.2rem; padding:0 .5rem; border-radius:4px;
            }
        }
        .line {
            max-width:310px; margin:10px auto; background:#fff; border-radius:6px; box-shadow:0 3px 12px rgba(0,0,0,.1); overflow:hidden;
        }
    }
</style>

<template>
    <div class="LoginM">
        <div class="login-box">
            <form>                
                <div class="o-mt">
                    <p class="c-text-65 c-line-12">用户名</p>
                    <input id="username" v-model="params.username" autocomplete="off" placeholder="请输入帐号">
                </div>
                <div class="o-mt">
                    <p class="c-text-65 c-line-12">密码</p>
                    <input id="password" v-model="params.pass" type="password" autocomplete="off" placeholder="请输入密码">
                </div>
                <div class="o-mt-l">
                    <Button type="default" full @click="submit">登录</Button>
                </div>
                <ul class="line c-text-c" style="display: flex; align-items: center; text-align:center;margin-top: 20px;">
                    <div v-for="(item, index) in lines" v-if="index < 3" :key="index" style="flex: 1; background-color: #FFF; display: flex; align-items: center;">
                        <div v-if="index > 0" style="width: 1px; height: 20px; background-color: #ddd;" />
                        <li style="flex: 1; line-height: 36px;">
                            <a class="inside" :href="item.url" target="_blank">
                                 <!-- <Icon class="icon" size="1" name="roundcheckfill" /> -->
                                <span style="padding-left:4px;">线路{{ index + 1 }}</span>
                            </a>
                        </li>
                    </div>
                </ul>
            </form>
            <div class="c-text-c o-ptb-l" style="color:#fff;">
                <a :href="ToPcLinks" style="text-decoration:underline;">切换为电脑版</a>
            </div>
        </div>
    </div>
</template>

<script>
import signMixin from '../../mixin/sign'
export default {
    name: 'LoginM',
    mixins: [signMixin],
    data() {
        return {
            key: null,
            poshide: false,
            state: false,
            txt: '点击按钮进行验证',
            mode : 1,
            appdownload : '',
            lines: []
        }
    },
    computed: {
        ToPcLinks(){
            let loc = window.location
            let host = loc.host.split('.')
            if(host.length > 2){
                host[0] = 'www'
            }else{
                host.unshift('www')
            }
            return `${loc.protocol}//${host.join('.')}/index.html`
        },
    },
    methods: {
        init() {
            this.DataReset('params') // 重置表单
            this.Dp('base/GET_LINE').then(res => {
                if (!res.err) {
                    this.$set(this, 'lines', res.data.map(url => ({url, ping: Math.round(Math.random()*192)})))
                }
            })
        },
        submit_signin(){
            return this.$store.dispatch('base/BASE_SIGNIN', this.params).then(res => {
                this.Toast('登录成功')
                // 存储数据
                localStorage.setItem('fantantoken', res.token);
                this.Cache('token', res.token)
                return this.Login(true)
            })
        }
    },
    activated() {
        this.init()
    },
    mounted() {

    },
}

</script>
