<style lang="scss">
   // @import "../style/config.scss";
    .Actionsheet {
        &.small {            
            .mint-cell, .mint-cell:last-child {
                min-height:39px!important; height:39px!important; line-height:39px!important; 
            }
            .mint-cell-title {
                display:none;
            }
            .mint-cell-value {
                font-size:14px;
            }
        }
        // &.mini {
        //     .mint-cell, .mint-cell:last-child {
        //         min-height:36px!important; height:36px!important; line-height:36px!important; 
        //     }
        //     .mint-cell-title, .mint-cell-value {
        //         font-size:12px;
        //     }
        //     .mint-cell-wrapper {
        //         padding:0 6px;
        //     }
        //     .mint-cell-value.is-link {
        //         margin-right:11px;
        //     }
        //     .mint-cell-allow-right:after {
        //         right:8px;
        //     }
        // }
    }
</style>
<template>
    <div class="Actionsheet" :class="size">
        <div @click="open()">
            <slot></slot>
            <mt-cell :title="title" is-link>
                <span v-if="Label">{{ Label }}</span>
                <span v-else style="color:#ccc;">{{ placeholder }}</span>
            </mt-cell>
        </div>
        <mt-actionsheet v-if="Actions" v-model="view" :actions="Actions"></mt-actionsheet>
    </div>
</template>

<script>
    export default {
        name : 'Actionsheet',
        data(){
            return {
                view : false,
                tvalue : null,
            }
        },
        props : {
            size : {
                default : 'small'
            },
            actions : {
                default : null
            },
            name : {
                default : 'name'
            },
            val : {
                default : 'value'
            },
            title : {
                default : '',
            },
            value : {},
            disabled : {
                default : false
            },
            size : {
                default : 'normal'
            },
            emptytext : {
                default : ''
            },
            placeholder : {
                default : ''
            }
        },
        watch : {
            value(val){
                this.$set(this,'tvalue',val)
            }
        },
        computed:{
            Actions(){
                let list = this.actions
                let name = this.name
                let val = this.val
                if(list && list.length){
                    let actions = []
                    for(let item of list){
                        let isObj = typeof item === 'object'
                        actions.push({
                            name : isObj ? item[name] : item,
                            method : ()=>{
                                this.$set(this,'tvalue',isObj ? item[val] : item)
                                this.$emit('input',isObj ? item[val] : item)
                                this.$emit('change',isObj ? item[val] : item)
                            }
                        })
                    }
                    return actions
                }
                return null
            },
            Label(){
                let value = this.tvalue
                let name = this.name
                let val = this.val
                for(let item of this.actions){
                    let isObj = typeof item === 'object'
                    if(isObj && item[val] == value){
                        return item[name]
                    }
                    if(!isObj && item === value){
                        return item
                    }
                }
                return this.emptytext
            },
        },
        methods: {
            open(){
                if(this.Actions && !this.disabled){
                    this.view = true
                }
            },
        },
        mounted(){
            this.$set(this,'tvalue',this.value)
        },
    }
</script>