<style lang="scss">
    @import "../../../style/config.scss";
    .Game {
        .row {
            .cover {
                width:64px; line-height:64px; 
                img {
                    width:100%; height:100%; display:block;
                }
            }
        }
    }
</style>
<template>
    <div class="Game">
        <ul class="list block">
            <li class="row" v-for="(item,index) in this.Opt.hasDir.game" @click="Go('index/pages/table',{id:item.id})">
                <div class="l-flex-c o-p-s">
                    <div class="cover">
                        <img :src="Img(item.info.logo_img)">
                    </div>
                    <div class="l-flex-1 o-plr">
                        <h6><b>{{ item.title }}</b></h6>
                    </div>
                    <Icon name="right" size=".7" style="color:#ccc"></Icon>
                </div>
            </li>
            <a :href="user_link">
                <li class="row">
                <div class="l-flex-c o-p-s">
                    <div class="cover">
                        <img src="https://api.ac6677.com/static/images/logo/quanming.jpg">
                    </div>
                    <div class="l-flex-1 o-plr">
                        <h6><b>番摊</b></h6>
                    </div>
                    <Icon name="right" size=".7" style="color:#ccc"></Icon>
                </div>
            </li>
                </a>
        </ul>
    </div>
</template>

<script>
import StoreMix from '@lottery.mobile/mixin/store.js'
export default {
    name: 'Game',
    mixins : [StoreMix],
    data() {
        return {
             user_link : 'https://api.ac6677.com/v3/ft?id='+localStorage.getItem('fantantoken'),
        }
    },
    methods: {
        init(){
            
        },
    },
    components: {

    },
    mounted() {
        this.init()
    }
}
</script>
