<style lang="scss">
    @import "../../style/config.scss";
    .Err {
        padding:6rem 0;
        h1 {font-size:4rem; margin:.7rem 0; }
        .name {
            color:#f1f548;
        }
    }
</style>

<template>
    <div class="Err c-text-c">
        <h1 class="c-color-f"><b>404</b></h1>
        <h2 class="name"><b>THIS PAGE NOT FOUND !</b></h2>
        <h5 class="o-mt c-color-f">抱歉，当前页面不存在</h5>
        <Button class="o-mt-h" type="default" @click="Rd('index')">返回首页</Button>
    </div>
</template>

<script>

export default {
    name : 'Error',
    data (){
        return {

        }
    },
}
</script>