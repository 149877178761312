<style lang="scss">
    @import "../../../../style/config.scss";
    .CreditSystemBetcancel {
        .mint-field .mint-cell-title {
            width:80px;
        }
        label {
            width:100px; display:inline-block;
        }
    }
</style>
<template>
    <div class="CreditSystemBetcancel">
        <div class="block o-mt">
            <mt-field class="u-bb" label="注单号" placeholder="请输入关键字" v-model="Filter.sequence">
                <Button @click="GetOrder()" class="o-ml" size="small" :disabled="!Filter.sequence">搜索</Button>
            </mt-field>            
        </div>
        <div class="block o-mt" v-if="list">
            <div v-for="(item,index) in list" class="o-plr">
                <div class="o-ptb c-line-14">
                    <p><label>注单号</label>{{ item.sequence }}</p>
                    <p><label>用户名</label>{{ item.username }}</p>
                    <p><label>游戏</label>{{ item.games }}</p>
                    <p><label>期号</label>{{ item.lottery_number }}</p>
                    <p><label>盘类玩法</label>{{ item.side }} / {{ item.plays }}</p>
                    <p><label>赔率</label>{{ item.odds }}</p>
                    <p><label>金额</label>{{ item.amount }}</p>
                    <p><label>下注时间</label>{{ item.create_at }}</p>
                    <p><label>状态</label><b :class="{'c-color-e':item.status=='删除'}">{{ item.status }}</b></p>
                </div>
                <div class="o-ptb u-bt c-text-r">
                    <Button type="warning" size="small" @click="Cancel(item,2)">撤单</Button>
                    <Button type="danger" size="small" @click="Cancel(item,9)">删除</Button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import StoreMix from '@lottery.2.0/mixin/store.js'
export default {
    name: 'CreditSystemBetcancel',
    mixins : [StoreMix],
    data() {
        return {
            store : 'admin/order',
            Filter : {
                sequence : '',
            },
            list : null,
        }
    },
    methods: {
        init(){
            // this.FilterIntervalSet('day')
        },
        GetOrder(){
            let params = {
                sequence:this.Filter.sequence
            }
            this.Dp('admin/_GET_ORDERINFO',{params}).then(res=>{
                if(res.data){
                    this.$set(this,'list',[res.data])
                }else{
                    this.Err('注单不存在')
                }
            })
        },
        Cancel(item,status){
            this.Dp('admin/_PUT_ORDER',{id:item.id,data:{status}}).then(res=>{
                this.Suc('注单已被删除')
                this.GetOrder()
            })
        },
    },
    components : {

    },
    mounted(){
        this.init()
    },
}
</script>
