<style lang="scss"></style>
<template>
    <div class="OperateLhc-7">
        <div class="mobile-tabs u-bb">
            <div v-for="item in tabs.list" class="mobile-tab" :class="{'active': tabs.active === item}" @click="tabs.active = item">{{ item }}</div>
        </div>
        <template v-for="(item,unit) in tabs.list" v-if="tabs.active === item">
            <board-fast :key="unit" :client="client" :group="[unit]" :step="true" type="lhc" :default-step="0.1" />
            <group :client="client" :list="Table[unit]" :length="1" show-total="end" />
        </template>
    </div>
</template>

<script>
import CommonMix from '../common.js'
import Group from '../group_mobile.vue'
import BoardFast from '../fast_mobile.vue'
export default {
    name: 'OperateLhc7',
    mixins: [CommonMix],
    data() {
        return {
            tabs: {
                active: '正码一',
                list: ['正码一', '正码二', '正码三', '正码四', '正码五', '正码六']
            }
        }
    },
    props: {
        client: {}
    },
    computed: {

    },
    methods: {

    },
    components: {
        Group,
        BoardFast
    }
}
</script>
