<style lang="scss">
    .CreditAnnoun {
        .title {font-size:.85rem; line-height:1.5em; font-weight:bold; color:#2c5bb9;}
        .content {padding-top:.3rem; padding-bottom:.5rem; line-height:1.5em;}
    }
</style>
<template>
    <div class="CreditAnnoun">
        <div class="block">
            <ul class="o-p o-pt-l">
                <li v-for="(item,index) in main._list">
                    <h4 class="title">{{ item.title }}</h4>
                    <p class="time">{{ item.create_at }}</p>
                    <div class="content" v-html="item.content"></div>
                </li>
            </ul>
            <Loading :main="main"></Loading>
        </div>
    </div>
</template>

<script>
import StoreMix from '@lottery.2.0/mixin/store.js'
export default {
    name: 'CreditAnnoun',
    mixins : [StoreMix],
    data() {
        return {
            store : 'base/announ',
        }
    },
    methods: {
        init() {
            this.Get()
        },
    },
    components : {
        //editer,
    },
    activated(){
        this.init()
    },
}
</script>
