<style lang="scss">
    @import "../../../../style/config.scss";
    .CreditSystemBase {
        padding-bottom: 60px;
        header {
            background-color:#7BAAFF; font-size:16px; font-weight:bold; text-align:center; padding:3px 0;
        }
        .block-bg {
            background-color:#DFF9FF;
        }
        .row {padding:2px 0;}
        .mint-field  {
            .mint-cell-title {
                width:120px;
            }            
            &.longlabel{
                .mint-cell-title {
                    width:160px;
                }
            }
        }
    }
</style>
<template>
    <div class="CreditSystemBase">
        <div>
            <mt-cell class="u-bb" title="全系统维护中">
                <HmSwitch v-model="Params.web_maintain_level" :active-value="'1'" :inactive-value="'2'"></HmSwitch>
            </mt-cell>
            <Actionsheet class="u-bb" v-model="Params.animals_year" :actions="['鼠','牛','虎','兔','龙','蛇','马','羊','猴','鸡','狗','猪']" title="本年生肖"></Actionsheet>
            <mt-field class="u-bb" label="管理人员公告" type="textarea" rows="3" v-model="Params.platform_lantern_members"></mt-field>
            <mt-field label="会员跑马灯" type="textarea" rows="3" v-model="Params.platform_lantern_agent"></mt-field>
            
        </div>


        <p class="o-p-s c-text-6" style="color:#999;">六合彩设定</p>
        <div class="block">
            <mt-cell class="u-bb" title="系统维护中">
                <HmSwitch v-model="Params.lhc_is_service" :active-value="'1'" :inactive-value="'0'"></HmSwitch>
            </mt-cell>
            <mt-cell class="u-bb" title="大股东操盘功能">
                <HmSwitch v-model="Params.lhc_trander_status" :active-value="'1'" :inactive-value="'0'"></HmSwitch>
            </mt-cell>
            <mt-cell class="u-bb" title="管理层特码减倍">
                <HmSwitch v-model="Params.lhc_special_code_reduction_status" :active-value="'1'" :inactive-value="'0'"></HmSwitch>
            </mt-cell>
            <mt-cell class="u-bb" title="走飞启动中">
                <HmSwitch v-model="Params.lhc_transfer_status" :active-value="'1'" :inactive-value="'0'"></HmSwitch>
            </mt-cell>
            <mt-cell class="u-bb" title="允许自动走飞">
                <HmSwitch v-model="Params.lhc_auto_matic_transfer_status" :active-value="'1'" :inactive-value="'0'"></HmSwitch>
            </mt-cell>
            <mt-cell class="u-bb" title="走飞值盘中修改">
                <HmSwitch v-model="Params.lhc_transfer_value_status" :active-value="'1'" :inactive-value="'0'"></HmSwitch>
            </mt-cell>
        </div>
        
        <p class="o-p-s c-text-6" style="color:#999;">新澳门时间设定</p>
        <div class="block">
            <Datetime class="u-bb" type="time" title="每期开盘时间" v-model="Params.lhc_xac_open_time" ></Datetime>
            <Datetime class="u-bb" type="time" title="连码截至时间" v-model="Params.lhc_xac_serial_end_time" ></Datetime>
            <Datetime class="u-bb" type="time" title="正码截至时间" v-model="Params.lhc_xac_positive_time" ></Datetime>
            <Datetime class="u-bb" type="time" title="两面截至时间" v-model="Params.lhc_xac_side_end_time" ></Datetime>
            <Datetime class="u-bb" type="time" title="特码截至时间" v-model="Params.lhc_xac_special_time" ></Datetime>
        </div>
        <p class="o-p-s c-text-6" style="color:#999;">香港时间设定</p>
        <div class="block">
            <Datetime class="u-bb" type="time" title="每期开盘时间" v-model="Params.lhc_open_time" ></Datetime>
            <Datetime class="u-bb" type="time" title="连码截至时间" v-model="Params.lhc_serial_end_time" ></Datetime>
            <Datetime class="u-bb" type="time" title="正码截至时间" v-model="Params.lhc_positive_time" ></Datetime>
            <Datetime class="u-bb" type="time" title="两面截至时间" v-model="Params.lhc_side_end_time" ></Datetime>
            <Datetime class="u-bb" type="time" title="特码截至时间" v-model="Params.lhc_special_time" ></Datetime>
        </div>

        <p class="o-p-s c-text-6" style="color:#999;">澳门时间设定</p>
        <div class="block">
            <Datetime class="u-bb" type="time" title="每期开盘时间" v-model="Params.lhc_ac_open_time" ></Datetime>
            <Datetime class="u-bb" type="time" title="连码截至时间" v-model="Params.lhc_ac_serial_end_time" ></Datetime>
            <Datetime class="u-bb" type="time" title="正码截至时间" v-model="Params.lhc_ac_positive_time" ></Datetime>
            <Datetime class="u-bb" type="time" title="两面截至时间" v-model="Params.lhc_ac_side_end_time" ></Datetime>
            <Datetime class="u-bb" type="time" title="特码截至时间" v-model="Params.lhc_ac_special_time" ></Datetime>
        </div>

        <p class="o-p-s c-text-6" style="color:#999;">台湾时间设定</p>
        <div class="block">
            <Datetime class="u-bb" type="time" title="每期开盘时间" v-model="Params.lhc_tw_open_time" ></Datetime>
            <Datetime class="u-bb" type="time" title="连码截至时间" v-model="Params.lhc_tw_serial_end_time" ></Datetime>
            <Datetime class="u-bb" type="time" title="正码截至时间" v-model="Params.lhc_tw_positive_time" ></Datetime>
            <Datetime class="u-bb" type="time" title="两面截至时间" v-model="Params.lhc_tw_side_end_time" ></Datetime>
            <Datetime class="u-bb" type="time" title="特码截至时间" v-model="Params.lhc_tw_special_time" ></Datetime>
        </div>

        <p class="o-p-s c-text-6" style="color:#999;">新加坡时间设定</p>
        <div class="block">
            <Datetime class="u-bb" type="time" title="每期开盘时间" v-model="Params.lhc_xjp_open_time" ></Datetime>
            <Datetime class="u-bb" type="time" title="连码截至时间" v-model="Params.lhc_xjp_serial_end_time" ></Datetime>
            <Datetime class="u-bb" type="time" title="正码截至时间" v-model="Params.lhc_xjp_positive_time" ></Datetime>
            <Datetime class="u-bb" type="time" title="两面截至时间" v-model="Params.lhc_xjp_side_end_time" ></Datetime>
            <Datetime class="u-bb" type="time" title="特码截至时间" v-model="Params.lhc_xjp_special_time" ></Datetime>
        </div>


        <!-- <p class="o-p-s c-text-6" style="color:#999;">赔率设定</p>
        <div class="block">
            <mt-field class="u-bb longlabel" label="特码赔率表 B = A -" v-model="Params.lhc_decrease_formula_b_a"></mt-field>
            <mt-field class="u-bb longlabel" label="特码赔率表 C = A -" v-model="Params.lhc_decrease_formula_c_a"></mt-field>
            <mt-field class="u-bb longlabel" label="特码赔率表 D = A -" v-model="Params.lhc_decrease_formula_d_a"></mt-field>
            <mt-field class="u-bb longlabel" label="特两面赔率 B = A -" v-model="Params.lhc_decrease_formula_side_b_a"></mt-field>
            <mt-field class="u-bb longlabel" label="特两面赔率 C = A -" v-model="Params.lhc_decrease_formula_side_c_a"></mt-field>
            <mt-field class="u-bb longlabel" label="特两面赔率 D = A -" v-model="Params.lhc_decrease_formula_side_d_a"></mt-field>
            <mt-field class="u-bb longlabel" label="连码减倍设定值" v-model="Params.lhc_serial_formula"></mt-field>
            <mt-field class="u-bb longlabel" label="连码减倍最大值" v-model="Params.lhc_serial_formula_max"></mt-field>
        </div> -->


        <div class="layout-bottom">
            <div class="o-ptb-s o-plr l-flex-c">
                <Button class="l-flex-1" type="warning" @click="init()" full>重读数据</Button>
                <Button class="l-flex-1" @click="Post()" full>确定更改</Button>
            </div>
        </div>
    </div>
</template>

<script>
import StoreMix from '@lottery.2.0/mixin/store.js'
export default {
    name: 'CreditSystemBase',
    mixins : [StoreMix],
    data() {
        return {
            store : 'admin/settings',
            Params : {},
            inputStyle : 'width:140px;',
            inputStyle2 : 'width:100px;',
            online : {},
        }
    },
    methods: {
        init(){    
            this.Get().then(res=>{
                this.$set(this,'Params',res.data)
            })
            // this.OnLine()
        },
        // OnLine(){
        //     this.Dp('base/GET_ONLINE').then(res=>{
        //         this.$set(this,'online',res)
        //     })
        // },
    },
    mounted(){

    },
    activated(){
        this.init()
        //this.OnLine()
    }
}
</script>
