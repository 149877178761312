<style lang="scss">
    @import "../../../style/config.scss";
    .IndexReport {
        .nav-board {
            // position:absolute; top:0; left:0; right:0; bottom:0;
            // background-color:#fff;
            border-bottom:.03rem solid #ccc; z-index:100;
            font-size:12px;
        }
        a.btn {
            padding:5px 9px; margin:0 3px; background-color:#f0f0f0; color:#999; border-radius:4px;
            &.active {
                background-color:$color-t; color:#fff;
            }
        }
    }
</style>
<template>
    <div class="IndexReport">
        <div class="nav-board block" style="z-index:11;">
            <Actionsheet class="u-bb" v-model="type" title="类型" :actions="Opt.isTeam ? [{name:'总账',value:'amount'},{name:'分类账',value:'subsidiary'}] : [{name:'总账',value:'amount'}]"></Actionsheet>
            <!-- <Actionsheet class="u-bb" v-model="Filter.games_type" title="游戏" :actions="lotteryList" name="title" val="id" @change="TypeChange()"></Actionsheet> -->
            <Actionsheet class="u-bb" v-model="Filter.games_type" title="游戏" :actions="lotteryList" name="title" val="id" @change="TypeChange()"></Actionsheet>
            <!-- <Actionsheet class="u-bb" v-model="Filter.lottery_id" title="期数" :actions="expect.list" name="number" val="id" placeholder="请选择期数或按时间筛选"></Actionsheet> -->
            <Actionsheet class="u-bb" v-model="Filter.status" title="状态" :actions="[{name:'已结算',value:1},{name:'未结算',value:0}]"></Actionsheet>
            <Datetime class="u-bb" type="datetime" title="起始时间" v-model="Filter.interval[0]"></Datetime>
            <Datetime class="u-bb" type="datetime" title="结束时间" v-model="Filter.interval[1]"></Datetime>
            <div class="o-ptb c-text-c">
                <a class="btn" v-for="(item,index) in datatime" :class="{'active':Filter.interval && Filter.interval[0] == item.start && Filter.interval[1] == item.end}" @click="PickDate(item.name)">{{ item.title }}</a>
            </div>
            <div class="o-p o-pb-l">
                <Button @click="Check()" full>生成报表</Button>
            </div>
        </div>
    </div>
</template>

<script>
import StoreMix from '../../../mixin/store.js'
export default {
    name: 'IndexReport',
    mixins : [StoreMix],
    data() {
        return {
            store : 'myRecord/order',
            type : 'amount',
            Filter : {
                games_type : 0,
                lottery_id : undefined,
                status : 1,
            },
            expect : {
                list : [],
            },
            datatime : [
                {name:'day',title:'今日',},
                {name:'yesterday',title:'昨天',},
                {name:'lweek',title:'上周',},
                {name:'week',title:'本周',},
                {name:'lmonth',title:'上月',},
                {name:'month',title:'本月',},
            ],
        }
    },
    computed: {
        games: vm => vm.$store.state.game.games,
        lotteryList: vm => [{id: 0, title: '全部'}, ...vm.games._list.map(row => ({id: row.id, title: row.title}))]
    },
    methods: {
        init(){
            for(let item of this.datatime){
                this.$set(item,'start',this.FilterInterval(item.name)[0])
                this.$set(item,'end',this.FilterInterval(item.name)[1])
            }
            this.GetHistory()
        },
        Reload(){
            this.DataReset('Filter')
            this.FilterIntervalSet('day')
        },
        Check(){
            // if(!this.Filter.games_type && this.Filter.games_type != 0){
            //     return this.Err('请选择游戏')
            // }
            // let todaydate = new Date().setHours(0, 0, 0, 0);
            // let paramsdate = new Date(this.Filter.interval[0]).getTime();
            // if (todaydate === paramsdate) {
            //     if(this.Filter.games_type < 1){
            //         this.Filter.status = 0;
            //         let nowtimeHours = new Date().getHours();
            //         let nowtimeMinutes = new Date().getMinutes();
            //         if (nowtimeHours >= 21) {
            //             if (nowtimeHours > 21) {
            //                 this.Filter.status = 1;
            //             } else if (nowtimeMinutes > 33) {
            //                 this.Filter.status = 1;
            //             }
            //         }
            //     }
                
            //     // if (this.Filter.games_type < 1) {
            //     //     this.Filter.status = 1;
            //     // }
            //     // if (this.Filter.games_type == 31 || this.Filter.games_type == 34 || this.Filter.games_type == 37) {
            //     //     if (nowtimeHours >= 21) {
            //     //         if (nowtimeHours > 21) {
            //     //             this.Filter.status = 1;
            //     //         } else if (nowtimeMinutes > 33) {
            //     //             this.Filter.status = 1;
            //     //         }
            //     //     }
            //     // }
            //     // if (this.Filter.games_type == 35) {
            //     //     if (nowtimeHours >= 20) {
            //     //         if (nowtimeHours > 20) {
            //     //             this.Filter.status = 1;
            //     //         } else if (nowtimeMinutes > 29) {
            //     //             this.Filter.status = 1;
            //     //         }
            //     //     }
            //     // }
            //     // if (this.Filter.games_type == 36) {
            //     //     if (nowtimeHours >= 18) {
            //     //         if (nowtimeHours > 18) {
            //     //             this.Filter.status = 1;
            //     //         } else if (nowtimeMinutes > 24) {
            //     //             this.Filter.status = 1;
            //     //         }
            //     //     }
            //     // }
            // } else {
            //     this.Filter.status = 1;
            // }
            this.Go(`index/pages/report/${this.type}`,this.Filter)          
        },
        PickDate(type){
            this.$set(this.Filter, 'lottery_id', undefined)
            this.FilterIntervalSet(type)
        },
        GetHistory(){
            this.Dp('myRecord/_GET_HISTORY',{params:{games_id:37,games_model:0,per_page:10}}).then(res=>{
                this.$set(this.expect,'list',res.data)
            })
        },
        TypeChange(val){
            this.$set(this.Filter,'lottery_id',undefined)
            this.GetHistory()
        },
    },
    activated(){
        this.init()
    },
    beforeRouteEnter(to, from, next){
        next(vm => {
            if(!from || !from.name || from.name.indexOf('index/pages/report') < 0){
                vm.Reload()
            }
        })
    },
    beforeRouteUpdate (to,from, next){
        next()
    },
}
</script>
