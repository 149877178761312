<style lang="scss">
    @import "../../../../style/config.scss";
    .CreditSystemBackwater {
        table.table {
            input {
                width:80px; padding:2px 5px;
            }
            tr td {
                border:.03rem solid #aaa;
                &:first-child {
                    border-left:none;
                }
                &:last-child {
                    border-right:none;
                }
            }
            .pick {
                background-color:#ffd5bc!important;
            }
        }
    }
</style>
<template>
    <div class="CreditSystemBackwater o-pb-h">
        <div class="l-flex-c o-pb">
            <div class="l-flex-1">
                <span>游戏：</span>
                <select v-model="Filter.games_id" style="margin-top: 6px;" @change="reloadZZZ">
                    <!-- <option value="34">澳门六合彩</option> -->
                    <option v-for="item in games._list" :key="item.id" :value="item.id">{{ item.title }}</option>
                </select>
            </div>
        </div>
        <div class="block">
            <table class="table" border="0" cellspacing="1" cellpadding="0">
                <thead>
                    <tr class="hbg-1" style="text-align:left;">
                        <td>玩法</td>
                        <td>单注最低</td>
                        <td>单注最高</td>
                        <td>单项最高</td>
                        <td>退水A</td>
                        <td>退水B</td>
                        <td>退水C</td>
                        <td>退水D</td>
                    </tr>
                </thead>
                <tbody class="zebra" style="text-align:left;">
                    <tr class="c-text-c" v-for="(item,index) in table.list" :class="{'pick':item.pick}">
                        <td>{{ item.name }}</td>
                        <td>{{ item.order_min_amount }}</td>
                        <td>{{ item.order_max_amount }}</td>
                        <td>{{ item.bets_limit_amount }}</td>
                        <td>{{ item.backwater_a }}</td>
                        <td>{{ item.backwater_b }}</td>
                        <td>{{ item.backwater_c }}</td>
                        <td>{{ item.backwater_d }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
<!--         <div class="layout-bottom">
            <div class="o-ptb-s o-plr l-flex-c">
                <Button class="l-flex-1" type="warning" @click="load()" full>重读数据</Button>
                <Button class="l-flex-1" @click="Submit()" full>提交修改</Button>
            </div>
        </div> -->
    </div>
</template>

<script>
import StoreMix from '@lottery.2.0/mixin/store.js'
export default {
    name: 'CreditSystemBackwater',
    mixins : [StoreMix],
    data() {
        return {
            store : 'admin/backwater',
            Filter : {
                games_id : 37,
            },
            Params : {},
            table : {
                list : [],
                fast : {
                    order_min_amount : null,
                    order_max_amount : null,
                    order_limit_amount : null,
                    backwater_a : null,
                    backwater_b : null,
                    backwater_c : null,
                    backwater_d : null,
                },
            },
            pickall : false,
        }
    },
    watch:{
        'table.fast.order_min_amount'(val){ if(val) for(let item of this.table.list){ if(item.pick) this.$set(item,'order_min_amount',val)} },
        'table.fast.order_max_amount'(val){ if(val) for(let item of this.table.list){ if(item.pick) this.$set(item,'order_max_amount',val)} },
        'table.fast.order_limit_amount'(val){ if(val) for(let item of this.table.list){ if(item.pick) this.$set(item,'order_limit_amount',val)} },
        'table.fast.backwater_a'(val){ if(val) for(let item of this.table.list){ if(item.pick) this.$set(item,'backwater_a',val)} },
        'table.fast.backwater_b'(val){ if(val) for(let item of this.table.list){ if(item.pick) this.$set(item,'backwater_b',val)} },
        'table.fast.backwater_c'(val){ if(val) for(let item of this.table.list){ if(item.pick) this.$set(item,'backwater_c',val)} },
        'table.fast.backwater_d'(val){ if(val) for(let item of this.table.list){ if(item.pick) this.$set(item,'backwater_d',val)} },
    },
    computed: {
        games: vm => vm.$store.state.game.games
    },
    methods: {
        init(){    
            this.load()
        },
        PickAll(e){
            for(let item of this.table.list){
                this.$set(item,'pick',e)
            }
        },
        load(){
            this.pickall = false
            this.DataReset('table')
            this.Dp('admin/_GET_BACKWATER').then(res=>{
                let list = []
                for(let item of res.data){
                    list.push({
                        pick : false,
                        ...item,
                    })
                }
                this.$set(this.table,'list',list)
            })
        },
        reloadZZZ(e){
            this.pickall = false
            this.DataReset('table')
            this.Dp('admin/_GET_BACKWATER', {params:{games_id:e.target.value}}).then(res=>{
                let list = []
                for(let item of res.data){
                    list.push({
                        pick : false,
                        ...item,
                    })
                }
                this.$set(this.table,'list',list)
            })
        },
        // Submit(){
        //     let data = this.Origin(this.table.list)
        //     this.Dp('admin/_PUT_BACKWATER',{
        //         id :1,
        //         data:{
        //             data:JSON.stringify(data)
        //         }
        //     }).then(res=>{
        //         this.Suc('限额已修改成功')
        //     })
        // },        
    },
    components : {

    },
    mounted(){
        this.init()
    },
}
</script>
