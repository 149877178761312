<style lang="scss">
    .CreditLimit {
        table tr td {padding-left:8px!important; padding-right:8px!important;}
        .row {
            background-color:#fff; padding-bottom:4px;
        }
        .row-title {
            background-color:#7baaff; color:#fff; padding:4px 10px;
        }
        input {
            padding:2px 4px;
        }
        button {
            cursor:pointer;
        }
        .table {
            tbody {
                td {
                    border:.03rem solid #ccc; font-size:10px; padding:5px 0; padding-left:2px!important; padding-right:2px!important;
                    &:first-child {
                        border-left:none;
                    }
                    &:last-child {
                        border-right:none;
                    }
                }
            }
            thead tr td {
                border:none;
            }
        }
    }
</style>
<template>
    <div class="CreditLimit">
<!--         <div class="block o-pt o-plr">
            <table class="table" v-for="(item,index) in table.list" border="0" cellspacing="1" cellpadding="0" style="margin-bottom:4px;">
                <tr style="text-align:left;">
                    <td class="hbg-1" style="text-align:left;" colspan="4"><p style="padding:4px 0;">{{ item.name }}</p></td>
                </tr>
                 <tr style="text-align:left;">
                    <td class="hbg-2" style="text-align:center;">A盘退水</td>
                    <td class="hbg-2" style="text-align:center;">B盘退水</td>
                    <td class="hbg-2" style="text-align:center;">C盘退水</td>
                    <td class="hbg-2" style="text-align:center;">D盘退水</td>
                </tr>
                <tr style="text-align:left;">
                    <td style="text-align:center;">{{ item.backwater_a }}</td>
                    <td style="text-align:center;">{{ item.backwater_b }}</td>
                    <td style="text-align:center;">{{ item.backwater_c }}</td>
                    <td style="text-align:center;">{{ item.backwater_d }}</td>
                </tr>
                <tr>
                    <td class="hbg-2" colspan="2" style="text-align:center;">单注限额</td>
                    <td class="hbg-2" colspan="2" style="text-align:center;">单项限额</td>
                </tr>
                <tr style="text-align:center;">
                    <td colspan="2" style="text-align:center;">{{ Price(item.bets_limit_amount) }}</td>
                    <td colspan="2" style="text-align:center;">{{ Price(item.order_max_amount) }}</td>
                </tr>
            </table>
        </div> -->
        <Actionsheet v-model="Filter.games_id" title="游戏" :actions="Opt.hasDir.game" size="small" name="title" val="id" emptytext="所有游戏" style="width: 150px;" @change="load()"></Actionsheet>
        <div class="block">
            <table class="table"  border="0" cellspacing="1" cellpadding="0">
                <thead>
                    <tr>
                        <td class="hbg-1" v-for="item in ['玩法名称','单注限额','单项限额','退水A','退水B','退水C','退水D','退水E']" style="text-align:center;">{{ item }}</td>
                    </tr>
                </thead>
                <tbody class="zebra">
                    <tr v-for="(item,index) in table.list">
                        <td style="text-align:center;">{{ item.name }}</td>
                        <td style="text-align:center;">{{ Number(item.bets_limit_amount) }}</td>
                        <td style="text-align:center;">{{ Number(item.order_max_amount) }}</td>
                        <td style="text-align:center;">{{ Number(item.backwater_a) }}</td>
                        <td style="text-align:center;">{{ Number(item.backwater_b) }}</td>
                        <td style="text-align:center;">{{ Number(item.backwater_c) }}</td>
                        <td style="text-align:center;">{{ Number(item.backwater_d) }}</td>
                        <td style="text-align:center;">{{ Number(item.backwater_e) }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
 //rowspan="2"
 //colspan="7"
import StoreMix from '@lottery.2.0/mixin/store.js'
export default {
    name: 'CreditLimit',
    mixins : [StoreMix],
    data() {
        return {
            store : 'myAccount/gamesgroup',
            Filter : {
                games_id : 37,
            },
            table : {
                length : 1,
                list : [],
                fast : [null],
            },
        }
    },
    methods: {
        init() {
            this.load()
        },
        load(){
            this.DataReset('table')
            this.Get().then(res=>{
                if(res){
                    let list = []
                    for(let item of res.data.data){
                        list.push(item)
                    }
                    let fast = []
                    for(let i=0;i<=length;i++){
                        fast.push(null)
                    }
                    this.$set(this.table,'list',list)
                }
            })
        },
    },
    activated(){
        this.init()
    },
}
</script>
