<style lang="scss">
    @import "../../../../style/config.scss";
    .IndexOperate {
         width:100%; height:100%; position:relative; position:fixed; top:0; left:0; bottom:0; right:0;
        .header-2 {
            $header-height:44px;
            height:$header-height; line-height:$header-height; background-color:#4477ad; color:#fff; //position:fixed; top:0; left:0; right:0;
            .head-btn {
                display:block; height:$header-height; padding:0 12px; 
                i {
                    font-size:20px; color:#fff;
                }
                &.back {
                    transform:rotate(180deg); width:20px;
                }
            }
        }
        .change {
            position:absolute; top:0; left:0; right:0; bottom:0; background-color:rgba(0,0,0,.5);
            .drawer {
                width:220px; height:100%; position:absolute; top:0; right:0; bottom:0; background-color:#fff;
                li {
                    background-color:#f5f5f5; padding:5px; border-radius:4px; border:.03rem solid #e5e5e5; margin-bottom:.4rem;
                    &.active {
                        border-color:rgba($color-t,.35);
                        .icon {
                            color:$color-t;
                        }
                    }
                }
            }
            .icon {
                color:#ddd;
            }
        }
        .tips {
            position:fixed; top:44px; left:0; right:0; bottom:0; background-color:rgba(0,0,0,.75); color:#fff;
        }
    }
</style>
<template>
    <div class="IndexOperate">
        <div class="l-flex-v" style="margin:0 auto; width:100%; height:100%; position:relative; max-width:768px;">
            <div class="header-2 l-flex-c o-plr-s" style="z-index: 10;" :style="headerStyle">  
                <a class="head-btn back" @click="Back()">
                    <Icon name="right"></Icon>
                </a>
                <div class="l-flex-1 ">
                    <p class="o-plr-s">
                        {{ Running.title || '' }}
                        <!-- <span>六合彩 - </span>
                        <span v-if="!target.bind">及时注单</span>
                        <span v-else-if="target.bind === 2">操盘</span>
                        <span v-else-if="target.bind === 3">走飞</span> -->
                    </p>
                </div>
                <!-- <a class="head-btn" @click="Drawer()">
                    <span style="padding-right:2px;">切换</span>
                    <Icon name="list"></Icon>
                </a> -->
            </div>
            <div class="l-flex-1" style="position:relative;">
                <Operate :games="games" :types="types" store="game" :user_type="User.user_type" :bind="target.bind" ref="operate"></Operate>
            </div>
            <div class="change" style="z-index:11;" v-if="drawer.view" @click.self="drawer.view = false">
                <div class="drawer">
                    <div class="o-ptb-l o-plr">
                        <p class="c-line-12">模式</p>
                        <ul>
                            <li class="l-flex-c" :class="{'active':drawer.bind == item.bind}" v-for="(item,index) in [{title:'及时注单',bind:0,auth:0},{title:'操盘',name:'manipulation',bind:2,auth:1},{title:'走飞',name:'transmit',bind:3,auth:2}]" v-if="(!item.name || hasOperate[item.name]) && CheckAuth(true,item.auth)" @click="drawer.bind = item.bind">
                                <p class="l-flex-1">{{ item.title }}</p>
                                <Icon class="icon" name="roundcheckfill"></Icon>
                            </li>
                        </ul>
                        <p class="c-line-12 o-mt">游戏</p>
                        <ul v-for="(item,index) in Opt.hasDir.game">
                            <li class="l-flex-c" :class="{'active':drawer.id == item.id}" @click="DrawerPick(item)">
                                <p class="l-flex-1">{{ item.title }}</p>
                                <Icon class="icon" name="roundcheckfill"></Icon>
                            </li>
                        </ul>
                        <div class="o-pt">
                            <mt-button id="login" style="width:100%" @click="DrawerApply2()" type="primary">确定</mt-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="tips" style="z-index: 1;" v-if="tipsView">
            <div class="context o-pt-h c-text-c">
                <p class="c-text-9 c-line-16">点击顶部右上角图标</p>
                <p class="c-text-9 c-line-16">可以切换操作模式与游戏</p>
                <Button class="o-mt-h" @click="TipConfirm()">知道了</Button>
            </div>
        </div> -->
    </div>
</template>

<script>
import StoreMix from '@lottery.2.0/mixin/store.js'
import Operate from '@lottery.admin.2.0/components/operate/index_mobile.vue'
export default {
    name: 'IndexOperate',
    mixins : [StoreMix],
    data() {
        return {
            //store : 'base/activity',
            isInit: false,
            target: {
                id: null,
                bind: 1, 
                info: {},
                type: null,
            },
            drawer: {
                view: false,
                name: null,
                id: null,
                bind: 1, // 1 = 及时注单; 2 = 操盘; 3 = 走飞
            },
            tipsView: false,
        }
    },
    computed: {
        games(){
            return this.$store.state.game.games
        },
        types(){
            return this.$store.state.game.group
        },
        hasOperate(){
            let tid = this.drawer.type
            if(tid && this.types._init){
                for(let item of this.types._list){
                    if(item.id === tid){
                        return this.Opt.operate[item.code]
                    }
                }
            }
            return {}
        },
        headerStyle() {
            const obj = {
                backgroundColor: '#4477ad'
            }
            const {id} = this.Running
            if (id) {
                for (const name in this.Games) {
                    const game = this.Games[name]
                    if (game.id === id && game.bg_color) {
                        obj.backgroundColor = game.bg_color
                    }
                }
            }
            return obj
        }
    },
    methods: {
        init(){
            for (let item of this.Opt.hasDir.game) {
                if (Number(localStorage.getItem('games_id')) === item.id) {
                    this.DrawerPick(item)
                    this.DrawerApply()
                    break;
                }
            }
            if (!this.Cache('tips_mobile_operate_handle')) {
                this.tipsView = true
            }
            this.isInit = true
        },
        Back(){
            this.$router.back()
        },
        Drawer(){
            this.drawer.id = this.target.id
            this.drawer.bind = this.target.bind
            this.drawer.type = this.target.type
            this.drawer.view = true
        },
        DrawerPick(item){
            this.$set(this.drawer,'id',item.id)
            this.$set(this.drawer,'title',item.title)
            this.$set(this.drawer,'type',item.info.type)
            this.$set(this.drawer,'info',item.info)
            if(this.drawer.bind == 2 && !this.hasOperate.manipulation){
                this.$set(this.drawer,'bind',0)
            }
            if(this.drawer.bind == 3 && !this.hasOperate.transmit){
                this.$set(this.drawer,'bind',0)
            }
        },
        DrawerApply2(){
            localStorage.setItem('games_id', this.drawer.id);
            this.DrawerApply();
            location.reload();
        },
        DrawerApply(){
            this.target.id = this.drawer.id
            this.target.bind = this.drawer.bind
            this.target.title = this.drawer.title
            this.target.type = this.drawer.type
            this.drawer.view = false
        },
        CheckAuth(origin,key){
            if(!origin){
                return false
            }
            if(this.Opt.isSub && key){
                if(!this.Opt.isSub[key]){
                     return false
                }               
            }
            return true
        },
        Clean(){
            this.$refs['operate'].OperateCleanAll()
            this.$refs['operate'].AutoReloadClean()
        },
        TipConfirm(){
            this.Cache('tips_mobile_operate_handle',true)
            this.tipsView = false
        },
    },
    components: {
        Operate
    },
    activated(){
        if (this.isInit) {
            localStorage.setItem('games_id', this.$route.params.id)
            location.reload()
        }
        this.init()
    },
    mounted() {

    }
}
</script>
