
export default {

    name: '其他',
    list: [{
            path: '/login',
            name: 'login',
            meta: {
                name: '用户登录',
                public: true,
                nodirectory: true,
            },
            component: require('@lottery.mobile/page/other/login.vue').default,
        },
        {
            path: '/registered',
            name: 'registered',
            meta: {
                name: '用户注册',
                public: true,
                nodirectory: true,
            },
            component: require('@lottery.mobile/page/other/registered').default,
        },
        {
            path: '/maintain',
            name: 'maintain',
            meta: {
                name: '系统维护',
                public: true,
                nodirectory: true,
            },
            component: require('@lottery.mobile/page/other/maintain').default,
        },
        {
            path: '*',
            name: '404',
            meta: {
                name: '404',
                public: true,
                nodirectory: true,
            },
            component: require('@lottery.mobile/page/other/404.vue').default,
        },
    ]
}
