<style lang="scss">
    @import "../../../../style/config.scss";
    .CreditSystemOpen {
        .ball {
            display:inline-block; font-size:.85rem; font-weight:bold;
            &.red {color:#f56c6c;}
            &.blue {color:#409eff;}
            &.green {color:#67c23a;}
        }
        table.table {
            tr td {text-align:center;}
        }
        li {
            border-radius:4px; box-shadow:0 3px 8px rgba(0,0,0,.05);
        }
    }
</style>
<template>
    <div class="CreditSystemOpen">
        <ul class="o-plr-s">
            <li v-for="(item,index) in main._list" class="o-mt-s block" @click="Go('index/pages/system/sub/open',item)">
                <div class="l-flex-c o-p">
                    <div class="l-flex-1">
                        <span>日期：{{ item.date }}</span>
                        <span class="o-pl">期号：{{ item.number }}</span>
                    </div>
                    <Icon name="right" size=".7" style="color:#ccc"></Icon>
                </div>
                <div class="o-plr-s o-pb">
                    <table class="table u-bb u-bl u-br">
                        <tr class="hbg-2">
                            <td v-for="(item,index) in ['正一','正二','正三','正四','正五','正六','特码']"><span class="c-text-55">{{ item }}</span></td>
                        </tr>
                        <tr>
                            <td v-for="(sub,i) in ['一','二','三','四','五','六','特']">
                                <div v-if="FormatCode(item.data)[i]">
                                    <p class="ball" :class="CodeColor(FormatCode(item.data)[i])">{{ FormatCode(item.data)[i] }}</p>
                                    <p class="text c-text-6">{{ CodeZodiac(FormatCode(item.data)[i]) }}</p>
                                </div>
                                <div v-else class="o-ptb-s">-</div>
                            </td>
                        </tr>
                    </table>
                </div>                
            </li>
        </ul>
        <div class="o-plr">
            <Pagination v-if="main._total > 1" class="o-mtb" v-model="Page" @turning="Get" :total="main._total" :count="main._count" type="mini" :pageSize="Filter.per_page"></Pagination>
        </div>
    </div>
</template>

<script>
import StoreMix from '@lottery.2.0/mixin/store.js'
import SixMix from '@lottery.admin.2.0/mixin/six.js'
export default {
    name: 'CreditSystemOpen',
    mixins : [StoreMix,SixMix],
    data() {
        return {
            store : 'game/history',
            Filter : {
                games_id : 31,
                games_model : 7,
                per_page : 20,
            },
            HeadNav : {
                text : '新增',
                icon : 'roundadd',
                event : ()=>{
                    this.Go('index/pages/system/sub/open')
                }
            },
        }
    },
    methods: {
        init() {
            this.Get()
        },
        FormatCode(str){
            if(str){
                let normal = str.split('+')[0]
                if(normal){
                    normal = normal.split(',')
                }
                let special = str.split('+')[1]
                if(special){
                    special = special.split(',')
                    normal = normal.concat(special)
                }
                return normal
            }
            return []
        },
    },
    mounted(){
        if(!this.Opt.isAdmin){
            this.Rd('index/home')
        }
        this.init()
    },
    beforeRouteEnter(to, from, next){
        next(vm=>{
            if(from && from.name === 'index/pages/system/sub/open'){
                vm.init()
            }
        })
    },
}
</script>
