<style lang="scss">
    .CreditActlog {
        padding-top:40px;
        .context {
            background-color:#f0f0f0; border-radius:4px; padding:8px 8px;
        }
    }
</style>
<template>
    <div class="CreditActlog">
        <HeadLimit v-model="Filter.interval" @change="MakeFilter('empty')"></HeadLimit>
        <PullTo :top-load-method="LoadTop" v-infinite-scroll="LoadMore" :is-bottom-bounce="false" :infinite-scroll-disabled="!main._init || main._loading || !main._more" infinite-scroll-distance="10">
            <li v-for="(item,index) in main._list" class="block o-p u-bb"> 
                <div>{{ item.title }}</div>
                <div class="c-text-6 c-color-g c-line-12 l-flex-c">
                    <div class="l-flex-1">时间: {{ item.create_at }}</div>
                    <div>IP: {{ item.ip }}</div>
                </div>
                <div class="c-text-6 l-flex-c">
                    <div class="l-flex-1">操作员: {{ item.username }}</div>
                    <div v-if="item.aims_username">目标: {{ item.aims_username }}</div>
                </div>
                <div class="context o-mt-s" v-if="item.note">
                    <div class="c-text-6">
                        {{ CheckContextLength(item.note,200) }}
                    </div>
                </div>
            </li>
            <Loading :main="main"></Loading>
        </PullTo>
    </div>
</template>

<script>
import StoreMix from '@lottery.mobile/mixin/store.js'
import Rolling from '@lottery.mobile/mixin/rolling.js'
export default {
    name: 'CreditActlog',
    mixins : [StoreMix,Rolling],
    data() {
        return {
            store : 'credit/actlog',
            Filter : {
                per_page : 24,
            },
            ready : false,
        }
    },
    methods: {
        init(){
            this.DataReset('Filter')
            this.FilterIntervalSet('week')
            this.Get()
        },
    },
    mounted(){
        this.$set(this.Roll,'target',document.getElementsByClassName('page-view')[0])
    },
    beforeRouteEnter(to, from, next) {
        next(vm=>{
            if(from && from.name === 'index/pages/sub/query'){
                vm.Roll.enable = true
            }else{
                vm.Roll.enable = false
                vm.init()
            }
        })
    },
}
</script>