<style lang="scss">
    @import "../style/config.scss";
    .Button {
        cursor:pointer; display:inline-block; 
        button {
            padding:7px 16px; outline:none; cursor:pointer; border:1px solid $color-n; font-size:.7rem; border-radius:4px;

        }
        &+.Button {
            margin-left:6px;
        }
        // 尺寸
        &.Button-big {
            button {
                padding:12px 18px; font-size:.75rem;
            }
        }
        &.Button-normal {
            button {
                padding:10px 15px; font-size:.75rem;
            }
        }
        &.Button-small {
            button {
                padding:5px 12px; font-size:.7rem;
            }
        }
        &.Button-mini {
            button {
                padding:4px 8px; font-size:.55rem; 
            }
            &+.Button {
                margin-left:3px;
            }
        }
        // 颜色
        &.Button-primary, &.Button-p {
            button {
                background-color:$color-t; color:#fff;
                &:active, &:hover {
                    background-color:darken($color-t,15%); 
                }
            }
        }
        &.Button-danger, &.Button-d {
            button {
                background-color:$color-e; color:#fff;
                &:active, &:hover {
                    background-color:darken($color-e,15%); 
                }
            }
        }
        &.Button-warning, &.Button-w {
            button {
                background-color:$color-w; color:#fff;
                &:active, &:hover {
                    background-color:darken($color-w,15%); 
                }
            }
        }
        &.Button-success, &.Button-s {
            button {
                background-color:$color-s; color:#fff;
                &:active, &:hover {
                    background-color:darken($color-s,15%); 
                }
            }
        }
        &.Button-info, &.Button-i {
            button {
                background-color:#ccc; color:#333;
                &:active, &:hover {
                    background-color:darken(#bbb,15%); 
                }
            }
        }
        &.Button-work {
            button {
                background-color:$color-p; color:#fff;
                &:active, &:hover {
                    background-color:darken($color-p,15%); 
                }
            }
        }
        &.Button-default {
            button {
                background-color:#fff; background:linear-gradient(180deg,#fff,#f0f0f0,#ccc); border:.03rem solid #ccc;
                &:active, &:hover {
                    background-color:#f0f0f0; background:linear-gradient(180deg,#fff,#f0f0f0,#bbb);
                    border-color:#aaa;
                }
            }
        }
        // 其他
        &[full], &[block]{
            display:block; width:100%;
            button {
                display:block; width:100%;
            }
        }
        &[block]{
            button {
                border-radius:0;
            }            
        }
        button[disabled] {
            background-color:#d9d9d9; color:#858585;
            &:active, &:hover {
                background-color:#d9d9d9; color:#858585;
            }
        }
    }
</style>
<template>
    <a class="Button" :class="['Button-'+type,'Button-'+size]">
        <button @click="Click($event)" :disabled="disabled" :type="mode">
            <slot></slot>
        </button>
    </a>
</template>

<script>
    export default {
        name : 'Button',
        data(){
            return {

            }
        },
        props : {
            size : {
                default : 'normal'
            },
            type : {
                default : 'primary'
            },
            loading : {
                default : false
            },
            disabled : {
                default : false
            },
            mode : {
                default : 'button'
            },
        },
        computed:{
           
        },
        methods: {
            Click(e){
                if(!this.disabled && !this.loading){
                    this.$emit('click',e)
                }
            },
        },
    }
</script>