<template>
    <div class="CreditTable">
        <Operate v-if="Opt.isTeam" ref="operate" />
        <Game v-else ref="game" />
    </div>
</template>

<script>
import Operate from './game/operate'
import Game from './game/game'
export default {
    name: 'CreditTable',
    components: {
        Operate,
        Game,
    },
    beforeRouteLeave(to, from, next){
    	if (this.Opt.isTeam) {
    		// 后退路由将会清除缓存
    		if (to.name.indexOf('index/pages/operate') < 0){
    			this.$refs['operate'].Clean()
    		}
    	}
        next()
    }
}
</script>