<style lang="scss">
    @import "../../../style/config.scss";
    %appfixed {
        width:100%; height:100%;
        position:fixed; z-index:0; top:0; left:0; right:0; bottom:0;
        max-width:768px; margin:0 auto;
    }
    .IndexPage {
        @extend %appfixed;
        $header-height:44px;
        .header-1 {
            height:$header-height; line-height:$header-height; background-color:#4477ad; color:#fff; z-index:10; position:fixed; top:0; left:0; right:0; position:relative; max-width:768px; margin:0 auto; box-sizing: border-box;
            .head-btn {
                display:block; height:$header-height; padding:0 12px; width:20px;
                i {
                    font-size:20px; color:#fff;
                }
                &.back {
                    transform:rotate(180deg);
                }
            }
            .head-btn.nav{
                width:auto;
                i {
                    margin-left:2px;
                }
            }
        }
        .page-view {
            position:absolute; z-index:0; top:44px; left:0; right:0; bottom:0; overflow:auto; //padding-top:$header-height;
        }
    }
</style>
<template>
    <div class="IndexPage">
        <header class="header-1 l-flex-c o-plr-s" v-if="HasHeader">
            <a class="head-btn back" @click="Back()">
                <Icon name="right"></Icon>
            </a>
            <div class="l-flex-1">
                <p class="o-plr-s">{{ $route.meta.name }}</p>
            </div>
            <a class="head-btn nav" v-if="nav" @click="nav.event()">
                <span v-if="nav.text">{{ nav.text }}</span>
                <Icon v-if="nav.icon" :name="nav.icon"></Icon>
            </a>
        </header>
        <div class="l-flex-1 l-flex-v" :class="{'page-view':HasHeader}">
            <div class="l-flex-1 o-pb-h">
                <keep-alive>
                    <router-view ref="sub"></router-view>
                </keep-alive>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'IndexPage',
    data() {
        return {
            nav : null,
        }
    },
    computed:{
        HasHeader(){
            return this.$route.name !== 'index/pages/table'
        }
    },
    watch:{
        '$route.name'(val){
            this.LoadNav()
        }
    },
    methods: {
        Back(){
            this.$router.back()
        },
        LoadNav(){
            this.$set(this,'nav',null)
            this.$nextTick(()=>{
                try{
                    let nav = this.$refs['sub'].$data.HeadNav
                    if(nav){
                        this.$set(this,'nav',nav)
                    }
                }catch(e){}
            })
        },
    },
    mounted(){
        this.LoadNav()
    },
}
</script>
