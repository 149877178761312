<style lang="scss">
    @import "../../../../style/config.scss";
    .CreditSystemOddsauto {
        table.table {
            input {
                width:80px; padding:2px 5px;
            }
            tr td {
                border:.03rem solid #aaa;
                &:first-child {
                    border-left:none;
                }
                &:last-child {
                    border-right:none;
                }
            }
            .pick {
                background-color:#ffd5bc!important;
            }
        }
    }
</style>
<template>
    <div class="CreditSystemOddsauto o-pb-h">
        <div class="l-flex-c o-pb">
            <div class="l-flex-1">
                <span>游戏：</span>
                <select v-model="Filter.games_id"  style="margin-top: 6px;" @change="reloadZZZ">
                    <!-- <option value="34">澳门六合彩</option> -->
                    <option v-for="item in games._list" :key="item.id" :value="item.id">{{ item.title }}</option>
                </select>
            </div>
        </div>
        <div class="block">
            <table class="table u-bl u-bb u-br" border="0" cellspacing="1" cellpadding="0">
                <thead>
                    <tr class="hbg-1" style="text-align:left;">
                        <td rowspan="2">玩法</td>
                        <td colspan="2">第一阶段(一次性)</td>
                        <td colspan="2">第二阶段(累进)</td>
                        <td rowspan="2">最低赔率</td>
                    </tr>
                    <tr lass="hbg-2"  style="text-align:left;">
                        <td>金额</td>
                        <td>降赔率</td>
                        <td>金额</td>
                        <td>降赔率</td>
                    </tr>
                </thead>
                <tbody style="text-align:left;">
                    <tr class="c-text-c" v-for="(item,index) in list">
                        <td>{{ item.name }}</td>
                        <td>{{ item.one_step_money }}</td>
                        <td>{{ item.one_step_odds }}</td>
                        <td>{{ item.two_step_money }}</td>
                        <td>{{ item.two_step_odds }}</td>
                        <td>{{ item.min_odds }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import StoreMix from '@lottery.2.0/mixin/store.js'
export default {
    name: 'CreditSystemOddsauto',
    mixins : [StoreMix],
    data() {
        return {
            store : 'admin/autoodds',
            Filter:{
                games_id: 37
            },
            list : [],
        }
    },
    computed: {
        games: vm => vm.$store.state.game.games
    },
    methods: {
        init(){    
            this.load()
        },
        load(){
            this.Get().then(res=>{
                this.$set(this,'list',res.data)
            })
        },
        reloadZZZ(e){
            this.Get().then(res=>{
                this.$set(this,'list',res.data)
            })
        },
        // Submit(){
        //     let data = this.Origin(this.list)
        //     this.Dp('admin/_POST_AUTOODDS',{
        //         data:{
        //             data:JSON.stringify(data)
        //         }
        //     }).then(res=>{
        //         this.Suc('自动赔率设置已保存')
        //         this.load()
        //     })
        // },
    },
    components : {

    },
    mounted(){
        this.init()
    },
}
</script>
