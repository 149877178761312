<style lang="scss">
    .lhc-ball {
        margin: 2px; display: inline-block; text-align: center; vertical-align: middle;
        background-size: contain;
        color: #fff;
    }
    .ball {
        width: 25px; height: 25px; line-height: 25px; margin: 3px auto;
        color: #fff; display: inline-block; border-radius: 99px; text-align: center;
        &.white {background-color:#FFF!important; color: #ec4d4d!important; font-weight: bold;}
        &.red {background-color:#ec4d4d!important; color: #FFF!important; font-weight: bold;}
        &.blue {background-color:#5f79ef!important; color: #FFF!important; font-weight: bold;}
        &.green {background-color:#4a9a34!important; color: #FFF!important; font-weight: bold;}
    }
</style>
<template>
    <!-- <i class="lhc-ball" :class="number" :style="Styles" /> -->
    <!-- <img class="lhc-ball" src="../../../../../image/game/ball/1.png" :style="Styles"> -->
    <!-- <img class="lhc-ball" :src="require(`../../../../../image/game/ball/${number}.png`)" :style="Styles"> -->
    <a :class="BallType(number)">{{ number }}</a>
</template>

<script>
export default {
    name: 'LHCBall',
    data() {
        return {
            avalue: false
        }
    },
    props: {
        number: {
            type: [Number, String],
            default: 1
        },
        size: {
            type: [Number, String],
            default: 20
        }
    },
    computed: {
        Styles() {
            const size = this.size
            const obj = {
                width: size, height: size
                // background: url(../images/hk6/ball_1.png) no-repeat center;

            }
            return obj
        },
        Base() {
            if (this.$store.state.base.options) {
                return this.$store.state.base.options
            }
            return {}
        },
    },
    methods: {
        BallType(str) {
            let type = 'text'
            if (str%1 === 0) {
                type = 'ball'
            }
            if (type === 'ball') {
                type = `${type} ${this.CodeColor(str)}`
            }
            if (type === 'text') {
                type = `${type} ${{'红': 'red', '蓝': 'blue', '绿': 'green'}[str.substr(0, 1)]}`
            }
            if (str === '?') {
                type = 'ball white'
            }
            return type
        },
        CodeColor(code) {
            if (code && typeof code === 'string') {
                if (code[0]==='红') {return 'red'}
                if (code[0]==='蓝') {return 'blue'}
                if (code[0]==='绿') {return 'green'}
            }
            let dir = {}
            for (let pack of [{key: 'lhc_red_wave', val: 'red'}, {key: 'lhc_blue_wave', val: 'blue'}, {key: 'lhc_green_wave', val: 'green'}]) {
                for (let item of this.Base[pack.key].split(',')) {
                    dir[item] = pack.val
                }
            }
            return code ? dir[code] : dir
        }
    }
}
</script>