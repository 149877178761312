<style lang="scss">
    @import "../../../style/config.scss";
    .Output {
        .ball {
            width:24px; height:24px; line-height:24px; text-align:center; border-radius:99px; display:inline-block;
            font-size:16px; font-weight:bold; background-color:#ddd;
            &.red {background-color:#f56c6c; color:#fff;}
            &.blue {background-color:#409eff; color:#fff;}
            &.green {background-color:#67c23a; color:#fff;}
        }
        .text {
            display:inline-block; margin-right:1px; position:relative; left:-4px; bottom:-5px;
            &.red {background-color:#f56c6c; color:#fff;}
            &.blue {background-color:#409eff; color:#fff;}
            &.green {background-color:#67c23a; color:#fff;}
        }
        .list {
            background-color:#fff;
            .row {
                padding:6px 0;
                &:nth-child(odd){
                    background-color:#f5f5f5;
                }
            }
        }
        .info {
            label {
                display:inline-block; width:50px; background-color:#d9e4fb; text-align:center; padding:4px 0;
            }
            span, b {
                padding-left:10px;
            }
            p {
                background-color:#f5f5f5;
            }
            .red {color:$color-six-red;}
            .blue {color:$color-six-blue;}
            .green {color:$color-six-green;}
        }
        .invisible {
            padding:5px;  background-color:#f0f0f0; border-radius:4px;
            .unknown {
                .ball {
                    background-color:#fff;
                }
            }
        }
    }
</style>
<template>
    <div class="Output">
        <HeadLimit v-model="Filter.interval" @change="MakeFilter('empty')">
            <Actionsheet v-model="Filter.games_id" title="游戏" :actions="Opt.hasDir.game" size="small" name="title" val="id" emptytext="所有游戏" @change="MakeFilter('empty')"></Actionsheet>
        </HeadLimit>
        <ul class="list">
            <li class="row u-bb" v-for="(item,index) in main._list" v-if="item.status">
                <div class="o-plr">
                    <div class="l-flex-c c-text-7">
                        <p class="l-flex-1">
                            <b>第 {{ item.number }} 期</b>
                        </p>
                    </div>
                    <div class="o-pt-s o-pb">
                        <a v-for="(sub,i) in ['一','二','三','四','五','六','特']" v-if="FormatCode(item.data)[i]">
                            <span class="ball" :class="CodeColor(FormatCode(item.data)[i])">{{ FormatCode(item.data)[i] }}</span>
                            <span class="text c-text-55">{{ CodeZodiac(FormatCode(item.data)[i]) }}</span>
                            <span v-if="i===5" style="padding-right:3px;">+</span>
                        </a>
                    </div>
                    <div class="info l-flex c-text-6" v-if="item.status != 0 && Filter.games_model === 7">
                        <div class="l-flex-1">
                            <p><label>特单双</label><span>{{ item.special_single_double }}</span></p>
                            <p><label>特大小</label><span>{{ item.special_big_small }}</span></p>
                            <p><label>波段</label><b :class="CodeColor(item.wave_band)">{{ item.wave_band }}</b></p>
                        </div>
                        <div class="l-flex-1">
                            <p><label>总分</label><span>{{ item.total_num }}</span></p>
                            <p><label>总单双</label><span>{{ item.total_num_single_double }}</span></p>
                            <p><label>总大小</label><span>{{ item.total_num_big_small }}</span></p>
                        </div>
                        <div class="l-flex-1">
                            <p><label>合单双</label><span>{{ item.total_single_double }}</span></p>
                            <p><label>合大小</label><span>{{ item.total_big_small }}</span></p>
                            <p><label>合尾大小</label><span>{{ item.total_tail_big_small }}</span></p>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import StoreMix from '@lottery.2.0/mixin/store.js'
import SixMix from '@lottery.admin.2.0/mixin/six.js'
export default {
    name: 'Output',
    mixins : [StoreMix,SixMix],
    data() {
        return {
            store : 'game/history',
            Filter : {
                games_id : 31,
                games_model : 7,
                per_page : 24,
            },
        }
    },
    methods: {
        init() {
            this.Get()
        },
        FormatCode(str){
            if(str){
                let normal = str.split('+')[0]
                if(normal){
                    normal = normal.split(',')
                }
                let special = str.split('+')[1]
                if(special){
                    special = special.split(',')
                    normal = normal.concat(special)
                }
                return normal
            }
            return []
        },
    },
    activated(){
        this.init()
    },
}
</script>
