<style lang="scss">
    .CreditPassword {
        // input {
        //     padding:0 6px; height:38px; line-height:38px; outline:none; border:1px solid #e0e0e0; border-radius:5px; width:200px;
        // }
    }
</style>
<template>
    <div class="CreditPassword">
        <div class="block o-mt">
            <mt-field class="u-bb longlabel" type="password" label="旧密码" placeholder="请输入旧密码" v-model="Params.old_pass"></mt-field>
            <mt-field class="u-bb longlabel" type="password" label="新密码" placeholder="请输入新密码" v-model="Params.pass"></mt-field>
            <mt-field class="u-bb longlabel" type="password" label="重复密码" placeholder="请输入重复输入新密码" v-model="Params.confirm_pass"></mt-field>            
        </div>
        <div class="o-plr o-pt-l">
            <Button @click="Submit()" full>提交</Button>
            <p class="o-ptb-l c-color-g c-text-6 c-text-c">为了系统的安全性，密码至少需要６码以上！</p>
        </div>
    </div>
</template>

<script>
import StoreMix from '@lottery.2.0/mixin/store.js'
export default {
    name: 'CreditPassword',
    mixins : [StoreMix],
    data() {
        return {
            store : 'myAccount/safety',            
        }
    },
    methods: {
        init(){    
            this.DataReset()
        },
        Submit(){
            this.Params.action = 'pass'
            this.Post().then(res=>{
                this.init()
            })
        },
    },
    mounted(){
        this.init()
    },
}
</script>
