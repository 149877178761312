<style lang="scss"></style>
<template>
    <el-dialog class="MyLotterPlaysMudul" :title="Title" :visible.sync="view" size="small" top="5vh" :modal-append-to-body="false" :close-on-click-modal="false">
        <el-form label-width="100px" size="small">
            <el-form-item label="玩法名称">
                <el-input v-model="Params.name" placeholder="请输入玩法名称"></el-input>
            </el-form-item>
            <el-form-item label="玩法规则">
                <el-input v-model="Params.rule" type="textarea" :rows="3" placeholder="请输入玩法规则"></el-input>
            </el-form-item>
            <el-form-item label="中奖举例">
                <el-input v-model="Params.example" type="textarea" :rows="3" placeholder="请输入中奖举例"></el-input>
            </el-form-item>
            <el-form-item label="玩法描述">
                <el-input v-model="Params.description" type="textarea" :rows="3" placeholder="请输入玩法描述"></el-input>
            </el-form-item>
            <el-form-item label="排序">
                <el-input v-model="Params.sort" placeholder="请输入排序"></el-input>
            </el-form-item>
        </el-form>
        <div class="c-text-r">
            <el-button @click="Cancel()">取消</el-button>
            <el-button :loading="main.editing" type="primary" @click="Submit()">提交</el-button>
        </div>
    </el-dialog>
</template>

<script>
import StoreMix from '@lottery.2.0/mixin/store.modul.js'
export default {
    name: 'MyLotterPlaysMudul',
    mixins : [StoreMix],
    data() {
        return {
            store : 'admin/plays',
        }
    },
    methods: {
        init(){

        },
    },
    mounted() {

    },
}
</script>
