<style lang="scss">
    .OperateLhc-10 {

    }
</style>
<template>
    <div class="OperateLhc-10">
        <board-fast v-show="OperateAuth.bind != 1" :client="client" :group="[0]" :step="true" :fast="true" type="lhc" :default-step="0.01" />
        <group :client="client" :list="Table[0]" :length="1" show-total="end" />
    </div>
</template>

<script>
import CommonMix from '../common.js'
import Group from '../group_mobile.vue'
import BoardFast from '../fast_mobile.vue'
export default {
    name: 'OperateLhc10',
    mixins: [CommonMix],
    props: {
        client: {}
    },
    components: {
        Group,
        BoardFast
    }
}
</script>
