
<style lang="scss">
    @import "../../../../style/config.scss";
    .GameCreditXGLHC {
        position:relative; padding-bottom:60px;
        .ctrl {

        }
        .contact {
            .group {
                border:1px solid #f0f0f0; border-radius:7px; cursor:pointer;
                i.icon {color:#ddd; position:relative; top:-2px; margin-right:2px;}
                &:hover {
                    border-color:$color-t;
                }
                &.active {
                    border-color:$color-t;
                    i.icon {color:$color-t;}
                }
            }
        }
        .GameCreditGroup{
            li {
                .goods {
                    background-color:#fff; padding-top:6px; padding-bottom:6px;
                    &.ball {
                        img,span {
                            font-size:.75rem; font-weight:bold;
                        }
                    }
                    &.text {
                        font-size:.75rem;
                    }
                }
            }
        }
        .tabs, .tabs-group {
            display:flex;
            .tab {
                flex:1; padding-top:6px; padding-bottom:6px; text-align:center; font-size:11.5px; background-color:#fff; border-bottom:1px solid $color-n;
            }
            .w5 {
                width:50%;
            }
            .w3 {
                width:33.33%;
            }
        }
        .tabs {
            .tab.active {
                border-color:$color-p; background-color:lighten($color-p,40%);
            }
        }
        .tabs-group {
            i {
                color:#ccc; margin-right:2px; font-size:18px; vertical-align:middle;
            }
            span {
                vertical-align:middle;
            }
            .tab {
                padding-top:6px; padding-bottom:6px;
            }
            .tab.active {
                color:$color-p;
                i {
                    color:$color-p;
                }
            }
        }
        .explain {
            padding:8px 12px; line-height:18px; position:relative; top:-1px; font-size:.6rem;
            &.mark{
                background-color:#feecff;
            }
        }
        .ToMergeInfo {
            a.btn {
                display:inline-block; padding:2px 8px; background-color:#e5e5e5; color:#888; border-radius:99px; margin-left:8px;
                &.active {
                    background-color:$color-p; color:#fff;
                }
            }
        }
    }
</style>
<template>
    <div class="GameCreditXGLHC">
        <div v-if="List && List.length">

            <Category title="特码">
                <div class="tabs">
                    <div class="tab" :class="{'active': direction.active === 'a'}" @click="direction.active = 'a';$emit('reset')">特码A</div>
                    <div class="tab" :class="{'active': direction.active === 'b'}" @click="direction.active = 'b';$emit('reset')">特码B</div>
                </div>
                <fast-six v-for="index in DirectionIndex" :key="index" class="o-mt-s" :direction="direction.active" :list="List[index]" @Reset="$emit('reset')">特码</fast-six>
                <group :list="SpecialGroup[0]" :title="false" :length="7" type="queue" :min="49" :six_color="true" :pick-mark="true" />
                <group :list="SpecialGroup[1]" :title="false" :length="4" type="queue" :min="35" :pick-mark="true" class="o-mt-s" />
            </Category>


            <Category title="生肖色波">
                <group-six-full :list="List[0]" :length="2" type="queue" />
            </Category>


            <Category title="半波">
                <group-six-full :list="List[0]" :length="1" type="queue" />
            </Category>


            <Category title="正码1-6">
                <div v-for="unit in [0,1,2,3,4,5]">
                    <group :key="unit" :list="List[unit]" :prompt="prompt" :length="4" :min="12" type="queue" />
                </div>
            </Category>


            <Category title="连码">
                <div class="tabs">
                    <div v-for="(item,index) in List" class="tab" :class="{'active': ToCode.active === index}" @click="ToCode.active = index">{{ item.title }}</div>
                </div>
                <div v-for="(item,index) in List" v-if="ToCode.active === index" class="tabs-group u-bt">
                    <li v-for="(sub,i) in item.child" v-if="i < 2" class="tab" :class="{'active': ToCode.mode === sub.title}" @click="ToCode.mode = sub.title;$emit('reset')">
                        <i class="icon icon-roundcheckfill" />
                        <span>{{ sub.title }}</span>
                    </li>
                </div>
                <div v-for="(item,index) in List" v-if="ToCode.active === index" class="tabs-group u-bt">
                    <li v-for="(sub,i) in item.child" v-if="i >= 2" class="tab" :class="{'active': ToCode.mode === sub.title}" @click="ToCode.mode = sub.title;$emit('reset')">
                        <i class="icon icon-roundcheckfill" />
                        <span>{{ sub.title }}</span>
                    </li>
                </div>
                <p class="explain u-bb o-plr mark">{{ ToCode.explain }}</p>
                <template v-if="ToCode.mode === '连碰' || ToCode.mode === '胆拖'">
                    <group :key="ToCode.active" :list="ToCodeList" :title="false" :length="7" type="queue" :min="49" mode="box" :six_color="true" @box="ToCodeChange" />
                </template>
                <group-six-full v-if="ToCode.mode === '生肖对碰'" :list="ToCodeList" :length="3" type="queue" mode="box" @box="ToCodeChange" />
                <group-six-full v-if="ToCode.mode === '尾数对碰'" :list="ToCodeList" :length="2" type="queue" mode="box" @box="ToCodeChange" />
                <group-six-full v-if="ToCode.mode === '混合对碰'" :list="ToCodeList" :length="3" type="queue" mode="box" @box="ToCodeChange" />
            </Category>


            <Category title="自选不中">
                <div class="tabs">
                    <li v-for="(item,index) in List" v-if="index < 4" class="tab" :class="{'active': ToMiss.active === index}" @click="ToMiss.active = index;$emit('reset');OddTimerRun(true);">{{ item.title }}</li>
                </div>
                <div class="tabs">
                    <li v-for="(item,index) in List" v-if="index > 3" class="tab" :class="{'active': ToMiss.active === index}" @click="ToMiss.active = index;$emit('reset');OddTimerRun(true);">{{ item.title }}</li>
                </div>
                <p class="explain u-bb o-plr mark">{{ ToMiss.explain }}</p>
                <div v-for="(list,index) in List" v-if="ToMiss.active === index">
                    <group :list="list" :title="false" :length="7" mode="box" type="queue" :min="49" :disabled="false" :six_color="true" @box="ToMissChange" />
                </div>
            </Category>


            <Category title="一肖尾数">
                <group-six-full :list="List[0]" :length="2" type="queue" class="o-mt-s" />
                <group :list="List[1]" :title="false" :length="5" type="queue" :min="8" class="o-mt-s" />
            </Category>


            <Category title="连肖">
                <div class="tabs">
                    <li v-for="(item,index) in List" class="tab" :class="{'active': ToContact.active === index}" @click="ToContact.active = index; ToContactGroup()">{{ item.title }}</li>
                </div>
                <div class="tabs-group u-bt">
                    <li v-for="(item,index) in ToContact.dir" class="tab" :class="{'active': ToContact.mode === item}" @click="ToContact.mode = item; ToContactGroup()">
                        <i class="icon icon-roundcheckfill" />
                        <span>{{ item }}</span>
                    </li>
                </div>
                <p class="explain u-bb o-plr mark">{{ ToContact.explain }}</p>
                <div v-for="(pack,unit) in List" v-if="ToContact.active === unit" :key="unit">
                    <group-six-full :list="pack" :length="2" type="queue" mode="box" @box="ToContactChange(pack,unit + 2)" />
                </div>
            </Category>


            <Category title="连尾">
                <div class="o-ptb-l c-text-c">
                    <el-radio v-for="(item,index) in List" :key="index" v-model="ToContact.active" :label="index" size="small" border @change="ToContactGroup()">{{ item.title }}</el-radio>
                </div>
                <div class="c-text-c o-pb">
                    <el-radio-group v-model="ToContact.mode" @change="ToContactGroup()">
                        <el-radio v-for="(item,index) in ToContact.dir" :key="index" class="o-plr" :label="item">{{ item }}</el-radio>
                    </el-radio-group>
                </div>
                <p class="explain u-bb o-plr mark">{{ ToContact.explain }}</p>
                <div v-for="(pack,unit) in List" v-if="ToContact.active === unit" :key="unit" class="o-mt">
                    <group v-for="(item,index) in pack.child" v-if="ToContact.mode == item.title" :key="index" :list="item" :title="false" mode="box" :disabled="false" type="queue" @box="ToContactChange(item,[2,3,4,2,3,4][unit])" />
                </div>
            </Category>


            <Category title="正码特">
                <div class="tabs">
                    <div v-for="(item,index) in ToSpecial.list" class="tab" :class="{'active': ToSpecial.active === item}" @click="ToSpecial.active = item;$emit('reset');OddTimerRun(true)">{{ item }}</div>
                </div>
                <template v-if="ToSpecialList">
                    <fast-six class="o-mt-s" :list="ToSpecialList" @Reset="$emit('reset')">{{ ToSpecial.active }}</fast-six>
                    <group :min="49" :title="false" :list="ToSpecialList" :length="7" type="queue" :six_color="true" :pick-mark="true" />
                </template>
            </Category>


            <Category title="合肖">
                <div class="block ToMergeInfo o-mt-s">
                    <div class="o-plr o-ptb-s l-flex-c">
                        <div class="l-flex-1">
                            <span v-if="ToMergeInfo.unit">{{ ToMergeInfo.unit }}{{ ToMergeInfo.mode }}赔率：<b class="c-color-e">{{ ToMergeOdds }}</b></span>
                            <span v-else>请选择生肖</span>
                        </div>
                        <a class="btn" :class="{'active': ToMergeInfo.mode === '中'}" @click="ToMergeInfo.mode = '中';ToMergeInfoChange();">中</a>
                        <a class="btn" :class="{'active': ToMergeInfo.mode === '不中'}" @click="ToMergeInfo.mode = '不中';ToMergeInfoChange();">不中</a>
                    </div>
                    <div class="tabs">
                        <li v-for="item in ['1肖','2肖','3肖','4肖','5肖','6肖']" class="tab" :class="{'active': ToMergeInfo.unit === item}" @click="ToMergeInfo.unit = item;ToMergeInfoChange()">{{ item }}</li>
                    </div>
                    <div class="tabs">
                        <li v-for="item in ['7肖','8肖','9肖','10肖','11肖']" class="tab" :class="{'active': ToMergeInfo.unit === item}" @click="ToMergeInfo.unit = item;ToMergeInfoChange()">{{ item }}</li>
                        <li class="tab" />
                    </div>
                </div>
                <!-- <Group :list="List[0]" :title="false" :length="4" :min="24" mode="box" @box="ToMergeChange" type="queue"></Group> -->
                <p class="explain u-bb o-plr mark" style="margin-top:2px;">{{ ToMerge.explain }}</p>
                <group-six-full v-if="ToMerge.dir" :list="ToMerge.dir" :length="3" type="queue" mode="box" :fortynine="false" :has-odds="false" @box="ToMergeChange()" />
            </Category>


            <Category title="正码">
                <fast-six class="o-mt-s" :list="List[0]" @Reset="$emit('reset')">正码</fast-six>
                <group :list="List[0]" :title="false" :length="7" type="queue" :min="55" :six_color="true" :pick-mark="true" />
            </Category>

            <!-- <div class="tools-expand o-mt-s">
                <div class="block">
                    <p class="o-p u-bb">长龙提示</p>
                    <div v-if="$store.state.game.tips._loading" class="c-text-c">
                        <Load />
                    </div>
                    <ul v-if="Tips" class="o-ptb-s" style="overflow: hidden;">
                        <li v-for="(item,index) in Tips" class="c-line-10" style="width: 50%; float: left;">
                            <div class="o-plr l-flex-c">
                                <div class="l-flex-1 c-text-55">{{ item.title }}</div>
                                <p class="c-text-6">{{ item.count }} 期</p>
                            </div>
                        </li>
                        <li v-if="Tips.length == 0" class="o-ptb-l c-text-c">暂无长龙</li>
                    </ul>
                </div>
            </div> -->


        </div>
    </div>
</template>

<script>
import Group from './components/group_mobile'
import FastSix from './components/group_fast_mobile'
import GroupSixFull from './components/group_six_full_mobile'
import Common from './xglhc.js'
export default {
    name: 'GameCreditXGLHC',
    mixins: [Common],
    data() {
        return {
            ToMergeInfo: {
                mode: '中',
                unit: '',
                odds: '-'
            }
        }
    },
    computed: {
        isMobile() {
            return true
        },
        ToMergeOdds() {
            try {
                if (this.Running.nav && this.Running.nav.item && this.Running.nav.item.title === '合肖') {
                    let title = `${this.ToMergeInfo.unit}${this.ToMergeInfo.mode}`
                    for (let item of this.List[0].child) {
                        if (item.name === title) {
                            for (let id in this.Running.play.odds) {
                                if (id == item.id) {
                                    return this.Running.play.odds[id].odds
                                }
                            }
                        }
                    }
                }
            } catch (err) {
                return '-'
            }
            return '-'
        }
    },
    watch: {
        'List'(val) {
            // this.DataReset('ToMergeInfo')
            // if(this.Running.nav && this.Running.nav.item && this.Running.nav.item.title === '合肖'){
            //     this.$nextTick(()=>{
            //         this.ToMergeInit()
            //         this.ToMergeInfoChange()
            //     })
            // }
        }
    },
    methods: {
        ToMergeInfoChange() {
            if (this.Running.nav.item.title === '合肖' && this.List && this.List[0]) {
                let title = `${this.ToMergeInfo.unit}${this.ToMergeInfo.mode}`
                for (let item of this.List[0].child) {
                    if (item.name === title) {
                        this.ToMergeChange(item)
                        return
                    }
                }
            }
        }
    },
    components: {
        Group, GroupSixFull, FastSix
    },
    mounted() {
        this.$store.state.base.Event.removeAllListeners('NavTable/GOODS_RELOAD')
        this.$store.state.base.Event.on('NavTable/GOODS_RELOAD', (res) => {
            this.DataReset('ToMergeInfo')
            this.CheckOpt()
            this.ToMergeInit()
            if (this.Running.nav && this.Running.nav.item && this.Running.nav.item.title === '合肖') {
                this.$nextTick(() => {
                    this.ToMergeInfoChange()
                })
            }
            this.OddTimerInit(true)
        })
        this.init()
    }
}
</script>
