<style lang="scss">
    @import "../../../../../style/config.scss";
    .GameCredit {        
        .statis {
            .statis-type {
                display:block; width:50%; height:2.4rem; line-height:2.4rem;
                color:#fff; text-shadow:0 1px 1px #000; background-color:#353535;
                text-align:center;
                &.active {
                    background-color:$color-t;
                }
            }
            .list {
                min-height:300px; max-height:500px; overflow:auto;
            }
        }
        .tools {
            $height:2.4rem;
            height:$height; line-height:$height; overflow:hidden;
            .rebate {
                height:$height; line-height:$height; float:left; width:400px;
            }
            .btn {
                float:right;
            }
        } 

        .selection {
            min-height:260px;
            .checkout-btn {
                button {
                    padding:9px 13px;
                }
            }
        }
        .rebate {
            .rebate-btn {
                width:1rem; height:1rem; line-height:1rem; margin:0 3px;  border:1px solid $color-t;
                font-size:.65rem; cursor:pointer; text-align:center; font-weight:bold;
                display:inline-block; background-color:#fff; color:$color-t;  border-radius:999px;
            }
        }
        .el-col-5 {
            width: 20%; //width: 20.83333%;
        }
        .table.el-col-19 {
            width: 80%;
        }
        .credit-empty {
            height:3rem; padding:1rem 0; margin:1rem 0; text-align:center; font-size:.8rem; border-radius:6px;
            color:#333;
        }

        // 全屏子窗口
        .checkout, .chips {
            width:100%; height:100%; position:fixed; top:0; left:0; right:0; bottom:0; background-color:rgba(0,0,0,.5);
            .core {
                width:100%; height:480px; height:73vh; background-color:#fff; position:absolute; left:0; right:0; bottom:0; box-shadow:-5px 0 10px rgba(0,0,0,.4);
            }
        }
        .checkout {
            .table-list {
                position:relative; overflow-y:auto;
                .table {
                    position:absolute; top:0; left:0; right:0; bottom:0; overflow-y:auto;
                    thead {
                        background-color:#7a868c; color:#fff;
                        td {
                            border-bottom:.03rem solid #ccc;
                        }
                    }
                    td {
                        padding:6px 5px;
                        &:first-child {padding-left:14px;}
                        &:last-child {padding-right:14px;}
                    }

                }
            }
        }
        .chips {
            .core {
                height:280px;
                .chip {
                    img {
                        display:block; width:80%; max-width:54px; margin:.2rem auto;
                    }
                }
            }
        }

        input {
            height: 24px; line-height: 24px; padding: 0 3px; width: 60px; outline:none; border: .03rem solid #ccc; border-radius: 4px;
        }

        .chip-pick {
            position: relative;
            .custom-amount {
                display:inline-block; padding:2px 5px; font-size:.65rem; background-color:$color-t; color:#fff; position:absolute; top:5px; right:14px; border-radius:3px; border:.03rem solid #333;
            }
        }

        .layout-bottom {
           input.input {
                width: 100%; display: block; height: 34px; line-height: 34px; font-size: 17px; border-radius: 6px; padding: 0 6px; font-weight: bold; box-sizing: border-box;
                color: rgb(243, 114, 27);
            }
            .btn {
                display: block;
                padding: 0 12px; font-size: 17px; line-height: 36px; border-radius: 6px; text-align: center;
                background-color: #4477ad; color: #FFF;
                &.submit {
                    background-color: rgb(243, 114, 27);
                }
            }
        }
    }
</style>
<template>
    <div class="GameCredit">
        <div v-if="!Running.play || Running.play.loading || !List.length" v-show="Running.expect.now.id" class="o-ptb-l c-text-c">
            <Load></Load>
        </div>
        <div v-else-if="Running.expect.now.id === null">
            <div v-if="Opt.isCredit" class="credit-empty">
                <Loading v-if="!Running.expect.sync" :loading="true"></Loading>
                <template v-else>
                    <p class="o-pb-l"><b>目前没有可以下注期数</b></p>               
                    <Button  size="small" @click="$emit('ExpectReload')">刷新</Button>
                </template>
            </div>            
        </div>
        <div v-else class="selection o-pb">
            <component v-if="Running.remote_data && Running.remote_data.credit_tmp" v-show="List.length" :is="Running.remote_data.credit_tmp" :List="List" :DB="DB" @reset="Reset()" @checkout="Checkout()" @updateOpt="updateOpt" ref="order"></component>
            
            <!-- <div class="layout-bottom" style="z-index: 1;">
                <div class="o-p-s">
                    <div class="l-flex-c">
                        <a class="o-plr" @click="Reset()">
                            <Icon name="delete"></Icon>
                        </a>
                        <div class="l-flex-1 l-flex-c o-plr-s u-bl">
                            <Icon class="c-color-w" name="rechargefill" size=".8"></Icon>
                            <span class="o-pl-s">{{ CBalance }}</span>
                        </div>
                        <div class="o-plr-s" v-if="QuickChip.mode === 2">
                            <NumberInput v-model.number="amount">
                                <input v-model.number="amount" size="small" placeholder="输入金额" style="width:70px; padding:2px 4px; font-size:.6rem;" readonly>
                            </NumberInput>
                        </div>
                        <div class="chip-pick o-plr">
                            <div v-if="!opt.disabledQuick" @click="chips.view = true">
                                <img :src="Img(QuickChip.icon)" style="width:30px; height:30px;">
                                <span class="custom-amount" v-if="QuickChip.type === 'custom'">{{ amount }}</span>
                            </div>
                        </div>
                        <Button class="checkout-btn" size="small" type="default" >下单</Button>
                        <NumberInput v-model.number="amount" type="model" ref="custom" @change="QuickAmountCustom($event)"></NumberInput>
                    </div>
                </div>
            </div> -->
            
            <div class="layout-bottom" style="z-index: 1; white-space: nowrap;">
                <div class="l-flex-c o-p-s" style="max-width: 480px; margin: 0 auto;">
                    <div class="o-pr-s" style="flex: 0 0 28%; box-sizing: border-box;">
                        <div class="btn" @click="quick.view = true">快选金额</div>
                    </div>
                    <div style="flex: 1 0 auto; height: 36px; box-sizing: border-box; position: relative;">
                        <div style="position: absolute; inset: 0;">
                            <NumberInput v-model.number="amount">
                                <input v-model.number="amount" class="input" placeholder="输入金额" readonly>
                            </NumberInput>
                        </div>
                    </div>
                    <div class="o-pl-s" style="flex: 0 0 20%; box-sizing: border-box;">
                        <div class="btn submit" @click="Checkout()">投注</div>
                    </div>
                    <div class="o-pl-s" style="flex: 0 0 20%; box-sizing: border-box;">
                        <div class="btn" @click="Reset()">重置</div>
                    </div>
                </div>

                <div v-show="quick.view" class="c-bg-f" style="position: fixed; z-index: 30; width: 100px; bottom: 54px; left: 10px; border-radius: 6px; box-shadow: 0 0 12px rgba(0, 0, 0, .1);">
                    <li v-for="(number, index) in quick.value" :key="index" class="c-text-c c-line-16 u-bb o-p-s" @click="setQuickAmount(number)">
                        <span class="c-text-10">{{ number }}</span>
                    </li>
                    <li class="c-text-c c-text-85 c-line-16 u-bb o-p-s" @click="useQuickOpts()">自定义</li>
                </div>
                
                <div v-show="quickOpts.view" style="position: fixed; top: 0; bottom: 0; left: 0; right: 0; background-color: rgba(0, 0, 0, .5);" @click.self="quickOpts.view = false">
                    <div class="c-bg-f l-flex-v o-plr" style="width: 220px; height: 400px; margin: 30px auto 0 auto; margin-top: 15vh; justify-content: center; align-items: center; border-radius: 12px;">
                        <div class="c-text-c c-text-8 o-ptb-s">快捷选项设置</div>
                        <li v-for="(item, index) in quickOpts.value" :key="index" class="l-flex-c o-pt-s o-plr-l">
                            <span class="c-line-16 o-mr">{{ index + 1 }}.</span>
                            <input v-model="quickOpts.value[index]" maxlength="6" style="display: block; width: 120px; height: 32px; line-height: 32px; font-size: 15px;" />
                        </li>
                        <Button class="o-mt" @click="saveQuickOpts()">保存配置</Button>
                    </div>
                </div>

            </div>
        </div>

        {{/* 确认注单 - 弹窗 */}}
        <div class="checkout" v-if="checkView" @click.self="checkView = false;" style="z-index: 12;">
            <div class="core l-flex-v">
                <div class="table-list l-flex-1">
                    <table class="table" style="width:100%">
                        <thead>
                            <tr>
                                <td>投注内容</td>
                                <td style="text-align:center;">赔率</td>
                                <td style="text-align:right;">下注金额</td>
                            </tr>
                        </thead>
                        <tbody class="zebra">
                            <tr v-for="(item,index) in Cart">
                                <td>{{ item.belong }}:{{ item.name }}</td>
                                <td style="text-align:center;">{{ item.odds }}</td>
                                <td style="text-align:right;">
                                    <NumberInput v-model.number="item.amount">
                                        <input v-model.number="item.amount" readonly>
                                    </NumberInput>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="l-flex-c o-plr o-ptb-s c-text-6 u-bt c-text-r">
                    <span>修改金额</span>
                    <input v-model.number="autoInput" class="o-mlr-s" type="number" maxlength="6" />
                    <div class="l-flex-1" />
                    <span>共 <b class="c-color-t">{{ calcuCart.count }}</b> 注，</span>
                    <span>合计 <b class="c-color-t">{{ calcuCart.total }}</b> 元</span>
                </div>
                <div class="l-flex o-plr o-pt-s o-pb">
                    <Button class="l-flex-2" type="info" @click="checkView = false" full>放弃</Button>
                    <Button class="l-flex-3" :disabled="$store.state.game.order._editing" @click="_submit()" full>确定投注</Button>
                </div>
            </div>
        </div>

        {{ /* 旋转筹码 */ }}
        <div class="chips" v-show="chips.view" @click.slef="chips.view = false" style="z-index: 12;">
            <div class="core">
                <div class="o-pt">
                    <p class="c-text-c c-color-g c-text-6 c-line-14">请选择筹码</p>
                    <ul class="l-flex-c" v-for="(pack,unit) in chips.tools">
                        <li class="chip l-flex-1 o-ptb-s" v-for="(item, index) in pack" @click="QuickAmountEvent(item)">
                            <img :src="Img(item.icon)">
                        </li>
                    </ul>
                </div>
            </div>
        </div>

    </div>
</template>
<script>

// import ssc from '@lottery.2.0/page/game/components/credit/ssc.vue'
// import pk10 from '@lottery.2.0/page/game/components/credit/pk10.vue'
// import p115 from '@lottery.2.0/page/game/components/credit/p115.vue'
// import k3 from '@lottery.2.0/page/game/components/credit/k3.vue'
import xglhc from '@lottery.2.0/page/game/components/credit/xglhc_mobile.vue'
import Common from '@lottery.2.0/page/game/components/credit.js'
export default {
    mixins: [Common],
    data(){
        return {
            isMobile : true,
            chips : {
                view : false,
                tools : [
                    [
                        {
                            event : 'type',
                            value : 3,
                            icon : 'chips/1.png',
                        },
                        {
                            event : 'custom',
                            icon : 'chips/2.png',
                        },
                        {
                            event : 'amount',
                            value : 5,
                            icon : 'chips/3.png',
                        }
                    ],
                    [
                        {
                            event : 'amount',
                            value : 10,
                            icon : 'chips/4.png',
                        },
                        {
                            event : 'amount',
                            value : 50,
                            icon : 'chips/5.png',
                        },
                        {
                            event : 'amount',
                            value : 100,
                            icon : 'chips/6.png',
                        }
                    ],
                    [
                        {
                            event : 'amount',
                            value : 500,
                            icon : 'chips/7.png',
                        },
                        {
                            event : 'amount',
                            value : 1000,
                            icon : 'chips/8.png',
                        },
                        {
                            event : 'amount',
                            value : 10000,
                            icon : 'chips/9.png',
                        }
                    ]
                ]
            },
            quickOpts: {
                value: [],
                view: false
            },
            autoInput: ''
        }
    },
    computed : {
        CBalance(){
            if (this.CreditInfo) {
                let { balance, ubalance } = this.CreditInfo
                try {
                    let amount = this.Big(balance).sub(ubalance).toNumber()
                    return amount
                }catch(err){
                    return '-'
                }
            }
            return '-'
        },
        QuickAmount(){
            return this.quick.value[0]
        },
        QuickChip(){
            let obj = {
                mode : this.type,
                type : 'default',
                icon : 'chips/1.png',
                dir : {
                    5 : 'chips/3.png',
                    10 : 'chips/4.png',
                    50 : 'chips/5.png',
                    100 : 'chips/6.png',
                    500 : 'chips/7.png',
                    1000 : 'chips/8.png',
                    10000 : 'chips/9.png',
                },
            }
            if (this.opt.disabledQuick) {
                obj.mode = 2
            }
            let amount = this.QuickAmount
            if (obj.mode == 1) {
                if(amount > 0 && obj.dir[amount]){
                    obj.icon = obj.dir[amount]
                }else{
                    obj.icon = 'chips/2.png'
                    obj.type = 'custom'
                }
            }
            return obj
        }
    },
    watch:{
        type(val){
            //this.Reset(true,true)
        },
        autoInput(val) {
            if (val && val == Number(val) && val > 0 && val <= 999999) {
                for (const item of this.Cart) {
                    item.amount = Number(val)
                }
            }
        },
        checkView: {
            immediate: true,
            handler: function(val) {
                if (val) {
                    this.cartSort()
                    this.autoInput = ''
                    document.getElementsByClassName('board')[0].style.overflowY = 'hidden'
                } else {
                    document.getElementsByClassName('board')[0].style.overflowY = 'auto'
                }
            }
        },
        'quickOpts.view': {
            immediate: true,
            handler: function(val) {
                if (val) {
                    document.getElementsByClassName('board')[0].style.overflowY = 'hidden'
                } else {
                    document.getElementsByClassName('board')[0].style.overflowY = 'auto'
                }
            }
        }
    },
    methods : {
        QuickChipInit(){
            this.$set(this, 'type', 2)
            this.$set(this.quick.value, '0', 10)
        },
        QuickAmountEvent(item){
            if (item.event === 'type') {                
                this.$set(this,'type',item.value)
            }
            if (item.event === 'amount') {
                this.QuickAmountSet(item.value)
            }
            if (item.event === 'custom') {
                this.$refs['custom'].Open(null)
            }
        },
        QuickAmountCustom(e){
            if (e && e > 0) {                
                this.$set(this, 'amount', e)
                this.QuickAmountSet(e)
            }
        },
        QuickAmountSet(amount){
            if (amount > 10000) {
                amount = 10000
                this.Msg('筹码金额最大 10000 元')
            }
            if (!amount || amount <= 0) {
                amount = 5
            }
            this.$set(this, 'type', 1)
            this.$set(this.chips, 'view', false)
            this.$set(this.quick.value, '0', amount)
        },
        setQuickAmount(e) {
            const amount = Number(e)
            if (amount) {
                if (amount > 999999) {
                    amount = 999999
                    this.Msg('筹码金额最大 999999 元')
                }
                if (!amount || amount <= 0) {
                    amount = 5
                    this.Msg('筹码金额最小 5 元')
                }
                this.amount = amount
            }
            this.quick.view = false
        },
        useQuickOpts() {
            this.quickOpts.view = true
            this.quick.view = false
            this.$set(this.quickOpts, 'value', this.quick.value)
        },
        saveQuickOpts() {
            const error = this.quickOpts.value.find(row => !row || row != Number(row) || row < 0 || row > 999999 || Number(row) % 1 != 0)
            if (error) return this.Msg('格式有误，请检查')
            this.quickOpts.view = false
            const values = this.quickOpts.value.map(num => Number(num))
            this.$set(this.quick, 'value', values)
            this.Cache('QUICK_COIN_VALUE', values)
        },
        cartSort() {
            this.Cart.sort((a, b) => {
                const aCode = a.name == Number(a.name) ? Number(a.name) : 99999
                const bCode = b.name == Number(b.name) ? Number(b.name) : 99999
                if (aCode < bCode) return -1
                if (aCode > bCode) return 1
                return 0
            })
        },
        _submit() {
            return this.Submit().then(res => {
                if (this.chipinState == 2) {
                    this.Msg(`投注成功，共 ${this.chipinSubmitTotal.count} 注，合计金额 ${this.chipinSubmitTotal.total} 元`)
                    this.amount = undefined
                } else {
                    this.Msg('提交失败')
                }
                this.checkView = false
            })
        }
    },
    components : {
       // ssc, pk10, p115, k3,
        xglhc,
    },
    mounted(){
        const values = this.Cache('QUICK_COIN_VALUE') || [10, 30, 50, 100, 300, 500, 1000]
        this.$set(this.quick, 'value', values)
        this.QuickChipInit()
    },
    activated() {
        // this.opt.onlyQuick = true
        // setTimeout(() => {}, 500)
    }
}
</script>
