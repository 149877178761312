<style lang="scss">
   // @import "../style/config.scss";
    .Datetime {

    }
</style>
<template>
    <div class="Datetime">
        <div @click="open()">
            <slot></slot>
            <mt-cell :title="title" is-link>{{ Label }}</mt-cell>
        </div>
        <mt-datetime-picker v-if="tvalue" :type="type" v-model="tvalue" ref="picker" @confirm="handleConfirm"></mt-datetime-picker>
    </div>
</template>

<script>
    export default {
        name : 'Datetime',
        data(){
            return {
                view : false,
                tvalue : null,
            }
        },
        props : {
            title : {
                default : '',
            },
            value : {},
            type : {
                default : 'time',
            },
        },
        watch : {
            value(val){
                this.SyncValue(val)
            },
            // tvalue(val){

            // },
        },
        computed:{
            Label(){
                if(this.type === 'datetime'){
                    return this.Time(this.value)
                }
                if(this.type === 'date'){
                    return this.Time(this.value,'yyyy-MM-dd')
                }
                return this.value
            },
        },
        methods: {
            open(){
                this.$refs.picker.open();
            },
            SyncValue(val=this.value){
                if(this.type==='time'){
                    if(val){
                        let times = val.split(':')
                        if(times.length>2){
                            val = [times[0],times[1]].join(':')
                        }
                    }else{
                        val = "00:00"
                    }
                }
                if(this.type==='date'){
                    val = val ? (new Date(val)) : new Date()
                }
                if(this.type==='datetime'){
                    if(val){                        
                        val = new Date(val)
                    }else{
                        val = new Date(val)
                    }
                }
                this.$set(this,'tvalue',val)
            },
            handleConfirm(){
                let val = this.tvalue
                if(this.type === 'time'){
                    let times = val.split(':')
                    if(times.length===2){
                        val = [times[0],times[1],'00'].join(':')
                    }
                }
                if(this.type === 'date'){
                    val = this.Time(val,'yyyy-MM-dd')
                }
                if(this.type === 'datetime'){
                    val = this.Time(val)
                }
                this.$emit('input',val)
            },
        },
        mounted(){
            this.SyncValue()
        },
    }
</script>