<style lang="scss">
    @import "../style/config.scss";
    .HeadNav {
        position:fixed; width:100%; height:100%; top:0; left:0; right:0; bottom:0; background-color:rgba(0,0,0,.5);
        .nav-core {
            width:220px; height:100%;
            position:absolute; top:0; right:0; bottom:0;
            background-color:#fff; box-shadow:0 -3px 12px rgba(0,0,0,.3);
            .user {
                background-color:#666; padding:24px 12px; color:#fff;
            }
            .links {
                li {
                    height:42px; line-height:42px;
                }
            }
        }
    }
</style>
<template>
    <div class="HeadNav" style="z-index:30;" v-if="view" @click.self="$emit('input',false)">
        <div class="nav-core">
            <div class="user">
                <div class="l-flex">
                    <div class="l-flex-1 o-pl o-ptb-s">
                        <h6 class="c-line-14"><b>{{ User.username }}</b></h6>
                        <p class="c-text-6">信用：{{ Price(CreditInfo.balance - CreditInfo.ubalance) }}</p>
                    </div>
                </div>
            </div>
            <ul class="links o-ptb">
                <li v-for="(item,index) in menu" v-if="!item.disabled" class="l-flex-c o-plr" :class="{'u-bt':index}" @click="Handle(item)">
                    <Icon :name="item.icon" size="1" :style="'color:'+item.color"></Icon>
                    <p class="l-flex-1 o-plr">{{ item.title }}</p>
                    <Icon name="right" size=".5"></Icon>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import StoreMix from '@lottery.mobile/mixin/store.js'
export default {
    name : 'HeadNav',
    mixins : [StoreMix],
    data(){
        return {
            view : false,
        }
    },
    props : {
        value : {
            default : false,
        }
    },
    watch:{
        value(val){
            this.view = val
        },
        '$route.name'(val){
            this.$emit('input',false)
        }
    },
    computed:{
        menu(){
            return [
                {
                    title : '本期注单',
                    name : 'index/pages/bets',
                    icon : 'newshot',
                    color : '#3785ea',
                    disabled : this.Opt.isTeam || !this.Running.id,
                },
                {
                    title : '投注记录',
                    name : 'index/pages/query',
                    icon : 'form',
                    color : '#ee463f',
                    disabled : this.Opt.isTeam,
                },
                {
                    title : '帐号管理',
                    name : 'index/pages/limit',
                    icon : 'friend',
                    color : '#3785ea',
                    disabled : !this.Opt.isTeam,
                },
                {
                    title : '彩种限额',
                    name : 'index/pages/limit',
                    icon : 'creative',
                    color : '#3aadfa',
                },
                {
                    title : '统计报表',
                    name : 'index/pages/report',
                    icon : 'rank',
                    color : '#3785ea',
                    disabled : !this.Opt.isTeam,
                },
                {
                    title : '个人报表',
                    name : 'index/pages/reportself',
                    icon : 'rank',
                    color : '#3785ea',
                    disabled : this.Opt.isTeam,
                },
                {
                    title : '开奖查询',
                    name : 'index/pages/output',
                    icon : 'upstage',
                    color : '#ff6600',
                    disabled : !this.Running.id,
                },
                {
                    title : '返回大厅',
                    name : 'index/home',
                    icon : 'home',
                    color : '#34c748',
                },
            ]
        },
    },
    methods: {
        Handle(item){
            this.$emit('input',false)
            if(item.name === this.$route.name){
                return
            }
            let params = {}
            if(item.name === 'index/pages/output'){
                if(this.Running.id){
                    params.id = this.Running.id
                    params.model = this.Running.model
                }else{
                    params.id = 31
                    params.model = 7
                }
            }
            if(item.name === 'index/pages/bets'){
                params.id = this.Running.id
                params.title = this.Running.title
                params.expect_num = this.Running.expect.now.expect
                params.expect_id = this.Running.expect.now.id
                console.log(params)
            }
            // if(item.name === 'index/home'){
            //     return this.Rd(item.name,params)
            // }
            this.Go(item.name,params)
        },
    },
    mounted(){
        this.view = this.value
    }
}
</script>