<style lang="scss">
    //@import "./group.scss";
    .GameCreditFast {
        .header {
            background-color:#fff; padding:0 0 0 14px; height:28px; line-height:28px;
            .fast-btn {
                padding:0px 12px; line-height:28px; background-color:#a87eff; color:#fff;
                &.g {
                    background:#eae1fb; color:#333;
                }
            }
        }
        .tabs {
            .tab {
                background-color:#ddd; color:#666; border-bottom: 1px solid #EEE!important;
                &.active {
                    background-color:#208cff; border-color:#208cff; color:#208cff;
                }
            }
        }
        .boardGroup {
            li {
                padding:6px 0; background-color:#fff;
                &.active {
                    background-color:#f8e8ff;
                }
            }
            .red {color:red; font-weight:bold;}
            .blue {color:blue; font-weight:bold;}
            .green {color:green; font-weight:bold;}
        }
    }
</style>
<template>
    <div v-if="list && (list.child || list.length)" class="GameCreditFast">
        <div class="l-flex-c o-plr c-text-6 header">
            <div class="l-flex-1">
                <slot />
            </div>
            <a class="fast-btn g" @click="Reset()">清除选择</a>
            <a class="fast-btn" :class="{'extend': extend}" @click="extend = !extend">快速选号</a>
        </div>
        <div v-if="extend" class="l-flex" style="padding-bottom:4px;">
            <div class="l-flex-1">
                <ul class="l-flex-c tabs">
                    <li v-for="(pack,unit) in boardGroup" class="l-flex-1 c-text-c tab" :class="{'active': active === unit}" @click="active = unit">{{ pack }}</li>
                </ul>
                <div v-for="(pack,unit) in boardGroup" v-if="active === unit" class="boardGroup">
                    <div v-for="(item,index) in boardDir[pack]" class="l-flex-c">
                        <li v-for="(sub,i) in item" class="l-flex-1 c-text-c u-bb" :class="{'active': boardPick[sub],'u-bl': i}" @click="PickByGroup(sub)">
                            <a :class="CodeColor(sub)">{{ sub }}</a>
                        </li>
                    </div>
                </div>
            </div>
        </div>
        <NumberInput ref="custom" type="model" @change="QuickAmountCustom($event)" />
    </div>
</template>

<script>
import Credit from '../../../../../mixin/credit.js'
import Prompt from './prompt'
import SixMix from '@lottery.admin.2.0/mixin/six.js'
export default {
    name: 'GameCreditFast',
    mixins: [SixMix, Credit],
    data() {
        return {
            custom_active: null,
            extend: false,
            active: 0,
            amount: null,
            boardDir: {
                '双面': [['大', '小', '单', '双', '合单', '合双'], ['家禽', '走兽', '尾大', '尾小', '合尾大', '合尾小']],
                '色波': [['红波', '红单', '红双', '红大', '红小'], ['蓝波', '蓝单', '蓝双', '蓝大', '蓝小'], ['绿波', '绿单', '绿双', '绿大', '绿小']],
                '头尾': [['0头', '1头', '2头', '3头', '4头'], ['0尾', '1尾', '2尾', '3尾', '4尾'], ['5尾', '6尾', '7尾', '8尾', '9尾']],
                '生肖': [['鼠', '牛', '虎', '兔', '龙', '蛇'], ['马', '羊', '猴', '鸡', '狗', '猪']]
            },
            boardPick: {}
        }
    },
    props: {
        list: {},
        direction: {
            default: 'a'
        },
        outbtn: {
            default: false
        }
    },
    computed: {
        boardGroup() {
            return ['双面', '色波', '生肖', '头尾']
        },
        directionName() {
            if (this.type && this.direction) {
                return `${this.type}${this.direction.toUpperCase()}`
            }
            return ''
        }
    },
    methods: {
        Reset() {
            this.$set(this, 'boardPick', {})
            this.$emit('Reset')
        },
        Checkout() {
            this.$emit('Checkout')
        },
        PickByGroup(name) {
            console.log('PickByGroup:', name, ',this.COptions.type:', this.COptions.type)
            if (this.COptions.type == 3) {
                this.custom_active = name
                return this.$refs['custom'].Open(null)
            }
            // this.$set(this.boardPick,name,!this.boardPick[name])
            let list = this.QuickBetList[name]
            if (list && this.list && this.list.child) {
                for (let item of this.list.child) {
                    for (let code of list) {
                        if (item.name == code) {
                            //this.$set(item,'pick',this.boardPick[name])
                            if (this.COptions.type == 1) {
                                let amount = item.amount ? item.amount : 0
                                this.$set(item, 'amount', this.COptions.quick.value[0] + amount)
                            } else if (this.COptions.type == 2) {
                                this.$set(item, 'pick', true)
                            }
                        }
                    }
                }
            }
        },
        QuickAmountCustom(e) {
            if (e && e > 0) {
                let name = this.custom_active
                let list = this.QuickBetList[name]
                if (list && this.list && this.list.child) {
                    for (let item of this.list.child) {
                        for (let code of list) {
                            if (item.name == code) {
                                this.$set(item, 'amount', e)
                            }
                        }
                    }
                }
            }
        },
        TransmitAmount() {
            let amount = this.amount
            if (amount) {
                this.$set(this, 'boardPick', {})
                if (this.Is('int', amount) && amount > 0) {
                    if (this.list && this.list.child) {
                        for (let item of this.list.child) {
                            if (item.pick) {
                                this.$set(item, 'amount', amount)
                                this.$set(item, 'pick', false)
                            }
                        }
                    }
                    return
                }
                return this.Err('金额格式填写错误，金额必须是整数。')
            }
        }
    },
    components: {

    }
}
</script>
