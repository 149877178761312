<style lang="scss">
    @import "./index.scss";
    .Operate {
        width:100%; height:100%;
        .info {
            height:0; padding-top:0; padding-bottom:0;
            .downtime {
                position:absolute; z-index:2; right:0; top:-3.25rem; padding-right:0;
            }
            .auto-reload-2 {
                position:absolute; z-index:2; top:4px; right:100px; padding-right:0;
            }
            .reload {
                position:absolute; z-index:2; top:4px; right:15px;
            }
            .tools {
                position:absolute; height:30px; line-height:30px; z-index:2; right:220px; top:-3.55rem; padding-right:0;
                button {
                    padding:2px 6px; font-size:.65rem; margin-left:4px;
                }
            }
        }
        .el-button.el-button--warning.reload-btn {
            color: #333;
            background-color: #94be39;
            border-color: #94be39;
            &:focus, &:hover {
                background: #81a92b;
                border-color: #81a92b;
                color: #333;
            }
            &.is-disabled {
                background-color: #81a92b;
                border-color: #81a92b;
                &:focus, &:hover {
                    background: #81a92b;
                    border-color: #81a92b;
                    color:#333;
                }
            }
        }
        .ctrl {
            width:100%; min-width:100%; max-width:100%; box-sizing:border-box; overflow-y:auto;
        }
        $color-thead : #7BAAFF;
        $color-tfoot : #4a7ba2;
        $color-hover : #eadcf3;
        $color-odd   : #fff;
        $color-even  : #edf1fb;
        table.table {
            width:100%; text-align:center; vertical-align:middle;
            tr {
                td {
                    padding:4px; text-align:inherit;
                }
            }
            thead {
                tr {
                    background-color:$color-thead;
                    &:nth-child(2){
                        background-color:#aecfff;
                    }
                    td {
                        text-align:center; vertical-align:middle;
                    }
                }
            }
            tbody {
                tr {
                    background-color:$color-even;
                    &:nth-child(odd){
                        background-color:$color-odd;
                    }
                    &:hover {
                        cursor:pointer; background-color:$color-hover;
                    }
                    td {
                        padding-top:7px; padding-bottom:7px;
                    }
                }
            }
            tfoot {
                tr {
                    td {
                        padding-top:6px; padding-bottom:6px;
                    }
                }
                .empty {
                    background-color:#fff; padding:24px 0; text-align:center; font-size:.65rem; color:#999;
                }
                .subtotal {
                    &, & td {
                        background-color:#cdcfff;
                    }
                }
                .total {
                    &, & td {
                        background-color:#537aa9; color:#fff;
                    }
                }
            }
        }
        .OperateGroup.mini table.table tr td {
            height:36px;
        }
        .OperateGroup.mini table.table .row .ball {
            width:1.1rem; height:1.1rem; line-height:1.1rem; font-size:.7rem;
        }
        .reload-btn {
            &, button {
                width:60px;
            }
        }
        .tools {
            .tool {
                &+.tool {

                }
            }
        }
        .mint-cell, .mint-cell:last-child {
            min-height:36px!important; height:36px!important; line-height:36px!important;
        }
        .mint-cell-title, .mint-cell-value {
            font-size:12px;
        }
        .mint-cell-wrapper {
            padding:0 6px;
        }
        .mint-cell-value.is-link {
            margin-right:11px;
        }
        .mint-cell-allow-right:after {
            right:8px;
        }
        .OperateGroup.mini table.table i.plus, .OperateGroup.mini table.table i.remove {
            margin: 0 3px;
            font-size: 1.2rem;
        }
        .mobile-tabs {
            height:32px; line-height:32px;
            display:flex; text-align:center; background-color:#fff;
            .mobile-tab {
                flex:1; font-size:12px; color:#999; border-bottom:2px solid #e0e0e0;
                &.active {
                    border-color:#208cff; color:#208cff; font-weight:bold;
                }
            }
        }
        .tabs-box {
            display: flex; align-items: center;
            ul.tabs-row {
                display: flex; flex-wrap: nowrap; flex: 1;
                li {
                    flex: 0 1 auto; padding: 0 12px; font-size: 14px; line-height: 38px; box-sizing: border-box;
                    word-break: keep-all; white-space: nowrap;
                    background-color: #FFF; border-bottom: 2px solid transparent;
                    &.active {
                        border-color: #208cff;
                    }
                }
            }
            scrollbar-width: none; -ms-overflow-style: none;
            &::-webkit-scrollbar {
                display: none;
            }
        }
    }
</style>
<template>
    <div class="Operate l-flex-v">
        <template v-if="TabItem && TabItem.lot && TabItem.lot.data && TabItem.lot.data.lottery_id">
            <div v-if="TabItem.lot.data.lottery_id || TabItem.history.target" class="block">
                <div class="l-flex-c c-bg-f u-bb" style="width: 100%; height: 40px; position: relative;">
                    <div style="flex: 0 0 15px; height: 100%; position: absolute; left: 0; top: 0; bottom: 0; z-index: 2; background-image: linear-gradient(to right, #FFF 40%, rgba(255, 255, 255, 0));">
                        <icon name="right" :size=".85" style="color: #AAA; flex: 0 0 15px; position: relative; top: 10px; transform: rotate(180deg);" />
                    </div>
                    <div class="tabs-box l-flex-1" style="overflow: auto; position: absolute; left: 0; right: 0; top: 0; bottom: 0; padding: 0 15px;">
                        <ul class="tabs-row">
                            <li :class="{'active': bind == 1}" @click="$parent.target.bind = 1">即时注单</li>
                            <li :class="{'active': bind == 3}" @click="$parent.target.bind = 3">走飞</li>
                            <li @click="Go('index/pages/user')">账号管理</li>
                            <li @click="Go('index/pages/report')">报表</li>
                            <li @click="Go('index/output')">开奖</li>
                        </ul>
                    </div>
                    <div style="flex: 0 0 15px; height: 100%; position: absolute; right: 0; top: 0; bottom: 0; z-index: 2; background-image: linear-gradient(to left, #FFF 40%, rgba(255, 255, 255, 0));">
                        <icon name="right" :size=".85" style="color: #AAA; flex: 0 0 15px; position: relative; top: 10px;" />
                    </div>
                </div>
                <div class="l-flex-c tools u-bb">
                    {{ /*及时注单*/ }}
                    <template v-if="!TabItem.mode">
                        <div class="l-flex-1 tool">
                            <Actionsheet key="history" v-model="TabItem.history.target" title="期数:" :actions="HistoryExpect" size="mini" @change="LoadNav(TabItem)" />
                        </div>
                        <div class="l-flex-1 tool u-bl">
                            <Actionsheet v-model="TabItem.instant" title="类型:" :actions="[{name: '全部',value: 0},{name: '占成',value: 1}]" size="mini" @change="LoadNav(TabItem)" />
                        </div>
                    </template>
                    {{ /*操盘*/ }}
                    <template v-if="OperateAuth.manipulation && bind === 2">
                        <div v-if="OperateAuth.admin" class="l-flex-2 tool">
                            <div class="btn c-text-c" @click="TraderStatus('bet',status.bet_status ? 0 : 1)">
                                <span v-if="status.bet_status">立即封盘</span>
                                <span v-else>重新开盘</span>
                            </div>
                        </div>
                        <div v-else class="l-flex-1 tool">
                            <span />
                        </div>
                    </template>
                    {{ /*走飞*/ }}
                    <template v-if="OperateAuth.transmit && bind === 3">
                        <div class="l-flex-1 tool" @click="Go('index/pages/operate/transfer',{games_id: TabItem.id,lottery_id: TabItem.lot.data.lottery_id,mode: 'transfer'})">
                            <div class="btn c-text-c">走飞历史</div>
                        </div>
                        <!--                         <div class="l-flex-1 tool u-bl" v-if="OperateAuth.admin">
                            <Actionsheet v-model="adminTransfer.type" title="模式:" :actions="[{name:'快速走飞',value:1},{name:'手动走飞',value:0}]" size="mini" @change="LoadNav(TabItem)"></Actionsheet>
                        </div> -->
                        <div v-if="!OperateAuth.admin" class="l-flex-1 tool u-bl" @click="Go('index/pages/operate/automatic',{id: 31})">
                            <div class="btn c-text-c">自动走飞</div>
                        </div>
                    </template>
                    <div class="l-flex-1 tool u-bl">
                        <Actionsheet v-model="opt.reload_interval" title="自动刷新:" :actions="[{name: '禁用',value: 0},{name: '10秒',value: 10000},{name: '30秒',value: 30000},{name: '60秒',value: 60000}]" size="mini" />
                    </div>
                    <Button class="reload-btn o-mlr-s" size="mini" :loading="opt.reload_loading" :disabled="opt.reload_loading" @click="OperateReload()">
                        <span>刷新</span>
                        <span v-show="opt.reload_interval && opt.reload_down && !opt.reload_loading">({{ Number(opt.reload_down) }})</span>
                    </Button>
                    <div style="display: none;">{{ opt }}</div>
                </div>
                
                <!-- <div class="l-flex-c u-bb o-plr o-ptb-s c-text-6">
                    <div class="l-flex-c l-flex-1" @click="drawer.nav = !drawer.nav">
                        <div class="l-flex-1">
                            <p v-for="(item,index) in TabItem.nav.list" v-if="TabItem.nav.active == index">
                                <span>{{ item.title }}(</span><b v-if="item.amount > 0" class="c-color-t">{{ item.amount }}</b><span v-else>0</span><span>)</span>
                            </p>
                        </div>
                        <span class="u-br" style="padding-right:4px;">
                            <span>切换</span>
                            <Icon class="c-color-g" size=".6" name="triangledownfill" style="position:relative; top:-1px;" />
                        </span>
                        <div v-if="ExpectCalcu && !TabItem.lot.loading && TabItem.lot.data.lottery_id" class="downtime o-plr-s" style="min-width:96px; box-sizing:border-box;">
                            <span v-if="ExpectCalcu.count_seal > 0">封盘：<b class="c-color-t">{{ ExpectCalcu.seal }}</b></span>
                            <span v-else-if="ExpectCalcu.count_end > 0">已截止投注</span>
                        </div>
                        <i class="icon icon-right" style="color:#ccc; font-size:12px;" />
                    </div>
                    <div v-if="TabItem.lot.data.lottery_id" class="o-pl-s">
                        <Button class="reload-btn" size="mini" :loading="opt.reload_loading" :disabled="opt.reload_loading" @click="OperateReload()">刷新<span v-if="opt.reload_interval && opt.reload_down && !opt.reload_loading">({{ opt.reload_down }})</span></Button>
                    </div>
                </div>
                <div v-if="drawer.nav" class="o-plr u-bb">
                    <li v-for="(item,index) in TabItem.nav.list" class="o-ptb-s" :class="{'u-bt': index}" @click="LoadPlay(TabItem,index);drawer.nav = false">
                        <span>{{ item.title }}(</span><b v-if="item.amount > 0" class="c-color-t">{{ item.amount }}</b><span v-else>0</span><span>)</span>
                    </li>
                </div> -->
                <div class="l-flex-c c-bg-f" style="width: 100%; height: 40px; position: relative;">
                    <div style="flex: 0 0 15px; height: 100%; position: absolute; left: 0; top: 0; bottom: 0; z-index: 2; background-image: linear-gradient(to right, #FFF 40%, rgba(255, 255, 255, 0));">
                        <icon name="right" :size=".85" style="color: #AAA; flex: 0 0 15px; position: relative; top: 10px; transform: rotate(180deg);" />
                    </div>
                    <div class="tabs-box l-flex-1" style="overflow: auto; position: absolute; left: 0; right: 0; top: 0; bottom: 0; padding: 0 15px;">
                        <ul class="tabs-row">
                            <li v-for="(item, index) in TabItem.nav.list" :key="item.id" :class="{'active': TabItem.nav.active == index}" @click="LoadPlay(TabItem, index)">{{ item.title }}</li>
                        </ul>
                    </div>
                    <div style="flex: 0 0 15px; height: 100%; position: absolute; right: 0; top: 0; bottom: 0; z-index: 2; background-image: linear-gradient(to left, #FFF 40%, rgba(255, 255, 255, 0));">
                        <icon name="right" :size=".85" style="color: #AAA; flex: 0 0 15px; position: relative; top: 10px;" />
                    </div>
                </div>
            </div>
            <!-- <div class="l-flex-c o-pt-s o-pr">
                <div class="l-flex-1 c-text-6 c-text-r o-pr-s">走飞选项</div>
                <mt-switch v-model="transmitOpts" />
            </div> -->
            
            <div class="l-flex-1" style="width:100%; position:relative; overflow-y:auto;">
                <div style="position:absolute; top:0; left:0; bottom:0; right:0;">
                    <div v-if="TplName && TabItem.play && TabItem.play.length" class="operate c-text-7 o-pt-s">
                        <component :is="UsePackage(TplName)" :client="TabItem" />
                    </div>
                    <div v-else class="c-text-c o-ptb-h">
                        <Load size="2.3" color="#4477ad" />
                    </div>
                </div>
            </div>
        </template>
        <div v-if="TabItem && !TabItem.lot.data.lottery_id && !TabItem.lot.loading" class="o-ptb-h block c-text-c">
            <div>当前无可下注期数</div>
            <div class="o-pt-l">
                <Button size="small" @click="LoadLot(TabItem)">刷新</Button>
            </div>
        </div>
        <transmit v-model="Transmit.view" ref="transmit" title="确认走飞列表" :form="Transmit.form" :model="store" :type="Transmit.type" :operate-auth="OperateAuth" @confirm="_TransmitPush" @cancel="OperateReload()" />
    </div>
</template>
<script>
import OperateMix from './operate.js'
import PackMix from './packages_mobile.js'
import Transmit from './modul/transmit_mobile'
import {Indicator} from 'mint-ui'
export default {
    mixins: [OperateMix, PackMix],
    data() {
        return {
            drawer: {
                nav: false
            },
            transmitOpts: false
        }
    },
    computed: {
        HistoryExpect() {
            let list = []
            for (let item of this.TabItem.history.list) {
                list.push({name: item.number, value: item.id})
            }
            return list
        }
    },
    methods: {
        _TransmitPush(data, fast) {
            Indicator.open()
            this.TransmitPush(data, fast).then(res => {
                Indicator.close()
            })
        }
    },
    components: {
        Transmit
    },
    mounted() {
        // window.tt = this
    }
    // deactivated(){
    //     console.log('deactivated',this.$route.name)
    //     // if(!this.User.role_id){
    //     //     this.OperateCleanAll()
    //     //     this.AutoReloadClean()
    //     // }
    // },
}
</script>
