<style lang="scss">
    @import "../style/config.scss";
    .HeadLimit {
        height:40px; line-height:40px; background-color:#fff; position:fixed!important; top:44px; left:0; right:0;
        .date-pick {
            width:100px; text-align:center;
            i.icon {
                font-size:8px; position:relative; top:-2px;
            }
        }
        &.full, &[full] {
            .date-pick {
                flex:1;
            }
            .slot {
                display:none;
            }
        }
    }
</style>
<template>
    <div class="HeadLimit" style="z-index:29;">
        <div class="l-flex-c u-bb">
            <div class="date-pick u-br" @click="$refs['picker_1'].open()">
                <span>{{ Time(start.value,'yyyy-MM-dd') }}</span>
                <i class="icon icon-triangledownfill"></i>
            </div>
            <div class="date-pick u-br" @click="$refs['picker_2'].open()">
                <span>{{ Time(end.value,'yyyy-MM-dd') }}</span>
                <i class="icon icon-triangledownfill"></i>
            </div>
            <div class="l-flex-3 slot">
                <slot></slot>
            </div>
            <mt-datetime-picker v-if="start.cache" type="date" v-model="start.cache" ref="picker_1" @confirm="HandleStart" year-format="{value} 年"  month-format="{value} 月" date-format="{value} 日" :endDate="start.limit"></mt-datetime-picker>
            <mt-datetime-picker v-if="end.cache" type="date" v-model="end.cache" ref="picker_2" @confirm="HandleEnd" year-format="{value} 年"  month-format="{value} 月" date-format="{value} 日" :startDate="end.limit"></mt-datetime-picker>
        </div>
    </div>
</template>

<script>
import StoreMix from '@lottery.mobile/mixin/store.js'
export default {
    name : 'HeadLimit',
    mixins : [StoreMix],
    data(){
        return {
            start : {
                value : null,
                cache : null,
                view : false,
                limit : undefined,
            },
            end : {
                value : null,
                cache : null,
                view : false,
                limit : undefined,
            },
        }
    },
    props : {
        value : {
            default : false,
        },
        // full : {
        //     default : false
        // }
    },
    watch:{
        value(val){
            this.LoadDate()
        },
    },
    computed:{

    },
    methods: {
        LoadDate(value = this.value){
            if(value){
                this.start.value = new Date(value[0].split(' ')[0])
                this.end.value = new Date(value[1].split(' ')[0])

                this.start.cache = this.start.value
                this.end.cache = this.end.value

                this.start.limit = this.end.value
                this.end.limit = this.start.value
            }
        },
        HandleStart(e){
            let target = this.end.value
            if(e > target){
                e = target
            }
            //let time = this.Time(e,'yyyy-MM-dd')
            this.start.value = e
            this.end.limit = e
            this.Event()
        },
        HandleEnd(e){
            let target = this.start.value
            if(e < target){
                e = target
            }
            //let time = this.Time(e,'yyyy-MM-dd')
            this.end.value = e
            this.start.limit = e
            this.Event()
        },
        Event(){
            let interval = [`${this.Time(this.start.value,'yyyy-MM-dd')} 00:00:00`,`${ this.Time(this.end.value,'yyyy-MM-dd')} 23:59:59`]
            this.$emit('input',interval)
            this.$emit('change',interval)
        },
    },
    mounted(){
        this.LoadDate()
    }
}
</script>