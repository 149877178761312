<style lang="scss">
    // @import './theme/credit.scss'; 
    @import "../../../../style/config.scss";   
    .IndexGame {
        width:100%; height:100%; position:relative; position:fixed; top:0; left:0; bottom:0; right:0; background-color:#e5e5e5;
        .header-3 {
            $header-height:44px;
            height:$header-height; line-height:$header-height; background-color:#4477ad; color:#fff; //position:fixed; top:0; left:0; right:0;
            .head-btn {
                display:block; height:$header-height; padding:0 12px; width:20px;
                i {
                    font-size:20px; color:#fff;
                }
                &.back {
                    transform:rotate(180deg);
                }
            }
            a.type {
                width:22px; height:22px; line-height:22px; display:inline-block; text-align:center; vertical-align:middle; position:relative; top:-1px;
                background-color:#be6ef1; color:#fff; font-weight:bold;
            }
        }
        .exhibition {
            background-color:#e5e5e5; padding: 4px 0 0 0;
        }
        .category {
            a.btn {
                padding:4px 7px; border-radius:99px;
                background-color:#fff; color:#999; line-height:24px;
                &.active {
                    background-color:$color-t; color:#fff;
                }
            }
            .sides {
                a.btn {
                    border-radius:3px; margin-left:6px; font-size:.6rem; background-color:#f0f0f0;
                    &.active {
                        background-color:$color-t; color:#fff;
                    }
                }
            }
            .nav-links {
                border-top:.03rem solod #aaa; border-bottom:1px solod #888;
                a.btn {
                    padding:2px 6px; border-radius:4px; margin-bottom:4px; margin-right:6px; display:inline-block; font-size:.65rem; color:#333;
                    background-color:#f0f0f0; color:#888;
                    &.active {
                        background-color:$color-t; color:#fff;
                    }
                }
            }
        }
        .tabs-box {
            display: flex; align-items: center;
            ul.tabs-row {
                display: flex; flex-wrap: nowrap; flex: 1;
                li {
                    flex: 0 1 auto; padding: 0 12px; font-size: 14px; line-height: 38px; box-sizing: border-box;
                    word-break: keep-all; white-space: nowrap;
                    background-color: #FFF; border-bottom: 3px solid transparent;
                    &.active {
                        border-color: #5f79ef;
                    }
                }
            }
            scrollbar-width: none; -ms-overflow-style: none;
            &::-webkit-scrollbar {
                display: none;
            }
        }
        .board {
            width: 100%; position: relative; // overflow-y: auto;
            .board-order {
                width:100%; position:absolute; top:0; left:0; bottom:0; right:0; overflow-y:auto;
            }
        }

        .rules {
            position:fixed; width:100%; height:100%; top:0; left:0; right:0; bottom:0; background-color:rgba(0,0,0,.5);
            .core {
                width:80%; margin:0 auto; background-color:#fff; margin-top:20px; margin-top:9vh; height:260px; height:62vh;  overflow-y:auto; border-radius:6px; box-shadow:0 4px 12px rgba(0,0,0,0.3); padding:14px 12px;
            }
            .content {
                font-size:12px;
            }
        }

        .balls {
            .ball {
                width:23px; height:23px; line-height:23px; text-align:center; font-size:17px; font-weight:bold; position:relative;
                background-color:$color-t; color:#fff; border-radius:99px; margin:0 2px; margin-right:4px;
                &.red {background-color:#ec4d4d;}
                &.blue {background-color:#5f79ef;}
                &.green {background-color:#4a9a34;}
                &.white {background-color:#fff; color:#ec4d4d;}
                &.valid {
                    margin-right:16px;
                    .explain {
                        position:absolute; right:-14px; bottom:-2px; display:inline-block;
                        width:16px; height:16px; line-height:16px; font-size:11.5px; font-weight:normal; border-radius:3px; text-align:center;
                        color:#000;
                    }
                }
            }
        }
    }
</style>
<template>
    <div class="IndexGame">
        <div class="l-flex-v" style="margin:0 auto; width:100%; height:100%; position:relative; max-width:768px;">
            <div class="header-3 l-flex-c o-plr-s" style="z-index: 8;" :style="headerStyle">
                <a class="head-btn back" @click="Back()">
                    <Icon name="right"></Icon>
                </a>
                <div class="l-flex-1">
                    <p class="o-plr-s">
                        <a class="type credit" v-if="Running.type=='credit'">信</a>
                        <a class="type formal" v-if="Running.type=='formal'">官</a>
                        <span>{{ Running.title }}</span>
                    </p>
                </div>
                <a class="head-btn" @click="GetRule()">
                    <Icon name="question"></Icon>
                </a>
                <a class="head-btn" @click="headnav = true">
                    <Icon name="gengduo"></Icon>
                </a>
            </div>
            <div class="exhibition">
                <div class="l-flex-c c-bg-f u-bt" style="width: 100%; height: 42px; position: relative;">
                    <div style="flex: 0 0 15px; height: 100%; position: absolute; left: 0; top: 0; bottom: 0; z-index: 2; background-image: linear-gradient(to right, #FFF 40%, rgba(255, 255, 255, 0));">
                        <icon name="right" :size=".85" style="color: #AAA; flex: 0 0 15px; position: relative; top: 10px; transform: rotate(180deg);" />
                    </div>
                    <div class="tabs-box l-flex-1" style="overflow: auto; position: absolute; left: 0; right: 0; top: 0; bottom: 0; z-index: 6, padding: 0 15px;">
                        <ul class="tabs-row">
                            <li v-for="item in navMenu.filter(row => row.name != 'index/home')" v-if="!item.disabled" :key="item.name" @click="navHandle(item)">{{ item.title }}</li>
                        </ul>
                    </div>
                    <div style="flex: 0 0 15px; height: 100%; position: absolute; right: 0; top: 0; bottom: 0; z-index: 2; background-image: linear-gradient(to left, #FFF 40%, rgba(255, 255, 255, 0));">
                        <icon name="right" :size=".85" style="color: #AAA; flex: 0 0 15px; position: relative; top: 10px;" />
                    </div>
                </div>
                <!-- <div class="l-flex-c u-bb c-text-c c-line-10" style="padding: 3px 6px; background-color: rgb(255, 235, 227);">
                    <div class="l-flex-1">
                        <div class="c-text-75" style="color: #ff4949;">{{ Price(CreditInfo.balance - CreditInfo.ubalance, {separate: false}) }}</div>
                        <div class="c-text-6">信用额度</div>
                    </div>
                    <div style="width: 1px; height: 15px; margin: 0 6px; background-color: #CCC;" />
                    <div class="l-flex-1">
                        <div class="c-text-75" style="color: #ff4949;">{{ Price(todayInfo.amount || 0, {float: 1}) }}</div>
                        <div class="c-text-6">未结算金额</div>
                    </div>
                    <div style="width: 1px; height: 15px; margin: 0 6px; background-color: #CCC;" />
                    <div class="l-flex-1">
                        <div class="c-text-75" style="color: #ff4949;">{{ Price(todayInfo.profit_loss || 0, {float: 1}) }}</div>
                        <div class="c-text-6">今日输赢</div>
                    </div>
                </div> -->
                <div class="l-flex-c u-bb" style="padding: 3px 0; background-color: #F0F0F0;">
                    <p class="row o-plr c-line-12">第 <b style="color: #ff4949;">{{ isTodayOpen ? Running.expect.now.expect : Running.expect.last.expect }}</b> 期</p>
                    <!-- <div class="balls l-flex-c o-plr-s" v-if="ResultObj && ResultObj.result && ResultObj.result.length" :class="{'ball-small':ResultObj.result.length > 8}">
                        <span class="ball" v-for="item in ResultObj.result" :class="[item.color, {'valid': item.code, 'white': !item.code}]">
                            <span>{{ item.code ? item.code : '?' }}</span>
                            <span class="explain" v-if="item.text">{{ item.text }}</span>
                        </span>
                        <template v-if="ResultObj.special && ResultObj.special.length">
                            <b class="special-title">+</b>
                            <span class="ball" v-for="item in ResultObj.special" :class="[item.color, {'valid': item.code, 'white': !item.code}]">
                                <span :class="item.color">{{ item.code ? item.code : '?' }}</span>
                                <span class="explain" v-if="item.text">{{ item.text }}</span>
                            </span>
                        </template>
                        <template v-else>
                            <b class="special-title">+</b>
                            <span class="ball white"><span>?</span></span>
                        </template>
                    </div> -->
                    <div class="l-flex-c o-plr-s c-text-c" v-if="ResultObj" >
                        <template v-if="ResultObj.result && ResultObj.result.length">
                            <template v-for="(pack, unit) of [ResultObj.result, ResultObj.special]">
                                <b v-if="unit === 1" class="special-title">+</b>
                                <div v-for="item in pack" :key="item.code" style="position: relative; margin-right: 7px;">
                                    <lhc-ball :number="item.code" />
                                    <p class="c-text-65 c-line-9 c-text-c c-bg-f" v-if="item.text" style="width: 17px; height: 17px; line-height: 17px; border-radius: 99px; position: absolute; right: -8px; bottom: -5px;">{{ item.text }}</p>
                                </div>
                            </template>
                        </template>
                        <template v-else>
                            <template v-for="(item, index) of 7">
                                <b v-if="index === 6" class="special-title">+</b>
                                <div style="position: relative; margin-right: 4px;">
                                    <lhc-ball number="?" />
                                </div>
                            </template>
                        </template>
                    </div>
                </div>
            </div>
            <div class="block">
                <div class="l-flex-c o-plr c-text-6" style="padding-top:8px; padding-bottom:4px;">
                    <p class="l-flex-1" v-if="Running.expect.now.id === null">非开盘时间</p>
                    <div class="l-flex-1" v-else>
                        第 {{ Running.expect.now.expect }} 期
                        <span v-if="isOpen">本期已开奖，停止下注</span>
                        <span v-else-if="CountDown">{{ CountDown.prompt }} <span class="c-color-e">{{ CountDown.format[0] }}:{{ CountDown.format[1] }}:{{ CountDown.format[2] }}</span></span>
                    </div>
                    <span>信用额度: {{ Price(CreditInfo.balance - CreditInfo.ubalance, {separate: false}) }}</span>
                </div>
            </div>
            <div class="category block" v-if="Running.nav && Running.nav.item">
                <div class="l-flex-c o-plr" style="padding-bottom:8px;">
                    <!-- <ul class="l-flex-c l-flex-1 o-pr" style="position:relative;">
                        <li class="l-flex-1 l-flex-c" @click="GameSheet.view = true">
                            <b class="l-flex-1 c-text-75">{{ Running.nav.item.title }}</b>
                            <span class="c-text-6 c-color-t" style="font-weight:bold;">切换玩法</span>
                            <Icon name="right" size=".6" style="color:#ccc"></Icon>
                        </li>
                        <mt-actionsheet v-if="GameSheetActions" v-model="GameSheet.view" :actions="GameSheetActions"></mt-actionsheet>
                    </ul> -->
                    
                    <ul class="sides">
                        <a class="btn" v-for="(item, index) in hasSide" :class="{'active': side === item.id}" @click="PickSide(item)">{{ item.title }}</a>
                    </ul>
                    <div class="l-flex-1" />
                    <ul class="sides">
                        <a class="btn active" @click="tipsView = true">长龙提示</a>
                    </ul>
                </div>
            </div>
            <div class="l-flex-c c-bg-f u-bt" style="width: 100%; height: 42px; position: relative;">
                <div style="flex: 0 0 15px; height: 100%; position: absolute; left: 0; top: 0; bottom: 0; z-index: 2; background-image: linear-gradient(to right, #FFF 40%, rgba(255, 255, 255, 0));">
                    <icon name="right" :size=".85" style="color: #AAA; flex: 0 0 15px; position: relative; top: 10px; transform: rotate(180deg);" />
                </div>
                <div class="tabs-box l-flex-1" style="overflow: auto; position: absolute; left: 0; right: 0; top: 0; bottom: 0; z-index: 6; padding: 0 15px;">
                    <ul class="tabs-row">
                        <li v-for="item in GameSheetActions" :key="item.name" :class="{'active': Running.nav.item && Running.nav.item.title === item.name}" @click="item.method()">{{ item.name }}</li>
                    </ul>
                </div>
                <div style="flex: 0 0 15px; height: 100%; position: absolute; right: 0; top: 0; bottom: 0; z-index: 2; background-image: linear-gradient(to left, #FFF 40%, rgba(255, 255, 255, 0));">
                    <icon name="right" :size=".85" style="color: #AAA; flex: 0 0 15px; position: relative; top: 10px;" />
                </div>
            </div>
            
            <div class="board l-flex-1" style="z-index: 8;">
                <Credit v-if="Running.type == 'credit'" ref="credit" @ExpectReload="ExpectInit('nav')"></Credit>
                <!-- <Formal v-if="Running.type == 'formal'"></Formal> -->
            </div>
            <div class="rules" v-if="rule.view" style="z-index:20;">
                <div class="core">
                    <h5 class="o-pb-s"><b>{{ rule.title }}</b></h5>
                    <div class="content" v-html="rule.content"></div>
                </div>
                <div class="c-text-c o-ptb-l" style="max-width:100px; margin:0 auto;">
                    <Button @click="rule.view =false" full>确认</Button>
                </div>
            </div>
        </div>
        <HeadNav v-model="headnav"></HeadNav>

        <div v-show="tipsView" style="position: fixed; top: 0; bottom: 0; left: 0; right: 0; z-index: 11; background-color: rgba(0, 0, 0, .65);" @click.slef="tipsView = false">
            <div class="c-bg-f l-flex-v" style="width: 310px; height: 360px; margin: 30px auto 0 auto; margin-top: 15vh;">
                <div class="o-ptb o-pl l-flex-c u-bb">
                    <p class="l-flex-1">长龙提示</p>
                    <div class="l-flex-c" style="width: 40px; height: 100%; justify-content: center;" @click.slef="tipsView = false">
                        <icon name="close" :size=".85" style="color: #333;" />
                    </div>
                </div>
                <div class="l-flex-1 o-p" style="border-radius: 6px; box-shadow: 0 0 15px rgba(0 0, 0, .15); overflow-y: auto;">
                    <ul v-if="Tips" class="o-ptb-s" style="overflow: hidden;">
                        <li v-for="item in Tips" class="c-line-12">
                            <div class="o-plr l-flex-c">
                                <div class="l-flex-1 c-text-7">{{ item.title }}</div>
                                <p class="c-text-7">{{ item.count }} 期</p>
                            </div>
                        </li>
                        <li v-if="Tips.length == 0" class="o-ptb-l c-text-c">暂无长龙</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import StoreMix from '@lottery.2.0/mixin/store.js'
import Common from '@lottery.2.0/page/game/index.js'
import Credit from './layout/credit.vue'
import LhcBall from '@lottery.2.0/page/game/components/credit/components/lhc_ball'
import HeadNav from '@lottery.mobile/components/headnav'
// import Formal from '@lottery.2.0/page/game/components/formal.vue'
export default {
    name: 'IndexGame',
    mixins : [StoreMix,Common],
    data() {
        return {
            theme : 'default',
            side : null,
            nav : {
                view : false,
            },
            rule :{
                view : false,
                title : null,
                content : null,
            },
            headnav : false,
            ResultTimer : null,
            GameSheet : {
                view : false,
            },
            tipsView: false
        }
    },
    computed: {
        CountDown() {
            try{
                let close = this.Running.expect.count.close
                let end = this.Running.expect.count.end
                let count = close > 0 ? close : 0
                let states = 0
                return {
                    states,
                    prompt: {
                        '0': '截至下注',
                    } [states],
                    format: this.CountDownFormat(count),
                }
            }catch(e){
                return null
            }
        },
        isOpen(){
            if(this.Running.expect.now.id){
                return this.Running.expect.now.status == 1
            }
            return false 
        },
        ResultObj(){
            let target = this.isTodayOpen ? this.Running.expect.now : this.Running.expect.last
            let obj = {
                result : [],
                special : [],
                codes: [],
                model: this.Running.model
            }
            let colors = target.colors ? target.colors.split(',') : []
            let zodiac = target.zodiac ? target.zodiac.split(',') : []
            for (let key of ['result', 'special']) {
                if(target[key] && target[key].length){
                    for(let i = 0; i < target[key].length; i++){
                        obj[key].push({
                            code  : target[key][i],
                            color : colors.shift(),
                            text  : zodiac.shift(),
                        })
                        obj.codes.push(target[key][i])
                    }
                }
            }
            return obj 
        },
        hasSide(){
            let type = this.Running.group
            let sides = this.Opt.hasDir.typeById[type]
            try{
                let list = []
                for(let item of this.Running.nav.table){
                    for(let side of sides){
                        if(item.title[0] === side){
                            list.push(item)
                        }
                    }
                }
                return list
            }catch(err){
                return []
            }
        },
        isTodayOpen(){
            if(this.Running.expect.now.id){
                let time = this.Running.expect.time
                let close = this.Running.expect.now.close
                return this.Time(time,'yyyy-MM-dd') === this.Time(close,'yyyy-MM-dd')
            }
            return false            
        },
        GameSheetActions(){
            if (this.Running.nav && this.Running.nav.list) {
                let list = []
                for(let item of this.Running.nav.list){
                    list.push({
                        name : item.title,
                        method : ()=>{
                            this.NavChange(item)
                        }
                    })                    
                }
                return list
            }            
            return null
        },
        headerStyle() {
            const obj = {
                backgroundColor: '#4477ad'
            }
            const {id} = this.Running
            if (id) {
                for (const name in this.Games) {
                    const game = this.Games[name]
                    if (game.id === id && game.bg_color) {
                        obj.backgroundColor = game.bg_color
                    }
                }
            }
            return obj
        },
        todayInfo: vm => vm.$store.state.credit.today._list || {},
        navMenu: HeadNav.computed.menu,
        Tips() {
            if (this.$store.state.game.tips._list) {
                let tips = this.$store.state.game.tips._list
                let list = []
                for (let group in tips) {
                    if (group === 'special') {
                        if (tips[group].num > 2) {
                            list.push({
                                title: `特码@${tips[group].number}`,
                                count: tips[group].num
                            })
                        }
                    } else {
                        for (let child in tips[group]) {
                            if (tips[group][child] > 2) {
                                list.push({
                                    title: `${group}@${child}`,
                                    count: tips[group][child]
                                })
                            }
                        }
                    }
                }
                return list
            }
            return null
        },
    },
    watch:{
        'Running.nav.side.id'(val){
            if(val){
                this.side = val
            }
        },
    },
    methods: {
        Theme(name){
            this.theme = name
            this.Cache('theme', name)
        },
        init(){
            this.ready = true
            let games_code = 'XGLHC'
            const loaclGamesId = localStorage.getItem('games_id') ? Number(localStorage.getItem('games_id')) : 31
            try {
                const row_game = this.Opt.hasDir.game.find(row => row.id == loaclGamesId)
                if (row_game) games_code = row_game.name
            } catch (e) {}
            this.ApplyGame(games_code, 'credit')
            this.ResultTimeRun()
        },
        Back(){
            this.$router.back()
        },
        CountDownFormat(count = 0) {
            let format = [0, 0, 0]
            for (let i = 0; i < format.length; i++) {
                let unit = [3600, 60, 1][i]
                format[i] = Math.floor(count / unit).toString()
                if (format[i].length < 2) {
                    format[i] = `0${format[i]}`
                }
                count = count > unit ? count % unit : count
            }
            return format
        },
        PickSide(item){
            this.side = item.id
            this.NavTable(item,this.Running.nav.item)
        },
        GetRule(){
            let id = this.Running.id
            let type = {
                'formal' : 1,
                'credit' : 2,
            }[this.Running.type]
            let title = `${this.Running.title}`
            this.Dp('game/_GET_RULE',{id,params:{type}}).then(res=>{
                this.$set(this.rule,'view',true)
                this.$set(this.rule,'title',title)
                this.$set(this.rule,'content',res.data.content)
            })
        },
        ResultTimeRun(){
            clearInterval(this.ResultTimer)
            this.ResultTimer = setInterval(()=>{
                if(this.$route.name === 'index/pages/table'){
                    if(this.Running.expect && this.Running.expect.now && this.Running.expect.now.id && this.Running.expect.now.status === 0){
                        let date = new Date(this.Running.expect.time * 1000)
                        if(date.getHours() > 21 && `${date.getHours()}${date.getMinutes()}${date.getSeconds()}` < '220000'){
                            this.ExpectInit() //this.$store.state.base.Event.emit('Credit/ExpectInit')
                        }else if(this.Opt.isDev){
                            this.ExpectInit() //this.$store.state.base.Event.emit('Credit/ExpectInit')
                        }
                    }
                }
            },5000)
        },
        navHandle: HeadNav.methods.Handle
    },
    components: {
        Credit, LhcBall, HeadNav
        // Formal,
    },
    mounted() {
        // console.log('HeadNav', HeadNav)
    }
}
</script>