<style lang="scss">
    @import '../../../../../style/config.scss';
    .MyLotterPlaysMudul {
        .box {
            border-radius:5px; background-color:#f5f5f5;
            .item {
                height:32px; line-height:32px;
                .ball {
                    display:block; width:28px; height:28px; line-height:28px; margin:2px auto;
                    background-color:#fb6c6c; color:#fff; border-radius:99px; font-size:15px; font-weight: bold;
                }
            }
        }
        .el-input__inner {
            padding:0 5px;
        }
        .el-dialog {
            $width:768px;
            width:$width!important; max-width:$width!important; min-width:$width!important;
        }
    }
</style>
<template>
    <el-dialog class="MyLotterPlaysMudul" :title="Title" :visible.sync="view" size="" top="5vh" :modal-append-to-body="false" :close-on-click-modal="false">
        <div class="o-pb">
            <el-row tag="div" :gutter="16" v-if="table">
                <el-col tag="ul" :span="24/table.length" v-for="(pack,unit) in table" :key="unit">
                    <div class="o-ptb o-plr-s box">
                        <el-row tag="li" class="o-pb c-text-6" :gutter="10">
                            <el-col tag="div" class="c-text-c" :span="4">-</el-col>
                            <el-col tag="div" class="c-text-c" :span="10">号码</el-col>
                            <el-col tag="div" class="c-text-c" :span="10">赔率</el-col>
<!--                             <el-col tag="div" class="c-text-c" :span="5">退水</el-col>
                            <el-col tag="div" class="c-text-c" :span="5">单注限额</el-input></el-col>
                            <el-col tag="div" class="c-text-c" :span="5">下注限额</el-input></el-col> -->
                        </el-row>
                        <div class="o-pt u-bt">
                            <el-row tag="li" class="o-mb item" :gutter="10" v-for="(item,index) in pack" :key="index">
                                <el-col :span="4" tag="div" class="c-text-c"><el-checkbox v-model="item.pick"></el-checkbox></el-col>
                                <el-col :span="10" tag="div" class="c-text-c" ><span :class="BallType(item.name)">{{ item.name }}</span></el-col>
                                <el-col :span="10" tag="div" class="c-text-c">                                    
                                    <div v-if="item.has_double_odds">
                                        <span class="c-text-6">最低</span>
                                        <el-input size="mini" v-model="item.odds" style="width:5rem;"></el-input>
                                        <span class="c-text-6 o-pl-s">最高</span>
                                        <el-input size="mini" v-model="item.max_odds" style="width:5rem;"></el-input>
                                    </div>
                                    <el-input v-else size="mini" v-model="item.odds"></el-input>
                                </el-col>
<!--                                 <el-col :span="5" tag="div" class="c-text-c"><el-input size="mini" v-model="item.backwater"></el-input></el-col>
                                <el-col :span="5" tag="div" class="c-text-c"><el-input size="mini" v-model="item.order_limit_amount"></el-input></el-col>
                                <el-col :span="5" tag="div" class="c-text-c"><el-input size="mini" v-model="item.bets_limit_amount"></el-input></el-col> -->
                            </el-row>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div class="o-pt-l u-bt l-flex-c">
            <div class="l-flex-1">
                <template v-if="table">
                    <el-checkbox v-model="quick.all" @change="QuickPick">全选</el-checkbox>
                    <span class="o-ml-l o-mr-s">赔率</span>
                    <el-input size="mini" style="width:60px;" v-model="quick.odds"></el-input>
<!--                     <span class="o-ml-l o-mr-s">单注限额</span>
                    <el-input size="mini" style="width:80px;" v-model="quick.order_limit_amount"></el-input>
                    <span class="o-ml-l o-mr-s">退水</span>
                    <el-input size="mini" style="width:80px;" v-model="quick.backwater"></el-input>
                    <span class="o-ml-l o-mr-s">下注限额</span>
                    <el-input size="mini" style="width:80px;" v-model="quick.bets_limit_amount"></el-input> -->
                    <span class="o-ml-l o-mr-s"></span>
                    <el-button type="warning" size="small" @click="Quick()">快速填写</el-button>
                </template>
            </div>
            <div>
                <el-button @click="Cancel()">取消</el-button>
                <el-button :loading="main.editing" type="primary" @click="Submit()">提交</el-button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import StoreMix from '@lottery.2.0/mixin/store.modul.js'
export default {
    name: 'MyLotterPlaysOddsMudul',
    mixins : [StoreMix],
    data() {
        return {
            store : 'admin/subset',
            Filter : {
                games_id : null,
                games_play_id : null,
            },
            table : null,
            quick : {
                all : false,
                odds : '',
                backwater : '',
                order_limit_amount : '',
                bets_limit_amount : '',
            },
        }
    },
    methods: {
        init(){
            this.DataReset('quick')
            this.Filter.games_id = this.form.games_id
            this.Filter.games_play_id = this.form.games_play_id
            this.Params.action = 'systemodds'
            if(this.form.has_subset){
                this.Cm('lottery/_LOTTERY_RESET','SUBSET')
                this.Get().then(res=>{
                    this.Sort(res.data,3)
                })
            }else{
                this.$set(this,'table',[[this.form]])
            }
        },
        Sort(list,len=4){
            let group = []
            let num = len
            while(num>=1){
                group.push([])
                num--
            }
            list.forEach((item,index)=>{
                let unit = index%len
                group[unit].push({
                    ...item,
                })
            })
            this.$set(this,'table',group)
        },
        BallType(str){
            if(str%1 === 0){
                return 'ball'
            }
            return 'text'
        },
        Quick(){
            for(let pack of this.table){
                for(let item of pack){
                    if(item.pick){
                        if(this.quick.odds){
                            this.$set(item,'odds',this.quick.odds)
                        }
                        if(this.quick.backwater){
                            this.$set(item,'backwater',this.quick.backwater)
                        }
                        if(this.quick.order_limit_amount){
                            this.$set(item,'order_limit_amount',this.quick.order_limit_amount)
                        }
                        if(this.quick.bets_limit_amount){
                            this.$set(item,'bets_limit_amount',this.quick.bets_limit_amount)
                        }                       
                    }
                }
            }
        },
        QuickPick(e){
            for(let pack of this.table){
                for(let item of pack){
                    this.$set(item,'pick',e)                    
                }
            }
        },
        Submit(){
            let data = {
                ...this.Origin(this.Params),
                data : [],
            }
            for(let pack of this.table){
                for(let item of pack){
                    data.data.push(item)
                }
            }
            this.Dp('admin/_POST_PLAYS',{data:{data:JSON.stringify(data)}}).then(src=>{
                this.$emit('input',false)
                this.Suc('操作成功')
                this.$emit('finish')
            })
        },
    },
    mounted() {

    },
}
</script>
