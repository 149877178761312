<style lang="scss">
    .OperateLhc-4 {
        h6.title {
            padding:3px 5px; text-align:center; background-color:#f3655e; color:#fff;
        }
    }
</style>
<template>
    <div class="OperateLhc-4">
        <div v-show="OperateAuth.bind != 3" class="mobile-tabs u-bb">
            <div v-for="(item,index) in tabs.list" v-if="index < 4" class="mobile-tab" :class="{'active': tabs.active === item}" @click="tabs.active = item">{{ item }}</div>
        </div>
        <div v-show="OperateAuth.bind != 3" class="mobile-tabs u-bb o-mb-s">
            <div v-for="(item,index) in tabs.list" v-if="index >= 4" class="mobile-tab" :class="{'active': tabs.active === item}" @click="tabs.active = item">{{ item }}</div>
        </div>
        {{/* 非走飞 */}}
        <div v-for="(item,index) in tabs.list" v-if="tabs.active === item" v-show="OperateAuth.bind != 3">
            <board-fast v-if="OperateAuth.bind === 2" :client="client" :group="[target]" :step="true" :default-step="0.01" />
            <group :client="client" :list="Table[target]" :length="1" :win="false" show-total="end" :total="totals[target]" />
        </div>
        {{/* 走飞 */}}
        <div v-show="OperateAuth.bind === 3" class="">
            <board-fast v-if="OperateAuth.bind === 3" :client="client" :group="[5,6,7,8,9,10,11,12]" transmit="six-balls" :step="true" :limit="limit" :default-step="0.01" @change="Change" />
            <div v-if="OperateAuth.bind === 3 && prompt" class="o-pt o-pb c-color-e c-text-6 c-text-c block"><b>{{ prompt }}</b></div>
            <group
                class="o-mt-s" :client="client" :list="Table[5]" :length="1" :radio="true"
                :transmit="false" :manipulation="false" :win="false" :table-index="51"
                :repair="Repair" @total="test($event)" @pick="AloneTransfer"
            />
            <group
                class="o-mt-s" :client="client" :list="Table[6]" :length="1" :radio="true"
                :transmit="false" :manipulation="false" :win="false" :table-index="62"
                @total="test($event)" @pick="AloneTransfer"
            />
            <group
                class="o-mt-s" :client="client" :list="Table[7]" :length="1" :radio="true"
                :transmit="false" :manipulation="false" :win="false" :table-index="73"
                @total="test($event)" @pick="AloneTransfer"
            />
            <group
                class="o-mt-s" :client="client" :list="Table[8]" :length="1" :radio="true"
                :transmit="false" :manipulation="false" :win="false" :table-index="84"
                @total="test($event)" @pick="AloneTransfer"
            />
            <group
                class="o-mt-s" :client="client" :list="Table[9]" :length="1" :radio="true"
                :transmit="false" :manipulation="false" :win="false" :table-index="95"
                @total="test($event)" @pick="AloneTransfer"
            />
            <group
                class="o-mt-s" :client="client" :list="Table[10]" :length="1" :radio="true"
                :transmit="false" :manipulation="false" :win="false" :table-index="106"
                @total="test($event)" @pick="AloneTransfer"
            />
            <group
                class="o-mt-s" :client="client" :list="Table[11]" :length="1" :radio="true"
                :transmit="false" :manipulation="false" :win="false" :table-index="117"
                @total="test($event)" @pick="AloneTransfer"
            />
            <group
                class="o-mt-s" :client="client" :list="Table[12]" :length="1" :radio="true"
                :transmit="false" :manipulation="false" :win="false" :table-index="128"
                @total="test($event)" @pick="AloneTransfer"
            />
        </div>
        <!--         <BoardFast v-if="OperateAuth.bind===3" :client="client" :group="[5,6,7,8,9,10,11,12]" transmit="six-balls" :step="true" :limit="limit" @change="Change"></BoardFast>
        <div v-if="OperateAuth.bind===3" class="o-mt o-pl c-color-e c-text-7"><b>{{ prompt }}</b></div> -->
    </div>
</template>

<script>
import CommonMix from '../common.js'
import Group from '../group_mobile.vue'
import BoardFast from '../fast_mobile.vue'
export default {
    name: 'OperateLhc4',
    mixins: [CommonMix],
    data() {
        return {
            // active : 51,
            // tabs2 : {'五不中':51,'六不中':62,'七不中':73,'八不中':84,'九不中':95,'十不中':106,'十一不中':117,'十二不中':128,},
            tabs: {
                active: '五不中',
                list: ['五不中', '六不中', '七不中', '八不中', '九不中', '十不中', '十一不中', '十二不中'],
                dir: {'五不中': 51, '六不中': 62, '七不中': 73, '八不中': 84, '九不中': 95, '十不中': 106, '十一不中': 117, '十二不中': 128}
            },
            totals: {
                '51': {amount: 0, count: 0},
                '62': {amount: 0, count: 0},
                '73': {amount: 0, count: 0},
                '84': {amount: 0, count: 0},
                '95': {amount: 0, count: 0},
                '106': {amount: 0, count: 0},
                '117': {amount: 0, count: 0},
                '128': {amount: 0, count: 0}
            },
            limit: undefined,
            picks: null,
            prompt: ''
        }
    },
    props: {
        client: {}
    },
    computed: {
        target() {
            let dir = this.tabs.dir
            let active = this.tabs.active
            return dir[active]
        },
        ImproperDir() {
            let improper = this.$store.state.game.improper
            if (improper._init) {
                let dir = {}
                for (let item of improper._list) {
                    let codes = item.str_data.split(',').sort()
                    if (item.status && codes.length === 5 && item.odds > 0) {
                        dir[codes.join(',')] = {
                            ...item
                        }
                    }
                }
                return dir
            }
            return null
        },
        Repair() {
            let name = this.picks
            this.prompt = ''
            if (this.ImproperDir && name) {
                if (this.ImproperDir[name] && this.ImproperDir[name].odds > 0) {
                    this.prompt = '注意：当前选择为热门号码，请注意部分项目赔率已变动!'
                    return this.Big(this.ImproperDir[name].odds).toNumber()
                }
            }
            return 0
        }
    },
    methods: {
        test(e) {
            if (e) {
                this.$set(this.totals, e.index, e)
            }
        },
        Change(e) {
            if (e) {
                let origin = this.Origin(e)
                let list = []
                for (let num of origin) {
                    list.push(parseInt(num))
                }
                if (list && list.length) {
                    this.picks = list.sort().join(',')
                }
            }
        }
    },
    components: {
        Group,
        BoardFast
    },
    mounted() {
        console.log(889)
        this.Dp('game/_GET_IMPROPER')
    }
}
</script>
