import StoreMix from '@lottery.admin.2.0/mixin/store.js'

export default {
    mixins: [StoreMix],
    data() {
        return {
        	routeName : null,
        	ready : false,
        }
    },
    watch:{
        'main._init'(val){
            if(val===true){
                this.$nextTick(()=>{
                    this.$set(this,'ready',true)
                })
            }
        },
    },
    computed: {

    },
	beforeRouteEnter(to, from, next) {
	    next(vm=>{
	    	vm.$set(vm,'routeName',vm.$route.name)
            if(vm.main._init){
                vm.$nextTick(()=>{
                    vm.$set(vm,'ready',true)
                })
            }
	    })
    },
}