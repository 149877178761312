<style lang="scss">
    .OperateLhc-2 {
        h6.title {
            padding:3px 5px; text-align:center; background-color:#f3655e; color:#fff;
        }
        .row-title {
            height:1.2rem; line-height:1.2rem; margin-top:.3rem; padding-left:.5rem; background-color:#dcd5ff;
        }
    }
</style>
<template>
    <div class="OperateLhc-2">
        <div class="mobile-tabs u-bb">
            <div v-for="item in tabs.list" class="mobile-tab" :class="{'active': tabs.active === item}" @click="tabs.active = item">{{ item }}</div>
        </div>
        <div v-for="(item,index) in tabs.list" v-if="tabs.active === item">
            <board-fast :client="client" :group="[(index + 1) * 10 + 1,(index + 1) * 10 + 2,(index + 1) * 10 + 3,(index + 1) * 10 + 4]" :step="true" :default-step="0.01" />
            <group :client="client" :list="Table[(index + 1) * 10 + 1]" :length="1" layout="none" show-total="end" />
            <group :client="client" :list="Table[(index + 1) * 10 + 2]" :length="1" layout="none" show-total="end" />
            <group :client="client" :list="Table[(index + 1) * 10 + 3]" :length="1" layout="none" show-total="end" />
            <group :client="client" :list="Table[(index + 1) * 10 + 4]" :length="1" layout="none" show-total="end" />
        </div>
    </div>
</template>

<script>
import CommonMix from '../common.js'
import Group from '../group_mobile.vue'
import BoardFast from '../fast_mobile.vue'
export default {
    name: 'OperateLhc2',
    mixins: [CommonMix],
    data() {
        return {
            tabs: {
                active: '正码一',
                list: ['正码一', '正码二', '正码三', '正码四', '正码五', '正码六']
            }
        }
    },
    props: {
        client: {}
    },
    computed: {

    },
    methods: {
        details(pack) {
            let {item, row} = pack
            this.Details(item, row)
        }
    },
    components: {
        Group,
        BoardFast
    }
}
</script>
