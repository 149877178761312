
export default {
	data(){
		return {
			Roll : {
				target : null,
				value : null,
				enable : true
			},
		}
	},
    methods:{
    	GetRoll(){
    		return [this.Roll.target.scrollLeft,this.Roll.target.scrollTop]
    	},
    	LoadRoll(){
    		if(this.Roll.value && this.Roll.enable){
    			this.$nextTick(()=>{
    				let val = this.Roll.value
    				this.Roll.target.scrollTo(val[0],val[1])
    			})
    		}
    	},
    	SaveRoll(){
    		let value = this.GetRoll()
    		this.$set(this.Roll,'value',value)
    	},
    },
    mounted(){
    	this.$set(this.Roll,'target',document.getElementsByTagName('section')[0])
    },
    beforeRouteEnter(to, from, next) {
        next(vm=>{
        	vm.LoadRoll()
        })
    },
    beforeRouteUpdate(to, from, next) {
       this.LoadRoll()
       next()
    },
    beforeRouteLeave(to, from, next) {
        this.SaveRoll()
        next()
    },
}