<style lang="scss">
    .CreditSubReport {

    }
</style>
<template>
    <div class="CreditSubReport">
        <ul class="list" v-infinite-scroll="LoadMore" :infinite-scroll-disabled="main._loading || !main._more || true" infinite-scroll-distance="10">
            <template v-if="!main._empty">
                <li v-for="(item,index) in main._list" @click="Go('index/pages/sub/query',item)" class="block o-mt-s"> 
                    <table class="table u-b" border="0" cellspacing="1" cellpadding="0">
                        <tr class="hbg-1">
                            <td colspan="4">注单号：{{ item.sequence }}</td>
                            <td style="text-align:right;">{{ item.status }}</td>
                        </tr>
                        <tr class="hbg-2">
                            <td style="text-align:center;">游戏</td>
                            <td style="text-align:center;">期号</td>
                            <td style="text-align:center;">盘类</td>
                            <td style="text-align:center;">玩法</td>
                            <td style="text-align:center;">赔率</td>
                        </tr>
                        <tr>
                            <td style="text-align:center;">{{ item.games }}</td>
                            <td style="text-align:center;">{{ item.lottery_number }}</td>
                            <td style="text-align:center;">{{ item.side }}</td>
                            <td style="text-align:center;">{{ item.plays }}</td>
                            <td style="text-align:center;">{{ item.odds }}</td>
                        </tr>
                        <tr class="hbg-2">
                            <td style="text-align:center;">投注时间</td>
                            <td style="text-align:center;">金额</td>
                            <td style="text-align:center;">派奖</td>
                            <td style="text-align:center;">退水</td>
                            <td style="text-align:center;">结果</td>
                        </tr>
                        <tr>
                            <td style="text-align:center;">{{ Time(item.create_at,'yy-MM-dd hh:mm') }}</td>
                            <td style="text-align:center;"><b class="c-color-e">{{ item.amount }}</b></td>
                            <td style="text-align:center;">{{ item.bonus }}</td>
                            <td style="text-align:center;">{{ item.backwater }}</td>
                            <td style="text-align:center;">

                                <div v-if="item.result > 0">
                                    {{ item.result }}
                                </div>
                                <div v-if="item.result < 1">
                                    <b class="c-color-e">{{ item.result }}</b>
                                </div>

                            </td>
                        </tr>
                        <tr>
                            <td class="u-bt" colspan="5" style="text-align:center;">投注内容：{{ CheckContextLength(item.code,100) }}</td>
                        </tr>
                    </table>
                </li>
            </template>
        </ul>
        <Loading :main="main"></Loading>
    </div>
</template>

<script>
import StoreMix from '@lottery.mobile/mixin/store.js'
import Rolling from '@lottery.mobile/mixin/rolling.js'
export default {
    name: 'CreditSubReport',
    mixins : [StoreMix,Rolling],
    data() {
        return {
            store : 'myRecord/order',
            Filter : {
                games_id : undefined,
                lottery_id : null,
                per_page : 24,
            },
            expect : {
                list : [],
            },
            summary : null,
            ready : false,
        }
    },
    methods: {
        init(){
            this.DataReset('Filter')
            for(let key in this.$route.params){
                this.$set(this.Filter,key,this.$route.params[key])
            }
            this.Get()
        },
    },
    mounted(){
        this.$set(this.Roll,'target',document.getElementsByClassName('page-view')[0])
    },
    beforeRouteEnter(to, from, next) {
        next(vm=>{
            if(from && from.name === 'index/pages/sub/query'){
                vm.Roll.enable = true
            }else{
                vm.Roll.enable = false
                vm.init()
            }
        })
    },
}
</script>