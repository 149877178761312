import StoreMix from './store.js'
export default {
    data() {
        return {
            view: false
        }
    },
    mixins: [StoreMix],
    // watch:{
    //     value(val){
    //         if(val){
    //             this.$set(this,'view',true)
    //             if(this.form){
    //                 this.$set(this.$data,'Params',this.form)
    //             }else{
    //                 this.DataReset('Params')
    //             }
    //             this.init()
    //         }else{
    //             this.$set(this,'view',false)
    //         }
    //     },
    //     view(val){
    //         if(!val){
    //             this.$emit('input',false)
    //         }
    //     },
    // },
    computed: {
        // Title(){
        //     if(this.title){
        //         return this.title
        //     }
        //     if(this.$parent && this.$parent.Editer && this.$parent.Editer.title){
        //         return this.$parent.Editer.title
        //     }
        // },
    },
    methods: {
        Submit(model=this.storePath[1], callback) {
            if (!callback) {
                callback = (res) => {
                    if (res) {
                        this.Suc('操作成功')
                        this.Back()
                    }
                    return res
                }
            }
            let params = this.Origin(this.Params)
            return params.id ?
                this.Put(params, model, callback) :
                this.Post(params, model, callback)
        },
        Post(params=this.Origin(this.Params), model=this.storePath[1], callback) {
            let path = `${this.storePath[0]}/_POST_${model.toUpperCase()}`
            if (!callback) {
                callback = (res) => {
                    if (res) {
                        this.Suc('操作成功')
                        this.Back()
                    }
                    return res
                }
            }
            return this.$store.dispatch(path, {data: params}).then(callback)
        },
        Put(params=this.Origin(this.Params), model=this.storePath[1], callback) {
            let path = `${this.storePath[0]}/_PUT_${model.toUpperCase()}`
            if (!callback) {
                callback = (res) => {
                    if (res) {
                        this.Suc('操作成功')
                        this.Back()
                    }
                    return res
                }
            }
            return this.$store.dispatch(path, {data: params, id: params.id||params.sequence}).then(callback)
        }
    },
    created() {
        //this.view = this.value
    }
}