<style lang="scss">
    .CreditOperateTransfer{        
        .table {
            font-size:.65rem;
        }
        .HeadLimit {
            height: 44px;
            line-height: 44px;
            background-color: #fff;
            position: fixed!important;
            top: 44px;
            left: 0;
            right: 0;
            overflow:hidden;
        }
        &.hasHeadLimit {
            padding-top:52px;
        }
    }
</style>
<template>
    <div class="CreditOperateTransfer" :class="{'hasHeadLimit':!mode}">
        <div class="HeadLimit l-flex-c u-bb" style="z-index:1;" v-if="!mode">
            <div class="l-flex-2 u-br">{{ Filter.title }}</div>
            <div class="l-flex-3">
                <Actionsheet v-model="Filter.transfer" title="注单类型" :actions="[{name:'下注金额',value:0},{name:'走飞金额',value:1},{name:'收飞金额',value:2}]" @change="MakeFilter()"></Actionsheet>
            </div>            
        </div>
        <PullTo :top-load-method="LoadTop" v-infinite-scroll="LoadMore" :is-bottom-bounce="false" :infinite-scroll-disabled="!main._init || main._loading || !main._more" infinite-scroll-distance="10">
            <li v-for="(item,index) in main._list" class="block o-mt-s"> 
                <table class="table u-b" border="0" cellspacing="1" cellpadding="0">
                    <tr class="hbg-1">
                        <td colspan="3">注单号：{{ item.sequence }}</td>
                        <td colspan="2" style="text-align:right;">日期：{{ item.lottery_date }}</td>
                    </tr>
                    <tr class="hbg-2">
                        <td style="text-align:center;">游戏</td>
                        <td style="text-align:center;">期号</td>
                        <td style="text-align:center;">盘类</td>
                        <td style="text-align:center;">玩法</td>
                        <td style="text-align:center;">赔率</td>
                    </tr>
                    <tr>
                        <td style="text-align:center;">{{ item.games }}</td>
                        <td style="text-align:center;">{{ item.lottery_number }}</td>
                        <td style="text-align:center;">{{ item.side }}</td>
                        <td style="text-align:center;">{{ item.plays }}</td>
                        <td style="text-align:center;">{{ item.odds }}</td>
                    </tr>
                    <tr class="hbg-2">
                        <td style="text-align:center;">投注时间</td>
                        <td v-if="Filter.transfer >= 1">走飞类型</td>
                        <td style="text-align:center;" :colspan="Filter.transfer >= 1 ? 1 : 2">
                            <span v-if="Filter.transfer == 0">下注金额</span>
                            <span v-if="Filter.transfer == 1">走飞金额</span>
                            <span v-if="Filter.transfer == 2">收飞金额</span>
                        </td>
                        <td style="text-align:center;">赔率</td>
                        <td style="text-align:center;">占成</td>
                    </tr>
                    <tr>
                        <td style="text-align:center;">{{ Time(item.create_at,'yy-MM-dd hh:mm') }}</td>
                        <td style="text-align:center;" v-if="Filter.transfer >= 1">
                            <span v-if="item.transfer_t == 1">自动</span>
                            <span v-if="item.transfer_t == 2">手动</span>
                         </td>
                        <td style="text-align:center;" :colspan="Filter.transfer >= 1 ? 1 : 2">{{ item.amount }}</td>
                        <td style="text-align:center;">{{ item.odds }}</td>
                        <td style="text-align:center;">{{ item.percent }}</td>                                 
                    </tr>
                    <tr>
                        <td class="u-bt" colspan="5" style="text-align:center;">投注内容：{{ CheckContextLength(item.code,100) }}</td>
                    </tr>
                </table>
            </li>
            <Loading :main="main"></Loading>
        </PullTo>
            
<!--         <div class="block o-pb o-plr-s o-pt" style="min-width:850px;">
            <p v-if="!mode" class="o-pb">
                <span></span>
            </p>
            <table class="table u-bl u-bt u-br u-bb normal" border="0" cellspacing="1" cellpadding="0">
                <thead>
                    <tr class="hbg-1">
                        <td>日期</td>
                        <td>期数</td>
                        <td>注单号</td>
                        <td>账号</td>
                        <td>下注时间</td>
                        <td>盘类</td>
                        <td>玩法</td>
                        <td>下注内容</td>
                        <td v-if="Filter.transfer >= 1">走飞类型</td>
                        <td>
                            <span v-if="Filter.transfer == 0">下注金额</span>
                            <span v-if="Filter.transfer == 1">走飞金额</span>
                            <span v-if="Filter.transfer == 2">收飞金额</span>
                        </td>
                        <td>赔率</td>
                        <td>占成</td>
                    </tr>
                </thead>
                <tbody class="zebra">
                    <tr class="c-text-c" v-for="(item,index) in main._list">
                        <td>{{ item.lottery_date }}</td>
                        <td>{{ item.lottery_number }}</td>
                        <td>{{ item.sequence }}</td>
                        <td>{{ item.username }}</td>
                        <td>{{ item.create_at }}</td>
                        <td>{{ item.side }}</td>
                        <td>{{ item.plays }}</td>
                        <td>{{ item.code }}</td>
                        <td v-if="Filter.transfer >= 1">
                            <span v-if="item.transfer_t == 1">自动</span>
                            <span v-if="item.transfer_t == 2">手动</span>
                        </td>
                        <td>{{ item.amount }}</td>
                        <td>{{ item.odds }}</td>
                        <td>{{ item.percent }}</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr v-if="main._empty">
                        <td class="empty" colspan="100">
                            <div class="o-ptb-l c-text-c">暂无数据</div>
                        </td>
                    </tr>
                    <tr class="subtotal fbg-2" v-if="!main._empty && main._total > 1">
                        <td style="text-align:left;" colspan="8">
                            <p class="c-text-r o-pr">页面小计 (共 {{ main._list.length }} 笔)</p>
                        </td>
                        <td v-if="Filter.transfer >= 1">-</td>
                        <td>{{ CalcuRow('amount') }}</td>
                        <td>-</td>
                        <td>{{ CalcuRow('percent_amount') }}</td>
                       
                    </tr>
                    <tr class="total fbg-3" v-if="!main._empty">
                        <td style="text-align:left;" colspan="8">
                            <p class="c-text-r o-pr">合计 (共 {{ summary.count }} 笔)</p>
                        </td>
                        <td v-if="Filter.transfer >= 1">-</td>
                        <td>{{ Price(summary.amount,{separate:false}) }}</td>
                        <td>-</td>
                        <td>{{ Price(summary.percent_amount,{separate:false}) }}</td>
                    </tr>
                </tfoot>
            </table>
            <Pagination v-if="main._total" class="o-mtb" v-model="Page" @turning="_Get" :total="main._total" :count="main._count" :pageSize="Filter.per_page"></Pagination> -->
        </div>
    </div>
</template>

<script>
import { Indicator } from 'mint-ui'
import StoreMix from '@lottery.mobile/mixin/store.js'
import Rolling from '@lottery.mobile/mixin/rolling.js'
export default {
    name: 'CreditOperateTransfer',
    mixins : [StoreMix,Rolling],
    data() {
        return {
            store : 'game/otrader',
            Params : {},
            Filter : {
                whole : 1,
                transfer : 0,
                per_page : 10,
            },
            summary : {},
            model : 'game',
            type : 'amount',
            mode : null,

            ready : false,
        }
    },
    watch:{
        active(val){
            this.all = false
        },
    },
    methods: {
        init(){
            this.ready = false
            this.DataReset('Filter')
            if(this.$route.params.mode === 'transfer'){
                this.Filter.transfer = 1
                this.mode = 'transfer'
                this.$delete(this.$route.params,'mode')
            }
            for(let key in this.$route.params){
                this.$set(this.Filter,key,this.$route.params[key])
            }
            this.Reload()
        },
        Reload(){
            this.Page = 1
            let filter = this.Origin(this.form)
            for(let key in filter){
                this.$set(this.Filter,key,filter[key])
            }
            this.Cm(`${this.model}/CLEAN`,{
                base : 'otrader', key : '_list'
            })
            this.$set(this,'summary',{})
            this.GetLoad(1)
            this.GetSum()
        },
        _Get(page){
            this.GetLoad(page)
            this.GetSum()
        },
        GetLoad(page){
            //Indicator.open('加载中...')
            this.Get(page).then(res=>{
                //Indicator.close()
            },err=>{
                //Indicator.close()
            })
        },
        GetSum(){
            let params = this.Origin(this.Filter)
            this.Dp(`${this.model}/_GET_TRADERSUM`,{params}).then(res=>{
                this.$set(this,'summary',res.data)
            })
        },
    },
    activated(){
        this.init()
    },
}
</script>
