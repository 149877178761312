<style lang="scss">
    .CreditSubReportAmount {
        .ctrl {
            height:40px; line-height:40px;
        }
        .table {
            width:1280px;
            td,th {
                &:first-child {padding-left:8px;}
                &:last-child {padding-right:8px;}
            }
            td {
                border:.03rem solid #999;
            }
            tbody {
                td,th {
                    padding-top:8px; padding-bottom:8px;
                }  
            }
        }
        .link {
            text-decoration:underline;
        }
        .mark {
            color:red;
        }
    }
</style>
<template>
    <div class="CreditSubReportAmount">
        <div class="block ctrl o-plr">
            <Button v-if="breadcrumb.length > 1" @click="Breadcrumb(breadcrumb.length-2)" size="mini">返回上一级</button>
            <span class="o-pl">{{ breadcrumb[breadcrumb.length-1].title }}</span>
        </div>
        <div class="block o-mt" style="overflow-x:auto;">
            <div v-if="Filter.status == 0 && summary && summary.status == 0">
                <div class="c-text-c o-ptb-h">
                    <div class="o-pb-l">当前系统账单已全部结算</div>
                    <Button size="small" @click="Rd('index/pages/report')">返回</Button>
                </div>
            </div>
            <div v-else>
                <template v-if="Filter.games_type < 2">

<!-- xac-->
<table class="table" border="0" cellspacing="1" cellpadding="0">
    <colgroup>
                            <col width="130">
                            <col>
                            <col v-if="Opt.isTeam">
                            <col v-if="Filter.status == 0 && Opt.isTeam">
                            <col>
                            <col>
                            <col v-if="!Opt.isTeam">
                            <col v-if="!Opt.isTeam">
                            <col>
                        </colgroup>
                    <thead>
                        <tr style="text-align:center;" class="hbg-2">
                            <td style="background-color: rgb(248, 216, 197); text-align:left;" rowspan="2">帐号（新澳门）</td>
                            <td style="background-color: rgb(248, 216, 197); text-align:left;" rowspan="2">名称</td>
                            <td style="background-color: rgb(248, 216, 197);" rowspan="2" v-if="Opt.isTeam">级别</td>
                            <td style="background-color: rgb(248, 216, 197);" rowspan="2" v-if="Filter.status == 0">关系</td>
                            <td style="background-color: rgb(248, 216, 197);" rowspan="2">下单数</td>
                            <td style="background-color: rgb(248, 216, 197);" rowspan="2">下注金额</td>
                            <!-- <td style="background-color: rgb(248, 216, 197);" rowspan="2">有效金额</td> -->
                            <td style="background-color: rgb(248, 216, 197);" rowspan="2" v-if="!Opt.isTeam">奖金</td>
                            <td style="background-color: rgb(248, 216, 197);" rowspan="2" v-if="!Opt.isTeam">退水</td>
                            <td style="background-color: rgb(248, 216, 197);" rowspan="2">
                                <span v-if="Opt.isTeam">会员结果</span>
                                <span v-else>结果</span>
                            </td>
                            <template v-if="Opt.isTeam && Filter.status != 0">
                                <td style="background-color: rgb(248, 216, 197);" rowspan="2">{{ isTop ? '代理结果' : '交收'}}</td>
                                <td style="background-color: rgb(248, 216, 197); text-align:center;" colspan="4" v-if="!isTop">代理<span v-if="summary">({{ summary.transfer_username_out || summary.username }})</span></td>
                                <td style="background-color: rgb(248, 216, 197); text-align:center;" colspan="5" v-if="!isTop">代理收飞</td>
                                <td style="background-color: rgb(248, 216, 197);" rowspan="2" v-if="isTop">收飞金额</td>
                                <td style="background-color: rgb(248, 216, 197);" rowspan="2" v-if="isTop">收飞结果</td>
                                <td style="background-color: rgb(248, 216, 197);" rowspan="2">总结果</td>
                                <td style="background-color: rgb(248, 216, 197);" rowspan="2" v-if="!isTop && Filter.pid != 1">上级结果</td>
                                <td style="background-color: rgb(248, 216, 197);" rowspan="2">贡献1</td>
                                <td style="background-color: rgb(248, 216, 197);" rowspan="2" v-if="Filter.pid != 1">贡献2</td>
                                <td style="background-color: rgb(248, 216, 197);" rowspan="2">贡献比例</td>
                            </template>
                        </tr>
                        <tr style="text-align:left;" v-if="Opt.isTeam && !isTop && Filter.status != 0" class="hbg-4">
                            <td>退水</td>
                            <td>金额</td>
                            <td>占成</td>
                            <td>结果</td>
                            <td>退水</td>
                            <td>金额</td>
                            <td>输赢</td>
                            <td>占成</td>
                            <td>结果</td>
                        </tr>
                    </thead>
                    <tbody style="text-align:left;" class="zebra">
                        <tr v-for="(item,index) in main._list">
 <template v-if="item.xac_bets_num > 0">
                        
                            <td style="text-align:left;">
                                <a class="link" v-if="item.user_type==0 || !item.user_type" @click="Edit(item,0,5)" style="display:inline-block; min-width:80px;">{{ item.username }}</a>
                                <a class="link" v-if="item.user_type==1" @click="GetByBreadcrumb(item,5)" style="display:inline-block; min-width:80px;">{{ item.username }}</a>                                
                            </td>
                            <td style="text-align:left;">
                                <span style="display:inline-block; min-width:40px;">{{ item.real_name }}</span>
                            </td>
                            <td v-if="Opt.isTeam">
                                <span style="display:inline-block; min-width:40px;">{{ item.level }}</span>
                            </td>
                            <td v-if="Filter.status == 0">
                                <a class="link" @click="LevelView(item)">关系</a>
                            </td>
                            <td style="text-align:left;">{{ item.xac_bets_num }}</td>
                            <td style="text-align:left;">
                                <a class="link" v-if="item.user_type==0 || !item.user_type" @click="Edit(item,0,5)">{{ item.xac_bets_amount }}</a>
                                <a class="link" v-if="item.user_type==1" @click="GetByBreadcrumb(item,5)">{{ item.xac_bets_amount }}</a>
                            </td>

                            <!-- <td style="text-align:left;" :class="{'mark':item.xac_valid_amount<0}">{{ item.xac_valid_amount }}</td> -->
                            <td style="text-align:left;" v-if="!Opt.isTeam" :class="{'mark':item.xac_bets_bonus<0}">{{ item.xac_bets_bonus }}</td>
                            <td style="text-align:left;" v-if="!Opt.isTeam" :class="{'mark':item.xac_percent_backwater_amount<0}">{{ item.xac_percent_backwater_amount }}</td>
                            <td style="text-align:left;" :class="{'mark':item.xac_members_result<0}">{{ item.xac_members_result }}</td>
                            <template v-if="Opt.isTeam && Filter.status != 0">
                                <td style="text-align:left;" :class="{'mark':item.xac_js_result<0}">{{ item.xac_js_result }}</td>

                                <template v-if="!isTop">
                                    <td style="text-align:left;" :class="{'mark':item.xac_percent_backwater_amount<0}">{{ Price(item.xac_percent_backwater_amount,{separate:false}) }}</td>
                                    <td style="text-align:left;" :class="{'mark':item.xac_percent_amount<0}">{{ item.xac_percent_amount }}</td>
                                    <td style="text-align:left;" :class="{'mark':item.xac_percent<0}">{{ item.xac_percent }}</td>
                                    <td style="text-align:left;" :class="{'mark':item.xac_percent_result<0}">{{ item.xac_percent_result }}</td>

                                    <td style="text-align:left;" :class="{'mark':item.xac_transfer_backwater<0}">{{ Price(item.xac_transfer_backwater,{separate:false}) }}</td>
                                    <td style="text-align:left;" :class="{'mark':item.xac_transfer_amount<0}"><a class="link" @click="Edit(item,2)">{{ item.xac_transfer_amount }}</a></td>
                                    <td style="text-align:left;" :class="{'mark':item.xac_transfer_wins<0}">{{ item.xac_transfer_wins }}</td>                                    
                                    <td style="text-align:left;" :class="{'mark':item.xac_transfer<0}">{{ item.xac_transfer }}</td>
                                    <td style="text-align:left;" :class="{'mark':item.xac_transfer_result<0}">{{ item.xac_transfer_result }}</td>
                                </template>

                                <td style="text-align:left;" :class="{'mark':item.xac_transfer_amount<0}" v-if="isTop"><a class="link" @click="Edit(item,2)">{{ item.xac_transfer_amount }}</a></td>
                                <td style="text-align:left;" :class="{'mark':item.xac_transfer_result<0}" v-if="isTop">{{ item.xac_transfer_result }}</td>

                                <td style="text-align:left;" :class="{'mark':item.xac_result<0}">{{ item.xac_result }}</td>
                                <td style="text-align:left;" :class="{'mark':item.xac_up_result<0}" v-if="!isTop && Filter.pid != 1">{{ item.xac_up_result}}</td>
                                <td style="text-align:left;" :class="{'mark':item.xac_contribution_one<0}">{{ item.xac_contribution_one }}</td>
                                <td style="text-align:left;" :class="{'mark':item.xac_contribution_two<0}" v-if="Filter.pid != 1">{{ item.xac_contribution_two }}</td>
                                <td style="text-align:center;">
                                    <div class="l-flex-c o-pr-s">
                                        <p class="l-flex-1 c-text-6 c-text-r">{{ item.xac_contribution_proportion }}%</p>
                                    </div>
                                </td>     
                            </template>                       
                    
                        </template>
                        </tr>
                    </tbody>
                    <tfoot>
                        <!-- <tr v-if="main._empty">
                            <td class="empty" colspan="100">
                                <div class="c-text-c o-ptb-h">暂无数据</div>
                            </td>
                        </tr> -->
                        <template v-if="!main._empty && Opt.isTeam">
                            <tr class="subtotal hbg-1" v-if="main._count >= 50">
                                <td style="text-align:left;" colspan="3">小计</td>
                                <td v-if="Filter.status == 0">-</td>

                                <td style="text-align:left;" :class="{'mark':CalcuRow('xac_bets_num')<0}">{{ CalcuRow('xac_bets_num') }}</td>
                                <td style="text-align:left;" :class="{'mark':CalcuRow('xac_bets_amount')<0}">{{ CalcuRow('xac_bets_amount') }}</td>
                                <!-- <td style="text-align:left;" :class="{'mark':CalcuRow('xac_valid_amount')<0}">{{ CalcuRow('xac_valid_amount') }}</td> -->
                                <td style="text-align:left;" :class="{'mark':CalcuRow('xac_members_result')<0}">{{ CalcuRow('xac_members_result') }}</td>
                                <template v-if="Filter.status != 0">
                                    <td style="text-align:left;" :class="{'mark':CalcuRow('xac_js_result')<0}">{{ CalcuRow('xac_js_result') }}</td>

                                    <template v-if="!isTop">
                                        <td style="text-align:left;" :class="{'mark':CalcuRow('xac_percent_backwater_amount')<0}">{{ CalcuRow('xac_percent_backwater_amount') }}</td>
                                        <td style="text-align:left;" :class="{'mark':CalcuRow('xac_percent_amount')<0}">{{ CalcuRow('xac_percent_amount') }}</td>
                                        <td style="text-align:left;">-</td>
                                        <td style="text-align:left;" :class="{'mark':CalcuRow('xac_percent_result')<0}">{{ CalcuRow('xac_percent_result') }}</td>

                                        <td style="text-align:left;" :class="{'mark':CalcuRow('xac_transfer_backwater')<0}">{{ CalcuRow('xac_transfer_backwater') }}</td>
                                        <td style="text-align:left;" :class="{'mark':CalcuRow('xac_transfer_amount')<0}">{{ CalcuRow('xac_transfer_amount') }}</td>
                                        <td style="text-align:left;" :class="{'mark':CalcuRow('xac_transfer_wins')<0}">{{ CalcuRow('xac_transfer_wins') }}</td>                                    
                                        <td style="text-align:left;">-</td>
                                        <td style="text-align:left;" :class="{'mark':CalcuRow('xac_transfer_result')<0}">{{ CalcuRow('xac_transfer_result') }}</td>
                                    </template>

                                    <td style="text-align:left;" :class="{'mark':CalcuRow('xac_transfer_amount')<0}" v-if="isTop">{{ CalcuRow('xac_transfer_amount') }}</td>
                                    <td style="text-align:left;" :class="{'mark':CalcuRow('xac_transfer_result')<0}" v-if="isTop">{{ CalcuRow('xac_transfer_result') }}</td>

                                    <td style="text-align:left;"  :class="{'mark':CalcuRow('xac_result')<0}">{{ CalcuRow('xac_result') }}</td>
                                    <td style="text-align:left;" :class="{'mark':CalcuRow('xac_up_result')<0}" v-if="!isTop && Filter.pid != 1">{{ CalcuRow('xac_up_result') }}</td>
                                    <td style="text-align:left;" :class="{'mark':CalcuRow('xac_contribution_one')<0}">{{ CalcuRow('xac_contribution_one') }}</td>
                                    <td style="text-align:left;" :class="{'mark':CalcuRow('xac_contribution_two')<0}" v-if="Filter.pid != 1">{{ CalcuRow('xac_contribution_two') }}</td>
                                    <td style="text-align:right;"><span class="o-pr-s">-</span></td>
                                </template>
                            </tr>
                            <tr class="total fbg-4" v-if="!main._empty && summary" >
                                <td style="text-align:left;" colspan="3">合计</td>
                                <td v-if="Filter.status == 0">-</td>

                                <td style="text-align:left;" :class="{'mark':summary.xac_bets_num<0}">{{ summary.xac_bets_num }}</td>
                                <td style="text-align:left;" :class="{'mark':summary.xac_bets_amount<0}">{{ summary.xac_bets_amount }}</td>
                                <!-- <td style="text-align:left;" :class="{'mark':summary.xac_valid_amount<0}">{{ summary.xac_valid_amount }}</td> -->
                                <td style="text-align:left;" :class="{'mark':summary.xac_members_result<0}">{{ summary.xac_members_result }}</td>
                                <template v-if="Filter.status != 0">
                                    <td style="text-align:left;" :class="{'mark':summary.xac_js_result<0}">{{ summary.xac_js_result }}</td>

                                    <template v-if="!isTop">
                                        <td style="text-align:left;" :class="{'mark':summary.xac_percent_backwater_amount<0}">{{ summary.xac_percent_backwater_amount }}</td>
                                        <td style="text-align:left;" :class="{'mark':summary.xac_percent_amount<0}">{{ summary.xac_percent_amount }}</td>
                                        <td style="text-align:left;">-</td>
                                        <td style="text-align:left;" :class="{'mark':summary.xac_percent_result<0}">{{ summary.xac_percent_result }}</td>

                                        <td style="text-align:left;" :class="{'mark':summary.xac_transfer_backwater<0}">{{ summary.xac_transfer_backwater }}</td>
                                        <td style="text-align:left;" :class="{'mark':summary.xac_transfer_amount<0}">{{ summary.xac_transfer_amount }}</td>
                                        <td style="text-align:left;" :class="{'mark':summary.xac_transfer_wins<0}">{{ summary.xac_transfer_wins }}</td>
                                        <td style="text-align:left;">-</td>
                                        <td style="text-align:left;" :class="{'mark':summary.xac_transfer_result<0}">{{ summary.xac_transfer_result }}</td>
                                    </template>

                                    <td style="text-align:left;" :class="{'mark':summary.xac_transfer_amount<0}" v-if="isTop">{{ summary.xac_transfer_amount }}</td>
                                    <td style="text-align:left;" :class="{'mark':summary.xac_transfer_result<0}" v-if="isTop">{{ summary.xac_transfer_result }}</td>

                                    <td style="text-align:left;" :class="{'mark':summary.xac_result<0}">{{ summary.xac_result }}</td>
                                    <td style="text-align:left;" by="上级结果" :class="{'mark':summary.xac_up_result<0}" v-if="!isTop && Filter.pid != 1">{{ summary.xac_up_result }}</td>
                                    <td style="text-align:left;" :class="{'mark':summary.xac_contribution_one<0}">{{ summary.xac_contribution_one }}</td>
                                    <td style="text-align:left;" :class="{'mark':summary.xac_contribution_two<0}" v-if="Filter.pid != 1">{{ summary.xac_contribution_two }}</td>
                                    <td style="text-align:right;"><span class="o-pr-s">-</span></td>
                                </template>
                            </tr>
                        </template>
                    </tfoot>
                </table>


                <template v-if="Opt.isTeam && summary && summary.xac_transfer_number_out > 0">
                    <table v-if="Filter.status == 0" class="table" border="0" cellspacing="1" cellpadding="0" style="margin-top:10px;">
                        <colgroup>
                            <col width="130">
                            <col width="190">
                            <col>
                            <col>
                            <col>
                        </colgroup>
                        <thead>
                            <tr style="text-align:left;" class="hbg-1">
                                <td style="background-color: rgb(248, 216, 197);">帐号</td>
                                <td style="background-color: rgb(248, 216, 197);">走飞笔数</td>
                                <td style="background-color: rgb(248, 216, 197);">走飞金额</td>
                                <td style="background-color: rgb(248, 216, 197);">收飞笔数</td>
                                <td style="background-color: rgb(248, 216, 197);">收飞金额</td>
                            </tr>
                        </thead>
                        <tbody style="text-align:left;">
                            <tr>
                                <td>{{ summary.username }}</td>
                                <td>{{ summary.xac_transfer_number_out }}</td>
                                <td :class="{'mark':summary.xac_transfer_amount_out<0}"><a class="link" @click="Edit(summary,5)">{{ summary.xac_transfer_amount_out }}</a></td>
                                <td>{{ summary.xac_reward_number }}</td>
                                <td :class="{'mark':summary.xac_reward_amount<0}"><a class="link" @click="Edit(summary,5)">{{ summary.xac_reward_amount }}</a></td>
                            </tr>
                        </tbody>
                    </table>
                    <table v-if="Filter.status == 1" class="table" border="0" cellspacing="1" cellpadding="0" style="margin-top:10px;">
                        <thead>
                            <tr style="text-align:left;" class="hbg-2">
                                <td style="background-color: rgb(248, 216, 197);">帐号</td>
                                <td style="background-color: rgb(248, 216, 197);">笔数</td>
                                <td style="background-color: rgb(248, 216, 197);">走飞金额</td>
                                <td style="background-color: rgb(248, 216, 197);">走飞退水</td>
                                <td style="background-color: rgb(248, 216, 197);">走飞结果</td>
                                <td style="background-color: rgb(248, 216, 197);">总结果合计</td>
                                <td style="background-color: rgb(248, 216, 197);" v-if="!TargetIsAdmin">上级结果合计</td>
                            </tr>
                        </thead>
                        <tbody style="text-align:left;">
                            <tr>
                                <td>{{ summary.xac_transfer_username_out }}</td>
                                <td :class="{'mark':summary.xac_transfer_number_out<0}">{{ summary.xac_transfer_number_out }}</td>
                                <td :class="{'mark':summary.xac_transfer_amount_out<0}"><a class="link" @click="Edit(summary,1)">{{ summary.xac_transfer_amount_out }}</a></td>
                                <td :class="{'mark':summary.xac_transfer_backwater_out<0}">{{ summary.xac_transfer_backwater_out }}</td>
                                <td :class="{'mark':summary.xac_transfer_result_out<0}">{{ summary.xac_transfer_result_out }}</td>
                                <td :class="{'mark':summary.xac_total_result<0}">{{ summary.xac_total_result }}</td>
                                <td :class="{'mark':summary.xac_total_up_result<0}" v-if="!TargetIsAdmin">{{ summary.xac_total_up_result }}</td>
                            </tr>
                        </tbody>
                    </table>
                </template>
<br/>

<!-- xg-->
<table class="table" border="0" cellspacing="1" cellpadding="0">
    <colgroup>
                            <col width="130">
                            <col>
                            <col v-if="Opt.isTeam">
                            <col v-if="Filter.status == 0 && Opt.isTeam">
                            <col>
                            <col>
                            <col v-if="!Opt.isTeam">
                            <col v-if="!Opt.isTeam">
                            <col>
                        </colgroup>
                    <thead>
                        <tr style="text-align:center;" class="hbg-2">
                            <td style="background-color: rgb(152 200 237); text-align:left;" rowspan="2">帐号（香港）</td>
                            <td style="background-color: rgb(152 200 237); text-align:left;" rowspan="2">名称</td>
                            <td style="background-color: rgb(152 200 237);" rowspan="2" v-if="Opt.isTeam">级别</td>
                            <td style="background-color: rgb(152 200 237);" rowspan="2" v-if="Filter.status == 0">关系</td>
                            <td style="background-color: rgb(152 200 237);" rowspan="2">下单数</td>
                            <td style="background-color: rgb(152 200 237);" rowspan="2">下注金额</td>
                            <!-- <td style="background-color: rgb(152 200 237);" rowspan="2">有效金额</td> -->
                            <td style="background-color: rgb(152 200 237);" rowspan="2" v-if="!Opt.isTeam">奖金</td>
                            <td style="background-color: rgb(152 200 237);" rowspan="2" v-if="!Opt.isTeam">退水</td>
                            <td style="background-color: rgb(152 200 237);" rowspan="2">
                                <span v-if="Opt.isTeam">会员结果</span>
                                <span v-else>结果</span>
                            </td>
                            <template v-if="Opt.isTeam && Filter.status != 0">
                                <td style="background-color: rgb(152 200 237);" rowspan="2">{{ isTop ? '代理结果' : '交收'}}</td>
                                <td style="background-color: rgb(152 200 237); text-align:center;" colspan="4" v-if="!isTop">代理<span v-if="summary">({{ summary.transfer_username_out || summary.username }})</span></td>
                                <td style="background-color: rgb(152 200 237); text-align:center;" colspan="5" v-if="!isTop">代理收飞</td>
                                <td style="background-color: rgb(152 200 237);" rowspan="2" v-if="isTop">收飞金额</td>
                                <td style="background-color: rgb(152 200 237);" rowspan="2" v-if="isTop">收飞结果</td>
                                <td style="background-color: rgb(152 200 237);" rowspan="2">总结果</td>
                                <td style="background-color: rgb(152 200 237);" rowspan="2" v-if="!isTop && Filter.pid != 1">上级结果</td>
                                <td style="background-color: rgb(152 200 237);" rowspan="2">贡献1</td>
                                <td style="background-color: rgb(152 200 237);" rowspan="2" v-if="Filter.pid != 1">贡献2</td>
                                <td style="background-color: rgb(152 200 237);" rowspan="2">贡献比例</td>
                            </template>
                        </tr>
                        <tr style="text-align:left;" v-if="Opt.isTeam && !isTop && Filter.status != 0" class="hbg-4">
                            <td>退水</td>
                            <td>金额</td>
                            <td>占成</td>
                            <td>结果</td>
                            <td>退水</td>
                            <td>金额</td>
                            <td>输赢</td>
                            <td>占成</td>
                            <td>结果</td>
                        </tr>
                    </thead>
                    <tbody style="text-align:left;" class="zebra">
                        <tr v-for="(item,index) in main._list">
        <template v-if="item.xg_bets_num > 0">
                        
                            <td style="text-align:left;">
                                <a class="link" v-if="item.user_type==0 || !item.user_type" @click="Edit(item,0,1)" style="display:inline-block; min-width:80px;">{{ item.username }}</a>
                                <a class="link" v-if="item.user_type==1" @click="GetByBreadcrumb(item,1)" style="display:inline-block; min-width:80px;">{{ item.username }}</a>                                
                            </td>
                            <td style="text-align:left;">
                                <span style="display:inline-block; min-width:40px;">{{ item.real_name }}</span>
                            </td>
                            <td v-if="Opt.isTeam">
                                <span style="display:inline-block; min-width:40px;">{{ item.level }}</span>
                            </td>
                            <td v-if="Filter.status == 0">
                                <a class="link" @click="LevelView(item)">关系</a>
                            </td>
                            <td style="text-align:left;">{{ item.xg_bets_num }}</td>
                            <td style="text-align:left;">
                                <a class="link" v-if="item.user_type==0 || !item.user_type" @click="Edit(item,0,1)">{{ item.xg_bets_amount }}</a>
                                <a class="link" v-if="item.user_type==1" @click="GetByBreadcrumb(item,1)">{{ item.xg_bets_amount }}</a>
                            </td>

                            <!-- <td style="text-align:left;" :class="{'mark':item.xg_valid_amount<0}">{{ item.xg_valid_amount }}</td> -->
                            <td style="text-align:left;" v-if="!Opt.isTeam" :class="{'mark':item.xg_bets_bonus<0}">{{ item.xg_bets_bonus }}</td>
                            <td style="text-align:left;" v-if="!Opt.isTeam" :class="{'mark':item.xg_percent_backwater_amount<0}">{{ item.xg_percent_backwater_amount }}</td>
                            <td style="text-align:left;" :class="{'mark':item.xg_members_result<0}">{{ item.xg_members_result }}</td>
                            <template v-if="Opt.isTeam && Filter.status != 0">
                                <td style="text-align:left;" :class="{'mark':item.xg_js_result<0}">{{ item.xg_js_result }}</td>

                                <template v-if="!isTop">
                                    <td style="text-align:left;" :class="{'mark':item.xg_percent_backwater_amount<0}">{{ Price(item.xg_percent_backwater_amount,{separate:false}) }}</td>
                                    <td style="text-align:left;" :class="{'mark':item.xg_percent_amount<0}">{{ item.xg_percent_amount }}</td>
                                    <td style="text-align:left;" :class="{'mark':item.xg_percent<0}">{{ item.xg_percent }}</td>
                                    <td style="text-align:left;" :class="{'mark':item.xg_percent_result<0}">{{ item.xg_percent_result }}</td>

                                    <td style="text-align:left;" :class="{'mark':item.xg_transfer_backwater<0}">{{ Price(item.xg_transfer_backwater,{separate:false}) }}</td>
                                    <td style="text-align:left;" :class="{'mark':item.xg_transfer_amount<0}"><a class="link" @click="Edit(item,2)">{{ item.xg_transfer_amount }}</a></td>
                                    <td style="text-align:left;" :class="{'mark':item.xg_transfer_wins<0}">{{ item.xg_transfer_wins }}</td>                                    
                                    <td style="text-align:left;" :class="{'mark':item.xg_transfer<0}">{{ item.xg_transfer }}</td>
                                    <td style="text-align:left;" :class="{'mark':item.xg_transfer_result<0}">{{ item.xg_transfer_result }}</td>
                                </template>

                                <td style="text-align:left;" :class="{'mark':item.xg_transfer_amount<0}" v-if="isTop"><a class="link" @click="Edit(item,2)">{{ item.xg_transfer_amount }}</a></td>
                                <td style="text-align:left;" :class="{'mark':item.xg_transfer_result<0}" v-if="isTop">{{ item.xg_transfer_result }}</td>

                                <td style="text-align:left;" :class="{'mark':item.xg_result<0}">{{ item.xg_result }}</td>
                                <td style="text-align:left;" :class="{'mark':item.xg_up_result<0}" v-if="!isTop && Filter.pid != 1">{{ item.xg_up_result}}</td>
                                <td style="text-align:left;" :class="{'mark':item.xg_contribution_one<0}">{{ item.xg_contribution_one }}</td>
                                <td style="text-align:left;" :class="{'mark':item.xg_contribution_two<0}" v-if="Filter.pid != 1">{{ item.xg_contribution_two }}</td>
                                <td style="text-align:center;">
                                    <div class="l-flex-c o-pr-s">
                                        <p class="l-flex-1 c-text-6 c-text-r">{{ item.xg_contribution_proportion }}%</p>
                                    </div>
                                </td>     
                            </template>                       
                        
</template>
</tr>
                    </tbody>
                    <tfoot>
                  
                        <template v-if="!main._empty && Opt.isTeam">
                            <tr class="subtotal hbg-1" v-if="main._count >= 50">
                                <td style="text-align:left;" colspan="3">小计</td>
                                <td v-if="Filter.status == 0">-</td>

                                <td style="text-align:left;" :class="{'mark':CalcuRow('xg_bets_num')<0}">{{ CalcuRow('xg_bets_num') }}</td>
                                <td style="text-align:left;" :class="{'mark':CalcuRow('xg_bets_amount')<0}">{{ CalcuRow('xg_bets_amount') }}</td>
                                <!-- <td style="text-align:left;" :class="{'mark':CalcuRow('xg_valid_amount')<0}">{{ CalcuRow('xg_valid_amount') }}</td> -->
                                <td style="text-align:left;" :class="{'mark':CalcuRow('xg_members_result')<0}">{{ CalcuRow('xg_members_result') }}</td>
                                <template v-if="Filter.status != 0">
                                    <td style="text-align:left;" :class="{'mark':CalcuRow('xg_js_result')<0}">{{ CalcuRow('xg_js_result') }}</td>

                                    <template v-if="!isTop">
                                        <td style="text-align:left;" :class="{'mark':CalcuRow('xg_percent_backwater_amount')<0}">{{ CalcuRow('xg_percent_backwater_amount') }}</td>
                                        <td style="text-align:left;" :class="{'mark':CalcuRow('xg_percent_amount')<0}">{{ CalcuRow('xg_percent_amount') }}</td>
                                        <td style="text-align:left;">-</td>
                                        <td style="text-align:left;" :class="{'mark':CalcuRow('xg_percent_result')<0}">{{ CalcuRow('xg_percent_result') }}</td>

                                        <td style="text-align:left;" :class="{'mark':CalcuRow('xg_transfer_backwater')<0}">{{ CalcuRow('xg_transfer_backwater') }}</td>
                                        <td style="text-align:left;" :class="{'mark':CalcuRow('xg_transfer_amount')<0}">{{ CalcuRow('xg_transfer_amount') }}</td>
                                        <td style="text-align:left;" :class="{'mark':CalcuRow('xg_transfer_wins')<0}">{{ CalcuRow('xg_transfer_wins') }}</td>                                    
                                        <td style="text-align:left;">-</td>
                                        <td style="text-align:left;" :class="{'mark':CalcuRow('xg_transfer_result')<0}">{{ CalcuRow('xg_transfer_result') }}</td>
                                    </template>

                                    <td style="text-align:left;" :class="{'mark':CalcuRow('xg_transfer_amount')<0}" v-if="isTop">{{ CalcuRow('xg_transfer_amount') }}</td>
                                    <td style="text-align:left;" :class="{'mark':CalcuRow('xg_transfer_result')<0}" v-if="isTop">{{ CalcuRow('xg_transfer_result') }}</td>

                                    <td style="text-align:left;"  :class="{'mark':CalcuRow('xg_result')<0}">{{ CalcuRow('xg_result') }}</td>
                                    <td style="text-align:left;" :class="{'mark':CalcuRow('xg_up_result')<0}" v-if="!isTop && Filter.pid != 1">{{ CalcuRow('xg_up_result') }}</td>
                                    <td style="text-align:left;" :class="{'mark':CalcuRow('xg_contribution_one')<0}">{{ CalcuRow('xg_contribution_one') }}</td>
                                    <td style="text-align:left;" :class="{'mark':CalcuRow('xg_contribution_two')<0}" v-if="Filter.pid != 1">{{ CalcuRow('xg_contribution_two') }}</td>
                                    <td style="text-align:right;"><span class="o-pr-s">-</span></td>
                                </template>
                            </tr>
                            <tr class="total fbg-4" v-if="!main._empty && summary" >
                                <td style="text-align:left;" colspan="3">合计</td>
                                <td v-if="Filter.status == 0">-</td>

                                <td style="text-align:left;" :class="{'mark':summary.xg_bets_num<0}">{{ summary.xg_bets_num }}</td>
                                <td style="text-align:left;" :class="{'mark':summary.xg_bets_amount<0}">{{ summary.xg_bets_amount }}</td>
                                <!-- <td style="text-align:left;" :class="{'mark':summary.xg_valid_amount<0}">{{ summary.xg_valid_amount }}</td> -->
                                <td style="text-align:left;" :class="{'mark':summary.xg_members_result<0}">{{ summary.xg_members_result }}</td>
                                <template v-if="Filter.status != 0">
                                    <td style="text-align:left;" :class="{'mark':summary.xg_js_result<0}">{{ summary.xg_js_result }}</td>

                                    <template v-if="!isTop">
                                        <td style="text-align:left;" :class="{'mark':summary.xg_percent_backwater_amount<0}">{{ summary.xg_percent_backwater_amount }}</td>
                                        <td style="text-align:left;" :class="{'mark':summary.xg_percent_amount<0}">{{ summary.xg_percent_amount }}</td>
                                        <td style="text-align:left;">-</td>
                                        <td style="text-align:left;" :class="{'mark':summary.xg_percent_result<0}">{{ summary.xg_percent_result }}</td>

                                        <td style="text-align:left;" :class="{'mark':summary.xg_transfer_backwater<0}">{{ summary.xg_transfer_backwater }}</td>
                                        <td style="text-align:left;" :class="{'mark':summary.xg_transfer_amount<0}">{{ summary.xg_transfer_amount }}</td>
                                        <td style="text-align:left;" :class="{'mark':summary.xg_transfer_wins<0}">{{ summary.xg_transfer_wins }}</td>
                                        <td style="text-align:left;">-</td>
                                        <td style="text-align:left;" :class="{'mark':summary.xg_transfer_result<0}">{{ summary.xg_transfer_result }}</td>
                                    </template>

                                    <td style="text-align:left;" :class="{'mark':summary.xg_transfer_amount<0}" v-if="isTop">{{ summary.xg_transfer_amount }}</td>
                                    <td style="text-align:left;" :class="{'mark':summary.xg_transfer_result<0}" v-if="isTop">{{ summary.xg_transfer_result }}</td>

                                    <td style="text-align:left;" :class="{'mark':summary.xg_result<0}">{{ summary.xg_result }}</td>
                                    <td style="text-align:left;" by="上级结果" :class="{'mark':summary.xg_up_result<0}" v-if="!isTop && Filter.pid != 1">{{ summary.xg_up_result }}</td>
                                    <td style="text-align:left;" :class="{'mark':summary.xg_contribution_one<0}">{{ summary.xg_contribution_one }}</td>
                                    <td style="text-align:left;" :class="{'mark':summary.xg_contribution_two<0}" v-if="Filter.pid != 1">{{ summary.xg_contribution_two }}</td>
                                    <td style="text-align:right;"><span class="o-pr-s">-</span></td>
                                </template>
                            </tr>
                        </template>
                    </tfoot>
                </table>


                <template v-if="Opt.isTeam && summary && summary.xg_transfer_number_out > 0">
                    <table v-if="Filter.status == 0" class="table" border="0" cellspacing="1" cellpadding="0" style="margin-top:10px;">
                        <colgroup>
                            <col width="130">
                            <col width="190">
                            <col>
                            <col>
                            <col>
                        </colgroup>
                        <thead>
                            <tr style="text-align:left;" class="hbg-1">
                                <td style="background-color: rgb(152 200 237);" >帐号</td>
                                <td style="background-color: rgb(152 200 237);">走飞笔数</td>
                                <td style="background-color: rgb(152 200 237);">走飞金额</td>
                                <td style="background-color: rgb(152 200 237);">收飞笔数</td>
                                <td style="background-color: rgb(152 200 237);">收飞金额</td>
                            </tr>
                        </thead>
                        <tbody style="text-align:left;">
                            <tr>
                                <td>{{ summary.username }}</td>
                                <td>{{ summary.xg_transfer_number_out }}</td>
                                <td :class="{'mark':summary.xg_transfer_amount_out<0}"><a class="link" @click="Edit(summary,1)">{{ summary.xg_transfer_amount_out }}</a></td>
                                <td>{{ summary.xg_reward_number }}</td>
                                <td :class="{'mark':summary.xg_reward_amount<0}"><a class="link" @click="Edit(summary,1)">{{ summary.xg_reward_amount }}</a></td>
                            </tr>
                        </tbody>
                    </table>
                    <table v-if="Filter.status == 1" class="table" border="0" cellspacing="1" cellpadding="0" style="margin-top:10px;">
                        <thead>
                            <tr style="text-align:left;" class="hbg-2">
                                <td style="background-color: rgb(152 200 237);">帐号</td>
                                <td style="background-color: rgb(152 200 237);">笔数</td>
                                <td style="background-color: rgb(152 200 237);">走飞金额</td>
                                <td style="background-color: rgb(152 200 237);">走飞退水</td>
                                <td style="background-color: rgb(152 200 237);">走飞结果</td>
                                <td style="background-color: rgb(152 200 237);">总结果合计</td>
                                <td style="background-color: rgb(152 200 237);" v-if="!TargetIsAdmin">上级结果合计</td>
                            </tr>
                        </thead>
                        <tbody style="text-align:left;">
                            <tr>
                                <td>{{ summary.xg_transfer_username_out }}</td>
                                <td :class="{'mark':summary.xg_transfer_number_out<0}">{{ summary.xg_transfer_number_out }}</td>
                                <td :class="{'mark':summary.xg_transfer_amount_out<0}"><a class="link" @click="Edit(summary,1)">{{ summary.xg_transfer_amount_out }}</a></td>
                                <td :class="{'mark':summary.xg_transfer_backwater_out<0}">{{ summary.xg_transfer_backwater_out }}</td>
                                <td :class="{'mark':summary.xg_transfer_result_out<0}">{{ summary.xg_transfer_result_out }}</td>
                                <td :class="{'mark':summary.xg_total_result<0}">{{ summary.xg_total_result }}</td>
                                <td :class="{'mark':summary.xg_total_up_result<0}" v-if="!TargetIsAdmin">{{ summary.xg_total_up_result }}</td>
                            </tr>
                        </tbody>
                    </table>
                </template>
<br/>
                <table class="table" border="0" cellspacing="1" cellpadding="0">
                    <colgroup>
                            <col width="130">
                            <col>
                            <col v-if="Opt.isTeam">
                            <col v-if="Filter.status == 0 && Opt.isTeam">
                            <col>
                            <col>
                            <col v-if="!Opt.isTeam">
                            <col v-if="!Opt.isTeam">
                            <col>
                        </colgroup>
                    <thead>
                        <tr style="text-align:center;" class="hbg-2">
                            <td style="background-color: rgb(190 206 239); text-align:left;" rowspan="2">帐号（旧澳门）</td>
                            <td style="background-color: rgb(190 206 239); text-align:left;" rowspan="2">名称</td>
                            <td style="background-color: rgb(190 206 239);" rowspan="2" v-if="Opt.isTeam">级别</td>
                            <td style="background-color: rgb(190 206 239);" rowspan="2" v-if="Filter.status == 0">关系</td>
                            <td style="background-color: rgb(190 206 239);" rowspan="2">下单数</td>
                            <td style="background-color: rgb(190 206 239);" rowspan="2">下注金额</td>
                            <!-- <td style="background-color: rgb(190 206 239);" rowspan="2">有效金额</td> -->
                            <td style="background-color: rgb(190 206 239);" rowspan="2" v-if="!Opt.isTeam">奖金</td>
                            <td style="background-color: rgb(190 206 239);" rowspan="2" v-if="!Opt.isTeam">退水</td>
                            <td style="background-color: rgb(190 206 239);" rowspan="2">
                                <span v-if="Opt.isTeam">会员结果</span>
                                <span v-else>结果</span>
                            </td>
                            <template v-if="Opt.isTeam && Filter.status != 0">
                                <td style="background-color: rgb(190 206 239);" rowspan="2">{{ isTop ? '代理结果' : '交收'}}</td>
                                <td style="background-color: rgb(190 206 239); text-align:center;" colspan="4" v-if="!isTop">代理<span v-if="summary">({{ summary.transfer_username_out || summary.username }})</span></td>
                                <td style="background-color: rgb(190 206 239); text-align:center;" colspan="5" v-if="!isTop">代理收飞</td>
                                <td style="background-color: rgb(190 206 239);" rowspan="2" v-if="isTop">收飞金额</td>
                                <td style="background-color: rgb(190 206 239);" rowspan="2" v-if="isTop">收飞结果</td>
                                <td style="background-color: rgb(190 206 239);" rowspan="2">总结果</td>
                                <td style="background-color: rgb(190 206 239);" rowspan="2" v-if="!isTop && Filter.pid != 1">上级结果</td>
                                <td style="background-color: rgb(190 206 239);" rowspan="2">贡献1</td>
                                <td style="background-color: rgb(190 206 239);" rowspan="2" v-if="Filter.pid != 1">贡献2</td>
                                <td style="background-color: rgb(190 206 239);" rowspan="2">贡献比例</td>
                            </template>
                        </tr>
                        <tr style="text-align:left;" v-if="Opt.isTeam && !isTop && Filter.status != 0" class="hbg-4">
                            <td>退水</td>
                            <td>金额</td>
                            <td>占成</td>
                            <td>结果</td>
                            <td>退水</td>
                            <td>金额</td>
                            <td>输赢</td>
                            <td>占成</td>
                            <td>结果</td>
                        </tr>
                    </thead>
                    <tbody style="text-align:left;" class="zebra">
                        <tr v-for="(item,index) in main._list">
 <template v-if="item.ac_bets_num > 0">
                        
                            <td style="text-align:left;">
                                <a class="link" v-if="item.user_type==0 || !item.user_type" @click="Edit(item,0,2)" style="display:inline-block; min-width:80px;">{{ item.username }}</a>
                                <a class="link" v-if="item.user_type==1" @click="GetByBreadcrumb(item),2" style="display:inline-block; min-width:80px;">{{ item.username }}</a>                                
                            </td>
                            <td style="text-align:left;">
                                <span style="display:inline-block; min-width:40px;">{{ item.real_name }}</span>
                            </td>
                            <td v-if="Opt.isTeam">
                                <span style="display:inline-block; min-width:40px;">{{ item.level }}</span>
                            </td>
                            <td v-if="Filter.status == 0">
                                <a class="link" @click="LevelView(item)">关系</a>
                            </td>
                            <td style="text-align:left;">{{ item.ac_bets_num }}</td>
                            <td style="text-align:left;">
                                <a class="link" v-if="item.user_type==0 || !item.user_type" @click="Edit(item,0,2)">{{ item.ac_bets_amount }}</a>
                                <a class="link" v-if="item.user_type==1" @click="GetByBreadcrumb(item,2)">{{ item.ac_bets_amount }}</a>
                            </td>

                            <!-- <td style="text-align:left;" :class="{'mark':item.ac_valid_amount<0}">{{ item.ac_valid_amount }}</td> -->
                            <td style="text-align:left;" v-if="!Opt.isTeam" :class="{'mark':item.ac_bets_bonus<0}">{{ item.ac_bets_bonus }}</td>
                            <td style="text-align:left;" v-if="!Opt.isTeam" :class="{'mark':item.ac_percent_backwater_amount<0}">{{ item.ac_percent_backwater_amount }}</td>
                            <td style="text-align:left;" :class="{'mark':item.ac_members_result<0}">{{ item.ac_members_result }}</td>
                            <template v-if="Opt.isTeam && Filter.status != 0">
                                <td style="text-align:left;" :class="{'mark':item.ac_js_result<0}">{{ item.ac_js_result }}</td>

                                <template v-if="!isTop">
                                    <td style="text-align:left;" :class="{'mark':item.ac_percent_backwater_amount<0}">{{ Price(item.ac_percent_backwater_amount,{separate:false}) }}</td>
                                    <td style="text-align:left;" :class="{'mark':item.ac_percent_amount<0}">{{ item.ac_percent_amount }}</td>
                                    <td style="text-align:left;" :class="{'mark':item.ac_percent<0}">{{ item.ac_percent }}</td>
                                    <td style="text-align:left;" :class="{'mark':item.ac_percent_result<0}">{{ item.ac_percent_result }}</td>

                                    <td style="text-align:left;" :class="{'mark':item.ac_transfer_backwater<0}">{{ Price(item.ac_transfer_backwater,{separate:false}) }}</td>
                                    <td style="text-align:left;" :class="{'mark':item.ac_transfer_amount<0}"><a class="link" @click="Edit(item,2)">{{ item.ac_transfer_amount }}</a></td>
                                    <td style="text-align:left;" :class="{'mark':item.ac_transfer_wins<0}">{{ item.ac_transfer_wins }}</td>                                    
                                    <td style="text-align:left;" :class="{'mark':item.ac_transfer<0}">{{ item.ac_transfer }}</td>
                                    <td style="text-align:left;" :class="{'mark':item.ac_transfer_result<0}">{{ item.ac_transfer_result }}</td>
                                </template>

                                <td style="text-align:left;" :class="{'mark':item.ac_transfer_amount<0}" v-if="isTop"><a class="link" @click="Edit(item,2)">{{ item.ac_transfer_amount }}</a></td>
                                <td style="text-align:left;" :class="{'mark':item.ac_transfer_result<0}" v-if="isTop">{{ item.ac_transfer_result }}</td>

                                <td style="text-align:left;" :class="{'mark':item.ac_result<0}">{{ item.ac_result }}</td>
                                <td style="text-align:left;" :class="{'mark':item.ac_up_result<0}" v-if="!isTop && Filter.pid != 1">{{ item.ac_up_result}}</td>
                                <td style="text-align:left;" :class="{'mark':item.ac_contribution_one<0}">{{ item.ac_contribution_one }}</td>
                                <td style="text-align:left;" :class="{'mark':item.ac_contribution_two<0}" v-if="Filter.pid != 1">{{ item.ac_contribution_two }}</td>
                                <td style="text-align:center;">
                                    <div class="l-flex-c o-pr-s">
                                        <p class="l-flex-1 c-text-6 c-text-r">{{ item.ac_contribution_proportion }}%</p>
                                    </div>
                                </td>     
                            </template>                       
                    
                        </template>
                        </tr>
                    </tbody>
                    <tfoot>
                        <!-- <tr v-if="main._empty">
                            <td class="empty" colspan="100">
                                <div class="c-text-c o-ptb-h">暂无数据</div>
                            </td>
                        </tr> -->
                        <template v-if="!main._empty && Opt.isTeam">
                            <tr class="subtotal hbg-1" v-if="main._count >= 50">
                                <td style="text-align:left;" colspan="3">小计</td>
                                <td v-if="Filter.status == 0">-</td>

                                <td style="text-align:left;" :class="{'mark':CalcuRow('ac_bets_num')<0}">{{ CalcuRow('ac_bets_num') }}</td>
                                <td style="text-align:left;" :class="{'mark':CalcuRow('ac_bets_amount')<0}">{{ CalcuRow('ac_bets_amount') }}</td>
                                <!-- <td style="text-align:left;" :class="{'mark':CalcuRow('ac_valid_amount')<0}">{{ CalcuRow('ac_valid_amount') }}</td> -->
                                <td style="text-align:left;" :class="{'mark':CalcuRow('ac_members_result')<0}">{{ CalcuRow('ac_members_result') }}</td>
                                <template v-if="Filter.status != 0">
                                    <td style="text-align:left;" :class="{'mark':CalcuRow('ac_js_result')<0}">{{ CalcuRow('ac_js_result') }}</td>

                                    <template v-if="!isTop">
                                        <td style="text-align:left;" :class="{'mark':CalcuRow('ac_percent_backwater_amount')<0}">{{ CalcuRow('ac_percent_backwater_amount') }}</td>
                                        <td style="text-align:left;" :class="{'mark':CalcuRow('ac_percent_amount')<0}">{{ CalcuRow('ac_percent_amount') }}</td>
                                        <td style="text-align:left;">-</td>
                                        <td style="text-align:left;" :class="{'mark':CalcuRow('ac_percent_result')<0}">{{ CalcuRow('ac_percent_result') }}</td>

                                        <td style="text-align:left;" :class="{'mark':CalcuRow('ac_transfer_backwater')<0}">{{ CalcuRow('ac_transfer_backwater') }}</td>
                                        <td style="text-align:left;" :class="{'mark':CalcuRow('ac_transfer_amount')<0}">{{ CalcuRow('ac_transfer_amount') }}</td>
                                        <td style="text-align:left;" :class="{'mark':CalcuRow('ac_transfer_wins')<0}">{{ CalcuRow('ac_transfer_wins') }}</td>                                    
                                        <td style="text-align:left;">-</td>
                                        <td style="text-align:left;" :class="{'mark':CalcuRow('ac_transfer_result')<0}">{{ CalcuRow('ac_transfer_result') }}</td>
                                    </template>

                                    <td style="text-align:left;" :class="{'mark':CalcuRow('ac_transfer_amount')<0}" v-if="isTop">{{ CalcuRow('ac_transfer_amount') }}</td>
                                    <td style="text-align:left;" :class="{'mark':CalcuRow('ac_transfer_result')<0}" v-if="isTop">{{ CalcuRow('ac_transfer_result') }}</td>

                                    <td style="text-align:left;"  :class="{'mark':CalcuRow('ac_result')<0}">{{ CalcuRow('ac_result') }}</td>
                                    <td style="text-align:left;" :class="{'mark':CalcuRow('ac_up_result')<0}" v-if="!isTop && Filter.pid != 1">{{ CalcuRow('ac_up_result') }}</td>
                                    <td style="text-align:left;" :class="{'mark':CalcuRow('ac_contribution_one')<0}">{{ CalcuRow('ac_contribution_one') }}</td>
                                    <td style="text-align:left;" :class="{'mark':CalcuRow('ac_contribution_two')<0}" v-if="Filter.pid != 1">{{ CalcuRow('ac_contribution_two') }}</td>
                                    <td style="text-align:right;"><span class="o-pr-s">-</span></td>
                                </template>
                            </tr>
                            <tr class="total fbg-4" v-if="!main._empty && summary" >
                                <td style="text-align:left;" colspan="3">合计</td>
                                <td v-if="Filter.status == 0">-</td>

                                <td style="text-align:left;" :class="{'mark':summary.ac_bets_num<0}">{{ summary.ac_bets_num }}</td>
                                <td style="text-align:left;" :class="{'mark':summary.ac_bets_amount<0}">{{ summary.ac_bets_amount }}</td>
                                <!-- <td style="text-align:left;" :class="{'mark':summary.ac_valid_amount<0}">{{ summary.ac_valid_amount }}</td> -->
                                <td style="text-align:left;" :class="{'mark':summary.ac_members_result<0}">{{ summary.ac_members_result }}</td>
                                <template v-if="Filter.status != 0">
                                    <td style="text-align:left;" :class="{'mark':summary.ac_js_result<0}">{{ summary.ac_js_result }}</td>

                                    <template v-if="!isTop">
                                        <td style="text-align:left;" :class="{'mark':summary.ac_percent_backwater_amount<0}">{{ summary.ac_percent_backwater_amount }}</td>
                                        <td style="text-align:left;" :class="{'mark':summary.ac_percent_amount<0}">{{ summary.ac_percent_amount }}</td>
                                        <td style="text-align:left;">-</td>
                                        <td style="text-align:left;" :class="{'mark':summary.ac_percent_result<0}">{{ summary.ac_percent_result }}</td>

                                        <td style="text-align:left;" :class="{'mark':summary.ac_transfer_backwater<0}">{{ summary.ac_transfer_backwater }}</td>
                                        <td style="text-align:left;" :class="{'mark':summary.ac_transfer_amount<0}">{{ summary.ac_transfer_amount }}</td>
                                        <td style="text-align:left;" :class="{'mark':summary.ac_transfer_wins<0}">{{ summary.ac_transfer_wins }}</td>
                                        <td style="text-align:left;">-</td>
                                        <td style="text-align:left;" :class="{'mark':summary.ac_transfer_result<0}">{{ summary.ac_transfer_result }}</td>
                                    </template>

                                    <td style="text-align:left;" :class="{'mark':summary.ac_transfer_amount<0}" v-if="isTop">{{ summary.ac_transfer_amount }}</td>
                                    <td style="text-align:left;" :class="{'mark':summary.ac_transfer_result<0}" v-if="isTop">{{ summary.ac_transfer_result }}</td>

                                    <td style="text-align:left;" :class="{'mark':summary.ac_result<0}">{{ summary.ac_result }}</td>
                                    <td style="text-align:left;" by="上级结果" :class="{'mark':summary.ac_up_result<0}" v-if="!isTop && Filter.pid != 1">{{ summary.ac_up_result }}</td>
                                    <td style="text-align:left;" :class="{'mark':summary.ac_contribution_one<0}">{{ summary.ac_contribution_one }}</td>
                                    <td style="text-align:left;" :class="{'mark':summary.ac_contribution_two<0}" v-if="Filter.pid != 1">{{ summary.ac_contribution_two }}</td>
                                    <td style="text-align:right;"><span class="o-pr-s">-</span></td>
                                </template>
                            </tr>
                        </template>
                    </tfoot>
                </table>


                <template v-if="Opt.isTeam && summary && summary.ac_transfer_number_out > 0">
                    <table v-if="Filter.status == 0" class="table" border="0" cellspacing="1" cellpadding="0" style="margin-top:10px;">
                        <colgroup>
                            <col width="130">
                            <col width="190">
                            <col>
                            <col>
                            <col>
                        </colgroup>
                        <thead>
                            <tr style="text-align:left;" class="hbg-1">
                                <td style="background-color: rgb(190 206 239);">帐号</td>
                                <td style="background-color: rgb(190 206 239);">走飞笔数</td>
                                <td style="background-color: rgb(190 206 239);">走飞金额</td>
                                <td style="background-color: rgb(190 206 239);">收飞笔数</td>
                                <td style="background-color: rgb(190 206 239);">收飞金额</td>
                            </tr>
                        </thead>
                        <tbody style="text-align:left;">
                            <tr>
                                <td>{{ summary.username }}</td>
                                <td>{{ summary.ac_transfer_number_out }}</td>
                                <td :class="{'mark':summary.ac_transfer_amount_out<0}"><a class="link" @click="Edit(summary,2)">{{ summary.ac_transfer_amount_out }}</a></td>
                                <td>{{ summary.ac_reward_number }}</td>
                                <td :class="{'mark':summary.ac_reward_amount<0}"><a class="link" @click="Edit(summary,2)">{{ summary.ac_reward_amount }}</a></td>
                            </tr>
                        </tbody>
                    </table>
                    <table v-if="Filter.status == 1" class="table" border="0" cellspacing="1" cellpadding="0" style="margin-top:10px;">
                        <thead>
                            <tr style="text-align:left;" class="hbg-2">
                                <td style="background-color: rgb(190 206 239);">帐号</td>
                                <td style="background-color: rgb(190 206 239);">笔数</td>
                                <td style="background-color: rgb(190 206 239);">走飞金额</td>
                                <td style="background-color: rgb(190 206 239);">走飞退水</td>
                                <td style="background-color: rgb(190 206 239);">走飞结果</td>
                                <td style="background-color: rgb(190 206 239);">总结果合计</td>
                                <td style="background-color: rgb(190 206 239);" v-if="!TargetIsAdmin">上级结果合计</td>
                            </tr>
                        </thead>
                        <tbody style="text-align:left;">
                            <tr>
                                <td>{{ summary.ac_transfer_username_out }}</td>
                                <td :class="{'mark':summary.ac_transfer_number_out<0}">{{ summary.ac_transfer_number_out }}</td>
                                <td :class="{'mark':summary.ac_transfer_amount_out<0}"><a class="link" @click="Edit(summary,1)">{{ summary.ac_transfer_amount_out }}</a></td>
                                <td :class="{'mark':summary.ac_transfer_backwater_out<0}">{{ summary.ac_transfer_backwater_out }}</td>
                                <td :class="{'mark':summary.ac_transfer_result_out<0}">{{ summary.ac_transfer_result_out }}</td>
                                <td :class="{'mark':summary.ac_total_result<0}">{{ summary.ac_total_result }}</td>
                                <td :class="{'mark':summary.ac_total_up_result<0}" v-if="!TargetIsAdmin">{{ summary.ac_total_up_result }}</td>
                            </tr>
                        </tbody>
                    </table>
                </template>
<br/>

<!-- tw -->
<table class="table" border="0" cellspacing="1" cellpadding="0">
    <colgroup>
                            <col width="130">
                            <col>
                            <col v-if="Opt.isTeam">
                            <col v-if="Filter.status == 0 && Opt.isTeam">
                            <col>
                            <col>
                            <col v-if="!Opt.isTeam">
                            <col v-if="!Opt.isTeam">
                            <col>
                        </colgroup>
                    <thead>
                        <tr style="text-align:center;" class="hbg-2">
                            <td style="background-color: rgb(183 231 189); text-align:left;" rowspan="2">帐号（台湾）</td>
                            <td style="background-color: rgb(183 231 189); text-align:left;" rowspan="2">名称</td>
                            <td style="background-color: rgb(183 231 189);" rowspan="2" v-if="Opt.isTeam">级别</td>
                            <td style="background-color: rgb(183 231 189);" rowspan="2" v-if="Filter.status == 0">关系</td>
                            <td style="background-color: rgb(183 231 189);" rowspan="2">下单数</td>
                            <td style="background-color: rgb(183 231 189);" rowspan="2">下注金额</td>
                            <td style="background-color: rgb(183 231 189);" rowspan="2" v-if="!Opt.isTeam">奖金</td>
                            <td style="background-color: rgb(183 231 189);" rowspan="2" v-if="!Opt.isTeam">退水</td>
                            <td style="background-color: rgb(183 231 189);" rowspan="2">
                                <span v-if="Opt.isTeam">会员结果</span>
                                <span v-else>结果</span>
                            </td>
                            <template v-if="Opt.isTeam && Filter.status != 0">
                                <td style="background-color: rgb(183 231 189);" rowspan="2">{{ isTop ? '代理结果' : '交收'}}</td>
                                <td style="background-color: rgb(183 231 189); text-align:center;" colspan="4" v-if="!isTop">代理<span v-if="summary">({{ summary.transfer_username_out || summary.username }})</span></td>
                                <td style="background-color: rgb(183 231 189); text-align:center;" colspan="5" v-if="!isTop">代理收飞</td>
                                <td style="background-color: rgb(183 231 189);" rowspan="2" v-if="isTop">收飞金额</td>
                                <td style="background-color: rgb(183 231 189);" rowspan="2" v-if="isTop">收飞结果</td>
                                <td style="background-color: rgb(183 231 189);" rowspan="2">总结果</td>
                                <td style="background-color: rgb(183 231 189);" rowspan="2" v-if="!isTop && Filter.pid != 1">上级结果</td>
                                <td style="background-color: rgb(183 231 189);" rowspan="2">贡献1</td>
                                <td style="background-color: rgb(183 231 189);" rowspan="2" v-if="Filter.pid != 1">贡献2</td>
                                <td style="background-color: rgb(183 231 189);" rowspan="2">贡献比例</td>
                            </template>
                        </tr>
                        <tr style="text-align:left;" v-if="Opt.isTeam && !isTop && Filter.status != 0" class="hbg-4">
                            <td>退水</td>
                            <td>金额</td>
                            <td>占成</td>
                            <td>结果</td>
                            <td>退水</td>
                            <td>金额</td>
                            <td>输赢</td>
                            <td>占成</td>
                            <td>结果</td>
                        </tr>
                    </thead>
                    <tbody style="text-align:left;" class="zebra">
                        <tr v-for="(item,index) in main._list">
 <template v-if="item.tw_bets_num > 0">
                        
                            <td style="text-align:left;">
                                <a class="link" v-if="item.user_type==0 || !item.user_type" @click="Edit(item,0,3)" style="display:inline-block; min-width:80px;">{{ item.username }}</a>
                                <a class="link" v-if="item.user_type==1" @click="GetByBreadcrumb(item,3)" style="display:inline-block; min-width:80px;">{{ item.username }}</a>                                
                            </td>
                            <td style="text-align:left;">
                                <span style="display:inline-block; min-width:40px;">{{ item.real_name }}</span>
                            </td>
                            <td v-if="Opt.isTeam">
                                <span style="display:inline-block; min-width:40px;">{{ item.level }}</span>
                            </td>
                            <td v-if="Filter.status == 0">
                                <a class="link" @click="LevelView(item)">关系</a>
                            </td>
                            <td style="text-align:left;">{{ item.tw_bets_num }}</td>
                            <td style="text-align:left;">
                                <a class="link" v-if="item.user_type==0 || !item.user_type" @click="Edit(item,0,3)">{{ item.tw_bets_amount }}</a>
                                <a class="link" v-if="item.user_type==1" @click="GetByBreadcrumb(item,3)">{{ item.tw_bets_amount }}</a>
                            </td>
                            <td style="text-align:left;" v-if="!Opt.isTeam" :class="{'mark':item.tw_bets_bonus<0}">{{ item.tw_bets_bonus }}</td>
                            <td style="text-align:left;" v-if="!Opt.isTeam" :class="{'mark':item.tw_percent_backwater_amount<0}">{{ item.tw_percent_backwater_amount }}</td>
                            <td style="text-align:left;" :class="{'mark':item.ac_members_result<0}">{{ item.ac_members_result }}</td>
                            <template v-if="Opt.isTeam && Filter.status != 0">
                                <td style="text-align:left;" :class="{'mark':item.tw_js_result<0}">{{ item.tw_js_result }}</td>

                                <template v-if="!isTop">
                                    <td style="text-align:left;" :class="{'mark':item.tw_percent_backwater_amount<0}">{{ Price(item.tw_percent_backwater_amount,{separate:false}) }}</td>
                                    <td style="text-align:left;" :class="{'mark':item.tw_percent_amount<0}">{{ item.tw_percent_amount }}</td>
                                    <td style="text-align:left;" :class="{'mark':item.tw_percent<0}">{{ item.tw_percent }}</td>
                                    <td style="text-align:left;" :class="{'mark':item.tw_percent_result<0}">{{ item.tw_percent_result }}</td>

                                    <td style="text-align:left;" :class="{'mark':item.tw_transfer_backwater<0}">{{ Price(item.tw_transfer_backwater,{separate:false}) }}</td>
                                    <td style="text-align:left;" :class="{'mark':item.tw_transfer_amount<0}"><a class="link" @click="Edit(item,2)">{{ item.tw_transfer_amount }}</a></td>
                                    <td style="text-align:left;" :class="{'mark':item.tw_transfer_wins<0}">{{ item.tw_transfer_wins }}</td>                                    
                                    <td style="text-align:left;" :class="{'mark':item.tw_transfer<0}">{{ item.tw_transfer }}</td>
                                    <td style="text-align:left;" :class="{'mark':item.tw_transfer_result<0}">{{ item.tw_transfer_result }}</td>
                                </template>

                                <td style="text-align:left;" :class="{'mark':item.tw_transfer_amount<0}" v-if="isTop"><a class="link" @click="Edit(item,2)">{{ item.tw_transfer_amount }}</a></td>
                                <td style="text-align:left;" :class="{'mark':item.tw_transfer_result<0}" v-if="isTop">{{ item.tw_transfer_result }}</td>

                                <td style="text-align:left;" :class="{'mark':item.tw_result<0}">{{ item.tw_result }}</td>
                                <td style="text-align:left;" :class="{'mark':item.tw_up_result<0}" v-if="!isTop && Filter.pid != 1">{{ item.tw_up_result}}</td>
                                <td style="text-align:left;" :class="{'mark':item.tw_contribution_one<0}">{{ item.tw_contribution_one }}</td>
                                <td style="text-align:left;" :class="{'mark':item.tw_contribution_two<0}" v-if="Filter.pid != 1">{{ item.tw_contribution_two }}</td>
                                <td style="text-align:center;">
                                    <div class="l-flex-c o-pr-s">
                                        <p class="l-flex-1 c-text-6 c-text-r">{{ item.tw_contribution_proportion }}%</p>
                                    </div>
                                </td>     
                            </template>                       
                    
                        </template>
                        </tr>
                    </tbody>
                    <tfoot>
                        <template v-if="!main._empty && Opt.isTeam">
                            <tr class="subtotal hbg-1" v-if="main._count >= 50">
                                <td style="text-align:left;" colspan="3">小计</td>
                                <td v-if="Filter.status == 0">-</td>

                                <td style="text-align:left;" :class="{'mark':CalcuRow('tw_bets_num')<0}">{{ CalcuRow('tw_bets_num') }}</td>
                                <td style="text-align:left;" :class="{'mark':CalcuRow('tw_bets_amount')<0}">{{ CalcuRow('tw_bets_amount') }}</td>
                                <td style="text-align:left;" :class="{'mark':CalcuRow('tw_members_result')<0}">{{ CalcuRow('tw_members_result') }}</td>
                                <template v-if="Filter.status != 0">
                                    <td style="text-align:left;" :class="{'mark':CalcuRow('tw_js_result')<0}">{{ CalcuRow('tw_js_result') }}</td>

                                    <template v-if="!isTop">
                                        <td style="text-align:left;" :class="{'mark':CalcuRow('tw_percent_backwater_amount')<0}">{{ CalcuRow('tw_percent_backwater_amount') }}</td>
                                        <td style="text-align:left;" :class="{'mark':CalcuRow('tw_percent_amount')<0}">{{ CalcuRow('tw_percent_amount') }}</td>
                                        <td style="text-align:left;">-</td>
                                        <td style="text-align:left;" :class="{'mark':CalcuRow('tw_percent_result')<0}">{{ CalcuRow('tw_percent_result') }}</td>

                                        <td style="text-align:left;" :class="{'mark':CalcuRow('tw_transfer_backwater')<0}">{{ CalcuRow('tw_transfer_backwater') }}</td>
                                        <td style="text-align:left;" :class="{'mark':CalcuRow('tw_transfer_amount')<0}">{{ CalcuRow('tw_transfer_amount') }}</td>
                                        <td style="text-align:left;" :class="{'mark':CalcuRow('tw_transfer_wins')<0}">{{ CalcuRow('tw_transfer_wins') }}</td>                                    
                                        <td style="text-align:left;">-</td>
                                        <td style="text-align:left;" :class="{'mark':CalcuRow('tw_transfer_result')<0}">{{ CalcuRow('tw_transfer_result') }}</td>
                                    </template>

                                    <td style="text-align:left;" :class="{'mark':CalcuRow('tw_transfer_amount')<0}" v-if="isTop">{{ CalcuRow('tw_transfer_amount') }}</td>
                                    <td style="text-align:left;" :class="{'mark':CalcuRow('tw_transfer_result')<0}" v-if="isTop">{{ CalcuRow('tw_transfer_result') }}</td>

                                    <td style="text-align:left;"  :class="{'mark':CalcuRow('tw_result')<0}">{{ CalcuRow('tw_result') }}</td>
                                    <td style="text-align:left;" :class="{'mark':CalcuRow('tw_up_result')<0}" v-if="!isTop && Filter.pid != 1">{{ CalcuRow('tw_up_result') }}</td>
                                    <td style="text-align:left;" :class="{'mark':CalcuRow('tw_contribution_one')<0}">{{ CalcuRow('tw_contribution_one') }}</td>
                                    <td style="text-align:left;" :class="{'mark':CalcuRow('tw_contribution_two')<0}" v-if="Filter.pid != 1">{{ CalcuRow('tw_contribution_two') }}</td>
                                    <td style="text-align:right;"><span class="o-pr-s">-</span></td>
                                </template>
                            </tr>
                            <tr class="total fbg-4" v-if="!main._empty && summary" >
                                <td style="text-align:left;" colspan="3">合计</td>
                                <td v-if="Filter.status == 0">-</td>

                                <td style="text-align:left;" :class="{'mark':summary.tw_bets_num<0}">{{ summary.tw_bets_num }}</td>
                                <td style="text-align:left;" :class="{'mark':summary.tw_bets_amount<0}">{{ summary.tw_bets_amount }}</td>
                                <td style="text-align:left;" :class="{'mark':summary.tw_members_result<0}">{{ summary.tw_members_result }}</td>
                                <template v-if="Filter.status != 0">
                                    <td style="text-align:left;" :class="{'mark':summary.tw_js_result<0}">{{ summary.tw_js_result }}</td>

                                    <template v-if="!isTop">
                                        <td style="text-align:left;" :class="{'mark':summary.tw_percent_backwater_amount<0}">{{ summary.tw_percent_backwater_amount }}</td>
                                        <td style="text-align:left;" :class="{'mark':summary.tw_percent_amount<0}">{{ summary.tw_percent_amount }}</td>
                                        <td style="text-align:left;">-</td>
                                        <td style="text-align:left;" :class="{'mark':summary.tw_percent_result<0}">{{ summary.tw_percent_result }}</td>

                                        <td style="text-align:left;" :class="{'mark':summary.tw_transfer_backwater<0}">{{ summary.tw_transfer_backwater }}</td>
                                        <td style="text-align:left;" :class="{'mark':summary.tw_transfer_amount<0}">{{ summary.tw_transfer_amount }}</td>
                                        <td style="text-align:left;" :class="{'mark':summary.tw_transfer_wins<0}">{{ summary.tw_transfer_wins }}</td>
                                        <td style="text-align:left;">-</td>
                                        <td style="text-align:left;" :class="{'mark':summary.tw_transfer_result<0}">{{ summary.tw_transfer_result }}</td>
                                    </template>

                                    <td style="text-align:left;" :class="{'mark':summary.tw_transfer_amount<0}" v-if="isTop">{{ summary.tw_transfer_amount }}</td>
                                    <td style="text-align:left;" :class="{'mark':summary.tw_transfer_result<0}" v-if="isTop">{{ summary.tw_transfer_result }}</td>

                                    <td style="text-align:left;" :class="{'mark':summary.tw_result<0}">{{ summary.tw_result }}</td>
                                    <td style="text-align:left;" by="上级结果" :class="{'mark':summary.tw_up_result<0}" v-if="!isTop && Filter.pid != 1">{{ summary.tw_up_result }}</td>
                                    <td style="text-align:left;" :class="{'mark':summary.tw_contribution_one<0}">{{ summary.tw_contribution_one }}</td>
                                    <td style="text-align:left;" :class="{'mark':summary.tw_contribution_two<0}" v-if="Filter.pid != 1">{{ summary.tw_contribution_two }}</td>
                                    <td style="text-align:right;"><span class="o-pr-s">-</span></td>
                                </template>
                            </tr>
                        </template>
                    </tfoot>
                </table>


                <template v-if="Opt.isTeam && summary && summary.tw_transfer_number_out > 0">
                    <table v-if="Filter.status == 0" class="table" border="0" cellspacing="1" cellpadding="0" style="margin-top:10px;">
                        <colgroup>
                            <col width="130">
                            <col width="190">
                            <col>
                            <col>
                            <col>
                        </colgroup>
                        <thead>
                            <tr style="text-align:left;" class="hbg-1">
                                <td style="background-color: rgb(183 231 189);">帐号</td>
                                <td style="background-color: rgb(183 231 189);">走飞笔数</td>
                                <td style="background-color: rgb(183 231 189);">走飞金额</td>
                                <td style="background-color: rgb(183 231 189);">收飞笔数</td>
                                <td style="background-color: rgb(183 231 189);">收飞金额</td>
                            </tr>
                        </thead>
                        <tbody style="text-align:left;">
                            <tr>
                                <td>{{ summary.username }}</td>
                                <td>{{ summary.tw_transfer_number_out }}</td>
                                <td :class="{'mark':summary.tw_transfer_amount_out<0}"><a class="link" @click="Edit(summary,3)">{{ summary.tw_transfer_amount_out }}</a></td>
                                <td>{{ summary.tw_reward_number }}</td>
                                <td :class="{'mark':summary.tw_reward_amount<0}"><a class="link" @click="Edit(summary,3)">{{ summary.tw_reward_amount }}</a></td>
                            </tr>
                        </tbody>
                    </table>
                    <table v-if="Filter.status == 1" class="table" border="0" cellspacing="1" cellpadding="0" style="margin-top:10px;">
                        <thead>
                            <tr style="text-align:left;" class="hbg-2">
                                <td style="background-color: rgb(183 231 189);">帐号</td>
                                <td style="background-color: rgb(183 231 189);">笔数</td>
                                <td style="background-color: rgb(183 231 189);">走飞金额</td>
                                <td style="background-color: rgb(183 231 189);">走飞退水</td>
                                <td style="background-color: rgb(183 231 189);">走飞结果</td>
                                <td style="background-color: rgb(183 231 189);">总结果合计</td>
                                <td style="background-color: rgb(183 231 189);" v-if="!TargetIsAdmin">上级结果合计</td>
                            </tr>
                        </thead>
                        <tbody style="text-align:left;">
                            <tr>
                                <td>{{ summary.tw_transfer_username_out }}</td>
                                <td :class="{'mark':summary.tw_transfer_number_out<0}">{{ summary.tw_transfer_number_out }}</td>
                                <td :class="{'mark':summary.tw_transfer_amount_out<0}"><a class="link" @click="Edit(summary,1)">{{ summary.tw_transfer_amount_out }}</a></td>
                                <td :class="{'mark':summary.tw_transfer_backwater_out<0}">{{ summary.tw_transfer_backwater_out }}</td>
                                <td :class="{'mark':summary.tw_transfer_result_out<0}">{{ summary.tw_transfer_result_out }}</td>
                                <td :class="{'mark':summary.tw_total_result<0}">{{ summary.tw_total_result }}</td>
                                <td :class="{'mark':summary.tw_total_up_result<0}" v-if="!TargetIsAdmin">{{ summary.tw_total_up_result }}</td>
                            </tr>
                        </tbody>
                    </table>
                </template>
<br/>

                    <!-- xjp -->

<table class="table" border="0" cellspacing="1" cellpadding="0">
    <colgroup>
                            <col width="130">
                            <col>
                            <col v-if="Opt.isTeam">
                            <col v-if="Filter.status == 0 && Opt.isTeam">
                            <col>
                            <col>
                            <col v-if="!Opt.isTeam">
                            <col v-if="!Opt.isTeam">
                            <col>
                        </colgroup>
                    <thead>
                        <tr style="text-align:center;" class="hbg-2">
                            <td style="background-color: rgb(245 208 220); text-align:left;" rowspan="2">帐号（新加坡）</td>
                            <td style="background-color: rgb(245 208 220); text-align:left;" rowspan="2">名称</td>
                            <td style="background-color: rgb(245 208 220);" rowspan="2" v-if="Opt.isTeam">级别</td>
                            <td style="background-color: rgb(245 208 220);" rowspan="2" v-if="Filter.status == 0">关系</td>
                            <td style="background-color: rgb(245 208 220);" rowspan="2">下单数</td>
                            <td style="background-color: rgb(245 208 220);" rowspan="2">下注金额</td>
                            <!-- <td style="background-color: rgb(245 208 220);" rowspan="2">有效金额</td> -->
                            <td style="background-color: rgb(245 208 220);" rowspan="2" v-if="!Opt.isTeam">奖金</td>
                            <td style="background-color: rgb(245 208 220);" rowspan="2" v-if="!Opt.isTeam">退水</td>
                            <td style="background-color: rgb(245 208 220);" rowspan="2">
                                <span v-if="Opt.isTeam">会员结果</span>
                                <span v-else>结果</span>
                            </td>
                            <template v-if="Opt.isTeam && Filter.status != 0">
                                <td style="background-color: rgb(245 208 220);" rowspan="2">{{ isTop ? '代理结果' : '交收'}}</td>
                                <td style="background-color: rgb(245 208 220); text-align:center;" colspan="4" v-if="!isTop">代理<span v-if="summary">({{ summary.transfer_username_out || summary.username }})</span></td>
                                <td style="background-color: rgb(245 208 220); text-align:center;" colspan="5" v-if="!isTop">代理收飞</td>
                                <td style="background-color: rgb(245 208 220);" rowspan="2" v-if="isTop">收飞金额</td>
                                <td style="background-color: rgb(245 208 220);" rowspan="2" v-if="isTop">收飞结果</td>
                                <td style="background-color: rgb(245 208 220);" rowspan="2">总结果</td>
                                <td style="background-color: rgb(245 208 220);" rowspan="2" v-if="!isTop && Filter.pid != 1">上级结果</td>
                                <td style="background-color: rgb(245 208 220);" rowspan="2">贡献1</td>
                                <td style="background-color: rgb(245 208 220);" rowspan="2" v-if="Filter.pid != 1">贡献2</td>
                                <td style="background-color: rgb(245 208 220);" rowspan="2">贡献比例</td>
                            </template>
                        </tr>
                        <tr style="text-align:left;" v-if="Opt.isTeam && !isTop && Filter.status != 0" class="hbg-4">
                            <td>退水</td>
                            <td>金额</td>
                            <td>占成</td>
                            <td>结果</td>
                            <td>退水</td>
                            <td>金额</td>
                            <td>输赢</td>
                            <td>占成</td>
                            <td>结果</td>
                        </tr>
                    </thead>
                    <tbody style="text-align:left;" class="zebra">
                        <tr v-for="(item,index) in main._list">
 <template v-if="item.xjp_bets_num > 0">
                        
                            <td style="text-align:left;">
                                <a class="link" v-if="item.user_type==0 || !item.user_type" @click="Edit(item,0,4)" style="display:inline-block; min-width:80px;">{{ item.username }}</a>
                                <a class="link" v-if="item.user_type==1" @click="GetByBreadcrumb(item,4)" style="display:inline-block; min-width:80px;">{{ item.username }}</a>                                
                            </td>
                            <td style="text-align:left;">
                                <span style="display:inline-block; min-width:40px;">{{ item.real_name }}</span>
                            </td>
                            <td v-if="Opt.isTeam">
                                <span style="display:inline-block; min-width:40px;">{{ item.level }}</span>
                            </td>
                            <td v-if="Filter.status == 0">
                                <a class="link" @click="LevelView(item)">关系</a>
                            </td>
                            <td style="text-align:left;">{{ item.xjp_bets_num }}</td>
                            <td style="text-align:left;">
                                <a class="link" v-if="item.user_type==0 || !item.user_type" @click="Edit(item,0,4)">{{ item.xjp_bets_amount }}</a>
                                <a class="link" v-if="item.user_type==1" @click="GetByBreadcrumb(item,4)">{{ item.xjp_bets_amount }}</a>
                            </td>

                            <!-- <td style="text-align:left;" :class="{'mark':item.xjp_valid_amount<0}">{{ item.xjp_valid_amount }}</td> -->
                            <td style="text-align:left;" v-if="!Opt.isTeam" :class="{'mark':item.xjp_bets_bonus<0}">{{ item.xjp_bets_bonus }}</td>
                            <td style="text-align:left;" v-if="!Opt.isTeam" :class="{'mark':item.xjp_percent_backwater_amount<0}">{{ item.xjp_percent_backwater_amount }}</td>
                            <td style="text-align:left;" :class="{'mark':item.xjp_members_result<0}">{{ item.xjp_members_result }}</td>
                            <template v-if="Opt.isTeam && Filter.status != 0">
                                <td style="text-align:left;" :class="{'mark':item.xjp_js_result<0}">{{ item.xjp_js_result }}</td>

                                <template v-if="!isTop">
                                    <td style="text-align:left;" :class="{'mark':item.xjp_percent_backwater_amount<0}">{{ Price(item.xjp_percent_backwater_amount,{separate:false}) }}</td>
                                    <td style="text-align:left;" :class="{'mark':item.xjp_percent_amount<0}">{{ item.xjp_percent_amount }}</td>
                                    <td style="text-align:left;" :class="{'mark':item.xjp_percent<0}">{{ item.xjp_percent }}</td>
                                    <td style="text-align:left;" :class="{'mark':item.xjp_percent_result<0}">{{ item.xjp_percent_result }}</td>

                                    <td style="text-align:left;" :class="{'mark':item.xjp_transfer_backwater<0}">{{ Price(item.xjp_transfer_backwater,{separate:false}) }}</td>
                                    <td style="text-align:left;" :class="{'mark':item.xjp_transfer_amount<0}"><a class="link" @click="Edit(item,2)">{{ item.xjp_transfer_amount }}</a></td>
                                    <td style="text-align:left;" :class="{'mark':item.xjp_transfer_wins<0}">{{ item.xjp_transfer_wins }}</td>                                    
                                    <td style="text-align:left;" :class="{'mark':item.xjp_transfer<0}">{{ item.xjp_transfer }}</td>
                                    <td style="text-align:left;" :class="{'mark':item.xjp_transfer_result<0}">{{ item.xjp_transfer_result }}</td>
                                </template>

                                <td style="text-align:left;" :class="{'mark':item.xjp_transfer_amount<0}" v-if="isTop"><a class="link" @click="Edit(item,2)">{{ item.xjp_transfer_amount }}</a></td>
                                <td style="text-align:left;" :class="{'mark':item.xjp_transfer_result<0}" v-if="isTop">{{ item.xjp_transfer_result }}</td>

                                <td style="text-align:left;" :class="{'mark':item.xjp_result<0}">{{ item.xjp_result }}</td>
                                <td style="text-align:left;" :class="{'mark':item.xjp_up_result<0}" v-if="!isTop && Filter.pid != 1">{{ item.xjp_up_result}}</td>
                                <td style="text-align:left;" :class="{'mark':item.xjp_contribution_one<0}">{{ item.xjp_contribution_one }}</td>
                                <td style="text-align:left;" :class="{'mark':item.xjp_contribution_two<0}" v-if="Filter.pid != 1">{{ item.xjp_contribution_two }}</td>
                                <td style="text-align:center;">
                                    <div class="l-flex-c o-pr-s">
                                        <p class="l-flex-1 c-text-6 c-text-r">{{ item.xjp_contribution_proportion }}%</p>
                                    </div>
                                </td>     
                            </template>                       
                    
                        </template>
                        </tr>
                    </tbody>
                    <tfoot>
                        <!-- <tr v-if="main._empty">
                            <td class="empty" colspan="100">
                                <div class="c-text-c o-ptb-h">暂无数据</div>
                            </td>
                        </tr> -->
                        <template v-if="!main._empty && Opt.isTeam">
                            <tr class="subtotal hbg-1" v-if="main._count >= 50">
                                <td style="text-align:left;" colspan="3">小计</td>
                                <td v-if="Filter.status == 0">-</td>

                                <td style="text-align:left;" :class="{'mark':CalcuRow('xjp_bets_num')<0}">{{ CalcuRow('xjp_bets_num') }}</td>
                                <td style="text-align:left;" :class="{'mark':CalcuRow('xjp_bets_amount')<0}">{{ CalcuRow('xjp_bets_amount') }}</td>
                                <!-- <td style="text-align:left;" :class="{'mark':CalcuRow('xjp_valid_amount')<0}">{{ CalcuRow('xjp_valid_amount') }}</td> -->
                                <td style="text-align:left;" :class="{'mark':CalcuRow('xjp_members_result')<0}">{{ CalcuRow('xjp_members_result') }}</td>
                                <template v-if="Filter.status != 0">
                                    <td style="text-align:left;" :class="{'mark':CalcuRow('xjp_js_result')<0}">{{ CalcuRow('xjp_js_result') }}</td>

                                    <template v-if="!isTop">
                                        <td style="text-align:left;" :class="{'mark':CalcuRow('xjp_percent_backwater_amount')<0}">{{ CalcuRow('xjp_percent_backwater_amount') }}</td>
                                        <td style="text-align:left;" :class="{'mark':CalcuRow('xjp_percent_amount')<0}">{{ CalcuRow('xjp_percent_amount') }}</td>
                                        <td style="text-align:left;">-</td>
                                        <td style="text-align:left;" :class="{'mark':CalcuRow('xjp_percent_result')<0}">{{ CalcuRow('xjp_percent_result') }}</td>

                                        <td style="text-align:left;" :class="{'mark':CalcuRow('xjp_transfer_backwater')<0}">{{ CalcuRow('xjp_transfer_backwater') }}</td>
                                        <td style="text-align:left;" :class="{'mark':CalcuRow('xjp_transfer_amount')<0}">{{ CalcuRow('xjp_transfer_amount') }}</td>
                                        <td style="text-align:left;" :class="{'mark':CalcuRow('xjp_transfer_wins')<0}">{{ CalcuRow('xjp_transfer_wins') }}</td>                                    
                                        <td style="text-align:left;">-</td>
                                        <td style="text-align:left;" :class="{'mark':CalcuRow('xjp_transfer_result')<0}">{{ CalcuRow('xjp_transfer_result') }}</td>
                                    </template>

                                    <td style="text-align:left;" :class="{'mark':CalcuRow('xjp_transfer_amount')<0}" v-if="isTop">{{ CalcuRow('xjp_transfer_amount') }}</td>
                                    <td style="text-align:left;" :class="{'mark':CalcuRow('xjp_transfer_result')<0}" v-if="isTop">{{ CalcuRow('xjp_transfer_result') }}</td>

                                    <td style="text-align:left;"  :class="{'mark':CalcuRow('xjp_result')<0}">{{ CalcuRow('xjp_result') }}</td>
                                    <td style="text-align:left;" :class="{'mark':CalcuRow('xjp_up_result')<0}" v-if="!isTop && Filter.pid != 1">{{ CalcuRow('xjp_up_result') }}</td>
                                    <td style="text-align:left;" :class="{'mark':CalcuRow('xjp_contribution_one')<0}">{{ CalcuRow('xjp_contribution_one') }}</td>
                                    <td style="text-align:left;" :class="{'mark':CalcuRow('xjp_contribution_two')<0}" v-if="Filter.pid != 1">{{ CalcuRow('xjp_contribution_two') }}</td>
                                    <td style="text-align:right;"><span class="o-pr-s">-</span></td>
                                </template>
                            </tr>
                            <tr class="total fbg-4" v-if="!main._empty && summary" >
                                <td style="text-align:left;" colspan="3">合计</td>
                                <td v-if="Filter.status == 0">-</td>

                                <td style="text-align:left;" :class="{'mark':summary.xjp_bets_num<0}">{{ summary.xjp_bets_num }}</td>
                                <td style="text-align:left;" :class="{'mark':summary.xjp_bets_amount<0}">{{ summary.xjp_bets_amount }}</td>
                                <!-- <td style="text-align:left;" :class="{'mark':summary.xjp_valid_amount<0}">{{ summary.xjp_valid_amount }}</td> -->
                                <td style="text-align:left;" :class="{'mark':summary.xjp_members_result<0}">{{ summary.xjp_members_result }}</td>
                                <template v-if="Filter.status != 0">
                                    <td style="text-align:left;" :class="{'mark':summary.xjp_js_result<0}">{{ summary.xjp_js_result }}</td>

                                    <template v-if="!isTop">
                                        <td style="text-align:left;" :class="{'mark':summary.xjp_percent_backwater_amount<0}">{{ summary.xjp_percent_backwater_amount }}</td>
                                        <td style="text-align:left;" :class="{'mark':summary.xjp_percent_amount<0}">{{ summary.xjp_percent_amount }}</td>
                                        <td style="text-align:left;">-</td>
                                        <td style="text-align:left;" :class="{'mark':summary.xjp_percent_result<0}">{{ summary.xjp_percent_result }}</td>

                                        <td style="text-align:left;" :class="{'mark':summary.xjp_transfer_backwater<0}">{{ summary.xjp_transfer_backwater }}</td>
                                        <td style="text-align:left;" :class="{'mark':summary.xjp_transfer_amount<0}">{{ summary.xjp_transfer_amount }}</td>
                                        <td style="text-align:left;" :class="{'mark':summary.xjp_transfer_wins<0}">{{ summary.xjp_transfer_wins }}</td>
                                        <td style="text-align:left;">-</td>
                                        <td style="text-align:left;" :class="{'mark':summary.xjp_transfer_result<0}">{{ summary.xjp_transfer_result }}</td>
                                    </template>

                                    <td style="text-align:left;" :class="{'mark':summary.xjp_transfer_amount<0}" v-if="isTop">{{ summary.xjp_transfer_amount }}</td>
                                    <td style="text-align:left;" :class="{'mark':summary.xjp_transfer_result<0}" v-if="isTop">{{ summary.xjp_transfer_result }}</td>

                                    <td style="text-align:left;" :class="{'mark':summary.xjp_result<0}">{{ summary.xjp_result }}</td>
                                    <td style="text-align:left;" by="上级结果" :class="{'mark':summary.xjp_up_result<0}" v-if="!isTop && Filter.pid != 1">{{ summary.xjp_up_result }}</td>
                                    <td style="text-align:left;" :class="{'mark':summary.xjp_contribution_one<0}">{{ summary.xjp_contribution_one }}</td>
                                    <td style="text-align:left;" :class="{'mark':summary.xjp_contribution_two<0}" v-if="Filter.pid != 1">{{ summary.xjp_contribution_two }}</td>
                                    <td style="text-align:right;"><span class="o-pr-s">-</span></td>
                                </template>
                            </tr>
                        </template>
                    </tfoot>
                </table>


                <template v-if="Opt.isTeam && summary && summary.xjp_transfer_number_out > 0">
                    <table v-if="Filter.status == 0" class="table" border="0" cellspacing="1" cellpadding="0" style="margin-top:10px;">
                        <colgroup>
                            <col width="130">
                            <col width="190">
                            <col>
                            <col>
                            <col>
                        </colgroup>
                        <thead>
                            <tr style="text-align:left;" class="hbg-1">
                                <td style="background-color: rgb(245 208 220);">帐号</td>
                                <td style="background-color: rgb(245 208 220);">走飞笔数</td>
                                <td style="background-color: rgb(245 208 220);">走飞金额</td>
                                <td style="background-color: rgb(245 208 220);">收飞笔数</td>
                                <td style="background-color: rgb(245 208 220);">收飞金额</td>
                            </tr>
                        </thead>
                        <tbody style="text-align:left;">
                            <tr>
                                <td>{{ summary.username }}</td>
                                <td>{{ summary.xjp_transfer_number_out }}</td>
                                <td :class="{'mark':summary.xjp_transfer_amount_out<0}"><a class="link" @click="Edit(summary,4)">{{ summary.xjp_transfer_amount_out }}</a></td>
                                <td>{{ summary.xjp_reward_number }}</td>
                                <td :class="{'mark':summary.xjp_reward_amount<0}"><a class="link" @click="Edit(summary,4)">{{ summary.xjp_reward_amount }}</a></td>
                            </tr>
                        </tbody>
                    </table>
                    <table v-if="Filter.status == 1" class="table" border="0" cellspacing="1" cellpadding="0" style="margin-top:10px;">
                        <thead>
                            <tr style="text-align:left;" class="hbg-2">
                                <td style="background-color: rgb(245 208 220);">帐号</td>
                                <td style="background-color: rgb(245 208 220);">笔数</td>
                                <td style="background-color: rgb(245 208 220);">走飞金额</td>
                                <td style="background-color: rgb(245 208 220);">走飞退水</td>
                                <td style="background-color: rgb(245 208 220);">走飞结果</td>
                                <td style="background-color: rgb(245 208 220);">总结果合计</td>
                                <td style="background-color: rgb(245 208 220);" v-if="!TargetIsAdmin">上级结果合计</td>
                            </tr>
                        </thead>
                        <tbody style="text-align:left;">
                            <tr>
                                <td>{{ summary.xjp_transfer_username_out }}</td>
                                <td :class="{'mark':summary.xjp_transfer_number_out<0}">{{ summary.xjp_transfer_number_out }}</td>
                                <td :class="{'mark':summary.xjp_transfer_amount_out<0}"><a class="link" @click="Edit(summary,1)">{{ summary.xjp_transfer_amount_out }}</a></td>
                                <td :class="{'mark':summary.xjp_transfer_backwater_out<0}">{{ summary.xjp_transfer_backwater_out }}</td>
                                <td :class="{'mark':summary.xjp_transfer_result_out<0}">{{ summary.xjp_transfer_result_out }}</td>
                                <td :class="{'mark':summary.xjp_total_result<0}">{{ summary.xjp_total_result }}</td>
                                <td :class="{'mark':summary.xjp_total_up_result<0}" v-if="!TargetIsAdmin">{{ summary.xjp_total_up_result }}</td>
                            </tr>
                        </tbody>
                    </table>
                </template>
<br/>

<!-- zh -->
                <table class="table" border="0" cellspacing="1" cellpadding="0">
                    <colgroup>
                            <col width="130">
                            <col>
                            <col v-if="Opt.isTeam">
                            <col v-if="Filter.status == 0 && Opt.isTeam">
                            <col>
                            <col>
                            <col v-if="!Opt.isTeam">
                            <col v-if="!Opt.isTeam">
                            <col>
                        </colgroup>
                    <thead>
                        <tr style="text-align:center;" class="hbg-2">
                            <td style="text-align:left;" rowspan="2">帐号(综合)</td>
                            <td style="text-align:left;" rowspan="2">名称</td>
                            <td rowspan="2" v-if="Opt.isTeam">级别</td>
                            <td rowspan="2" v-if="Filter.status == 0">关系</td>
                            <td rowspan="2">下单数</td>
                            <td rowspan="2">下注金额</td>
                            <!-- <td rowspan="2">有效金额</td> -->
                            <td rowspan="2" v-if="!Opt.isTeam">奖金</td>
                            <td rowspan="2" v-if="!Opt.isTeam">退水</td>
                            <td rowspan="2">
                                <span v-if="Opt.isTeam">会员结果</span>
                                <span v-else>结果</span>
                            </td>
                            <template v-if="Opt.isTeam && Filter.status != 0">
                                <td rowspan="2">{{ isTop ? '代理结果' : '交收'}}</td>
                                <td style="text-align:center;" colspan="4" v-if="!isTop">代理<span v-if="summary">({{ summary.transfer_username_out || summary.username }})</span></td>
                                <td style="text-align:center;" colspan="5" v-if="!isTop">代理收飞</td>
                                <td rowspan="2" v-if="isTop">收飞金额</td>
                                <td rowspan="2" v-if="isTop">收飞结果</td>
                                <td rowspan="2">总结果</td>
                                <td rowspan="2" v-if="!isTop && Filter.pid != 1">上级结果</td>
                                <td rowspan="2">贡献1</td>
                                <td rowspan="2" v-if="Filter.pid != 1">贡献2</td>
                                <td rowspan="2">贡献比例</td>
                            </template>
                        </tr>
                        <tr style="text-align:left;" v-if="Opt.isTeam && !isTop && Filter.status != 0" class="hbg-4">
                            <td>退水</td>
                            <td>金额</td>
                            <td>占成</td>
                            <td>结果</td>
                            <td>退水</td>
                            <td>金额</td>
                            <td>输赢</td>
                            <td>占成</td>
                            <td>结果</td>
                        </tr>
                    </thead>
                    <tbody style="text-align:left;" class="zebra">
                        <tr v-for="(item,index) in main._list">
                            <td style="text-align:left;">
                                <a class="link" v-if="item.user_type==0 || !item.user_type" @click="Edit(item,0)" style="display:inline-block; min-width:80px;">{{ item.username }}</a>
                                <a class="link" v-if="item.user_type==1" @click="GetByBreadcrumb(item,0)" style="display:inline-block; min-width:80px;">{{ item.username }}</a>                                
                            </td>
                            <td style="text-align:left;">
                                <span style="display:inline-block; min-width:40px;">{{ item.real_name }}</span>
                            </td>
                            <td v-if="Opt.isTeam">
                                <span style="display:inline-block; min-width:40px;">{{ item.level }}</span>
                            </td>
                            <td v-if="Filter.status == 0">
                                <a class="link" @click="LevelView(item)">关系</a>
                            </td>
                            <td style="text-align:left;">{{ item.bets_num }}</td>
                            <td style="text-align:left;">
                                <a class="link" v-if="item.user_type==0 || !item.user_type" @click="Edit(item,0)">{{ item.bets_amount }}</a>
                                <a class="link" v-if="item.user_type==1" @click="GetByBreadcrumb(item,0)">{{ item.bets_amount }}</a>
                            </td>

                            <!-- <td style="text-align:left;" :class="{'mark':item.valid_amount<0}">{{ item.valid_amount }}</td> -->
                            <td style="text-align:left;" v-if="!Opt.isTeam" :class="{'mark':item.bets_bonus<0}">{{ item.bets_bonus }}</td>
                            <td style="text-align:left;" v-if="!Opt.isTeam":class="{'mark':item.percent_backwater_amount<0}">{{ item.percent_backwater_amount }}</td>
                            <td style="text-align:left;" :class="{'mark':item.members_result<0}">{{ item.members_result }}</td>
                            <template v-if="Opt.isTeam && Filter.status != 0">
                                <td style="text-align:left;" :class="{'mark':item.js_result<0}">{{ item.js_result }}</td>

                                <template v-if="!isTop">
                                    <td style="text-align:left;" :class="{'mark':item.percent_backwater_amount<0}">{{ Price(item.percent_backwater_amount,{separate:false}) }}</td>
                                    <td style="text-align:left;" :class="{'mark':item.percent_amount<0}">{{ item.percent_amount }}</td>
                                    <td style="text-align:left;" :class="{'mark':item.percent<0}">{{ item.percent }}</td>
                                    <td style="text-align:left;" :class="{'mark':item.percent_result<0}">{{ item.percent_result }}</td>

                                    <td style="text-align:left;" :class="{'mark':item.transfer_backwater<0}">{{ Price(item.transfer_backwater,{separate:false}) }}</td>
                                    <td style="text-align:left;" :class="{'mark':item.transfer_amount<0}"><a class="link" @click="Edit(item,2)">{{ item.transfer_amount }}</a></td>
                                    <td style="text-align:left;" :class="{'mark':item.transfer_wins<0}">{{ item.transfer_wins }}</td>                                    
                                    <td style="text-align:left;" :class="{'mark':item.transfer<0}">{{ item.transfer }}</td>
                                    <td style="text-align:left;" :class="{'mark':item.transfer_result<0}">{{ item.transfer_result }}</td>
                                </template>

                                <td style="text-align:left;" :class="{'mark':item.transfer_amount<0}" v-if="isTop"><a class="link" @click="Edit(item,2)">{{ item.transfer_amount }}</a></td>
                                <td style="text-align:left;" :class="{'mark':item.transfer_result<0}" v-if="isTop">{{ item.transfer_result }}</td>

                                <td style="text-align:left;" :class="{'mark':item.result<0}">{{ item.result }}</td>
                                <td style="text-align:left;" :class="{'mark':item.up_result<0}" v-if="!isTop && Filter.pid != 1">{{ item.up_result}}</td>
                                <td style="text-align:left;" :class="{'mark':item.contribution_one<0}">{{ item.contribution_one }}</td>
                                <td style="text-align:left;" :class="{'mark':item.contribution_two<0}" v-if="Filter.pid != 1">{{ item.contribution_two }}</td>
                                <td style="text-align:center;">
                                    <div class="l-flex-c o-pr-s">
                                        <p class="l-flex-1 c-text-6 c-text-r">{{ item.contribution_proportion }}%</p>
                                    </div>
                                </td>     
                            </template>                       
                        </tr>
                    </tbody>
                    <tfoot>
                        <!-- <tr v-if="main._empty">
                            <td class="empty" colspan="100">
                                <div class="c-text-c o-ptb-h">暂无数据</div>
                            </td>
                        </tr> -->
                        <template v-if="!main._empty && Opt.isTeam">
                            <tr class="subtotal hbg-1" v-if="main._count >= 50">
                                <td style="text-align:left;" colspan="3">小计</td>
                                <td v-if="Filter.status == 0">-</td>

                                <td style="text-align:left;" :class="{'mark':CalcuRow('bets_num')<0}">{{ CalcuRow('bets_num') }}</td>
                                <td style="text-align:left;" :class="{'mark':CalcuRow('bets_amount')<0}">{{ CalcuRow('bets_amount') }}</td>
                                <!-- <td style="text-align:left;" :class="{'mark':CalcuRow('valid_amount')<0}">{{ CalcuRow('valid_amount') }}</td> -->
                                <td style="text-align:left;" :class="{'mark':CalcuRow('members_result')<0}">{{ CalcuRow('members_result') }}</td>
                                <template v-if="Filter.status != 0">
                                    <td style="text-align:left;" :class="{'mark':CalcuRow('js_result')<0}">{{ CalcuRow('js_result') }}</td>

                                    <template v-if="!isTop">
                                        <td style="text-align:left;" :class="{'mark':CalcuRow('percent_backwater_amount')<0}">{{ CalcuRow('percent_backwater_amount') }}</td>
                                        <td style="text-align:left;" :class="{'mark':CalcuRow('percent_amount')<0}">{{ CalcuRow('percent_amount') }}</td>
                                        <td style="text-align:left;">-</td>
                                        <td style="text-align:left;" :class="{'mark':CalcuRow('percent_result')<0}">{{ CalcuRow('percent_result') }}</td>

                                        <td style="text-align:left;" :class="{'mark':CalcuRow('transfer_backwater')<0}">{{ CalcuRow('transfer_backwater') }}</td>
                                        <td style="text-align:left;" :class="{'mark':CalcuRow('transfer_amount')<0}">{{ CalcuRow('transfer_amount') }}</td>
                                        <td style="text-align:left;" :class="{'mark':CalcuRow('transfer_wins')<0}">{{ CalcuRow('transfer_wins') }}</td>                                    
                                        <td style="text-align:left;">-</td>
                                        <td style="text-align:left;" :class="{'mark':CalcuRow('transfer_result')<0}">{{ CalcuRow('transfer_result') }}</td>
                                    </template>

                                    <td style="text-align:left;" :class="{'mark':CalcuRow('transfer_amount')<0}" v-if="isTop">{{ CalcuRow('transfer_amount') }}</td>
                                    <td style="text-align:left;" :class="{'mark':CalcuRow('transfer_result')<0}" v-if="isTop">{{ CalcuRow('transfer_result') }}</td>

                                    <td style="text-align:left;"  :class="{'mark':CalcuRow('result')<0}">{{ CalcuRow('result') }}</td>
                                    <td style="text-align:left;" :class="{'mark':CalcuRow('up_result')<0}" v-if="!isTop && Filter.pid != 1">{{ CalcuRow('up_result') }}</td>
                                    <td style="text-align:left;" :class="{'mark':CalcuRow('contribution_one')<0}">{{ CalcuRow('contribution_one') }}</td>
                                    <td style="text-align:left;" :class="{'mark':CalcuRow('contribution_two')<0}" v-if="Filter.pid != 1">{{ CalcuRow('contribution_two') }}</td>
                                    <td style="text-align:right;"><span class="o-pr-s">-</span></td>
                                </template>
                            </tr>
                            <tr class="total fbg-4" v-if="!main._empty && summary" >
                                <td style="text-align:left;" colspan="3">合计</td>
                                <td v-if="Filter.status == 0">-</td>

                                <td style="text-align:left;" :class="{'mark':summary.bets_num<0}">{{ summary.bets_num }}</td>
                                <td style="text-align:left;" :class="{'mark':summary.bets_amount<0}">{{ summary.bets_amount }}</td>
                                <!-- <td style="text-align:left;" :class="{'mark':summary.valid_amount<0}">{{ summary.valid_amount }}</td> -->
                                <td style="text-align:left;" :class="{'mark':summary.members_result<0}">{{ summary.members_result }}</td>
                                <template v-if="Filter.status != 0">
                                    <td style="text-align:left;" :class="{'mark':summary.js_result<0}">{{ summary.js_result }}</td>

                                    <template v-if="!isTop">
                                        <td style="text-align:left;" :class="{'mark':summary.percent_backwater_amount<0}">{{ summary.percent_backwater_amount }}</td>
                                        <td style="text-align:left;" :class="{'mark':summary.percent_amount<0}">{{ summary.percent_amount }}</td>
                                        <td style="text-align:left;">-</td>
                                        <td style="text-align:left;" :class="{'mark':summary.percent_result<0}">{{ summary.percent_result }}</td>

                                        <td style="text-align:left;" :class="{'mark':summary.transfer_backwater<0}">{{ summary.transfer_backwater }}</td>
                                        <td style="text-align:left;" :class="{'mark':summary.transfer_amount<0}">{{ summary.transfer_amount }}</td>
                                        <td style="text-align:left;" :class="{'mark':summary.transfer_wins<0}">{{ summary.transfer_wins }}</td>
                                        <td style="text-align:left;">-</td>
                                        <td style="text-align:left;" :class="{'mark':summary.transfer_result<0}">{{ summary.transfer_result }}</td>
                                    </template>

                                    <td style="text-align:left;" :class="{'mark':summary.transfer_amount<0}" v-if="isTop">{{ summary.transfer_amount }}</td>
                                    <td style="text-align:left;" :class="{'mark':summary.transfer_result<0}" v-if="isTop">{{ summary.transfer_result }}</td>

                                    <td style="text-align:left;" :class="{'mark':summary.result<0}">{{ summary.result }}</td>
                                    <td style="text-align:left;" by="上级结果" :class="{'mark':summary.up_result<0}" v-if="!isTop && Filter.pid != 1">{{ summary.up_result }}</td>
                                    <td style="text-align:left;" :class="{'mark':summary.contribution_one<0}">{{ summary.contribution_one }}</td>
                                    <td style="text-align:left;" :class="{'mark':summary.contribution_two<0}" v-if="Filter.pid != 1">{{ summary.contribution_two }}</td>
                                    <td style="text-align:right;"><span class="o-pr-s">-</span></td>
                                </template>
                            </tr>
                        </template>
                    </tfoot>
                </table>


                <template v-if="Opt.isTeam && summary && summary.transfer_number_out > 0">
                    <table v-if="Filter.status == 0" class="table" border="0" cellspacing="1" cellpadding="0" style="margin-top:10px;">
                        <colgroup>
                            <col width="130">
                            <col width="190">
                            <col>
                            <col>
                            <col>
                        </colgroup>
                        <thead>
                            <tr style="text-align:left;" class="hbg-2">
                                <td>帐号</td>
                                <td>走飞笔数</td>
                                <td>走飞金额</td>
                                <td>收飞笔数</td>
                                <td>收飞金额</td>
                            </tr>
                        </thead>
                        <tbody style="text-align:left;">
                            <tr>
                                <td>{{ summary.username }}</td>
                                <td>{{ summary.transfer_number_out }}</td>
                                <td :class="{'mark':summary.transfer_amount_out<0}"><a class="link" @click="Edit(summary,1)">{{ summary.transfer_amount_out }}</a></td>
                                <td>{{ summary.reward_number }}</td>
                                <td :class="{'mark':summary.reward_amount<0}"><a class="link" @click="Edit(summary,1)">{{ summary.reward_amount }}</a></td>
                            </tr>
                        </tbody>
                    </table>
                    <table v-if="Filter.status == 1" class="table" border="0" cellspacing="1" cellpadding="0" style="margin-top:10px;">
                        <thead>
                            <tr style="text-align:left;" class="hbg-2">
                                <td>帐号</td>
                                <td>笔数</td>
                                <td>走飞金额</td>
                                <td>走飞退水</td>
                                <td>走飞结果</td>
                                <td>总结果合计</td>
                                <td v-if="!TargetIsAdmin">上级结果合计</td>
                            </tr>
                        </thead>
                        <tbody style="text-align:left;">
                            <tr>
                                <td>{{ summary.transfer_username_out }}</td>
                                <td :class="{'mark':summary.transfer_number_out<0}">{{ summary.transfer_number_out }}</td>
                                <td :class="{'mark':summary.transfer_amount_out<0}"><a class="link" @click="Edit(summary,1)">{{ summary.transfer_amount_out }}</a></td>
                                <td :class="{'mark':summary.transfer_backwater_out<0}">{{ summary.transfer_backwater_out }}</td>
                                <td :class="{'mark':summary.transfer_result_out<0}">{{ summary.transfer_result_out }}</td>
                                <td :class="{'mark':summary.total_result<0}">{{ summary.total_result }}</td>
                                <td :class="{'mark':summary.total_up_result<0}" v-if="!TargetIsAdmin">{{ summary.total_up_result }}</td>
                            </tr>
                        </tbody>
                    </table>
                </template>


                </template>

                <template v-if="Filter.games_type > 7">
                    <table class="table" border="0" cellspacing="1" cellpadding="0">
                    <thead>
                        <tr style="text-align:center;" class="hbg-2">
                            <td style="text-align:left;" rowspan="2">帐号</td>
                            <td style="text-align:left;" rowspan="2">名称</td>
                            <td rowspan="2" v-if="Opt.isTeam">级别</td>
                            <td rowspan="2" v-if="Filter.status == 0">关系</td>
                            <td rowspan="2">下单数</td>
                            <td rowspan="2">下注金额</td>
                            <!-- <td rowspan="2">有效金额</td> -->
                            <td rowspan="2" v-if="!Opt.isTeam">奖金</td>
                            <td rowspan="2" v-if="!Opt.isTeam">退水</td>
                            <td rowspan="2">
                                <span v-if="Opt.isTeam">会员结果</span>
                                <span v-else>结果</span>
                            </td>
                            <template v-if="Opt.isTeam && Filter.status != 0">
                                <td rowspan="2">{{ isTop ? '代理结果' : '交收'}}</td>
                                <td style="text-align:center;" colspan="4" v-if="!isTop">代理<span v-if="summary">({{ summary.transfer_username_out || summary.username }})</span></td>
                                <td style="text-align:center;" colspan="5" v-if="!isTop">代理收飞</td>
                                <td rowspan="2" v-if="isTop">收飞金额</td>
                                <td rowspan="2" v-if="isTop">收飞结果</td>
                                <td rowspan="2">总结果</td>
                                <td rowspan="2" v-if="!isTop && Filter.pid != 1">上级结果</td>
                                <td rowspan="2">贡献1</td>
                                <td rowspan="2" v-if="Filter.pid != 1">贡献2</td>
                                <td rowspan="2">贡献比例</td>
                            </template>
                        </tr>
                        <tr style="text-align:left;" v-if="Opt.isTeam && !isTop && Filter.status != 0" class="hbg-4">
                            <td>退水</td>
                            <td>金额</td>
                            <td>占成</td>
                            <td>结果</td>
                            <td>退水</td>
                            <td>金额</td>
                            <td>输赢</td>
                            <td>占成</td>
                            <td>结果</td>
                        </tr>
                    </thead>
                    <tbody style="text-align:left;" class="zebra">
                        <tr v-for="(item,index) in main._list">
                            <td style="text-align:left;">
                                <a class="link" v-if="item.user_type==0 || !item.user_type" @click="Edit(item,0)" style="display:inline-block; min-width:80px;">{{ item.username }}</a>
                                <a class="link" v-if="item.user_type==1" @click="GetByBreadcrumb(item)" style="display:inline-block; min-width:80px;">{{ item.username }}</a>                                
                            </td>
                            <td style="text-align:left;">
                                <span style="display:inline-block; min-width:40px;">{{ item.real_name }}</span>
                            </td>
                            <td v-if="Opt.isTeam">
                                <span style="display:inline-block; min-width:40px;">{{ item.level }}</span>
                            </td>
                            <td v-if="Filter.status == 0">
                                <a class="link" @click="LevelView(item)">关系</a>
                            </td>
                            <td style="text-align:left;">{{ item.bets_num }}</td>
                            <td style="text-align:left;">
                                <a class="link" v-if="item.user_type==0 || !item.user_type" @click="Edit(item,0)">{{ item.bets_amount }}</a>
                                <a class="link" v-if="item.user_type==1" @click="GetByBreadcrumb(item)">{{ item.bets_amount }}</a>
                            </td>

                            <!-- <td style="text-align:left;" :class="{'mark':item.valid_amount<0}">{{ item.valid_amount }}</td> -->
                            <td style="text-align:left;" v-if="!Opt.isTeam" :class="{'mark':item.bets_bonus<0}">{{ item.bets_bonus }}</td>
                            <td style="text-align:left;" v-if="!Opt.isTeam":class="{'mark':item.percent_backwater_amount<0}">{{ item.percent_backwater_amount }}</td>
                            <td style="text-align:left;" :class="{'mark':item.members_result<0}">{{ item.members_result }}</td>
                            <template v-if="Opt.isTeam && Filter.status != 0">
                                <td style="text-align:left;" :class="{'mark':item.js_result<0}">{{ item.js_result }}</td>

                                <template v-if="!isTop">
                                    <td style="text-align:left;" :class="{'mark':item.percent_backwater_amount<0}">{{ Price(item.percent_backwater_amount,{separate:false}) }}</td>
                                    <td style="text-align:left;" :class="{'mark':item.percent_amount<0}">{{ item.percent_amount }}</td>
                                    <td style="text-align:left;" :class="{'mark':item.percent<0}">{{ item.percent }}</td>
                                    <td style="text-align:left;" :class="{'mark':item.percent_result<0}">{{ item.percent_result }}</td>

                                    <td style="text-align:left;" :class="{'mark':item.transfer_backwater<0}">{{ Price(item.transfer_backwater,{separate:false}) }}</td>
                                    <td style="text-align:left;" :class="{'mark':item.transfer_amount<0}"><a class="link" @click="Edit(item,2)">{{ item.transfer_amount }}</a></td>
                                    <td style="text-align:left;" :class="{'mark':item.transfer_wins<0}">{{ item.transfer_wins }}</td>                                    
                                    <td style="text-align:left;" :class="{'mark':item.transfer<0}">{{ item.transfer }}</td>
                                    <td style="text-align:left;" :class="{'mark':item.transfer_result<0}">{{ item.transfer_result }}</td>
                                </template>

                                <td style="text-align:left;" :class="{'mark':item.transfer_amount<0}" v-if="isTop"><a class="link" @click="Edit(item,2)">{{ item.transfer_amount }}</a></td>
                                <td style="text-align:left;" :class="{'mark':item.transfer_result<0}" v-if="isTop">{{ item.transfer_result }}</td>

                                <td style="text-align:left;" :class="{'mark':item.result<0}">{{ item.result }}</td>
                                <td style="text-align:left;" :class="{'mark':item.up_result<0}" v-if="!isTop && Filter.pid != 1">{{ item.up_result}}</td>
                                <td style="text-align:left;" :class="{'mark':item.contribution_one<0}">{{ item.contribution_one }}</td>
                                <td style="text-align:left;" :class="{'mark':item.contribution_two<0}" v-if="Filter.pid != 1">{{ item.contribution_two }}</td>
                                <td style="text-align:center;">
                                    <div class="l-flex-c o-pr-s">
                                        <p class="l-flex-1 c-text-6 c-text-r">{{ item.contribution_proportion }}%</p>
                                    </div>
                                </td>     
                            </template>                       
                        </tr>
                    </tbody>
                    <tfoot>
                        <!-- <tr v-if="main._empty">
                            <td class="empty" colspan="100">
                                <div class="c-text-c o-ptb-h">暂无数据</div>
                            </td>
                        </tr> -->
                        <template v-if="!main._empty && Opt.isTeam">
                            <tr class="subtotal hbg-1" v-if="main._count >= 50">
                                <td style="text-align:left;" colspan="3">小计</td>
                                <td v-if="Filter.status == 0">-</td>

                                <td style="text-align:left;" :class="{'mark':CalcuRow('bets_num')<0}">{{ CalcuRow('bets_num') }}</td>
                                <td style="text-align:left;" :class="{'mark':CalcuRow('bets_amount')<0}">{{ CalcuRow('bets_amount') }}</td>
                                <!-- <td style="text-align:left;" :class="{'mark':CalcuRow('valid_amount')<0}">{{ CalcuRow('valid_amount') }}</td> -->
                                <td style="text-align:left;" :class="{'mark':CalcuRow('members_result')<0}">{{ CalcuRow('members_result') }}</td>
                                <template v-if="Filter.status != 0">
                                    <td style="text-align:left;" :class="{'mark':CalcuRow('js_result')<0}">{{ CalcuRow('js_result') }}</td>

                                    <template v-if="!isTop">
                                        <td style="text-align:left;" :class="{'mark':CalcuRow('percent_backwater_amount')<0}">{{ CalcuRow('percent_backwater_amount') }}</td>
                                        <td style="text-align:left;" :class="{'mark':CalcuRow('percent_amount')<0}">{{ CalcuRow('percent_amount') }}</td>
                                        <td style="text-align:left;">-</td>
                                        <td style="text-align:left;" :class="{'mark':CalcuRow('percent_result')<0}">{{ CalcuRow('percent_result') }}</td>

                                        <td style="text-align:left;" :class="{'mark':CalcuRow('transfer_backwater')<0}">{{ CalcuRow('transfer_backwater') }}</td>
                                        <td style="text-align:left;" :class="{'mark':CalcuRow('transfer_amount')<0}">{{ CalcuRow('transfer_amount') }}</td>
                                        <td style="text-align:left;" :class="{'mark':CalcuRow('transfer_wins')<0}">{{ CalcuRow('transfer_wins') }}</td>                                    
                                        <td style="text-align:left;">-</td>
                                        <td style="text-align:left;" :class="{'mark':CalcuRow('transfer_result')<0}">{{ CalcuRow('transfer_result') }}</td>
                                    </template>

                                    <td style="text-align:left;" :class="{'mark':CalcuRow('transfer_amount')<0}" v-if="isTop">{{ CalcuRow('transfer_amount') }}</td>
                                    <td style="text-align:left;" :class="{'mark':CalcuRow('transfer_result')<0}" v-if="isTop">{{ CalcuRow('transfer_result') }}</td>

                                    <td style="text-align:left;"  :class="{'mark':CalcuRow('result')<0}">{{ CalcuRow('result') }}</td>
                                    <td style="text-align:left;" :class="{'mark':CalcuRow('up_result')<0}" v-if="!isTop && Filter.pid != 1">{{ CalcuRow('up_result') }}</td>
                                    <td style="text-align:left;" :class="{'mark':CalcuRow('contribution_one')<0}">{{ CalcuRow('contribution_one') }}</td>
                                    <td style="text-align:left;" :class="{'mark':CalcuRow('contribution_two')<0}" v-if="Filter.pid != 1">{{ CalcuRow('contribution_two') }}</td>
                                    <td style="text-align:right;"><span class="o-pr-s">-</span></td>
                                </template>
                            </tr>
                            <tr class="total fbg-4" v-if="!main._empty && summary" >
                                <td style="text-align:left;" colspan="3">合计</td>
                                <td v-if="Filter.status == 0">-</td>

                                <td style="text-align:left;" :class="{'mark':summary.bets_num<0}">{{ summary.bets_num }}</td>
                                <td style="text-align:left;" :class="{'mark':summary.bets_amount<0}">{{ summary.bets_amount }}</td>
                                <!-- <td style="text-align:left;" :class="{'mark':summary.valid_amount<0}">{{ summary.valid_amount }}</td> -->
                                <td style="text-align:left;" :class="{'mark':summary.members_result<0}">{{ summary.members_result }}</td>
                                <template v-if="Filter.status != 0">
                                    <td style="text-align:left;" :class="{'mark':summary.js_result<0}">{{ summary.js_result }}</td>

                                    <template v-if="!isTop">
                                        <td style="text-align:left;" :class="{'mark':summary.percent_backwater_amount<0}">{{ summary.percent_backwater_amount }}</td>
                                        <td style="text-align:left;" :class="{'mark':summary.percent_amount<0}">{{ summary.percent_amount }}</td>
                                        <td style="text-align:left;">-</td>
                                        <td style="text-align:left;" :class="{'mark':summary.percent_result<0}">{{ summary.percent_result }}</td>

                                        <td style="text-align:left;" :class="{'mark':summary.transfer_backwater<0}">{{ summary.transfer_backwater }}</td>
                                        <td style="text-align:left;" :class="{'mark':summary.transfer_amount<0}">{{ summary.transfer_amount }}</td>
                                        <td style="text-align:left;" :class="{'mark':summary.transfer_wins<0}">{{ summary.transfer_wins }}</td>
                                        <td style="text-align:left;">-</td>
                                        <td style="text-align:left;" :class="{'mark':summary.transfer_result<0}">{{ summary.transfer_result }}</td>
                                    </template>

                                    <td style="text-align:left;" :class="{'mark':summary.transfer_amount<0}" v-if="isTop">{{ summary.transfer_amount }}</td>
                                    <td style="text-align:left;" :class="{'mark':summary.transfer_result<0}" v-if="isTop">{{ summary.transfer_result }}</td>

                                    <td style="text-align:left;" :class="{'mark':summary.result<0}">{{ summary.result }}</td>
                                    <td style="text-align:left;" by="上级结果" :class="{'mark':summary.up_result<0}" v-if="!isTop && Filter.pid != 1">{{ summary.up_result }}</td>
                                    <td style="text-align:left;" :class="{'mark':summary.contribution_one<0}">{{ summary.contribution_one }}</td>
                                    <td style="text-align:left;" :class="{'mark':summary.contribution_two<0}" v-if="Filter.pid != 1">{{ summary.contribution_two }}</td>
                                    <td style="text-align:right;"><span class="o-pr-s">-</span></td>
                                </template>
                            </tr>
                        </template>
                    </tfoot>
                </table>


                <template v-if="Opt.isTeam && summary && summary.transfer_number_out > 0">
                    <table v-if="Filter.status == 0" class="table" border="0" cellspacing="1" cellpadding="0" style="margin-top:10px;">
                        <colgroup>
                            <col width="130">
                            <col width="190">
                            <col>
                            <col>
                            <col>
                        </colgroup>
                        <thead>
                            <tr style="text-align:left;" class="hbg-1">
                                <td>帐号</td>
                                <td>走飞笔数</td>
                                <td>走飞金额</td>
                                <td>收飞笔数</td>
                                <td>收飞金额</td>
                            </tr>
                        </thead>
                        <tbody style="text-align:left;">
                            <tr>
                                <td>{{ summary.username }}</td>
                                <td>{{ summary.transfer_number_out }}</td>
                                <td :class="{'mark':summary.transfer_amount_out<0}"><a class="link" @click="Edit(summary,1)">{{ summary.transfer_amount_out }}</a></td>
                                <td>{{ summary.reward_number }}</td>
                                <td :class="{'mark':summary.reward_amount<0}"><a class="link" @click="Edit(summary,1)">{{ summary.reward_amount }}</a></td>
                            </tr>
                        </tbody>
                    </table>
                    <table v-if="Filter.status == 1" class="table" border="0" cellspacing="1" cellpadding="0" style="margin-top:10px;">
                        <thead>
                            <tr style="text-align:left;" class="hbg-2">
                                <td>帐号</td>
                                <td>笔数</td>
                                <td>走飞金额</td>
                                <td>走飞退水</td>
                                <td>走飞结果</td>
                                <td>总结果合计</td>
                                <td v-if="!TargetIsAdmin">上级结果合计</td>
                            </tr>
                        </thead>
                        <tbody style="text-align:left;">
                            <tr>
                                <td>{{ summary.transfer_username_out }}</td>
                                <td :class="{'mark':summary.transfer_number_out<0}">{{ summary.transfer_number_out }}</td>
                                <td :class="{'mark':summary.transfer_amount_out<0}"><a class="link" @click="Edit(summary,1)">{{ summary.transfer_amount_out }}</a></td>
                                <td :class="{'mark':summary.transfer_backwater_out<0}">{{ summary.transfer_backwater_out }}</td>
                                <td :class="{'mark':summary.transfer_result_out<0}">{{ summary.transfer_result_out }}</td>
                                <td :class="{'mark':summary.total_result<0}">{{ summary.total_result }}</td>
                                <td :class="{'mark':summary.total_up_result<0}" v-if="!TargetIsAdmin">{{ summary.total_up_result }}</td>
                            </tr>
                        </tbody>
                    </table>
                </template>
                </template>
                
                <Pagination v-if="main._count >= 50" class="o-mtb" v-model="Page" @turning="Get" :total="main._total" :count="main._count" :pageSize="Filter.per_page"></Pagination>
            </div>
        </div>
    </div>
</template>

<script>
import { Indicator } from 'mint-ui'
import StoreMix from '@lottery.admin.2.0/mixin/store.sub.js'
import LevelMix from '@lottery.2.0/mixin/level.js'
export default {
    name: 'CreditSubReportAmount',
    mixins : [StoreMix,LevelMix],
    data() {
        return {
            store : 'credit/report',
            crumbkey : 'pid',
            Filter : {
                games_type : null,
                per_page : 50,
                pid : null,
            },
            summary : null,
            test : true,
        }
    },
    watch:{
        'main._loading'(val){
            if(val){
                Indicator.open()
            }else{
                Indicator.close()
            }
        },
        'breadcrumb.length'(val){
            this.GetSum()
        },
    },
    computed: {
        isTop(){
            if(this.test){
                return false 
            }
            if(this.Opt.isAdmin && this.breadcrumb.length <= 1){
                return true 
            }
            return false 
        },
        TargetIsAdmin(){
            if(this.breadcrumb && this.breadcrumb.length){

                let index = this.breadcrumb.length - 1
                let target = this.breadcrumb[index]
                return target.is_admin
            }
            return false
        },
    },
    methods: {
        init(){
            this.DataReset('summary')
            this.DataReset('breadcrumb')
            this.DataReset('Filter')
            for(let key in this.$route.params){
                this.$set(this.Filter,key,this.$route.params[key])
            }
            this.Filter.pid = this.User.id
            this.breadcrumb[0].id = this.User.id
            this.Reload()
        },
        Reload(){
            this.Get(1)
            this.GetSum()
        },
        GetSum(){
            this.Get('reportsum').then(res=>{
                this.$set(this,'summary',res.data)
            })
        },
        CalcuNumber(number){
            try{
                return this.Big(number).round(2,0).toNumber()
            }catch(err){
                return 0
            }
        },
        Edit(item,transfer,val=0){
            if(this.Filter.status == 0){
                this.Editer.form = {
                    uid : item.id,
                    whole : 1,
                    //games_id : 31,
                    games_type : this.Filter.games_type,
                    games_mark : val,
                    interval : this.Origin(this.Filter.interval),
                    // username : item.username,
                    lottery_id : this.Filter.lottery_id,
                    status : this.Filter.status,
                    transfer,
                }
            }else{
                this.Editer.form = {
                    uid : item.id,
                    games_type : this.Filter.games_type,
                    games_mark : val,
                    interval : this.Origin(this.Filter.interval),
                    username : item.username,
                    lottery_id : this.Filter.lottery_id,
                    status : this.Filter.status,
                    transfer,
                }
            }
            // this.Editer.view = true
            this.Go('index/pages/report/details',this.Editer.form)
        },
        LevelView(item){            
            this.Level.form = this.Origin(item)
            this.Level.view = true
        },
    },
    beforeRouteEnter(to,from,next){
        next(vm=>{
            if(from.name.indexOf('index/pages/report/details') < 0){
                vm.init()
            }
        })
    },
    beforeRouteLeave(to,from ,next){
        next()
    },
}
</script>
