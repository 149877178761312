<style lang="scss">
    .CreditSubReportSubsidiary {
        .ctrl {
            height:40px; line-height:40px;
        }
        .table {
            width:1280px;
            td,th {
                &:first-child {padding-left:8px;}
                &:last-child {padding-right:8px;}
            }
            td {
                border:.03rem solid #999;
            }
            tbody {
                td,th {
                    padding-top:8px; padding-bottom:8px;
                }  
            }
        }
    }
</style>
<template>
    <div class="CreditSubReportSubsidiary">
        <div class="block o-mt" style="overflow-x: auto;">
            <table class="table" border="0" cellspacing="1" cellpadding="0" style="border-collapse: collapse; width: 700px;">
                <thead>
                    <tr style="text-align:left;" class="hbg-2">
                        <!-- <td style="text-align:left;" v-if="!Filter.games_type">类型</td> -->
                        <td style="text-align:left;">游戏</td>
                        <td style="text-align:left;">名称</td>
                        <td style="text-align:left;">下单数</td>
                        <td style="text-align:left;">下注金额</td>
                        <td style="text-align:left;">会员结果</td>
                        <td style="text-align:left;">代理结果</td>

                        <td style="text-align:left;">走飞金额</td>
                        <td style="text-align:left;">走飞结果</td>

                        <td style="text-align:left;">代理合计</td>
                        <td style="text-align:left;">自己走飞</td>
                    </tr>
                </thead>
                <tbody style="text-align:left;" class="zebra">
                    <tr v-for="(item,index) in main._list">
                        <!-- <td style="text-align:left;" v-if="!Filter.games_type">{{ Opt.typeById[item.games_type] && Opt.typeById[item.games_type].title }}</td> -->
                        <td style="text-align:left;">{{ item.games }}</td>
                        <td style="text-align:left;">{{ item.name }}</td>
                        <td style="text-align:left;">{{ item.bets_num }}</td>
                        <td style="text-align:left;" :class="{'mark':item.bets_amount<0}">
                            <a class="btn" @click="Edit(item,0)">{{ item.bets_amount }}</a>
                        </td>                        
                        <td style="text-align:left;" :class="{'mark':item.members_result<0}">{{ item.members_result }}</td>
                        <td style="text-align:left;" :class="{'mark':item.js_result<0}">{{ item.js_result }}</td>
                        <td style="text-align:left;">                                
                            <a class="btn" @click="Edit(item,1)" :class="{'mark':item.transfer_amount<0}">{{ item.transfer_amount }}</a>
                        </td>
                        <td style="text-align:left;" :class="{'mark':item.transfer_result<0}">{{ item.transfer_result }}</td>

                        <td style="text-align:left;" :class="{'mark':item.total_result<0}">{{ item.total_result }}</td>
                        <td style="text-align:left;" :class="{'mark':item.out_transfer<0}">{{ item.out_transfer }}</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr v-if="main._empty">
                        <td class="empty" colspan="100">
                            <div class="c-text-c o-ptb-h">暂无数据</div>
                        </td>
                    </tr>
                    <tr class="subtotal hbg-1" v-if="!main._empty">
                        <td style="text-align:left;">合计</td>
                        <td style="text-align:left;"></td>
                        <td style="text-align:left;">{{ CalcuRow('bets_num') }}</td>
                        <td style="text-align:left;" :class="{'mark':CalcuRow('bets_amount')<0}">{{ CalcuRow('bets_amount') }}</td>
                        <td style="text-align:left;" :class="{'mark':CalcuRow('members_result')<0}">{{ CalcuRow('members_result') }}</td>
                        <td style="text-align:left;" :class="{'mark':CalcuRow('js_result')<0}">{{ CalcuRow('js_result') }}</td>

                        <td style="text-align:left;" :class="{'mark':CalcuRow('transfer_amount')<0}">{{ CalcuRow('transfer_amount') }}</td>
                        <td style="text-align:left;" :class="{'mark':CalcuRow('transfer_result')<0}">{{ CalcuRow('transfer_result') }}</td>

                        <td style="text-align:left;" :class="{'mark':CalcuRow('total_result')<0}">{{ CalcuRow('total_result') }}</td>
                        <td style="text-align:left;" :class="{'mark':CalcuRow('out_transfer')<0}">{{ CalcuRow('out_transfer') }}</td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
</template>

<script>
import StoreMix from '@lottery.admin.2.0/mixin/store.sub.js'
export default {
    name: 'CreditSubReportSubsidiary',
    mixins : [StoreMix],
    data() {
        return {
            store : 'credit/subsidiary',
            Params : {},
            Filter : {
                games_type : null,
                per_page : 999,
                uid : null,
            },
            EditerTransfer : {
                view : false,
                form : null,
            },
        }
    },
    methods: {
        init(){
            this.DataReset('Filter')
            for(let key in this.$route.params){
                this.$set(this.Filter,key,this.$route.params[key])
            }
            this.Filter.uid = this.User.id
            this.Reload()
        },
        Reload(){
            this.Get(1)
        },
        CalcuNumber(number){
            try{
                return this.Big(number).mul(100).round(2,0).toNumber()
            }catch(err){
                return 0
            }
        },
        Edit(item,transfer){
            // this.Editer.view = true
            this.Editer.form = {
                uid : this.User.id,
                pid : this.User.id,
                games_type : item.games_type,
                interval : this.Filter.interval,
                lottery_id : this.Filter.lottery_id,
                mark_id : item.id,
                transfer,
            }
            this.Go('index/pages/report/details',this.Editer.form)
        },
    },
    computed : {

    },
    beforeRouteEnter(to, from, next){
        next(vm => {
            if(from.name.indexOf('index/pages/report/details') < 0){
                vm.init()
            }
        })
    },
    beforeRouteLeave(to, from , next){
        next()
    },
}
</script>
