<style lang="scss">
    .CreditUser {
        table.table {
            tr td {
                padding-top:6px; padding-bottom:6px; text-align:center;
            }
        }
    }
</style>
<template>
    <div class="CreditUser">
        <div class="block o-plr" style="height:32px; line-height:32px;">
            <Button v-if="breadcrumb.length > 1" size="mini" @click="Breadcrumb(breadcrumb.length-2)">返回上一级</Button>
            <span class="o-pl" v-if="BreadcrumbTarget">
                <span v-if="BreadcrumbTarget.id">代理 <b>{{ BreadcrumbTarget.title }}</b> 的所有下级</span>
                <span v-else>所有下级</span>
            </span>
        </div>
        <div class="block">
            <table class="table" border="0" cellspacing="1" cellpadding="0" style="border-collapse:collapse;">
                <colgroup>
                    <col title="名称">
                    <col title="期号">
                    <col title="类型">
                    <col title="操作" width="140px">
                </colgroup>
                <thead>
                    <tr class="hbg-1">
                        <td>帐号</td>
                        <td>名称</td>
                        <td>类型</td>
                        <td>操作</td>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="(item, index) in main._list">
                        <tr>
                            <td class="u-bt">
                                <p style="color: rgb(21, 68, 238); text-decoration: underline;" @click.stop="GetByBreadcrumb(item)">
                                    <a style="padding: 0 6px;" >{{ item.username || '匿名用户' }}</a>
                                </p>
                            </td>                    
                            <td class="u-bt"><p>{{ item.real_name }}</p></td>
                            <td class="u-bt">
                                <p style="padding:4px 0;">
                                    <span v-if="item.type == 0">会员</span>
                                    <span v-if="item.type == 1">代理</span>
                                </p>
                            </td>
                            <td class="u-bt">
                                <!-- <Button size="mini" type="default" @click="action('details', item)">详情</Button> -->
                                <!-- <Button size="mini" type="default" @click="action('edit', item)">编辑</Button> -->
                                <!-- <Button v-if="item.type == 1" size="mini" type="default" @click.stop="GetByBreadcrumb(item)">下级</Button> -->
                                <Button v-if="item.type == 0" size="mini" type="default" @click.stop="action('level', item)">占成</Button>
                                <Button size="mini" type="default" @click.stop="action('limit', item)">限额</Button> 
                                <Button size="mini" type="default" @click.stop="action('edit', item)">编辑</Button>
                                <!-- <Button size="mini" type="default" @click="edit(item)">编辑</Button> -->
                                <!-- <Button v-if="item.type == 1" size="mini" type="default" @click.stop="GetByBreadcrumb(item)">查询代理下级</Button> -->
                            </td>
                        </tr>
                        <tr v-show="item._view">
                            <td colspan="4">
                                <div class="o-pt-s" style="padding:5px 5px 10px 5px; border-radius:4px;">
                                    <table class="table u-bl u-bb u-br">
                                        <thead>
                                            <tr class="hbg-1">
                                                <td colspan="5">六合彩</td>
                                            </tr>
                                            <tr class="hbg-2">
                                                <td>额度</td>
                                                <td>占成</td>
                                                <td>操盘</td>
                                                <td>走飞</td>
                                                <td>封盘时间</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr style="background:#fff;">
                                                <td>{{ item.lhc_amount }}</td>
                                                <td>{{ Price(item.lhc_proportion,{separate:false}) }}</td>
                                                <td>
                                                    <b v-if="item.lhc_trader_status == 1">启用</b>
                                                    <b v-else class="c-color-e">停用</b>
                                                </td>
                                                <td>
                                                    <b v-if="item.lhc_transfer_status == 1">启用</b>
                                                    <b v-else class="c-color-e">停用</b>
                                                </td>
                                                <td>{{ item.lhc_end_bets_date }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
<!--                                     <div class="l-flex o-pt-s">
                                        <div class="l-flex-1" style="text-align:left;">
                                            <Button v-if="item.type == 1" size="mini" type="success" @click.stop="">查询代理</Button>
                                            <Button v-if="item.type == 0" size="mini" type="success" >上级占成</Button>
                                        </div>                                        
                                        <Button size="mini" @click.stop="Edit(item)"></Button>
                                    </div> -->
                                </div>
                            </td>
                        </tr>
                    </template>
                </tbody>
                <tfoot>
                    <tr v-if="main._empty">
                        <td class="empty" colspan="100">暂无数据</td>
                    </tr>
                </tfoot>
            </table>            
        </div>
        <div class="o-plr">
            <Pagination v-if="main._total > 1" class="o-mtb" v-model="Page" @turning="Get" :total="main._total" :count="main._count" type="mini" :pageSize="Filter.per_page"></Pagination>
        </div>
        <mt-actionsheet :actions="actions.options" v-model="actions.view" />
    </div>
</template>

<script>
import StoreMix from '@lottery.2.0/mixin/store.js'
import LevelMix from '@lottery.2.0/mixin/level.js'
export default {
    name: 'CreditUser',
    mixins : [StoreMix,LevelMix],
    data() {
        return {
            store : 'myTeam/user',
            crumbkey : 'pid',
            Filter : {
                per_page : 20,
            },
            transfer:{
                view : false,
                form : null,
                params : {
                    username:null,
                    pass:null,
                    money:null,
                },
            },
            percentage:{
                view : false,
                form : null,
                params : {
                    username:null,
                    odds:null,
                },
                list : null,
            },
            parent : {},
            HeadNav : {
                text : '新增',
                icon : 'friendadd',
                event : ()=>{
                    this.action('edit')
                }
            },
            actions: {
                options: [],
                view: false
            }
        }
    },
    computed:{
        BreadcrumbTarget(){
            let breadcrumb = this.breadcrumb
            let length = breadcrumb.length
            return breadcrumb[length-1]
        },
    },
    methods: {
        init(){
            this.DataReset('breadcrumb')
            this.DataReset('Filter')
            this.Loading()
            this.MakeFilter().then(res=>{
                this.Loading(false)
            })
        },
        Transfer(item){
            this.DataReset('transfer')
            this.transfer.view = true
            this.transfer.form = this.Origin({
                username : item.username,
                pass:null,
                money:null,
            })
        },
        Percentage(item){
            this.DataReset('percentage')
            this.percentage.view = true
            this.percentage.form = this.Origin({
                username : item.username,
                odds : null,
            })
        },
        edit(item) {
            this.$set(this.actions, 'options', [
                {name: '查看详情', method: () => this.action('details', item)},
                {name: '编辑信息', method: () => this.action('edit', item)},
                {name: '编辑限额', method: () => this.action('limit', item)},
            ])
            this.$set(this.actions, 'view', true)
        },
        action(name, item) {
            const obj = item ? this.Origin(item) : {}             
            const crumb = this.breadcrumb[this.breadcrumb.length-1]
            if (crumb && crumb.id) {
                obj.parent = {...crumb}
            } else {
                obj.parent = {id:this.User.id}
            }
            switch (name) {
                case 'edit': {
                    return this.Go('index/pages/sub/user', obj)
                }
                case 'details': {
                    return this.Go('index/pages/sub/user/details', obj)
                }
                case 'limit': {
                    return this.Go('index/pages/sub/user/limit', obj)
                }
                case 'level': {
                    return this.Go('index/pages/sub/user/level', obj)
                }
            }
        }
    },
    beforeRouteEnter(to, from, next){
        next(vm => {
            if (from) {
                if (from.name === 'index/pages/sub/user') {
                    vm.Loading()
                    return vm.Get(vm.Page).then(res => {
                        vm.Loading(false)
                    })
                }
                for (let route of ['index/pages/sub/user','index/pages/sub/user/level','index/pages/sub/user/details']) {
                    if (from.name === route) {
                        return
                    }
                }
            }
            vm.init()
        })
    }
}
</script>
