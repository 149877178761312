<style lang="scss">
    .CreditBets {

    }
</style>
<template>
    <div class="CreditBets">
        <div class="block">
            <div class="o-p u-bb l-flex-c">
                <div class="l-flex-1">{{ info.title }}</div>
                <div class="l-flex-1">第 {{info.expect_num }} 期</div>
                <div class="l-flex-1"> </div>
            </div>
        </div>
        <div class="o-mt-s">
            <div v-if="ready">
                <PullTo :top-load-method="LoadTop" v-infinite-scroll="LoadMore" :is-bottom-bounce="false" :infinite-scroll-disabled="!main._init || main._loading || !main._more" infinite-scroll-distance="10">
                    <li v-for="(item,index) in main._list" @click="Go('index/pages/sub/query',item)" class="block" :class="{'u-bt':index}"> 
                        <div class="o-plr-s o-ptb">
                            <div class="l-flex-c o-plr-s">
                                <div class="l-flex-1">
                                    <div class="o-pb-s" style="line-height:18px;">
                                        <span>注单号：{{ item.sequence }}</span>
                                    </div>
                                    <div class="c-text-6" style="line-height:18px;">
                                        <span>玩法：<b class="c-color-p">{{ item.side }}</b> / <b class="c-color-p">{{ item.plays }}</b></span>
                                        <span class="o-pl">赔率：<b class="c-color-p">{{ item.odds }}</b></span>
                                        <span class="o-pl">金额：<b class="c-color-e">{{ item.amount }}</b></span>
                                    </div>
                                    <div class="c-text-6" style="line-height:18px;">
                                        <span>内容：{{ item.code }} </span>
                                        <span class="o-pl">时间：{{ item.create_at }}</b></span>
                                    </div>
                                </div>
                                <Icon name="right" size=".6" style="color:#ccc;"></Icon>
                            </div>
                        </div>
                    </li>
                    <Loading :main="main"></Loading>
                </PullTo>
            </div>
            
        </div>
    </div>
</template>

<script>
import StoreMix from '@lottery.mobile/mixin/store.js'
import Rolling from '@lottery.mobile/mixin/rolling.js'
export default {
    name: 'CreditBets',
    mixins : [StoreMix,Rolling],
    data() {
        return {
            store : 'myRecord/order',
            Filter : {
                games_id : 31,
                lottery_id : null,
                per_page : 24,
                //status : 1,
            },
            summary : null,
            ready : false,
            info : {},
        }
    },
    methods: {
        init(){            
            this.DataReset('Filter')
            this.FilterIntervalSet('all')
            this.$set(this,'info',this.$route.params)
            this.Filter.games_id = this.$route.params.id
            if(this.$route.params.expect_id){
                this.Filter.lottery_id = this.$route.params.expect_id
            }
            this.Get()
        },
    },
    mounted(){
        this.$set(this.Roll,'target',document.getElementsByClassName('page-view')[0])
    },
    beforeRouteEnter(to, from, next) {
        next(vm=>{
            if(from && from.name === 'index/pages/sub/query'){
                vm.Roll.enable = true
            }else{
                vm.Roll.enable = false
                vm.init()
            }
        })
    },
}
</script>
