import Vue from 'vue'
import { Indicator } from 'mint-ui'

Vue.mixin({
    methods : {
    	Loading(str='加载中...'){
    		if(str){
    			Indicator.open(str)
    		}else{
    			Indicator.close()
    		}
    	},
    },
})