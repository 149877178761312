<style lang="scss">
    @import "../../../../../style/config.scss";
    .GameCreditGroup.SixFull {
         $border:.04rem solid #ddd;
        .title {
            background-color:#ccc; color:#333; font-size:12px; padding:2px 0; text-align:center;
        }
        .row {
            text-align:center; background-color:#f5f5f5;
            border-bottom:$border; border-left:$border;
            padding-top:7px; padding-bottom:7px; box-sizing:border-box; display:block; position:relative;
            &:first-child{
                border-top:$border;
            }
            .goods {
                font-weight:bold; font-size:.8rem;
                &.ball {
                    font-size:18px; font-weight:bold;
                }
                .red { color:$color-six-red;}
                .blue { color:$color-six-blue;}
                .green { color:$color-six-green;}
            }
            &.pick {
                background-color:#ffe9c3;
            }
            &.dcheckbox {
                &::after {
                    content: ""; background-color:rgba(200,200,200,.3);
                    position:absolute; top:0; left:0; right:0; bottom:0; z-index: 1;
                    width: 100%; height: 100%; display:block; outline:2px dashed #ccc;
                }
            }
            .odds {
                color:#9a1d0f; height:14px; font-size:9px; line-height:14px; position:absolute; top:1.5px; right:2px;
            }
            .balls {
                //display:flex; flex-wrap:warp; justify-content:center;
                text-align:center; padding:0 2px;
                // a.six_ball_mini {
                //     display:inline-block; width:16px; height:16px; line-height:16px; font-size:10.5px; margin:0 1px; text-align:center;
                //     background-color:#f0f0f0; border-radius:99px;
                //     &.red {background-color:$color-six-red; color:#fff;}
                //     &.blue {background-color:$color-six-blue; color:#fff;}
                //     &.green {background-color:$color-six-green; color:#fff;}
                // }
                a.six_ball_mini {
                    display:inline-block; font-size:11px; margin:0 0px; text-align:center;
                    &.red {color:$color-six-red;}
                    &.blue {color:$color-six-blue;}
                    &.green {color:$color-six-green;}
                }
            }
            .amount {
                height:14px; line-height:14px; font-size:11px; margin-top:5px;
            }
        }
        .column {
            box-sizing:border-box;
            &:first-child{
                .row {
                    border-left:none;
                }
            }
        }
    }
</style>
<template>
    <ul v-if="list && (list.child || list.length)" class="GameCreditGroup SixFull" :class="['type-' + COptions.type,type]">
        <div v-if="type == 'normal'">
            <label v-for="(item,index) in list.child" :key="index" class="row" :class="{'pick': item.pick || item.amount,'dcheckbox': mode === 'box' && (disabled || item.disabled)}" @click="Pick(item)">
                <p class="goods" :class="CodeColor(item.name)">{{ item.name }}</p>
                <!-- <div class="odds">
                    <template v-if="CalcuOdds(item)">
                        <span>x{{ CalcuOdds(item).odds }}</span>
                        <span v-if="CalcuOdds(item).has_double_odds">
                            <span style="padding:0 2px;">/</span>
                            <span>{{ CalcuOdds(item).max_odds }}</span>
                        </span>
                    </template>
                </div> -->
                <ul v-if="QuickBetList[item.name]" class="balls">
                    <lhc-ball v-for="(i, index) in BetList(item.name)" :key="i" :number="i" />
                </ul>
                <!-- <div v-if="!COptions.opt.onlyQuick" class="amount">
                    <span v-show="!Quick && item.amount">{{ item.amount }}元</span>
                </div> -->
                <div v-if="CalcuOdds(item)" style="color: #ff4949;">
                    <span>{{ CalcuOdds(item).odds }}</span>
                    <span v-if="CalcuOdds(item).has_double_odds">
                        <span style="padding:0 2px;">/</span>
                        <span>{{ CalcuOdds(item).max_odds }}</span>
                    </span>
                </div>
                <span v-else style="color: #ff4949;">-</span>
            </label>
        </div>
        <div v-else-if="type == 'queue'" class="l-flex">
            <div v-for="(pack, unit) in MakeQueue(list, length)" :key="unit" class="column l-flex-1">
                <GameCreditGroupSixFull :list="pack" :title="false" :mode="mode" :fortynine="fortynine" :disabled="disabled" :has-odds="hasOdds" @box="$emit('box',$event)" />
            </div>
        </div>
        <NumberInput ref="custom" type="model" @change="QuickAmountCustom($event)" />
    </ul>
</template>

<script>
import Credit from '../../../../../mixin/credit.js'
import Prompt from './prompt'
import SixMix from '@lottery.admin.2.0/mixin/six.js'
import LhcBall from './lhc_ball'
export default {
    name: 'GameCreditGroupSixFull',
    mixins: [SixMix, Credit],
    data() {
        return {
            active: 0,
            custom_active: {}
        }
    },
    props: {
        list: {},
        quick: {
            default: true
        },
        color: {
            default: null
        },
        length: {
            default: 5
        },
        dice: {
            default: false
        },
        type: {
            default: 'normal'
        },
        mode: {
            default: 'normal'
        },
        disabled: {
            default: false
        },
        fortynine: {
            default: true
        },
        hasOdds: {
            default: true
        }
    },
    computed: {
        Quick() {
            if (this.COptions.opt && this.COptions.opt.onlyQuick) {
                return false
            }
            return this.COptions.type == 2
        }
    },
    methods: {
        Pick(item) {
            console.log('Pick 2', this.mode === 'box', this.COptions.type == 3, this.Quick)
            if (this.mode === 'box') {
                if (!this.disabled && !item.disabled) {
                    this.$set(item, 'pick', !item.pick)
                    this.$emit('box', item)
                }
            } else if (this.COptions.type == 3) {
                this.custom_active = item
                this.$refs['custom'].Open(item.amount ? item.amount : null)
            } else if (item.id) {
                if (this.Quick) {
                    this.$set(item, 'pick', !item.pick)
                } else {
                    let amount = item.amount ? item.amount : 0
                    this.$set(item, 'amount', this.COptions.quick.value[0] + amount)
                }
            }
        },
        QuickAmountCustom(e, item) {
            this.$set(this.custom_active, 'amount', e ? e : null)
        },
        IsInt(str) {
            if (!str) {
                return false
            }
            return /^[0-9]*$/.test(str)
        },
        Input(item, val) {
            this.$set(item, 'amount', val)
            this.$set(item, 'quickView', false)
        },
        Span(len) {
            return this.Big(24).div(len).round(0, 1).toNumber()
        },
        BetList(name) {
            let list = this.Origin(this.QuickBetList[name])
            if (this.fortynine === false) {
                for (let item of list) {
                    if (item===49) {
                        list.pop()
                        return list
                    }
                }
            }
            return list
        }
    },
    components: {
        Prompt, LhcBall
    }
}
</script>
