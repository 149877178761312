<style lang="scss">
.NumberInput {
    display: inline-block; position: relative;
    .slot {
        display:inline-block;
    }
    .model {
        width: 100%; height: 100%; position:fixed; top:0; left:0; right:0; bottom:0; background-color:rgba(0,0,0,.3);
        .core {
            width: 100%; height: 290px; background-color:#fff; position:absolute; left:0; right:0; bottom:0; box-shadow:-5px 0 10px rgba(0,0,0,.4);
        }
        .num-header {
            background-color:#f0f0f0;
            input.input {
                width:100%; height:auto; font-size:.7rem; padding:4px 5px; box-sizing: border-box; 
            }
        }
        .num-row {
            .item {
 
            }
            .inside {
                border-radius:4px; text-align:center; font-size:18px; height:40px; line-height:40px;
               &.btn {
                    background-color:#ff6057; color:#fff;
                }
            }
        }
    }
    &.model-view {
        position:absolute;
    }
}
</style>
<template>
    <div class="NumberInput" :class="{'model-view':type==='model'}">
        <a class="slot" @click="Open()">
            <slot></slot>
        </a>
        <div class="model" v-show="view" @click.self="Cancel()" style="z-index:101;">
            <div class="core">
                <div class="num-header l-flex-c o-ptb">
                    <div class="o-pl-s">金额：</div>
                    <div class="l-flex-1 o-plr-s">
                        <input class="input" v-model.number="amount" placeholder="请键入金额" readonly>
                    </div>
                    <div class="o-pr">
                        <Icon name="caozuo-tuige" size="1.6" style="color:#bbb;" @click="Back()"></Icon>
                    </div>                     
                </div>
                <div class="o-pt-s o-pb">
                    <div v-for="(pack,unit) in [[1,2,3],[4,5,6],[7,8,9]]" class="num-row l-flex-c o-plr-s">
                        <div v-for="(item,index) in pack" @click="Input(item)" class="item l-flex-1 c-text-c o-p-s">
                            <div class="u-bt u-bb u-bl u-br inside">{{ item }}</div>
                        </div>
                    </div>
                    <div class="num-row l-flex-c o-plr-s">
                        <div @click="Clean()" class="item l-flex-1 c-text-c o-p-s">
                            <div class="u-bt u-bb u-bl u-br btn inside">归零</div>
                        </div>
                        <div @click="Input(0)" class="item l-flex-1 c-text-c o-p-s">
                            <div class="u-bt u-bb u-bl u-br inside">0</div>
                        </div>
                        <div @click="Submit()" class="item l-flex-1 c-text-c o-p-s">
                            <div class="u-bt u-bb u-bl u-br btn inside">确定</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name : 'NumberInput',
    data(){
        return {
            view : false,
            amount : null,
        }
    },
    computed:{

    },
    props : {
        value : {},
        type : {
            default : 'default'
        },
    },
    watch:{
        value(val){
            this.$set(this,'amount',this.value)
        },
    },
    methods: {
        Open(amount){
            if(amount === null){
                amount= ''
            }else if(!amount){
                amount = this.value ? `${this.value}` : ''
            }else{
                amount = `${amount}`
            }
            this.$set(this,'amount',amount)
            this.$set(this,'view',true)
        },
        Cancel(){
            this.$set(this,'view',false)
        },
        Input(num){
            if(num || num == 0){
                this.$set(this,'amount',`${this.amount}${num}`)
            }
        },
        Back(){
            if(this.amount){
                this.$set(this,'amount',this.amount.substr(0,this.amount.length - 1))
            }
        },
        Clean(){
            this.$set(this,'amount','')
        },
        Submit(){
            if(this.type === 'default'){
                this.$emit('input',this.amount ? parseFloat(this.amount) : 0)
                this.Cancel()
            }else{
                this.$emit('change',this.amount ? parseFloat(this.amount) : 0)
                this.Cancel()
            }
        },
    },
    mounted(){

    }
}
</script>