<style lang="scss">
    @import "../../../../style/config.scss";
    .CreditSystemOddsbase {
        .el-button.el-button--mini {
            padding:4px 10px;
        }
        .el-button+.el-button {
            margin-left: 6px;
        }
    }
</style>
<template>
    <div class="CreditSystemOddsbase">
        <div class="ctrl o-mb">
            <span>游戏：</span>
            <el-select v-model="games_id" placeholder="请选择游戏" style="width:160px;" size="small">
                <el-option v-for="(item,index) in Opt.hasDir.game" :key="item.id" :label="item.title" :value="item.id"></el-option>
            </el-select>
            <span class="o-pl-h">分类：</span>
            <el-select v-model="games_play_group_id" placeholder="请选择类别" @change="" :disabled="!group1._init || group1._loading" style="width:130px;" size="small">
                <el-option v-for="(item,index) in group1._list" :key="item.games_play_group_id" :label="item.title" :value="item.games_play_group_id"></el-option>
            </el-select>
            <el-select v-model="Filter.games_play_group_id" placeholder="请选择类别" @change="" :disabled="!group2._init || group2._loading || group1._loading" style="width:130px;" size="small">
                <el-option v-for="(item,index) in group2._list" :key="item.games_play_group_id" :label="item.title" :value="item.games_play_group_id"></el-option>
            </el-select>
        </div>
        <table class="table u-bl u-bb u-br" border="0" cellspacing="1" cellpadding="0" v-loading="main._loading">
            <thead>
                <tr class="hbg-1" style="text-align:left;">
                    <td>名称</td>
                    <td>赔率</td>
                    <td>排序</td>
                    <td>规则说明</td>
                    <td>中奖举例</td>
                    <td>备注</td>
                    <td>状态</td>
                    <td>操作</td>
                </tr>
            </thead>
            <tbody class="zebra" style="text-align:left;">
                <tr class="c-text-c" v-for="(item,index) in main._list">
                    <td>{{ item.name }}</td>
                    <td>{{ item.odds }}</td>
                    <td>{{ item.sort }}</td>
                    <td>{{ TableStrLength(item.rule,40) }}</td>
                    <td>{{ TableStrLength(item.example,40) }}</td>
                    <td>{{ TableStrLength(item.description,40) }}</td>
                    <td>
                        <el-tag v-if="item.status === 1" type="success" size="small">正常</el-tag>
                        <el-tag v-if="item.status === 2" type="danger" size="small">停用</el-tag>
                    </td>
                    <td>
                        <el-button type="work" @click="Edit(item)" size="mini">编辑</el-button>
                        <el-button type="warning" @click="Edit(item,item.name+' > 赔率设置','EditerOdds')" size="mini">赔率设置</el-button>
                        <el-button v-if="item.status === 2" type="info" @click="shelves(item,1)" size="mini">上架</el-button>
                        <el-button v-if="item.status === 1" type="danger" @click="shelves(item,2)" size="mini">下架</el-button>
                    </td>
                </tr>
            </tbody>
        </table>
        <Editer v-model="Editer.view" :form="Editer.form" @finish="Get(Page)"></Editer>
        <EditerOdds v-model="EditerOdds.view" :title="EditerOdds.title" :form="EditerOdds.form" @finish="Get(Page)"></EditerOdds>
    </div>
</template>

<script>
import StoreMix from '@lottery.2.0/mixin/store.js'
import Editer from './model/plays'
import EditerOdds from './model/plays_odds'
export default {
    name: 'CreditSystemOddsbase',
    mixins : [StoreMix],
    data() {
        return {
            store : 'admin/plays',
            Filter : {
                games_play_group_id : null,
                per_page : 100,
            },
            games_id : null,
            games_play_group_id : null,
            EditerOdds : {
                view : false,
                form : null,
            },
        }
    },
    computed: {
        group1(){
            return this.$store.state.admin.games_group_1
        },
        group2(){
            return this.$store.state.admin.games_group_2
        },
    },
    watch:{
        games_id(val){
            if(val){
                this.Dp('admin/_GET_GAMES_GROUP_1',{params:{games_id:val}}).then(res=>{
                    if(res){
                        this.$set(this,'games_play_group_id',res.data[0].games_play_group_id)
                    }
                })
            }
        },
        games_play_group_id(val){
            if(val){
                this.Dp('admin/_GET_GAMES_GROUP_2',{params:{games_id:this.games_id,games_play_group_id:val,is_plays:1}}).then(res=>{
                    if(res){
                        this.$set(this.Filter,'games_play_group_id',res.data[0].games_play_group_id)
                    }
                })
            }
        },
        'Filter.games_play_group_id'(val){
            if(val){
                this.Filter.games_id = this.games_id
                this.Get(1)
            }
        },
    },
    methods: {
        init(){
            this.$set(this,'games_id',31)
        },
        shelves(item,status){
            this.Put({
                ...item,
                status,
            }).then(res=>{
                this.Get(this.Page)
            })
        }, 
    },
    components : {
        Editer,
        EditerOdds,
    },
    mounted(){
        this.init()
    },
}
</script>
