<style lang="scss">
    .OperateLhc-3 {
        h6.title {
            padding:3px 5px; text-align:center; background-color:#f3655e; color:#fff;
        }
    }
</style>
<template>
    <div class="OperateLhc-3">
        <board-fast :client="client" :group="[0]" transmit="six-balls" :radio="true" :limit="limit" :double="has_double_odds" />
        <group :client="client" :list="Table[0]" :length="1" :radio="true" :transmit="false" :double-line="true" show-total="end"@pick="AloneTransfer" />
    </div>
</template>

<script>
import CommonMix from '../common.js'
import Group from '../group_mobile.vue'
import BoardFast from '../fast_mobile.vue'
export default {
    name: 'OperateLhc3',
    mixins: [CommonMix],
    data() {
        return {
            limit: undefined,
            has_double_odds: false
        }
    },
    props: {
        client: {}
    },
    computed: {

    },
    methods: {

    },
    components: {
        Group,
        BoardFast
    }
}
</script>
